import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './contactbutton.scss';

export default class ContactButton extends Component {

    static propTypes = {
        icon : PropTypes.any.isRequired,
        onClick : PropTypes.func.isRequired,
        children : PropTypes.any.isRequired,
        className : PropTypes.string
    }

    render = () => {
        return (
            <div className={`contact-button ${this.props.className ? this.props.className : ''}`} onClick={this.props.onClick}>
                <div className="contact-button-icon">
                    {this.props.icon}
                </div>
                <div className="contact-button-label">
                    {this.props.children}
                </div>
            </div>
        );
    }

}