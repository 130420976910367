

let _export = {};

_export.colors = {
  "primary1" : "#ffed00",
  "primary2": "#03A9F4",
  "secondary1": "#878787",
  "secondary2": "#6b6b6b",
  "secondary3": "#dadada",
  "secondary4": "#f2f2f1",
  "secondary5": "#cccccc",
  "secondary6": "#bbbbbb",
  "secondary7": "#ededed",
  "secondary8": "#333333",
  "green" : "#95c11f",
  "white" : "#FFFFFF",
  "black" : "#000000",
  "cwarning" : "#f39200",
  "cwarning_low" : "#F0DEC5",
  "cerror" : "#e30613",
  "cerror_low" : "#F5CED0",
  "cnormal": "#248a3c"
}
// Let's make all colors available in the root (i.e. $primary1)
for( let key in _export.colors) {
  _export[key] = _export.colors[key];
}

_export.layout = {
  "mobile-max" : "(max-width : 980px)",
  "mobile-min" : "(min-width : 981px)"
}
// Let's make all layout settings available in the root (i.e. $mobile-max)
for( let key in _export.layout) {
  _export[key] = _export.layout[key];
}

_export.fontsizes = {
  "fontsize1" : "16px",
  "fontsize2" : "19px",
  "fontsize3" : "25px",
  "fontsize4" : "32px",
  "fontsize5" : "42px",
}
// Let's make all fontsizes available in the root (i.e. $primary1)
for( let key in _export.fontsizes) {
  _export[key] = _export.fontsizes[key];
}

//module.exports = _export;

export default _export;
