import React, {Component} from 'react';
import PropTypes from 'prop-types';

import ReactDOM from 'react-dom';
import { FormattedMessage } from 'react-intl';
import './calculationresultCustom.scss';
import SectionHeader from 'layout/sectionHeader/SectionHeader';
import {MdExpandMore, MdExpandLess} from "react-icons/md";

export default class CalculationResultCustom extends Component {

    static propTypes = {
        title: PropTypes.node,
        toggle: PropTypes.bool,
        initialToggle: PropTypes.bool
    }

    constructor(props) {
        super(props);

        this.state = {
            toggleActive : props.initialToggle
        }
    }

    toggle = () =>  {
      this.setState({
        toggleActive : !this.state.toggleActive
      })
    }

    render() {

        return (
            <div className="calculation-result">
                <div className="calculation-result-custom-triangle-container">
                    <div className={`calculation-result-custom-triangle calculation-result-custom-triangle-valid`}></div>
                </div>
                <div className="calculation-result-custom-header" onClick={()=>{this.toggle()}}>
                  <SectionHeader>
                    <div className="calculation-result-custom-header-container">
                      <div className={"calculation-result-custom-header-container-title"}>
                        {this.props.title}
                      </div>
                      <If condition={this.props.toggle}>
                        <div className={`calculation-result-custom-header-toggle`}>
                          {this.state.toggleActive ? <MdExpandLess /> : <MdExpandMore/>}
                        </div>
                      </If>
                    </div>
                  </SectionHeader>
                </div>
                <If condition={!this.props.toggle || this.state.toggleActive}>
                  <div className="calculation-result-custom-container">
                    <div className="calculation-result-custom-values">
                      {this.props.children}
                    </div>
                  </div>
                </If>
            </div>
        )
    }
}
