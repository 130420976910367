import React, { Component } from 'react';
import Layout from 'layout/Layout';
import { injectIntl, FormattedMessage, FormattedDate, defineMessages } from 'react-intl';
import { bindActionCreators } from "redux";
import * as UIActions from 'storeState/ui/actions';
import { connect } from "react-redux";
import { menuItems } from 'storeState/ui/constants';
import { getContacts, getContactSuggestions } from 'storeState/data/selectors';
import { getMyLocation, getMapLocation } from 'storeState/ui/selectors';
import ContactButton from './ContactButton';
import ContactMultiButton from './ContactMultiButton';
import {getMapsUrl} from 'service/GeoService';
import SectionHeader from 'layout/sectionHeader/SectionHeader';
import {FaFax as Fax, FaPhone as Phone} from "react-icons/fa";
import {MdDirections as Directions, MdArrowBack as ArrowBack, MdGpsFixed as GPSIcon} from "react-icons/md";
import {GoSearch as SearchIcon} from "react-icons/go";

import {Map as olMap, View as olView, Collection} from 'ol';
import { Icon, Style } from 'ol/style';

import {layer, Feature, geom, source, Map } from 'lib/@map46/ol-react';
import { MapProvider } from 'lib/@map46/ol-react/map-context';
import { CollectionProvider } from 'lib/@map46/ol-react/collection-context';
import { transform } from 'ol/proj'

import { ATTRIBUTION as osmAttribution } from 'ol/source/OSM'
import { fromLonLat } from 'ol/proj';

import MarkerImage from 'assets/images/marker.png';
import _ from 'lodash';

import './contact.scss';

const wgs84 = "EPSG:4326";
const wm = "EPSG:3857";

const messages = defineMessages({
  contact_caption: {
    id: 'contact_caption',
    defaultMessage: 'Contact'
  }
});

const attributions = [
  osmAttribution,
];


class Contact extends Component {

  constructor(props){
    super(props);

    this.state = {
      showSearchField : false,
      searchResults : null,
      initialSearchFieldFocusSet: false,
    };

    const point = this.transform(this.getLongitude(), this.getLatitude());

    this.mapLayers = new Collection();
    this.theMap = new olMap({
      view: new olView({ center: point, zoom: 14}),
      controls: [],
      layers: this.mapLayers,
    });

    this.theMap.updateSize();
  }

  defaultLatitude = 51.81053;
  defaultLongitude = 8.1195238;

  componentDidMount() {
    setTimeout(() => {
      this.props.uiActions.setActiveMenuItem({ item: menuItems.contact })

      this.props.uiActions.setMapLocation({
        latitude: this.defaultLatitude,
        longitude: this.defaultLongitude
      });
    })

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(!prevState.selectedContactSuggestion && this.props.contactSuggestions && this.props.contactSuggestions.length>0) {
      this.handleContactSuggestionClick(this.props.contactSuggestions[0])
    } else {
      if (!prevProps.mapLocation && !prevState.selectedContactSuggestion) {
        if (this.props.contacts) {
          let contactsInOelde = _.filter(this.props.contacts, (c)=>c.city == "Oelde");
          if (contactsInOelde.length > 0) {
            setTimeout(() => {
              prevProps.uiActions.setMapLocation({
                latitude: contactsInOelde[0].latitude,
                longitude: contactsInOelde[0].longitude
              });
            })
          }
        }
      }
    }
  }

  handleGPSClick = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      this.props.uiActions.setMyLocation(position.coords);
    });
  };

  handleSearchClick = () => {
    this.setState({
      showSearchField : true,
      initialSearchFieldFocusSet: false,
    })
  }

  getLatitude = () => {
    let l = this.defaultLatitude;
    if(this.props.mapLocation) {
      l = this.props.mapLocation.latitude;
    }
    return l
  }

  getLongitude = () => {
    let l = this.defaultLongitude;
    if(this.props.mapLocation) {
      l = this.props.mapLocation.longitude;
    }
    return l
  }

  transform = (lon,lat) => {
    const point = fromLonLat([lon,lat]);
    return point;
  }

  handleContactSuggestionClick = (contact) => {
    this.setState({
      selectedContactSuggestion : contact
    });
    this.props.uiActions.setMapLocation(contact);
    const point = this.transform(contact.longitude, contact.latitude);
    this.theMap.getView().setCenter(point);
  }

  handleNavigateButtonClick = () => {
    if(window) {
      window.location = getMapsUrl(this.state.selectedContactSuggestion);
    }
  }

  handleCallButtonClick = () => {
    if(window) {
      window.location = "tel:"+this.state.selectedContactSuggestion.phone;
    }
  }

  handleBackClick = () => {
    this.setState({
      selectedContactSuggestion : null,
      showSearchField : false,
      searchResults : null
    })
    this.props.uiActions.setMyLocation(null);
  }

  search = (search, text) => {
    return _.lowerCase(text).indexOf(_.lowerCase(search))!=-1 || _.toLower(text).indexOf(_.toLower(search))!=-1;
  }

  handleMapRef = () => {
    if(this.theMap) {
      this.theMap.updateSize();
      const point = this.transform(this.getLongitude(), this.getLatitude());
      this.theMap.getView().setZoom(14);
      this.theMap.getView().setCenter(point);
    }
  }

  handleSearch = (ev) => {
    let search = ev.target.value;
    let results = _.filter(this.props.contacts, (contact) => {
      return (
          this.search(search,contact.name)
          || this.search(search,contact.city)
          || this.search(search,contact.country)
          || this.search(search,contact.country_code)
          || this.search(search,contact.zip)
          || this.search(search,contact.street)
          || this.search(search,contact.keywords)
      );
    });

    let selectedContactSuggestion = this.state.selectedContactSuggestion;
    if(!selectedContactSuggestion || results.indexOf(selectedContactSuggestion) == -1) {
      if(results.length>0) {
        this.handleContactSuggestionClick(results[0]);
      }
    }

    this.setState({
      searchResults: results
    })
  }

  renderMarker = (longitude, latitude, index) => {
    var iconPath = MarkerImage;

    const iconStyle = new Style({
      image: new Icon({
        anchor: [0.5, 46],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        src: iconPath,
      }),
    });

    const astoria_wm = transform([longitude, latitude], wgs84,wm);

    return (
        <Feature id={"" + index} key={index} style={iconStyle} >
          <geom.Point transform={ this.transform } >
            {astoria_wm}
          </geom.Point>
        </Feature>
    )
  }

  getShownContacts = () => {
    if(this.props.contactSuggestions && this.props.contactSuggestions.length>0) {
      return this.props.contactSuggestions;
    }

    if(this.state.searchResults) {
      return this.state.searchResults;
    }

    return [];
  }

  getHTTPLink = (link) => {
    if(link && link.indexOf("http")!=0) {
      link = "http://"+link;
    }
    return link;
  }

  render() {

    const { formatMessage } = this.props.intl;

    const point = this.transform(this.getLongitude(), this.getLatitude());

    //console.log("contact suggestions", this.props.contactSuggestions);

    return (
        <Layout caption={formatMessage(messages.contact_caption)}>
          <div className="contact">
            <div> {/*IE FIX*/}
              <SectionHeader><FormattedMessage id="contact_header" defaultMessage="CHOOSE YOUR PARTNER"/></SectionHeader>
            </div>
            <div className={`contact-height-wrapper`}>
              <div className={`contact-background-map ${this.props.contactSuggestions.length>0 ? 'contact-background-map-small' : ''}`}>
                <MapProvider map={this.theMap} >
                  <Map useDefaultControls={false}>
                    <CollectionProvider collection={this.mapLayers}>
                      <layer.Tile title="OSM" opacity={1}> <source.OSM/> </layer.Tile>
                      <layer.Vector name="Display" opacity={0.75} title="OSM-Map">
                        <source.Vector>
                          <For each="contact" of={this.props.contacts} index="index">
                            {this.renderMarker(contact.longitude, contact.latitude, index)}
                          </For>
                        </source.Vector>
                      </layer.Vector>
                    </CollectionProvider>
                  </Map>
                </MapProvider>
                <div className="contact-map-osm-attribution">
                  &copy; OpenStreetMap | <a href="http://www.openstreetmap.org/copyright">Terms</a>
                </div>
              </div>
              <div className="contact-overlay-top">
                <If condition={this.state.selectedContactSuggestion || this.state.showSearchField}>
                  <div className="contact-back-button" onClick={this.handleBackClick}>
                    <ArrowBack />
                  </div>
                </If>
                <If condition={!this.state.selectedContactSuggestion && !this.state.showSearchField}>
                  <div className="contact-main-buttons">
                    <If condition={navigator.geolocation}>
                      <ContactButton
                          className="contact-main-button contact-button-gps"
                          icon={<GPSIcon />}
                          onClick={this.handleGPSClick}
                      >
                        <FormattedMessage id="contact_button_gps" defaultMessage="GPS Locating"  />
                      </ContactButton>
                    </If>
                    <ContactButton
                        className="contact-main-button contact-button-search"
                        icon={<SearchIcon />}
                        onClick={this.handleSearchClick}
                    >
                      <FormattedMessage id="contact_button_search" defaultMessage="Search"  />
                    </ContactButton>
                  </div>
                </If>
                <If condition={this.state.showSearchField}>
                  <div className="contact-search-wrapper">
                    <SearchIcon />
                    <input type="text" onChange={this.handleSearch} className="contact-search-input" ref={
                      (el) => {
                        if(!this.state.initialSearchFieldFocusSet && el){
                          this.setState({initialSearchFieldFocusSet: true});
                          el.focus();
                        }
                      }
                    } />
                  </div>
                </If>
              </div>
              <div className="contact-overlay-bottom-container">
                <div className="contact-overlay-bottom-spacer" />
                <div className="contact-overlay-bottom">
                  <div className="contact-action-button-container">
                    <If condition={this.state.selectedContactSuggestion}>
                      <ContactMultiButton>
                        <ContactButton
                            className="contact-action-button contact-button-call"
                            icon={<Phone />}
                            onClick={this.handleCallButtonClick}
                        >
                          <FormattedMessage id="contact_button_call" defaultMessage="Direct Call"  />
                        </ContactButton>
                        <ContactButton
                            className="contact-action-button contact-button-call"
                            icon={<Directions />}
                            onClick={this.handleNavigateButtonClick}
                        >
                          <FormattedMessage id="contact_button_navigate" defaultMessage="Get Directions"  />
                        </ContactButton>
                      </ContactMultiButton>
                    </If>
                  </div>
                  <If condition={this.getShownContacts().length>0}>
                    <div className={`contact-suggestions-triangle-container ${this.getShownContacts()[0] == this.state.selectedContactSuggestion ? 'contact-suggestions-triangle-container-active' : ''}`}>
                      <div className={`contact-suggestions-triangle`}></div>
                    </div>
                    <div className="contact-suggestions">
                      <For each="contact" of={this.getShownContacts()}>
                        <div key={contact.id} className={`contact-suggestion ${contact == this.state.selectedContactSuggestion ? 'contact-suggestion-selected' : ''}`} onClick={() => this.handleContactSuggestionClick(contact)}>
                          <div className="contact-suggestion-name">{contact.name}</div>
                          <div className="contact-suggestion-country">{contact.country}</div>

                          <div className="contact-suggestion-person">{contact.contact_person}</div>

                          <div className="contact-suggestion-left">
                            <div className="contact-suggestion-street">{contact.street}</div>
                            <div className="contact-suggestion-place">{contact.zip} {contact.city}</div>
                          </div>
                          <div className="contact-suggestion-right">
                            <div className="contact-suggestion-phone">
                              <If condition={contact.phone}>
                                <Phone />&nbsp;
                                <a href={`tel:${contact.phone}`}>{contact.phone}</a>
                              </If>
                            </div>
                            <div className="contact-suggestion-fax">
                              <If condition={contact.fax}>
                                <Fax />&nbsp;
                                {contact.fax}
                              </If>
                            </div>
                          </div>
                          <If condition={contact.www}>
                            <div className="contact-suggestion-website">
                              <a href={this.getHTTPLink(contact.www)}>{contact.www}</a>
                            </div>
                          </If>
                          <If condition={contact.email}>
                            <div className="contact-suggestion-email">
                              <a href={`mailto:${contact.email}`}>{contact.email}</a>
                            </div>
                          </If>
                        </div>
                      </For>
                    </div>
                  </If>
                </div>
              </div>
            </div>
          </div>
        </Layout>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    contacts: getContacts(state),
    contactSuggestions: getContactSuggestions(state),
    mapLocation : getMapLocation(state),
    myLocation : getMyLocation(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(UIActions, dispatch),
  }
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(Contact))
