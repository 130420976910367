import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './collapseButton.scss';
import style from 'style';
import {HiChevronDown, HiChevronUp} from "react-icons/hi";

export default class CollapseButton extends Component {

  static propTypes = {
    startCollapsed: PropTypes.bool,
    onCollapse: PropTypes.func
  };

  static defaultProps = {
    startsOpen: false
  }

  constructor(props) {
    super(props);
    this.state = {
      collapsed: true
    }
  }

  componentDidMount() {
    if (this.props.startsOpen) {
      this.handleCollapse();
    }
  }

  handleCollapse = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      collapsed: !this.state.collapsed
    }, () => {
      if (this.props.onCollapse) this.props.onCollapse(this.state.collapsed);
    });
  }

  render() {
    return (
      <div className={`ci-collapse-button ${!this.state.collapsed ? 'ci-collapse-button-open' : ''}`}
           onClick={this.handleCollapse}>
        <If condition={this.state.collapsed}><HiChevronDown/></If>
        <If condition={!this.state.collapsed}><HiChevronUp/></If>
      </div>
    );
  }

};
