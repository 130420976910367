import * as types from "./constants";

let initState = {
  items: [], //server-data
};

export default function reducer(state = initState, action = "") {
  //console.log("action:",action.type, " payload:", action.payload)
  if(action.type.startsWith(types.PREFIX)){

    switch (action.type) {
      case types.READ_NEWS_REQUEST:
        return {
          ...state,
          loading: {
            ...state.loading,
            news: true
          }
        }
        break;
      case types.READ_NEWS_RESPONSE:
        return {
          ...state,
          items: action.payload.items,
          loading: {
            ...state.loading,
            news: false
          }
        }
        break;
      default:
        return state
        break;
    }
  }

  return state;
}
