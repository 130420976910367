import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {FormattedMessage, injectIntl} from 'react-intl';
import { connect } from "react-redux";
import _ from 'lodash';
import NozzleImage from "components/layout/nozzleImage/NozzleImage";
import colors from 'style'

import 'components/layout/productWizard/productWizardMasterjet.scss';
import SectionHeader from "components/layout/sectionHeader/SectionHeader";
import WizardItem from "components/layout/productWizard/WizardItem";
import ReactDOM from "react-dom";

import MODAL_TYPE from "components/layout/productWizard/masterJetModals/MasterjetModalTypes.constants";
import {getMasterjetConfigurationParams} from "storeState/ui/selectors";
import {getMasterjetProducts} from "storeState/data/selectors";
import {getNozzleArrangement} from "service/MasterJetService";
import {formatValueWithUnit} from "service/MathService";

import WizardNavigation from "components/layout/productWizard/WizardNavigation";
import {MdClose as CloseIcon} from "react-icons/md";

import masterjet_product_application_chart from 'assets/images/new/Produkteinsatz-Schaubild.png';
import masterjet_nozzle_count_chart from "assets/images/new/Duesenanzahl-Schaubild.png";
import masterjet_performance_class_chart from "assets/images/new/Performance-Class-Schaubild.png";
import masterjet_thread_chart from 'assets/images/new/Gewindetyp-Schaubild.png';

import masterjet_nozzle_diameter_same from "assets/images/new/Duesendurchmesser-gleich.png";
import masterjet_nozzle_diameter_different from "assets/images/new/Duesendurchmesser-unterschiedlich.png";
import masterjet_thread_clean from 'assets/images/new/Gewindtyp-M.png';
import masterjet_thread_point from 'assets/images/new/Gewindtyp-A.png';
import masterjet_pressure_3200 from 'assets/images/new/Edelstahl-mit-Rillen.png';
import masterjet_pressure_1800 from 'assets/images/new/Edelstahl-ohne-Rillen.png';
import masterjet_pressure_1000 from 'assets/images/new/Bronze-ohne-Rillen.png';
import masterjet_variablespeed_variable from 'assets/images/new/Drehzahl-variabel.png';
import masterjet_variablespeed_static from 'assets/images/new/Drehzahl-fest.png';

class ModalProduct extends Component {

  static propTypes = {
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  static defaultProps = {

  }

  constructor(props) {
    super(props);

    this.state = {
      svgLines: []
    };
  }

  registerRef = (key, ref) => {
    if(ref) {
      this[key] = ref;
    }

    if(this.state.svgLines.length === 0 && this.infoContainerRef && this.infoMessageRef) {
      const infoContainerDom = ReactDOM.findDOMNode(this.infoContainerRef);
      const infoContainerBounds = infoContainerDom.getBoundingClientRect();
      const infoDom = ReactDOM.findDOMNode(this.infoMessageRef);
      const infoBounds = infoDom.getBoundingClientRect();

      let lines = [];
      lines.push({
        from: {x: 32, y: 4},
        to: {x: 32, y: infoBounds.y - infoContainerBounds.y + infoBounds.height / 2},
        marker: 'start'
      })
      lines.push({
        from: {x: 32, y: infoBounds.y - infoContainerBounds.y + infoBounds.height / 2},
        to: {x: infoBounds.x - infoContainerBounds.x - 8, y: infoBounds.y - infoContainerBounds.y + infoBounds.height / 2}
      })
      lines.push({
        from: {x: infoBounds.x - infoContainerBounds.x + infoBounds.width + 8, y: infoBounds.y - infoContainerBounds.y + infoBounds.height / 2},
        to: {x: infoContainerBounds.width - 32, y: infoBounds.y - infoContainerBounds.y + infoBounds.height / 2},
      })
      lines.push({
        from: {x: infoContainerBounds.width - 32, y: infoBounds.y - infoContainerBounds.y + infoBounds.height / 2},
        to: {x: infoContainerBounds.width - 32, y: infoContainerBounds.height - 4},
        marker: 'end'
      })

      this.setState({
        svgLines: lines,
        svgViewBox: {
          width: infoContainerBounds.width,
          height: infoContainerBounds.height
        }
      })
    }
  }

  hasConfigParams = (params) => {
    let output = true;
    _.each(params, param => {
      const hasParam =this.props.calculationParams[param] !== undefined
        && this.props.calculationParams[param] !== null
        && this.props.calculationParams[param] !== false
      output = output && hasParam;
    })
    return output;
  }

  renderInfoGraphic = () => {
    if(this.state.svgLines && this.state.svgViewBox) {
      return (
        <svg className={`product-wizard-masterjet-modal-item-container-info-svg`} viewBox={`0 0 ${this.state.svgViewBox.width} ${this.state.svgViewBox.height}`}>
          <defs>
            <marker id="arrowhead" markerWidth="18" markerHeight="18" viewBox={`0 0 48 48`}
                    refX="23" refY="24" orient="auto">
              <path style={{fill: colors.secondary1}} d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z"/>
            </marker>
            <marker id="arrowheadreverse" markerWidth="18" markerHeight="18" viewBox={`0 0 48 48`}
                    refX="23" refY="24" orient="auto">
              <path style={{fill: colors.secondary1}} d="M28.05 36 16 23.95 28.05 11.9l2.15 2.15-9.9 9.9 9.9 9.9Z"/>
            </marker>
          </defs>
          <For each="svgLine" of={this.state.svgLines} index="index">
            <line key={`line_2_${index}_${JSON.stringify(svgLine)}`} x1={svgLine.from.x} y1={svgLine.from.y} x2={svgLine.to.x} y2={svgLine.to.y} style={{
              stroke: colors.secondary1,
              strokeWidth: 1
            }} markerEnd={svgLine.marker === 'end' ? 'url(#arrowhead)': null} markerStart={svgLine.marker === 'start' ? 'url(#arrowheadreverse)': null} />
          </For>
        </svg>
      )
    }
  }

  getCurrentProductName = () => {
    let item = null;
    if(this.props.calculationParams?.productId) {
      item = _.find(this.props.masterjetProducts, product => product.id === this.props.calculationParams.productId);
    }
    if(item) {
      return item.name
    } else {
      return <FormattedMessage id="product_wizard_masterjet_choose" defaultMessage="Wählen Sie direkt Ihren Masterjet-Typ"/>
    }
  }

  renderNozzleAmountImage = (renderPC = false) => {

    _.map(Array(this.props.calculationParams.maxNozzleCount), num => {return {type: 'placeholder_active'}})

    const maxNozzle = this.props.calculationParams.maxNozzleCount;
    const nozzleCount = this.props.calculationParams.nozzleCount;
    const nozzleArrangement = getNozzleArrangement(nozzleCount, maxNozzle);
    let items = [];
    for(let i = 0; i < maxNozzle; i++) {
      if(nozzleArrangement.indexOf(i) >= 0) {
        items.push({type: 'placeholder_active'})
      } else {
        items.push({type: 'placeholder_inactive'})
      }
    }

    return (
      <NozzleImage pc={renderPC?this.props.calculationParams.performanceClass:null} style={{backgroundColor: 'transparent', border: `1px solid ${colors.secondary1}`}} items={items} ></NozzleImage>
    )
  }

  renderNozzleAmountItem = () => {

    const maxNozzle = this.props.calculationParams.maxNozzleCount;
    const nozzleCount = this.props.calculationParams.nozzleCount;

    return(
      <WizardItem
        border={true}
        image={this.renderNozzleAmountImage()}
        label={
          <span>{nozzleCount} <FormattedMessage id="product_wizard_masterjet_product_nozzle_count_thead" defaultMessage="Düsen"/> {maxNozzle - nozzleCount} <FormattedMessage id="product_wizard_masterjet_product_nozzle_count_plug" defaultMessage="Stopfen"/></span>
        }
        onClick={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_NOZZLE_AMOUNT)}
      />
    )
  }

  continueWizard = () => {

    let modal = MODAL_TYPE.MODAL_TYPE_PRODUCT_APPLICATION;

    if (this.hasConfigParams(['maxNozzleCount', 'nozzleCount', 'nozzleVariant', 'performanceClass', 'threadType', 'maxPressure'])) {
      modal = MODAL_TYPE.MODAL_TYPE_VARIABLE_SPEED;
    } else if (this.hasConfigParams(['maxNozzleCount', 'nozzleCount', 'nozzleVariant', 'performanceClass', 'threadType'])) {
      modal = MODAL_TYPE.MODAL_TYPE_PRESSURE;
    } else if (this.hasConfigParams(['maxNozzleCount', 'nozzleCount', 'nozzleVariant', 'performanceClass'])) {
      modal = MODAL_TYPE.MODAL_TYPE_THREAD;
    } else if (this.hasConfigParams(['maxNozzleCount', 'nozzleCount', 'nozzleVariant'])) {
      modal = MODAL_TYPE.MODAL_TYPE_PERFORMANCE_CLASS;
    } else if (this.hasConfigParams(['maxNozzleCount', 'nozzleCount'])) {
    } else if (this.hasConfigParams(['maxNozzleCount'])) {
      modal = MODAL_TYPE.MODAL_TYPE_NOZZLE_AMOUNT;
    } else if(this.props.calculationParams.productType) {
      modal = MODAL_TYPE.MODAL_TYPE_NOZZLE_COUNT;
    }

    this.props.openModal(modal)
  }

  threadTypeHasPoint = () => {
    if (this.props.calculationParams.threadType !== false) {
      return this.props.calculationParams.threadType === "A";
    }
  }

  render() {
    return (
      <div className="product-wizard-masterjet-modal-item-container">

        <SectionHeader style={{padding: 0}}>
          <div className="product-wizard-masterjet-modal-item-section-row">
            <div className={"product-wizard-masterjet-modal-item-section-row-icon"} onClick={()=>this.props.closeModal()}>
              <CloseIcon></CloseIcon>
            </div>
            <div className={"product-wizard-masterjet-modal-item-section-row-label"}>
              <FormattedMessage id="product_wizard_masterjet_product" defaultMessage="Welches Produkt haben Sie?"/>
            </div>
          </div>
        </SectionHeader>

        <WizardItem label={this.getCurrentProductName()} onClick={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_PRODUCT_CHOOSE)} />
        <div className={"product-wizard-masterjet-modal-item-container-info"} ref={ref => this.registerRef("infoContainerRef", ref)} type={"simple"}>
          <WizardItem label={
            <div className={"product-wizard-masterjet-modal-item-container-info-item"} style={{textAlign: 'center'}}>
              <div style={{display: 'inline'}} ref={ref => this.registerRef("infoMessageRef", ref)}>
                <FormattedMessage id="product_wizard_masterjet_product_info" defaultMessage="Direktauswahl oder Produktfinder"/>
              </div>
            </div>
          } />
          {this.renderInfoGraphic()}
        </div>
        <div style={{marginTop: 16}}></div>
        <If condition={!this.props.calculationParams.productType}>
          <WizardNavigation
            type={"full"}
            onNextLabel={<FormattedMessage id="product_wizard_masterjet_product_wizard_start" defaultMessage="Produktfinder starten"/>}
            onNext={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_PRODUCT_APPLICATION)}
          />
        </If>

        <If condition={this.props.calculationParams.productType}>
          <SectionHeader><FormattedMessage id="product_wizard_masterjet_product_application" defaultMessage="Produkteinsatz"/></SectionHeader>
          <div className={"product-wizard-masterjet-modal-item-image product-wizard-masterjet-modal-item-image-fullwidth"}>
            <img src={masterjet_product_application_chart} />
          </div>
          <WizardItem border={true} label={<FormattedMessage id="product_wizard_masterjet_product_application_info" defaultMessage="Woran möchten sie die Düse betreiben?"/>} />
          <WizardItem label={
            <If condition={this.props.calculationParams.productType === "manual"}>
              <FormattedMessage id="product_wizard_masterjet_product_application_manual" defaultMessage="Wird per Hand betrieben"/>
            <Else />
              <FormattedMessage id="product_wizard_masterjet_product_application_not_maual" defaultMessage="Wird nicht per Hand betrieben"/>
            </If>
          } onClick={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_PRODUCT_APPLICATION)} />
        </If>

        <If condition={this.hasConfigParams(['maxNozzleCount'])}>
          <SectionHeader><FormattedMessage id="product_wizard_masterjet_nozzle_image" defaultMessage="Düsenbild"/></SectionHeader>
          <div className={"product-wizard-masterjet-modal-item-image product-wizard-masterjet-modal-item-image-fullwidth"}>
            <img src={masterjet_nozzle_count_chart} />
          </div>
          <WizardItem border={true} label={<FormattedMessage id="product_wizard_masterjet_product_nozzle_opening" defaultMessage="Verfügbare Düsen"/>} />
          <WizardItem
            border={true}
            image={
              <NozzleImage style={{backgroundColor: 'transparent', border: `1px solid ${colors.secondary1}`}} items={_.map(Array(this.props.calculationParams.maxNozzleCount), num => {return {type: 'placeholder_active'}})} ></NozzleImage>
            }
            label={<span>{this.props.calculationParams.maxNozzleCount} <FormattedMessage id="product_wizard_masterjet_product_nozzle_opening" defaultMessage="Verfügbare Düsen"/></span>}
            onClick={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_NOZZLE_COUNT)}
          />
        </If>

        <If condition={this.hasConfigParams(['maxNozzleCount', 'nozzleCount'])}>
          <WizardItem border={true} label={<FormattedMessage id="product_wizard_masterjet_product_nozzle_max_count" defaultMessage="Wie viele Düsen wollen Sie einsetzen?"/>} />
          {this.renderNozzleAmountItem()}
        </If>

        <If condition={this.hasConfigParams(['maxNozzleCount', 'nozzleCount', 'nozzleVariant'])}>
          <WizardItem border={true} label={<FormattedMessage id="product_wizard_masterjet_product_nozzle_diameter" defaultMessage="Sind einheitliche Düsendurchmesser okay?"/>} />
          <WizardItem
            border={true}
            image={
              <div className={"product-wizard-masterjet-modal-item-image-large"}>
                <If condition={this.props.calculationParams?.nozzleVariant === "equal"}>
                  <img src={masterjet_nozzle_diameter_same} />
                <Else />
                  <img src={masterjet_nozzle_diameter_different} />
                </If>
              </div>
            }
            label={
              <If condition={!this.props.calculationParams?.nozzleVariant}>
                <FormattedMessage id="product_wizard_masterjet_product_nozzle_opening_identical" defaultMessage="Sind alle gleich groß?"/>
              <Else />
                <If condition={this.props.calculationParams?.nozzleVariant === "equal"}>
                  <FormattedMessage id="product_wizard_masterjet_nozzle_diameter_same"
                                    defaultMessage="Gleiche Düsendurchmesser"/>
                <Else />
                  <FormattedMessage id="product_wizard_masterjet_nozzle_diameter_different"
                                    defaultMessage="Verschiedene Düsendurchmesser"/>
                </If>
              </If>
            }
            onClick={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_NOZZLE_DIAMETER)}
          />
        </If>

        <If condition={this.hasConfigParams(['performanceClass'])}>
          <SectionHeader><FormattedMessage id="product_wizard_masterjet_performance_class" defaultMessage="Performance Class"/></SectionHeader>
          <div className={"product-wizard-masterjet-modal-item-image product-wizard-masterjet-modal-item-image-fullwidth"}>
            <img src={masterjet_performance_class_chart} />
          </div>
          <WizardItem border={true} label={<FormattedMessage id="product_wizard_masterjet_performance_class_points" defaultMessage="Wie viele Punkte hat der Düsenhalter?"/>} />
          <WizardItem
            border={true}
            image={this.renderNozzleAmountImage(true)}
            label={
              <span>
                <FormattedMessage id="product_wizard_masterjet_performance_class_points_pc" defaultMessage="PC"/> {(this.props.calculationParams.performanceClass === 0 || this.props.calculationParams.performanceClass === "0" ) ? "LOW FLOW" : this.props.calculationParams.performanceClass} <FormattedMessage id="product_wizard_masterjet_performance_class_points_nozzle" defaultMessage="Düse"/>
              </span>
            }
            onClick={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_PERFORMANCE_CLASS)} />
        </If>

        <If condition={this.hasConfigParams(['threadType'])}>
          <SectionHeader><FormattedMessage id="product_wizard_masterjet_connection_thread" defaultMessage="Anschlussgewinde"/></SectionHeader>
          <div className={"product-wizard-masterjet-modal-item-image product-wizard-masterjet-modal-item-image-fullwidth"}>
            <img src={masterjet_thread_chart} />
          </div>
          <WizardItem border={true} label={<FormattedMessage id="product_wizard_masterjet_connection_thread_metrical" defaultMessage="Ist das Anschlussgewinde Metrisch?"/>} />
          <WizardItem
            border={true}
            image={
              <div className={"product-wizard-masterjet-modal-item-image-large"}>
                <If condition={this.threadTypeHasPoint() === false}>
                  <img src={masterjet_thread_clean} />
                  <Else />
                  <img src={masterjet_thread_point} />
                </If>
              </div>
            }
            label={
              <If condition={this.threadTypeHasPoint() === false}>
                <FormattedMessage id="product_wizard_masterjet_connection_thread_metrical_yes" defaultMessage="Ja (M)"/>
                <Else />
                <FormattedMessage id="product_wizard_masterjet_connection_thread_metrical_no" defaultMessage="Nein (A)"/>
              </If>
            }
            onClick={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_THREAD)}
          />
        </If>

        <If condition={this.hasConfigParams(['threadType', 'maxPressure'])}>
          <WizardItem border={true} label={<FormattedMessage id="product_wizard_masterjet_connection_thread_notches" defaultMessage="Wie soll der Sechskant am Anschlussgewinde aussehen?"/>} />
          <WizardItem
            border={true}
            image={
              <div className={"product-wizard-masterjet-modal-item-image-large"}>
                <If condition={this.props.calculationParams.maxPressure >= 3200}>
                  <img src={masterjet_pressure_3200} />
                <Else />
                  <If condition={this.props.calculationParams.maxPressure >= 1800}>
                    <img src={masterjet_pressure_1800} />
                  <Else />
                    <img src={masterjet_pressure_1000} />
                  </If>
                </If>
              </div>
            }
            label={
              <span>{formatValueWithUnit({ valueWithUnit: this.props.calculationParams.maxPressure+" bar", si_unit: "bar", us_unit: "psi", precision: 0 })} <FormattedMessage id="product_wizard_masterjet_connection_thread_variant" defaultMessage="Variante"/></span>
            }
            onClick={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_PRESSURE)} />
        </If>

        <If condition={this.hasConfigParams(['threadType', 'maxPressure', 'variableSpeed'])}>
          <WizardItem border={true} label={<FormattedMessage id="product_wizard_masterjet_variableSpeed_hint" defaultMessage="Können Sie die Drehzahl einstellen?"/>} />
          <WizardItem
            border={true}
            image={
              <div className={"product-wizard-masterjet-modal-item-image-large"}>
                <If condition={this.props.calculationParams?.variableSpeed === "1.0"}>
                  <img src={masterjet_variablespeed_variable} />
                <Else />
                  <img src={masterjet_variablespeed_static} />
                </If>
              </div>
            }
            label={
              <span>
                <If condition={this.props.calculationParams?.variableSpeed === "1.0"}>
                  <FormattedMessage id="product_wizard_masterjet_variableSpeed_variable" defaultMessage="Einstellbar"/>
                <Else />
                  <FormattedMessage id="product_wizard_masterjet_variableSpeed_static" defaultMessage="Fest"/>
                </If>
              </span>
            }
            onClick={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_VARIABLE_SPEED)} />
        </If>

        <If condition={this.props.calculationParams.productType}>
          <WizardNavigation
            type={"full"}
            onNextLabel={<FormattedMessage id="product_wizard_masterjet_product_wizard_continue" defaultMessage="Produktfinder fortsetzen"/>}
            onNext={()=>this.continueWizard()}
          />
        </If>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const calculationParams = getMasterjetConfigurationParams(state);
  const masterjetProducts = getMasterjetProducts(state);

  return {
    masterjetProducts: masterjetProducts,
    calculationParams: calculationParams || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {

  }
}

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalProduct))
