import { takeLatest } from 'redux-saga/effects';
import { all, put } from 'redux-saga/effects';
import * as types from './constants';
import * as actions from './actions';

import { reverse } from 'service/GeoService';

function* geocodePosition(action) {

  if(action.payload && action.payload.coords) {
    let {err, result} = yield reverse({
      lat: action.payload.coords.latitude,
      lon: action.payload.coords.longitude
    });
    if (!err) {
      yield put(actions.setReverseGeocodeResult(result));
    } else {
      yield put(actions.setReverseGeocodeResult(null));
    }
  } else {
    yield put(actions.setReverseGeocodeResult(null));
  }
    // {
    //  address: {...},
    //  display_name: "22, Golestan, Iran"
    //  lat: "36.9631102"
    //  lon: "54.9534786"
    //  osm_id: "196174062"
    //  ...
    // }

}

function* saga() {
  yield all([
    function* (){yield takeLatest(types.SET_MY_LOCATION, geocodePosition)}()
  ])
}

export default saga;
