import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LoadingIndicator from 'layout/loadingIndicator/LoadingIndicator';
import * as SplashAction from 'storeState/splash/actions';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {getLocale} from 'storeState/ui/selectors';

import './splash.scss';

class Splash extends Component {

    static propTypes = {
        logo: PropTypes.string,
        title: PropTypes.string,
        backgroundColor: PropTypes.string,
        textColor: PropTypes.string,
        indicatorColor: PropTypes.string,
        hideSplashTimeout: PropTypes.number,
    };

    static defaultProps = {
        logo: null,
        title: null,
        indicatorColor: "black",
        hideSplashTimeout: 10000,
    }

    constructor(props){
        super(props);
    }

    componentDidMount() {
        const {locale} = this.props;
        this.props.splashAction.loadAndNavigate(locale);
        setTimeout(()=>{
          this.props.splashAction.disableSplash();
        }, this.props.hideSplashTimeout);
    }

    render() {

        const {logo, title, backgroundColor, textColor, indicatorColor} = this.props;

        return (
            <div className={`splash-container`} style={{backgroundColor: backgroundColor, color: textColor, display: 'flex'}}>
                <If condition={logo}>
                    <div className={`splash-logo`}>
                        <img src={logo}/>
                    </div>
                </If>
                <If condition={title}>
                    <div className={`splash-title`}>
                        {title}
                    </div>
                </If>
                <LoadingIndicator className={`splash-loading-indicator`} colorName={indicatorColor} />
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
      locale: getLocale(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        splashAction: bindActionCreators(SplashAction, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Splash)
