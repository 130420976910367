import { takeEvery, takeLatest } from 'redux-saga/effects';
import { all, take, put } from 'redux-saga/effects';

import * as types from './constants';
import * as dataActions from './../data/actions';
import * as dataConstants from './../data/constants';
import * as newsActions from './../news/actions';
import * as newsConstants from './../news/constants';
import * as splashActions from './../splash/actions';

function* loadAndNavigate(action) {
    yield put(dataActions.readDataRequest());
    yield take(dataConstants.READ_DATA_RESPONSE);
    yield put(newsActions.readNewsRequest({language: action.payload.language}));
    yield take(newsConstants.READ_NEWS_RESPONSE);
    yield put(splashActions.disableSplash());
}

function* saga() {
    yield all([
        function* (){yield takeLatest(types.LOAD_AND_NAVIGATE, loadAndNavigate)}()
    ])
}

export default saga;
