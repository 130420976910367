import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { slide as Menu } from 'react-burger-menu';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UIActions from 'storeState/ui/actions';
import {isIE, isFF} from 'service/LayoutService';

import {Link} from 'react-router-dom';
//import Radium from 'radium';

import {MdMenu as IconMenu, MdClose as IconClose} from "react-icons/md";

import logo from 'assets/images/logo.jpg';

import './sidemenu.scss';
const isAnyIE = isIE();
const isAnyFF = isFF();
//const RadiumLink = Radium(Link);

const messages = defineMessages({
  sidemenu_hamelmann_url: {
    id: 'sidemenu_hamelmann_url',
    defaultMessage: 'http://www.hammelmann.de/us/'
  },
  sidemenu_masterjet_configuration: {
    id: 'sidemenu.masterjet_configuration',
    defaultMessage: 'Masterjet konfigurieren'
  }
});

class Sidemenu extends Component {

  static propTypes = {
    hidden: PropTypes.bool,
    iosStatusbar: PropTypes.bool,
  };

  static defaultProps = {
    hidden: false,
  }

  constructor(props){
    super(props);
    this.state={
      triggerClose: false,
    }
  }

  renderMenuLink = (to, id) => {

    const { formatMessage } = this.props.intl;

    let message = "";
    if(messages[id]) {
      message = formatMessage(messages[id]);
    } else {
      message = <FormattedMessage id={id} />
    }

    return (
      <Link onClick={()=>{
        this.setState({triggerClose:true});
      }} className="menu-item" to={to}>
        {message}
      </Link>
    )
  }

  render() {

    const { formatMessage } = this.props.intl;
    const {hidden, iosStatusbar, initialMenuOpened} = this.props;
    const {triggerClose} = this.state;

    if(hidden){
      return null;
    }

    const isOpen = triggerClose?false:initialMenuOpened;
    return (
      <Menu
        key={`sidemenu_open_${isOpen}`}
        isOpen={isOpen}
        customBurgerIcon={ <div className={`menu-icon-open`}><IconMenu/></div> }
        customCrossIcon={ <div className={`menu-icon-close`}><IconClose/></div> }
        styles={{
          bmBurgerButton: iosStatusbar?{}:{
            top: '6px'
          },
          bmCrossButton: iosStatusbar?{}:{
            top: '6px'
          },
          bmItemList: {
            height: 'auto',
            minHeight: '100%',
          }
        }}
      >
        <div className={`menu-logo-container`}>
          <a target="_blank" rel="noopener external" href={formatMessage(messages.sidemenu_hamelmann_url)}><img src={logo} className={`logo-menu`}/></a>
        </div>
        <div className={`menu-link-container`}>
          {this.renderMenuLink("/nozzlecalculation", "sidemenu.nozzle_calculation")}
          {this.renderMenuLink("/flowrate", "sidemenu.flow_rate")}
          {this.renderMenuLink("/tankcleaningunit", "sidemenu.tank_cleaning_unit")}
          {this.renderMenuLink("/masterjetconfiguration", "sidemenu.masterjet_configuration")}
          {this.renderMenuLink("/conversion", "sidemenu.conversion")}
          <div className={`menu-spacer1`}></div>
          {this.renderMenuLink("/properties", "sidemenu.properties")}
          <div className={`menu-spacer2`}></div>
          {this.renderMenuLink("/news", "sidemenu.news")}
          {this.renderMenuLink("/contact", "sidemenu.contact")}
          {this.renderMenuLink("/impress", "sidemenu.imprint")}
          {this.renderMenuLink("/disclaimer", "sidemenu.disclaimer")}
          {null/*
          <RadiumLink onClick={()=>this.setState({triggerClose:true})} className="menu-item-disclaimer" to="/disclaimer">
            <FormattedMessage id="sidemenu.disclaimer" defaultMessage="Disclaimer" />
          </RadiumLink>
          */}
        </div>
      </Menu>
    );
  }
}

function mapStateToProps(state, props) {
    return {
        initialMenuOpened: state.ui.initialMenuOpened,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(UIActions, dispatch),
    }
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(Sidemenu))
