import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class RouteContainer extends Component {

    static propTypes = {
        onUpdate: PropTypes.func
    };

    static defaultProps = {
        onUpdate: (prevLocation, location) => {}
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.props.onUpdate(prevProps.location, this.props.location);
    }

    render() {
        return this.props.children;
    }
}

