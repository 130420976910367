export const nozzleArrangement = {
  4: {
    2: [1,3],
    4: [0,1,2,3]
  },
  5: {
    5: [0,1,2,3,4]
  },
  6: {
    2: [0,3],
    3: [1,3,5],
    4: [1,2,4,5],
    6: [0,1,2,3,4,5],
  }
}

export const getNozzleArrangement = (nozzleCount, maxNozzleCount) => {
  let nc = parseInt(nozzleCount);
  let mnc = parseInt(maxNozzleCount);
  let arrangement = [];
  if(nozzleArrangement[mnc] && nozzleArrangement[mnc][nc]) {
    arrangement = nozzleArrangement[mnc][nc];
  }
  return arrangement;
}
