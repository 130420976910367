import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {FormattedMessage, injectIntl} from 'react-intl';
import { connect } from "react-redux";
import _ from 'lodash';
import NozzleImage from "components/layout/nozzleImage/NozzleImage";
import colors from 'style'

import 'components/layout/productWizard/productWizardMasterjet.scss';
import SectionHeader from "components/layout/sectionHeader/SectionHeader";
import WizardItem from "components/layout/productWizard/WizardItem";

import MODAL_TYPE from "components/layout/productWizard/masterJetModals/MasterjetModalTypes.constants";
import {MdClose as CloseIcon} from "react-icons/md";
import WizardNavigation from "components/layout/productWizard/WizardNavigation";
import {bindActionCreators} from "redux";
import {getMasterjetConfigurationParams} from "storeState/ui/selectors";
import * as UIActions from 'storeState/ui/actions';
import {MASTERJET_CONFIGURATION_PARAMETERS} from "components/views/masterjetConfiguration/MasterjetConfiguration";
import {getNozzleArrangement} from "service/MasterJetService";

class ModalNozzleAmount extends Component {

  static propTypes = {
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    filteredProducts: PropTypes.array
  }

  static defaultProps = {
    filteredProducts: []
  }

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  setValue = (value) => {

    let newParams = {
      nozzleCount: value
    };

    if(this.props.calculationParams.nozzleCount !== value) {
      // reset
      newParams.productId = false;
      newParams.nozzleVariant = false;
      newParams.performanceClass = false;
      newParams.threadType = false;
      newParams.maxPressure = false;
    }

    this.props.uiActions.setCalculationParameters({
      key : MASTERJET_CONFIGURATION_PARAMETERS,
      params : newParams
    });

    // this.props.openModal(MODAL_TYPE.MODAL_TYPE_NOZZLE_DIAMETER);
  }

  getItems = () => {
    let numnoz_allowed = [];
    _.each(this.props.filteredProducts, product => {
      numnoz_allowed.push(..._.map(_.keys(product.dataunits.numnoz_to_alpha_avg.value), val => parseInt(val)));
    })
    numnoz_allowed = numnoz_allowed.filter(val => !isNaN(val))
    numnoz_allowed = _.sortBy(_.uniq(numnoz_allowed), val=>val);

    let output = [];
    _.each(numnoz_allowed, numNoz => {

      const maxNozzle = this.props.calculationParams.maxNozzleCount;
      const nozzleArrangement = getNozzleArrangement(numNoz, maxNozzle);
      let items = [];
      for(let i = 0; i < maxNozzle; i++) {
        if(nozzleArrangement.indexOf(i) >= 0) {
          items.push({type: 'placeholder_active'})
        } else {
          items.push({type: 'placeholder_inactive'})
        }
      }

      output.push(
        <WizardItem
          key={`ModalNozzleCount_num_noz_${numNoz}`}
          type={"simple"}
          border={true}
          image={<NozzleImage size={60} style={{border: `1px solid ${colors.black}`}} items={items} ></NozzleImage>}
          label={<div className={"product-wizard-masterjet-table-modal-item-label"}>{numNoz} <FormattedMessage id={"product_wizard_masterjet_product_nozzle_count_label"} defaultMessage={"Düsen"} /></div>}
          onClick={()=>this.setValue(numNoz)}
          isActive={this.props.calculationParams?.nozzleCount === numNoz}
        />
      )
    })

    return output;
  }

  render() {

    return (
      <div className="product-wizard-masterjet-modal-item-container">
        <SectionHeader style={{padding: 0}}>
          <div className="product-wizard-masterjet-modal-item-section-row">
            <div className={"product-wizard-masterjet-modal-item-section-row-icon"} onClick={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_PRODUCT)}>
              <CloseIcon></CloseIcon>
            </div>
            <div className={"product-wizard-masterjet-modal-item-section-row-label"}>
              <FormattedMessage id="product_wizard_masterjet_nozzle_amount" defaultMessage="Düsenmenge"/>
            </div>
          </div>
        </SectionHeader>

        <WizardNavigation
          onPrev={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_NOZZLE_COUNT)}
          onNext={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_NOZZLE_DIAMETER)}
          onNextDisabled={!this.props.calculationParams.nozzleCount}
        />
        <div className={"product-wizard-item-collection"}>
          {this.getItems()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    calculationParams: getMasterjetConfigurationParams(state) || {}
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(UIActions, dispatch),
  }
}

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalNozzleAmount))
