import en from './en';
import de from './de';
import bg from './bg';
import da from './da';
import el from './el';
import es from './es';
import fa from './fa';
import fi from './fi';
import fr from './fr';
import he from './he';
import hi from './hi';
import hu from './hu';
import it from './it';
import jp from './jp';
import ko from './ko';
import nl from './nl';
import no from './no';
import pl from './pl';
import pt from './pt';
import rm from './rm';
import ru from './ru';
import sv from './sv';
import th from './th';
import tr from './tr';
import uk from './uk';
import vi from './vi';
import zh from './zh';

const locales = {
    en: en,
    de: de,
    bg: bg,
    da: da,
    el: el,
    es: es,
    fa: fa,
    fi: fi,
    fr: fr,
    he: he,
    hi: hi,
    hu: hu,
    it: it,
    jp: jp,
    ko: ko,
    nl: nl,
    no: no,
    pl: pl,
    pt: pt,
    rm: rm,
    ru: ru,
    sv: sv,
    th: th,
    tr: tr,
    uk: uk,
    vi: vi,
    zh: zh,
};

export default locales;
