if (typeof process === 'undefined'){
  window.process = {}
}
var path = require('path');

export default {
  apiUrl: process.env.REACT_APP_API_URL || 'https://www.water-jetting-calculator.com/api',
  uploadUrl: process.env.REACT_APP_UPLOADS_URL || 'https://www.water-jetting-calculator.com/uploads',
  appSrc: path.resolve(__dirname, "..")
}
