import React, {Component} from 'react';
import PropTypes from 'prop-types';

import DataUnitLabel from 'layout/dataUnitLabel/DataUnitLabel';
import DataUnitTextField from 'layout/dataUnitTextField/DataUnitTextField';
import { connect } from "react-redux";
import { getAquamats } from "storeState/data/selectors";
import clone from 'lodash/clone';
import ModalSelector from 'layout/modalSelector/ModalSelector';
import {getUploadUrl} from 'service/ApiService';
import AsyncImage from 'layout/asyncImage/AsyncImage';
import {injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import _ from 'lodash';

import './aquamatselector.scss';
import {formatValueWithUnitAndLabel} from "../../../service/MathService";

const messages = defineMessages({
  aquamat_selector_op_pressure: {
    id: 'aquamat_selector_op_pressure',
    defaultMessage: 'Op. Pressure'
  }
});

class AquamatSelector extends Component {

    static propTypes = {
        onChange : PropTypes.func,
        unit: PropTypes.object,
        pressure: PropTypes.string,
        nozzle_holder: PropTypes.object,
        units: PropTypes.array,
    }

    constructor(props) {
        super(props);
        this.state = {
            selectionModalOpen: false
        }
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.unit != this.props.unit) {
            let unit = this.props.unit;
            setTimeout(() => {
                this.handleUnitSelect(unit)
            })
        }
    }

    handleUnitSelect = (newUnit) => {
        this.setState({
            selectionModalOpen : false
        })
        if(this.props.onChange) {
            this.props.onChange({
                unit: newUnit,
            });
        }
    }

    handleValueChange = (field,value) => {
        let change = {};
        change[field] = value;
        this.props.onChange(change);
    }

    handlePressureChange = (value) => this.handleValueChange("pressure", value);
    handleNozzleHolderChange = (value) => this.handleValueChange("nozzle_holder", value);

    renderAquamatModalItemRow = (item, selected) => {
        const {unit} = this.state;
        return (
          <div>
            <div key={item.id} className={`aquamat-modal-item ${unit && unit.id == item.id?'selected':''}`}>
                <div className="aquamat-modal-item-image">
                    <AsyncImage src={item.image} />
                </div>
                <div className="aquamat-modal-item-name">
                    <span className={`aquamat-modal-item-name-title ${selected?'selected':''}`}>{item.name}</span>
                    <div className="aquamat-modal-item-size">
                        <div className="aquamat-modal-item-size-row">
                            <FormattedMessage id="aquamat-model-item-height" defaultMessage="Height" />
                            <DataUnitTextField align="left" value={item.dataunits.height.value_with_unit} si="mm" us="in" />
                        </div>
                        <div className="aquamat-modal-item-size-row">
                            <FormattedMessage id="aquamat-model-item-width" defaultMessage="Width" />
                            <DataUnitTextField align="left" value={item.dataunits.width.value_with_unit} si="mm" us="in" />
                        </div>
                        <div className="aquamat-modal-item-size-row">
                            <FormattedMessage id="aquamat-model-item-length" defaultMessage="Length" />
                            <DataUnitTextField align="left" value={item.dataunits.length.value_with_unit} si="mm" us="in" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='aquamat-modal-row-spacer'/>
          </div>
        );
    }

    renderAquamatModalSelectedItem = (item) => {
        return (
            <div>
                <div className="aquamat-selector-row-label">
                    <FormattedMessage id="aquamat_selector_label" defaultMessage="Aquamat" />
                </div>
                <div className="aquamat-selector-row-value">
                    <If condition={item}>
                        {item.name}
                    <Else />
                        <FormattedMessage id="aquamat_selector_please_select" defaultMessage="Please select" />
                    </If>
                </div>
            </div>
        );
    }

    renderNozzleHolderModalItemRow = (item) => {
      const {nozzle_holder} = this.props;

      if(item){
        return (
          <div>
            <div key={'nzh_'+item.id} className={`aquamat-modal-nozzle-holder-item aquamat-modal-nozzle-holder-item-small ${nozzle_holder && nozzle_holder.id==item.id?'selected':''}`}>
                <span className={"aquamat-modal-nozzle-holder-item-image"}>
                    <AsyncImage src={item.image} />
                </span>
                <span className="aquamat-modal-nozzle-holder-item-name">
                    {item.dataunits.displayName.value} -&nbsp;
                    <FormattedMessage id="aquamat_selector_nozzle_holder_max_pressure" defaultMessage="Max: {pressure}" values={{
                        pressure: formatValueWithUnitAndLabel({ valueWithUnit: item.dataunits.pNZHMax.value_with_unit, si_unit: "bar", us_unit: "psi", precision: 0 })
                    }} />
                </span>
            </div>
            <div className='aquamat-modal-row-spacer'/>
          </div>
        );
      }else{
        return null;
      }
    }

    renderNozzleHolderModalSelectedItem = (item) => {
        return (
            <div>
                <div className="aquamat-selector-row-label">
                    <FormattedMessage id="unit_selector_nozzle_holder" defaultMessage="Nozzle Holder" />
                </div>
                <div className="aquamat-selector-row-value">
                    <If condition={item}>
                        {item.dataunits.displayName.value}
                    <Else />
                        <FormattedMessage id="aquamat_selector_please_select" defaultMessage="Please select" />
                    </If>
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(!_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state)) {
            return true;
        }
        return false;
    }

    render() {
        const {pressure, nozzle_holder, unit, units} = this.props;
        const { formatMessage } = this.props.intl;
        const ceRenderObj = new Object();

        let aquamatImage = null;
        if(unit){
          aquamatImage = _.find(units, un => {return un.id == unit.id}).image;
        }

        return (
            <div className='aquamat-modal'>
              <div className='aquamat-modal-item-inner'>
                <If condition={aquamatImage}>
                  <AsyncImage className="aquamat-modal-image" src={aquamatImage} />
                </If>
                <div className="aquamat-modal-item-content">
                  <div className="aquamat-selector-row aquamat-selector-row-unit">
                    <div className='aquamat-modal-selector-container'>
                      <ModalSelector
                          items={units}
                          selectedItem={unit}
                          onChange={this.handleUnitSelect}
                          renderModalItemRow={this.renderAquamatModalItemRow}
                          renderSelectedItem={this.renderAquamatModalSelectedItem}
                          renderModalNoItemsRow={() => <span />}
                      />
                    </div>
                  </div>
                  <div className="aquamat-selector-row">
                      <div className="aquamat-selector-row-label">
                          <DataUnitLabel si={formatMessage(messages.aquamat_selector_op_pressure)} />
                      </div>
                      <div className="aquamat-selector-row-value">
                          <DataUnitTextField align="right" renderObj={ceRenderObj} value={pressure} si="bar" us="psi" precision={2} editable={true} onChange={this.handlePressureChange} />
                      </div>
                  </div>
                  <If condition={unit}>
                      <div className="aquamat-selector-row aquamat-selector-row-nozzle-holder">
                        <div className='aquamat-modal-selector-container'>
                          <ModalSelector
                              items={unit.NZHs}
                              selectedItem={nozzle_holder}
                              onChange={this.handleNozzleHolderChange}
                              renderModalItemRow={this.renderNozzleHolderModalItemRow}
                              renderSelectedItem={this.renderNozzleHolderModalSelectedItem}
                              renderModalNoItemsRow={() => <span />}
                              getItemKey={(item) => item.id}
                          />
                        </div>
                      </div>
                  </If>
                </div>
              </div>
            </div>
        );
    }
}


export default injectIntl(AquamatSelector)
