import React from 'react';
import _ from 'lodash';
import store from '../initStore';
import { dataUnitSystems } from 'storeState/data/constants';
import 'decimal.js';

function getMathJSInstance() {
    var core = require('mathjs/core');
    var math = core.create();

    math.import(require('mathjs/lib'));

    math.createUnit('mbar', '0.001 bar');
    math.createUnit('ftlb', '1.35581794884 N');
    math.createUnit('Nm', '0.737562149 ftlb');


    return math;
}

const math = getMathJSInstance();

const formatOptions = {
    notation: 'fixed',
    precision: 16
}


export function formatValueWithUnitAndLabel({valueWithUnit, si_unit, us_unit, si_label, us_label, precision}) {
    us_unit = us_unit ? us_unit : si_unit;
    us_label = us_label ? us_label : us_unit;
    si_label = si_label ? si_label : si_unit;
    if(store.getState().data.currentDataUnitSystem == dataUnitSystems.si) {
        return (<span style={{whiteSpace: "nowrap"}}><span>{formatValue(valueWithUnit, si_unit, precision)}</span> <span>{si_label}</span></span>);
    }
    if(store.getState().data.currentDataUnitSystem == dataUnitSystems.us) {
        return (<span style={{whiteSpace: "nowrap"}}><span>{formatValue(valueWithUnit, us_unit, precision)}</span> <span>{us_label}</span></span>);
    }
}

export function formatValueWithUnitAndLabelAsString({valueWithUnit, si_unit, us_unit, si_label, us_label, precision}) {
    us_unit = us_unit ? us_unit : si_unit;
    us_label = us_label ? us_label : us_unit;
    si_label = si_label ? si_label : si_unit;
    if(store.getState().data.currentDataUnitSystem == dataUnitSystems.si) {
        return `${formatValue(valueWithUnit, si_unit, precision)} ${si_label}`;
    }
    if(store.getState().data.currentDataUnitSystem == dataUnitSystems.us) {
        return `${formatValue(valueWithUnit, us_unit, precision)} ${us_label}`;
    }
}

export function formatValueWithUnit({valueWithUnit, si_unit, us_unit, precision}) {
    if(store.getState().data.currentDataUnitSystem == dataUnitSystems.si) {
        return (<span style={{whiteSpace: "nowrap"}}>{(formatValue(valueWithUnit, si_unit, precision)+" "+si_unit)}</span>);
    }
    if(store.getState().data.currentDataUnitSystem == dataUnitSystems.us) {
        return (<span style={{whiteSpace: "nowrap"}}>{(formatValue(valueWithUnit, us_unit, precision)+" "+us_unit)}</span>);
    }
}

export function formatValue(valueWithUnit, unit, precision) {
    // this will return the number in the target unit (outputs only the number, not the unit)
    try {
        return math.eval(valueWithUnit+" in ("+unit+")").toNumber().toFixed(precision)
    } catch(e) {
        console.error("Format Error: "+`${valueWithUnit} in ${unit}`);
        console.error(e, e.stack);
    }
}

export function getRawValue(valueWithUnit) {
    try {
        return math.eval(valueWithUnit).toNumber()
    } catch(e) {
        console.error("getRawValue Error: "+`${valueWithUnit}`);
    }
}

export function convert(valueWithUnit, toUnit) {
    //try {
    //console.log("Convert "+valueWithUnit+" in "+toUnit)
    return math.eval(`${valueWithUnit} in (${toUnit})`).format(formatOptions);
    /*} catch(e) {
        console.error("Conversion Error: "+`${valueWithUnit} in ${toUnit}`);
        return "Conversion Error";
    }*/
}

export function addUnit(rawValue, unit) {
    return math.eval(rawValue+" "+unit).format(formatOptions);
}

/*function parseObj(o) {
    if(_.isArray(o)) {
        let els = [];
        _.each(o, (value) => {
            els.push(parseObj(value));
        });
        let s = "[" + els.join(", ") +"]";
        return s;
    } else if(typeof o == "object") {
        let els = [];
        _.each(o, (value,key) => {
            els.push('"'+key+'" : ' + parseObj(value));
        });
        let s = "{" + els.join(", ") +"}";
        return s;
    } else {
        return o;
    }
}*/

function parseObj(o) {
    if(_.isNumber(o)) {
        return new Number(o).toString();
    }
    else if(_.isArray(o)) {
        return _.map(o, (value,key) => {
            return parseObj(value);
        });
    } else if(typeof o == "object") {
        return _.mapValues(o, (value,key) => {
            return parseObj(value);
        });
    } else {
        try {
            return math.unit(o);
        } catch(e) {
            return new Number(o).toString();
        }
    }
}

const DEBUG_EVALUATE=false;

export function evaluateMathProgram(text, scope) {
    if(!text) {
        !DEBUG_EVALUATE || console.error("evaluated empty formula")
        return {};
    }
    //console.log("scope",scope);
    var parser = getMathJSInstance().parser();
    !DEBUG_EVALUATE || console.log("===evaluating===")
    !DEBUG_EVALUATE || console.log("============= scope:")
    _.forEach(scope, (value,key) => {
        /*if(key=="μ") {
            debugger;
        }*/
        parser.set(key, parseObj(value));
        !DEBUG_EVALUATE || console.log(key,parseObj(value));
    });


    //console.log("============= formula:", text)


    var codeLines = text.split(";");
    if(codeLines) {
        for(var i = 0; i<codeLines.length; i++) {
            //remove line-breaks in-between and add one at the end to treat everything as a single expression
            var evalString = codeLines[i].replace(/(?:\r\n|\r|\n)/g, '')+"\n";
            if(evalString.trim()) {
                //console.log("eval",evalString);
                try {
                    parser.eval(evalString);
                } catch(e) {
                    !DEBUG_EVALUATE || console.log(e);
                    !DEBUG_EVALUATE || console.error("Error evaluating line: "+evalString);
                }
            }
        }
        !DEBUG_EVALUATE || console.log("============= result:",parser.getAll());
        !DEBUG_EVALUATE || console.log("===evaluating-end===")
    }
    return parser.getAll();
}

export function isASmallerOrEqualB(a,b){
    return math.compare(a,b)!==1
}
