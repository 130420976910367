import { takeLatest } from 'redux-saga/effects';
import { all, call, put } from 'redux-saga/effects';
import FetchService from 'service/FetchService';

import * as types from './constants';
import * as actions from './actions';
import api from "./api";

function* readNews(action) {
  const url = api.buildReadNewsUrl({language: action.payload.params.language});
  const {response, data} = yield call(FetchService.get, url);
  const error = response.status !== 200;

  const items = data ? data.entries : [];

  yield put(actions.readNewsResponse({ payload: {items: items}, error: error }));
}

function* saga() {
  yield all([
    function* (){yield takeLatest(types.READ_NEWS_REQUEST, readNews)}()
  ])
}

export default saga;
