import React, { Component } from 'react';
import Layout from 'layout/Layout';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UIActions from 'storeState/ui/actions';
import * as math from 'mathjs';
import Modal from 'react-modal';
import { menuItems } from 'storeState/ui/constants';
import { getNozzleDiameterCalculationParams } from 'storeState/ui/selectors';
import { getFormulas, getDataunitsBySymbol,getUnits } from 'storeState/data/selectors';
import { TabNavigation, Tab } from 'layout/tabNavigation/TabNavigation';
import SectionHeader from 'layout/sectionHeader/SectionHeader';
import {MdMoreVert as Ellipsis} from "react-icons/md";
import UnitSelector from 'layout/unitSelector/UnitSelector';
import CalculationResult from 'layout/calculationResult/CalculationResult';
import DataUnitTextField from 'layout/dataUnitTextField/DataUnitTextField';
import NozzleConfigurationTable, {getNozzleDiametersByNozzleType} from 'layout/configurationTable/NozzleConfigurationTable';
import {evaluateMathProgram, formatValueWithUnit} from 'service/MathService';
import {getRPMLable} from 'service/LableService';
import _ from 'lodash';
import './nozzlediameter.scss';
import {Navigate} from "react-router";

const messages = defineMessages({
  nozzle_diameter_caption: {
    id: 'nozzle_diameter_caption',
    defaultMessage: 'Nozzle calculation'
  }
});

class NozzleDiameter extends Component {

  constructor(props) {
    super(props);
    this.lastParams = null;
    this.state = {
      result : {
        warnings : [],
        values : []
      },
      navigate: null,
      transferDataToNozzleCalculationModal: false
    }
  }


  handleUnitSelect = (unit) => {
      this.props.uiActions.setCalculationParameters({
          key : "nozzleDiameter",
          params : {
              unit: {
                  ...this.getUnitParams(),
                  ...unit
              }
          }
      });
  }

  handleNozzleConfigurationChange = (items) => {
      this.props.uiActions.setCalculationParameters({
          key : "nozzleDiameter",
          params : {
              nozzle: items[0]
          }
      });
  }

  componentDidMount() {
    setTimeout(() => {
         this.props.uiActions.setActiveMenuItem({ item: menuItems.nozzleCalculation })
    })
    this.doRecalc()
  }

  componentDidUpdate(prevProps, prevState) {

    if(this.props.calculationParams.unit) {
      const propUnit = _.find(this.props.units, un => un.id === this.props.calculationParams.unit.id);
      if(propUnit) {
        const comparePropParams = {
          maxFlowrate: propUnit.maxFlowrate,
          maxPressure: propUnit.maxPressure,
          rpmMax: propUnit.rpmMax,
          rpmMin: propUnit.rpmMin,
        }
        const compareCalcParams = {
          maxFlowrate: this.props.calculationParams.unit.maxFlowrate,
          maxPressure: this.props.calculationParams.unit.maxPressure,
          rpmMax: this.props.calculationParams.unit.rpmMax,
          rpmMin: this.props.calculationParams.unit.rpmMin,
        }
        if(!_.isEqual(comparePropParams, compareCalcParams)) {
          this.handleUnitSelect(propUnit);
        }
      }
    }

    this.doRecalc();
  }

  getUnitParams() {
      let unitParams={};
      if(this.props.calculationParams && this.props.calculationParams.unit) {
          unitParams = this.props.calculationParams.unit;
      } else {
          if(this.props.units.length>0) {
              unitParams = {
                  unit: this.props.units[0],
                  ...this.props.units[0]
              }
          } else {
              unitParams = {}
          }
      }
      return unitParams;
  }

  verifyInput = () => {
    if(!this.props.calculationParams.nozzle) {
      return false;
    }
    return true;
  }

  doRecalc = () => {
    if(this.recalcTimer) {
      clearTimeout(this.recalcTimer);
    }
    this.recalcTimer = setTimeout(() => {
      this.recalculate()
    },300)
  }

  recalculate = () => {
    const f = this.props.formulas.nozzle_diameter;
    if(this.verifyInput()) {
      try {

        let warnings = [];

        if(!this.getUnitParams()|| !this.getUnitParams().rpmMin || !this.getUnitParams().rpmMax) {
          warnings.push({
            type: "info",
            label: <FormattedMessage id="nozzle_calculation_wshort_NOTSET_UHD" defaultMessage="R.P.M. not set"/>,
            info: <FormattedMessage id="nozzle_calculation_wlong_NOTSET_UHD"
                                    defaultMessage="If you have an electrically driven or diesel driven plunger high pressure water unit, please create a present under “Properties” to be warned if your R.P.M. are possible, by setting the max. and min. limits of your engine."/>
          })
        }
        if(!this.getUnitParams() ||!this.getUnitParams().maxFlowrate) {
          warnings.push({
            type: "info",
            label: <FormattedMessage id="nozzle_calculation_wshort_NOTSET_QMAX"
                                     defaultMessage="No max. flow rate set"/>,
            info: <FormattedMessage id="nozzle_calculation_wlong_NOTSET_QMAX"
                                    defaultMessage="If you have an electrically driven or diesel driven plunger high pressure water unit, please create a present under “Properties” to be warned if your flow rate is possible, by setting the max. limits of your unit."/>
          })
        }
        if(!this.getUnitParams() ||!this.getUnitParams().maxPressure) {
          warnings.push({
            type: "info",
            label: <FormattedMessage id="nozzle_calculation_wshort_NOTSET_PBMAX" defaultMessage="No max. op. pressure set" />,
            info: <FormattedMessage id="nozzle_calculation_wlong_NOTSET_PBMAX" defaultMessage="If you have an electrically driven or diesel driven plunger high pressure water unit, please create a present under “Properties” to be warned if your operating pressure is possible, by setting the max. limits of your unit." />
          })
        }

        const unitParams = this.getUnitParams();
        const params = {
            'μ' : this.props.calculationParams.nozzle.efficiency,
            'n' : this.props.calculationParams.nozzle.amount,
            pNZmax: this.props.calculationParams.nozzle.maxPressure,
            pB: unitParams ? unitParams.pressure : null, //bar
            pBmax : unitParams ? unitParams.maxPressure : null, //bar
            Qtot : unitParams ? unitParams.flowrate : null,
            Qmax : unitParams ? unitParams.maxFlowrate : null, //400 l/mins;
            uHDmax : unitParams ? unitParams.rpmMax : null, //300 mins ^ -1 in mins ^ -1;
            uHDmin : unitParams ? unitParams.rpmMin : null,//300 mins ^ -1 in mins ^ -1;
        };
        if(_.isEqual(params, this.lastParams)) {
            return;
        }
        const res = evaluateMathProgram(f, params);
        this.lastParams = params;

        /*
         MAX_FR (in N)
         MAX2_FR (in N)
         WARN_UHD_LOW
         WARN_UHD_HIGH
         WARN_FR_HIGH
         WARN_FR_VERY_HIGH
         WARN_QTOT
         WARN_PB_HIGH
         */

         if(res["WARN_PNZMAX_EXCEED"]) {
           let wNozzle = this.props.calculationParams.nozzle;
           warnings.push({
             type: "error",
             label: <FormattedMessage id="flowrate_wshort_PNZMAX" defaultMessage="Nozzle insert at max. op. pressure" />,
             info: <FormattedMessage id="flowrate_wlong_PNZMAX" defaultMessage="Please do not exceed the maximum pressure specifications of the nozzle insert, for {nozzle} nozzles it is {pressure}"
               values={{nozzle: wNozzle.type.name, pressure: formatValueWithUnit({ valueWithUnit: wNozzle.maxPressure, si_unit: "bar", us_unit: "psi", precision: 0 })}}
             />
           });
         }
        if(res["WARN_UHD_LOW"]) {
          warnings.push({
            type: "error",
            label: <FormattedMessage id="nozzle_diameter_wshort_UHD_LOW" defaultMessage="R.P.M. too low" />,
            info: <FormattedMessage id="nozzle_diameter_wlong_UHD_LOW" defaultMessage="The min. r.p.m of the high pressure water unit has been exceeded for the desired flow rate." />
          })
        }
        if(res["WARN_UHD_HIGH"]) {
          warnings.push({
            type: "error",
            label: <FormattedMessage id="nozzle_diameter_wshort_UHD_HIGH" defaultMessage="R.P.M. too high" />,
            info: <FormattedMessage id="nozzle_diameter_wlong_UHD_HIGH" defaultMessage="The max. r.p.m of the high pressure water unit has been exceeded for the desired flow rate." />
          })
        }
        if(res["WARN_FR_VERY_HIGH"]) {
          warnings.push({
            type: "error",
            label: <FormattedMessage id="nozzle_diameter_wshort_FR_VERY_HIGH" defaultMessage="Hand held reaction force" />,
            info: <FormattedMessage id="nozzle_diameter_wlong_FR_VERY_HIGH" defaultMessage="The hand held use of high pressure water blasting guns is forbidden above 250 N reaction force. Please see local waterblasting safety regulations." />
          })
        } else if(res["WARN_FR_HIGH"]) {
          warnings.push({
            type: "warning",
            label: <FormattedMessage id="nozzle_diameter_wshort_FR_HIGH" defaultMessage="Hand held reaction force" />,
            info: <FormattedMessage id="nozzle_diameter_wlong_FR_HIGH" defaultMessage="Please be warned that the use of hand held high pressure water blasting guns without a shoulder stock is not recommended above 150 N reaction force. Please see local waterblasting safety regulations." />
          })
        }
        if(res["WARN_QTOT"]) {
          warnings.push({
            type: "error",
            label: <FormattedMessage id="nozzle_diameter_wshort_QTOT" defaultMessage="Max. flow rate exceeded" />,
            info: <FormattedMessage id="nozzle_diameter_wlong_QTOT" defaultMessage="The max. flow rate associated with your unit has been exceeded! Go to „Properties“ to change your max. flow rate or decrease your flow rate in your result by changing your input." />
          })
        }
        if(res["WARN_PB_HIGH"]) {
          warnings.push({
            type: "error",
            label: <FormattedMessage id="nozzle_diameter_wshort_PB_HIGH" defaultMessage="Caution! Over pressurizing!" />,
            info: <FormattedMessage id="nozzle_diameter_wlong_PB_HIGH" defaultMessage="Over pressurizing of the pump – rupture disc burst or safety valve actuating possible!" />
          })
        }

        let values = [{
          type: "valid",
          label: <FormattedMessage id="nozzle_diameter_result_nr" defaultMessage="Computed Nozzle diameter"/>,
          value: (<DataUnitTextField decimalPlaces={3} align="right-m-left-d" value={res['d']} editable={false} si="mm" us="in" />)
        }]

        if(res['d']) {
          const validDiameters = getNozzleDiametersByNozzleType(this.props.calculationParams.nozzle.type);

          math.sort(validDiameters, function(a,b) { return math.largerEq(math.unit(a),math.unit(b)) ? 1 : -1 });
          //console.log("validDiameters", validDiameters)

          let valid_nozzles = [];

          for (let i = 0; i < validDiameters.length; i++) {
              //console.log("Current Diameter:",validDiameters[i]);
              if (math.largerEq(math.unit(validDiameters[i]), math.unit(res['d']))) {
                  //console.log("leq")
                  if (i != 0) {
                      valid_nozzles.push(
                          <div className="nozzle-diameter-valid-nozzle-item" onClick={() => this.openTransferDataModal({diameter: validDiameters[i - 1]})}>
                              <DataUnitTextField
                                  className="nozzle-diameter-valid-nozzle-dutf"
                                  decimalPlaces={3}
                                  align="right-m-left-d"
                                  value={validDiameters[i - 1]}
                                  editable={false}
                                  si="mm"
                                  us="in"/>
                              <Ellipsis className="nozzle-diameter-valid-nozzle-ellipsis" />
                          </div>
                      );
                  }

                  valid_nozzles.push(
                      <div className="nozzle-diameter-valid-nozzle-item" onClick={() =>this.openTransferDataModal({diameter: validDiameters[i]})}>
                          <DataUnitTextField
                              className="nozzle-diameter-valid-nozzle-dutf"
                              decimalPlaces={3}
                              align="right-m-left-d"
                              value={validDiameters[i]}
                              editable={false}
                              si="mm"
                              us="in"/>
                          <Ellipsis className="nozzle-diameter-valid-nozzle-ellipsis" />
                      </div>
                  );
                  break;
              } else if (i == validDiameters.length - 1) { //last one ist the greatest smaller
                  //console.log("smaller; but end reached")
                  valid_nozzles.push(
                      <div onClick={() => this.openTransferDataModal({diameter: validDiameters[i]})}>
                          <DataUnitTextField
                              className="nozzle-diameter-valid-nozzle-dutf"
                              decimalPlaces={3}
                              align="right-m-left-d"
                              value={validDiameters[i]}
                              editable={false}
                              si="mm"
                              us="in"/>
                          <Ellipsis className="nozzle-diameter-valid-nozzle-ellipsis" />
                      </div>
                  );
              }
          }

          if(valid_nozzles.length>0) {
              values.push({
                  type: "valid",
                  label: <FormattedMessage id="nozzle_diameter_result_valid_nozzles" defaultMessage="Available nozzles"/>,
                  value: (<div className="nozzle-diameter-valid-nozzle-dutf-container">{valid_nozzles}</div>)
              })
          }
        }

        values.push({
            type: res["WARN_FR_VERY_HIGH"] ? "error" : res["WARN_FR_HIGH"] ? "warning" : "valid",
            label: <FormattedMessage id="nozzle_diameter_result_fr" defaultMessage="Reaction Force"/>,
            value: (<DataUnitTextField decimalPlaces={0} align="right-m-left-d" value={res['Fr']} editable={false} si="N" us="lbf" />)
        })

        if(typeof res['uHD'] !== "undefined") {
          values.push({
            type: (res["WARN_UHD_LOW"] || res["WARN_UHD_HIGH"]) ? "error" : "valid",
            label: <FormattedMessage id="nozzle_diameter_result_rpm" defaultMessage="RPM"/>,
            value: (<DataUnitTextField decimalPlaces={0} align="right-m-left-d" value={res['uHD']} editable={false} si="mins^-1" customLabel={getRPMLable()} />)
          })
        }

        /*this.props.uiActions.setCalculationParameters({
            key : "nozzleDiameter",
            params : {
                nozzle : {
                    ...this.props.calculationParams.nozzle,
                    diameter : res["d"] ? res["d"].toString() : null
                }
            }
        });*/
        this.setState({
          result : {
            values : values,
            warnings : warnings,
          }
        })

      } catch(ex) {
        console.error("Calculation eror occured");
        console.error(ex, ex.stack);
        this.resetResults();
      }
    } else {
      this.resetResults();
    }
  }

  resetResults = () => {
      let reset = {
          result: {
              values: [],
              warnings: [],
          },
      };
      let tmp = _.merge(this.state, reset)
      if(!_.isEqual(tmp, this.state)) {
          this.setState(reset)
      }
    /*if(this.props.calculationParams.nozzle) {
        this.props.uiActions.setCalculationParameters({
            key: "nozzleDiameter",
            params: {
                nozzle: {
                    ...this.props.calculationParams.nozzle,
                    diameter: null
                }
            }
        });
    }*/
  }

  openTransferDataModal = ({ diameter }) => {
    this.setState({
      transferDataToNozzleCalculationModal: true,
      transferDataModalDiameter: diameter
    })
  }

  cancelTransferDataModal = () => {
    this.setState({
      transferDataToNozzleCalculationModal: false,
      transferDataModalDiameter: null
    })
  }

  runTransferData = ({ diameter }) => {
    this.props.uiActions.setCalculationParameters({
      key: "nozzleCalculation",
      params: {
        nozzles: [{
          ...this.props.calculationParams.nozzle,
          diameter: diameter
        }],
        unit: this.props.calculationParams.unit
      }
    });

    this.setState({
      navigate: "/nozzlecalculation"
    })
  }

  render() {

    if(this.state.navigate) return <Navigate to={this.state.navigate}/>

    const { formatMessage } = this.props.intl;
    const unitParams = this.getUnitParams();
    const nozzleItems = this.props.calculationParams && this.props.calculationParams.nozzle ? [this.props.calculationParams.nozzle] : [];
    const { transferDataToNozzleCalculationModal, transferDataModalDiameter } = this.state;

    return (
        <Layout caption={formatMessage(messages.nozzle_diameter_caption)}>
          <div className='nozzle-tab-info-mobile'><FormattedMessage id='nozzle_tabs_info' defaultMessage='Please choose your results'/></div>
          <TabNavigation>
            <Tab to="/nozzlecalculation" active={false}><FormattedMessage id="nozzle_tabs_flowrate" defaultMessage="Flow rate" /></Tab>
            <Tab to="/nozzlediameter" active={true}><FormattedMessage id="nozzle_tabs_diameter" defaultMessage="Nozzle diameter" /></Tab>
            <div className='nozzle-tab-info'><FormattedMessage id='nozzle_tabs_info' defaultMessage='Please choose your results'/></div>
          </TabNavigation>

          <SectionHeader><FormattedMessage id="nozzle_calculation_header_unit" defaultMessage="UNIT"/></SectionHeader>
          <UnitSelector
              onUnitChange={this.handleUnitSelect}
              showPressure={true}
              showFlowrate={true}
              units = {this.props.units}
              {...unitParams}
          />

          <SectionHeader><FormattedMessage id="nozzle_calculation_header_configuration" defaultMessage="NOZZLE CONFIGURATION"/></SectionHeader>
          <NozzleConfigurationTable
              onConfigurationChange={this.handleNozzleConfigurationChange}
              maxCount={1}
              enableDeletion={false}
              showDiameter={false}
              editableDiameter={false}
              items={nozzleItems}
          />
          <CalculationResult
              values = {this.state.result.values}
              warnings = {this.state.result.warnings}
          />
          <Modal isOpen={ transferDataToNozzleCalculationModal } className={`ci-modal ci-modal-nopadding nozzle-diameter-modal`} overlayClassName="ci-modal-overlay" onRequestClose={this.handleSelectionModalCancel}>
            <div className="nozzle-diameter-modal-container">
              <div className="nozzle-diameter-modal-item" onClick={() => this.runTransferData({ diameter: transferDataModalDiameter })}>
                <FormattedMessage id="nozzle_diameter_transfer_nozzle" defaultMessage="Transfer nozzle settings to flowrate calculation" />
              </div>
              <div className="nozzle-diameter-modal-item-noevents">
                <span className="nozzle-diameter-modal-item-subline">
                  <FormattedMessage id="nozzle_diameter_transfer_nozzle_subline" defaultMessage="available nozzle angles R, D, B" />
                </span>
              </div>
              <div className="nozzle-diameter-modal-item nozzle-diameter-modal-item-cancel" onClick={() => this.cancelTransferDataModal()}>
                <FormattedMessage id="button_cancel" defaultMessage="Cancel" />
              </div>
            </div>
          </Modal>
        </Layout>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    //enabled: getEnabled(state),
    formulas: getFormulas(state),
    dataunits: getDataunitsBySymbol(state),
    calculationParams: getNozzleDiameterCalculationParams(state),
    units: getUnits(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(UIActions, dispatch),
  }
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(NozzleDiameter))
