import { takeLatest } from 'redux-saga/effects';
import { all, call, put } from 'redux-saga/effects';
import FetchService from 'service/FetchService';
import packagedData from 'assets/data/data.json';

import * as types from './constants';
import * as actions from './actions';
import api from "./api";

function* readData(action) {
  const url = api.buildReadDataUrl();
  try {
    const {response, data} = yield call(FetchService.get, url);
    const error = response.status !== 200;
    if(!error) {
      yield put(actions.readDataResponse({ payload: {data: data}, error: error }));
    } else {
      yield put(actions.readDataResponse({ payload: {data: packagedData}, error: false }));
    }
  } catch (e) {
    yield put(actions.readDataResponse({ payload: {data: packagedData}, error: false }));
  }
}

function* saga() {
  yield all([
    function* (){yield takeLatest(types.READ_DATA_REQUEST, readData)}(),
  ])
}

export default saga;
