import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {FormattedMessage, injectIntl} from 'react-intl';
import { connect } from "react-redux";

import 'components/layout/productWizard/productWizardMasterjet.scss';
import SectionHeader from "components/layout/sectionHeader/SectionHeader";
import WizardItem from "components/layout/productWizard/WizardItem";

import MODAL_TYPE from "components/layout/productWizard/masterJetModals/MasterjetModalTypes.constants";
import {MdClose as CloseIcon} from "react-icons/md";
import WizardNavigation from "components/layout/productWizard/WizardNavigation";

import * as UIActions from 'storeState/ui/actions';
import {MASTERJET_CONFIGURATION_PARAMETERS} from "components/views/masterjetConfiguration/MasterjetConfiguration";
import {bindActionCreators} from "redux";
import {getMasterjetConfigurationParams} from "storeState/ui/selectors";

import masterjet_nozzle_diameter_same from "assets/images/new/Duesendurchmesser-gleich.png";
import masterjet_nozzle_diameter_different from "assets/images/new/Duesendurchmesser-unterschiedlich.png";

class ModalNozzleDiameter extends Component {

  static propTypes = {
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  static defaultProps = {

  }

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  setValue = (value) => {

    let newParams = {
      nozzleVariant: value
    };

    if(this.props.calculationParams.nozzleVariant !== value) {
      // reset
      newParams.productId = false;
      newParams.performanceClass = false;
      newParams.threadType = false;
      newParams.maxPressure = false;
    }

    this.props.uiActions.setCalculationParameters({
      key : MASTERJET_CONFIGURATION_PARAMETERS,
      params : newParams
    });

    // this.props.openModal(MODAL_TYPE.MODAL_TYPE_PRESSURE)
  }

  render() {

    return (
      <div className="product-wizard-masterjet-modal-item-container">

        <SectionHeader style={{padding: 0}}>
          <div className="product-wizard-masterjet-modal-item-section-row">
            <div className={"product-wizard-masterjet-modal-item-section-row-icon"} onClick={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_PRODUCT)}>
              <CloseIcon></CloseIcon>
            </div>
            <div className={"product-wizard-masterjet-modal-item-section-row-label"}>
              <FormattedMessage id="product_wizard_masterjet_nozzle_diameter" defaultMessage="Düsendurchmesser"/>
            </div>
          </div>
        </SectionHeader>

        <WizardNavigation
          onPrev={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_NOZZLE_AMOUNT)}
          onNext={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_PERFORMANCE_CLASS)}
          onNextDisabled={!this.props.calculationParams.nozzleVariant}
        />
        <div className={"product-wizard-item-collection"}>
          <WizardItem
            type={"simple"}
            border={true}
            image={<img src={masterjet_nozzle_diameter_same} />}
            label={
            <div className={"product-wizard-masterjet-table-modal-item-label"}>
              <FormattedMessage id="product_wizard_masterjet_nozzle_diameter_same"
                                defaultMessage="Gleiche Düsendurchmesser"/>
            </div>
            }
            onClick={()=>this.setValue("equal")}
            isActive={this.props.calculationParams?.nozzleVariant === "equal"}
          />
          <If condition={this.props.calculationParams?.nozzleCount % 2 === 0}>
            <WizardItem
              type={"simple"}
              border={true}
              image={<img src={masterjet_nozzle_diameter_different} />}
              label={
              <div className={"product-wizard-masterjet-table-modal-item-label"}>
                <FormattedMessage id="product_wizard_masterjet_nozzle_diameter_different"
                                  defaultMessage="Verschiedene Düsendurchmesser"/>
              </div>
              }
              onClick={()=>this.setValue("unequal")}
              isActive={this.props.calculationParams?.nozzleVariant === "unequal"}
            />
          </If>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    calculationParams: getMasterjetConfigurationParams(state) || {}
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(UIActions, dispatch),
  }
}

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalNozzleDiameter))
