import React, { Component } from 'react';
import Layout from 'layout/Layout';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UIActions from 'storeState/ui/actions';
import * as NewsActions from 'storeState/news/actions';
import { menuItems } from 'storeState/ui/constants';
import { getLocale } from 'storeState/ui/selectors';
import * as DataActions from 'storeState/data/actions';
import { dataUnitSystems } from 'storeState/data/constants';
import { getUnits, getFreeUnitId, getCurrentDataUnitSystem } from 'storeState/data/selectors';
import Modal from 'react-modal';
import Button from 'layout/button/Button';
import ButtonBar from 'layout/buttonBar/ButtonBar';
import ToggleButton from 'layout/toggleButton/ToggleButton';
import SectionHeader from 'layout/sectionHeader/SectionHeader';
import UnitImage from 'assets/images/unit.png';
import {GoPlus as PlusIcon} from "react-icons/go";
import Dropdown from 'react-dropdown'
import locales from 'locales';
import ReactDOM from 'react-dom'

import style from 'style';

import UnitItemHeader from './UnitItemHeader';
import UnitItemProperties from './UnitItemProperties';
import './properties.scss';

const messages = defineMessages({
  properties_new_unit: {
    id: 'properties_new_unit',
    defaultMessage: 'New Unit'
  },
  properties_caption: {
    id: 'properties_caption',
    defaultMessage: 'Properties'
  },
  properties_toggle_lable_metric: {
    id: 'properties_toggle_lable_metric',
    defaultMessage: 'SI'
  },
  properties_toggle_lable_us: {
    id: 'properties_toggle_lable_us',
    defaultMessage: 'US'
  },
  properties_toggle_lable_yes: {
    id: 'properties_toggle_lable_yes',
    defaultMessage: 'YES'
  },
  properties_toggle_lable_no: {
    id: 'properties_toggle_lable_no',
    defaultMessage: 'NO'
  },
  properties_language_en: {
    id: 'properties_language_en',
    defaultMessage: '🇬🇧 English'
  },
  properties_language_de: {
    id: 'properties_language_de',
    defaultMessage: '🇩🇪 Deutsch'
  },
  properties_language_bg: {
    id: 'properties_language_bg',
    defaultMessage: '🇧🇬 български'
  },
  properties_language_da: {
    id: 'properties_language_da',
    defaultMessage: '🇩🇰 dansk'
  },
  properties_language_el: {
    id: 'properties_language_el',
    defaultMessage: '🇬🇷 ελληνικά'
  },
  properties_language_es: {
    id: 'properties_language_es',
    defaultMessage: '🇪🇸 Español'
  },
  properties_language_fa: {
    id: 'properties_language_fa',
    defaultMessage: '🇮🇷 زبان فارسی'
  },
  properties_language_fi: {
    id: 'properties_language_fi',
    defaultMessage: '🇫🇮 Suomi'
  },
  properties_language_fr: {
    id: 'properties_language_fr',
    defaultMessage: '🇫🇷 Français'
  },
  properties_language_he: {
    id: 'properties_language_he',
    defaultMessage: '🇮🇱 עברית'
  },
  properties_language_hi: {
    id: 'properties_language_hi',
    defaultMessage: '🇮🇳 हिन्दी'
  },
  properties_language_hu: {
    id: 'properties_language_hu',
    defaultMessage: '🇭🇺 magyar'
  },
  properties_language_it: {
    id: 'properties_language_it',
    defaultMessage: '🇮🇹 Italiano'
  },
  properties_language_jp: {
    id: 'properties_language_jp',
    defaultMessage: '🇯🇵 日本語'
  },
  properties_language_ko: {
    id: 'properties_language_ko',
    defaultMessage: '🇰🇷 한국어'
  },
  properties_language_nl: {
    id: 'properties_language_nl',
    defaultMessage: '🇳🇱 Nederlands'
  },
  properties_language_no: {
    id: 'properties_language_no',
    defaultMessage: '🇳🇴 Norsk'
  },
  properties_language_pl: {
    id: 'properties_language_pl',
    defaultMessage: '🇵🇱 polszczyzna'
  },
  properties_language_pt: {
    id: 'properties_language_pt',
    defaultMessage: '🇵🇹 Português'
  },
  properties_language_rm: {
    id: 'properties_language_rm',
    defaultMessage: '🇨🇭 rumantsch grischun'
  },
  properties_language_ro: {
    id: 'properties_language_ro',
    defaultMessage: '🇷🇴 limba română'
  },
  properties_language_ru: {
    id: 'properties_language_ru',
    defaultMessage: '🇷🇺 Русский'
  },
  properties_language_sv: {
    id: 'properties_language_sv',
    defaultMessage: '🇸🇪 svenska'
  },
  properties_language_th: {
    id: 'properties_language_th',
    defaultMessage: '🇹🇭 ไทย'
  },
  properties_language_tr: {
    id: 'properties_language_tr',
    defaultMessage: '🇹🇷 Türkçe'
  },
  properties_language_uk: {
    id: 'properties_language_uk',
    defaultMessage: '🇺🇦 українська'
  },
  properties_language_vi: {
    id: 'properties_language_vi',
    defaultMessage: '🇻🇳 Tiếng Việt'
  },
  properties_language_zh: {
    id: 'properties_language_zh',
    defaultMessage: '🇨🇳 中文'
  }
});

class Properties extends Component {

  constructor(props){
    super(props);

    this.initlabels();

    this.state = {
      activeUnitId : null,
      askUnitDeletion : null,
      selectLanguageActive: false,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.props.uiActions.setActiveMenuItem({ item: menuItems.properties })
    })
  }

  initlabels = () => {

    this.localeLables = [];
    _.map(locales, (localeValue, localeKey) => {
      this.localeLables.push({
        //label: this.props.intl.formatMessage(messages[`properties_language_${localeKey}`]),
        label: messages[`properties_language_${localeKey}`].defaultMessage,
        value: localeKey
      });
    });
  }

  handleAddNewUnit = () => {
    let unit = {
      id : this.props.freeUnitId,
      name : this.props.intl.formatMessage(messages.properties_new_unit),
      maxPressure: "0 bar",
      maxFlowrate: "0 l/mins",
      rpmMin: "0 mins^-1",
      rpmMax: "0 mins^-1",
    }
    this.props.dataActions.setUnit({unit: unit});
    this.handleUnitClick(unit);
  }

  handleUnitClick = (unit) => {
    if(this.state.activeUnitId == unit.id){
      this.setState({
        activeUnitId : null
      })
    }else{
      this.setState({
        activeUnitId : unit.id
      })
    }
  }

  handleUnitChange = (unit) => {
    this.props.dataActions.setUnit({unit: unit});
  }

  handleUnitDeleteClick = (unit) => {
    this.setState({
      askUnitDeletion : unit
    })
  }

  handleUnitDeletionConfirmation = () => {
    this.props.dataActions.deleteUnit({id : this.state.askUnitDeletion.id});
    this.setState({
      askUnitDeletion : null
    })
  }

  handleUnitDeletionCancel = () => {
    this.setState({
      askUnitDeletion : null
    })
  }

  handleUsUnitsToggle = (oldValue) => {
    this.props.dataActions.setDataUnitSystem({dataUnitSystem : oldValue ? dataUnitSystems.us : dataUnitSystems.si});
  }

  setLocale = (locale) => {
    this.props.uiActions.setLocale(locale.value, false);
    this.props.newsActions.readNewsRequest({language: locale.value})
  }

  render() {

    const { formatMessage } = this.props.intl;
    const { units, locale } = this.props;
    const { activeUnitId } = this.state;

    const localeLabel = _.find(this.localeLables, localeLable => localeLable.value == locale);

    return (
      <Layout caption={formatMessage(messages.properties_caption)}>
        <div className="properties">
          <SectionHeader><FormattedMessage id="properties_header_units" defaultMessage="YOUR UNIT" /></SectionHeader>
          <div className='properties-units-container'>
            <For each="item" index="index" of={ units }>
              <div key={index} className='properties-unit-container'>
                <div className='properties-unit-inner'>
                  <UnitItemHeader unit={item} onClick={() => this.handleUnitClick(item)} onDeleteClick={this.handleUnitDeleteClick} />
                  <img className="properties-unit-image" src={UnitImage} />
                  <div className={`properties-unit-properties ${activeUnitId == item.id?'active':''}`}>
                    <UnitItemProperties key={item.id} unit={item} onUnitChange={this.handleUnitChange} />
                    <div className="properties-unit-button-container">
                      <ButtonBar>
                        <Button onClick={()=>{this.handleUnitClick(item)}}>OK</Button>
                      </ButtonBar>
                    </div>
                  </div>
                </div>
              </div>
            </For>
            <div className='properties-unit-container new-item' onClick={this.handleAddNewUnit}>
              <div className='properties-unit-inner new-item'>
                <div className='properties-add-unit-icon'>
                  <PlusIcon />
                </div>
                <div className='properties-add-unit-lable'>
                  <FormattedMessage id="properties_add_new_unit" defaultMessage="Add new unit" />
                </div>
              </div>
            </div>
          </div>
          <SectionHeader><FormattedMessage id="properties_header_measurement" defaultMessage="SYSTEM OF MEASUREMENT" /></SectionHeader>
          <div className="properties-setting-container">
            <div className="properties-setting">
              <div className="properties-setting-lables">
                <div className="properties-setting-line1"><FormattedMessage id="properties_us_units_line1" defaultMessage="US Units?" /></div>
                <div className="properties-setting-line2"><FormattedMessage id="properties_us_units_line2" defaultMessage="Inch, PSI, GPM, rpm?" /></div>
              </div>
              <div className="properties-setting-toggle">
                <div className='properties-setting-toggle-mobile'>
                  <ToggleButton
                    thumpColor={style.secondary4}
                    onToggle={this.handleUsUnitsToggle}
                    value={this.props.currentDataUnitSystem != dataUnitSystems.us}
                    inActiveLabel={this.props.intl.formatMessage(messages['properties_toggle_lable_us'])}
                    activeLabel={this.props.intl.formatMessage(messages['properties_toggle_lable_metric'])}
                  />
                </div>
                <div className='properties-setting-toggle-desktop'>
                  <ToggleButton
                    thumpColor={style.secondary6}
                    onToggle={this.handleUsUnitsToggle}
                    value={this.props.currentDataUnitSystem != dataUnitSystems.us}
                    inActiveLabel={this.props.intl.formatMessage(messages['properties_toggle_lable_us'])}
                    activeLabel={this.props.intl.formatMessage(messages['properties_toggle_lable_metric'])}
                  />
                </div>
              </div>
              <div className="properties-setting-lables">
                <div className="properties-setting-line1"><FormattedMessage id="properties_metric_units_line1" defaultMessage="Metric?" /></div>
                <div className="properties-setting-line2"><FormattedMessage id="properties_metric_units_line2" defaultMessage="mm, bar, litre?" /></div>
              </div>
            </div>
          </div>
          <SectionHeader><FormattedMessage id="properties_header_language" defaultMessage="LANGUAGE" /></SectionHeader>
          <div className="properties-setting-container">
            <div className="properties-setting">
              <div className="properties-dropdown-container" onClick={()=>this.setState({selectLanguageActive: true})}>
                <div className="Dropdown-root">
                  <div className="Dropdown-control">
                    {localeLabel.label}
                    <span className="Dropdown-arrow"></span>
                  </div>
                </div>
                {/*
                  <Dropdown
                    options={this.localeLables}
                    onChange={(locale) => this.setLocale(locale)}
                    value={_.find(this.localeLables, localeLable => localeLable.value == locale)}
                  />
                */}
              </div>
            </div>
          </div>

        </div>

        <Modal isOpen={this.state.askUnitDeletion!=null} className="ci-modal" overlayClassName="ci-modal-overlay" onRequestClose={this.handleUnitDeletionCancel}>
          <If condition={this.state.askUnitDeletion}>
            <div className="ci-modal-confirmation-text">
              <FormattedMessage id="properties_unit_deletion_confirmation" defaultMessage='Do you really want do delete "{name}"?' values={{name: this.state.askUnitDeletion.name}}/>
            </div>
            <ButtonBar>
              <Button backgroundColor={style.secondary2} textColor={style.white} onClick={this.handleUnitDeletionCancel}><FormattedMessage id="button_cancel" defaultMessage="Cancel" /></Button>
              <Button onClick={this.handleUnitDeletionConfirmation}><FormattedMessage id="button_ok" defaultMessage="OK" /></Button>
            </ButtonBar>
          </If>
        </Modal>
        <Modal isOpen={this.state.selectLanguageActive} className="ci-modal ci-modal-nopadding modal-selector-container modal-selector-scroller" overlayClassName="ci-modal-overlay" onRequestClose={()=>{this.setState({selectLanguageActive: false})}}>
          <div ref={ref=>{
              if(ref){
                let domNode = ReactDOM.findDOMNode(ref);
                let scrollDOMContainer = domNode.parentNode;
                let selectedIndex = _.findIndex(this.localeLables, item => {
                  return item.value==locale;
                });
                if(selectedIndex > 0){
                  let scrollHeight = 0;
                  for(var i=0; i<(selectedIndex); i++){
                    scrollHeight += domNode.childNodes[i].clientHeight + 2;
                  }
                  scrollDOMContainer.scrollTop = Math.max(0,(scrollHeight + (domNode.childNodes[selectedIndex].clientHeight+2)/2 - scrollDOMContainer.clientHeight/2))
                }
              }
            }}>
            <For each="item" of={this.localeLables} index="index">
              <div key={index}>
                <div key={`item_${item.label}`} className={`unit-selector-modal-row ${item.value==locale?'selected':''}`} onClick={()=>{this.setState({selectLanguageActive: false}); this.setLocale(item)}}>
                  {item.label}
                </div>
                <div className='unit-selector-modal-row-spacer' />
              </div>
            </For>
          </div>
        </Modal>
      </Layout>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    units: getUnits(state),
    freeUnitId: getFreeUnitId(state),
    currentDataUnitSystem: getCurrentDataUnitSystem(state),
    locale: getLocale(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(UIActions, dispatch),
    dataActions: bindActionCreators(DataActions, dispatch),
    newsActions: bindActionCreators(NewsActions, dispatch),
  }
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(Properties))
