import React, { Component } from 'react';
import Layout from 'layout/Layout';
import './disclaimer.scss';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';

const messages = defineMessages({
  sidemenu_disclaimer: {
    id: 'sidemenu_disclaimer',
    defaultMessage: 'Disclaimer'
  },
  sidemenu_disclaimer_content: {
    id: 'sidemenu_disclaimer_content',
    defaultMessage: `
<h3>Privacy Policy</h3>
<p>
  Data protection is important to us. We adhere to the relevant statutory regulations. The following privacy statement contains information on the type of data we collect for specific purposes and to what extent said data is made available to third parties.
</p>
<h4>1. Data collection and processing</h4>
<p>
  Personal data provided in the context of requests or by contacting us will solely be collected, processed and stored to process requests and to facilitate communication regarding possible contractual relationships. Only if we have obtained a separate permission in advance will your data also be used for other purposes that are explicitly specified in the permission.
</p>
<p>
  For site-related information you can turn on geo-localization. Your permission for geo-localization will be verified by the app ("May Water Jetting Calculator use your current location even if the app is currently not used?"). If you consented to geo-localization, you can revoke the permission in the general settings of your mobile device at any time.
</p>
<p>
  Short messages, also of promotional nature, can be sent to you via push messages. The consent on the receipt of push messages is given if you approve the app’s request in this respect ("May Water Jetting Calculator send you messages?"). The permission can be configured and revoked in the general settings of your mobile device.
</p>
<p>
  You will only receive commercial news (newsletters) or telephone calls regarding your requests if you have given your explicit approval in this respect.
</p>
<h4>2. Use and passing on of personal data</h4>
<p>
  Your data provided will only be used internally to provide the app’s functionality. Your data will only be passed on to third parties if this is necessary to provide functionality, if we are obliged to do so by legal provisions or in case you have given your consent in this respect.
</p>
<h4>3. Google Analytics</h4>
<p>
  This app uses Google Analytics, a web analyzing service of Google Inc., 1600 Amphitheatre Parkway Mountain View, CA 94043, USA ("Google"). Google Analytics uses so-called "cookies", i.e. text files stored on your end device, which allow an analysis of your app’s use. The information generated by the cookie on your use of the app (including IP address) will be passed on to and stored on a Google server in the US.
</p>
<p>
  This app uses Google Universal Analytics with the additional function "User ID". The user ID is a unique, permanent, non-personally identifiable character string which is assigned to you and not to a device. This allows to track your visit and user behavior of our app with different end devices (e. g. smartphone, tablet, laptop). The user ID will only be assigned directly if we can clearly identify you as user. This is normally the case when you register on our platform for the first time. We do not associate the data collected by the user ID with your personally identifiable data. Only the anonymized user ID will be transferred to Google Universal Analytics and used as your pseudonym towards Google. Other data and information with respect to your account will not be transferred to Google. In the following your user behavior with respect to our app will be transferred with your user ID to the Google servers, stored there and used for analyzing purposes. Google links the transferred data with anonymized user profiles and provides these in aggregated form. We do not link the user profiles transferred by Google with your personally identifiable data. A direct reference to persons is not possible.
  <br/>
  You can revoke the transfer of a user ID to Google by sending an email, reference "Data protection: user ID".
  <br/>
  We explicitly point out that this app uses Google Analytics with the extension "_anonymizeIp()" and, therefore, IP addresses by Google in member states within the European Union or in contracting parties to the Agreement on the European Economic Area will be abbreviated before the transfer to prevent direct personal reference. Only in exceptional cases will the full IP address be transferred to a Google server in the US and abbreviated there.
  <br/>
  Google will use this information, by order of the provider, to analyze your use of the app, to compile reports on the app’s activities for the app providers and to provide further services associated with the use of the app and the internet use. Google may pass on this information to third parties if required by law or in case third parties process these data on behalf of Google. Google will not associate your IP address with other data by Google.
  <br/>
  The purpose and scope of the data collection, the further processing and use of data by Google as well as your rights and configuration options to protect your privacy can be taken from the privacy statements by Google on http://www.google.de/intl/de/policies/privacy/?hl=de and, in particular, for Google Analytics on https://www.google.com/policies/privacy/partners/?hl=de and on https://support.google.com/analytics/answer/6004245?hl=de as well as for Universal Analytics on https://support.google.com/analytics/answer/2838718?hl=de.
  <br/>
  By using the app, you agree with the processing of the data collected on you by Google in the before- mentioned manner and purpose.
  <br/>
  The "Google Analytics" switch allows to deactivate the collection of your data by Google Analytics when using this app in the future.
</p>
<h4>4. Disclosure, messaging, blocking, deletion</h4>
<p>
  On request, we are glad to send you information on the data stored with us. In accordance with the Federal Data Protection Act you have a right to correction, blocking or deletion of personally identifiable data. Please send an email to mail@Hammelmann.de, reference "Data protection".
</p>
<h4>5. Security note</h4>
<p>
  We make huge technical and organizational efforts to store your personally identifiable data in a secure way so that third parties cannot access them. Your data will not be merged with other data sources.
</p>
<h3>General terms and conditions</h3>
<p>
  Consumers are entitled to a right of revocation according to the following conditions, whereby the consumer is any natural person entering into a legal transaction for a purpose that can be attributed neither to a commercial nor a self-employed occupational activity.
</p>
<h4>Revocation instruction</h4>
<h4 className='small-top-padding'>Revocation right</h4>
<p>
  You have the right to withdraw from said contract without giving any reasons within 14 (fourteen) days after you or a third person designated by you, who is not the transport company, took possession of the goods.
</p>
<p>
  In order to exercise your revocation right, a clear statement on your intention to withdraw from the contract must be submitted (Hammelmann GmbH, Carl-Zeiss-Straße 6-8, 59302 Oelde, Germany, email: mail@hammelmann.de, telephone number: 02522 760) e.g. via mail, fax or email to us. You may use the sample revocation form in annex; this is, however, not mandatory. For compliance with the revocation period it is sufficient to notify us on the revocation before expiration of the revocation period.
</p>
<h4>Consequences of a revocation</h4>
<p>
  In case of an effective revocation we shall return any payments received by you, including delivery costs (with the exception of additional costs which were incurred because you chose another type of delivery than our inexpensive standard delivery) immediately and, at the latest within 14 days as of the date we received your notification on the revocation of the contract. We will use the same means of payment you used in the initial transaction for repayment, unless otherwise agreed with you; we will not charge any fees for repayment.
  <br/>
  We may refuse to make repayment until we have received all the goods or received evidence that you have shipped the goods to us, whichever occurs earlier.
  <br/>
  You shall return the goods immediately to us or hand the goods over to us, at the latest within 14 days as of the date you notified us on the revocation of this contract. The deadline is met if you dispatch the goods before expiration of this deadline. You shall bear the costs for returning the goods.
  <br/>
  The consumer shall only be liable for any diminished value of the goods resulting from the handling other than what is necessary to ascertain the nature, characteristics and functioning of the goods.
</p>
<h4>Premature lapse of the right of revocation</h4>
<p>
  The right of revocation expires prematurely for a contract on the delivery of digital content not provided on a physical data carrier if the company initiated the execution of the contract after the consumer has given his explicit consent and also confirmed that he is aware that his right of revocation expires on the execution of the contract.
</p>
<h4>Cancellation form</h4>
<p>
  (Please fill in said form and return it to us if you want to withdraw from the contract.)
</p>
<p>
  To Hammelmann GmbH, Carl-Zeiss-Straße 6-8, 59302 Oelde, Germany; email: mail@hammelmann.de:
</p>
<p>
  - I / we (*) revoke the contract concluded by me / us (*) on the purchase of the following goods (*)/ the following services (*)
  <br/>
  - Ordered on (*)/received on (*)
  <br/>
  - Name of the consumer(s)
  <br/>
  - Address of the consumer(s)
  <br/>
  - Signature of the consumer(s) (only for notifications via mail)
  <br/>
  - Date
  <br/>
  <br/>
  (*) Cross out non-applicable
</p>
<h4>YOU MUST ACCEPT THESE TERMS BEFORE STARTING AND USING THE APP!</h4>
<h4 className='small-top-padding'>1. GENERAL TERMS</h4>
<p>
  1.1 This agreement on the right of use (hereinafter referred to as "contract" or "agreement") is a binding contract that contains provisions between the user (either a natural or legal person) and the provider Hammelmann GmbH, Hammelmann GmbH, Carl-Zeiss-Straße 6-8, 59302 Oelde, Germany, (hereinafter referred to as "provider"). The copyright on the Water Jetting Calculator App (hereinafter referred to as "app") and any documents associated with it (including any files, pictures and texts) as well as accompanying written material are owned by the provider and are protected by national and international laws.
  <br/>
  1.2 By installing, copying, downloading or otherwise using the app the user agrees with the provider that he is bound by the terms and conditions of this agreement which governs the use of the app. If the user does not agree with this contract, he must not install the app.
  <br/>
  1.3 By installing the app the user also confirms that he has unlimited capacity to conduct business and is at least 18 years old.
  <br/>
  1.4 There is no right to the conclusion of this user agreement.
</p>
<h4>2. TERMS OF USE AND SPECIFICATION OF SERVICES</h4>
<p>
  2.1 The provider grants and the user accepts a non-transferrable and non-exclusive right to use the software in accordance with the following terms and conditions. The user may install the app for free and use the following features.
  <br/>
  2.2 The following is forbidden:
  <br/>
  a) The user is not allowed to rent out, lease, sublease, lend, copy, modify, amend, adapt/port, merge, translate, reverse engineer, operate, decompile the app or integrate it into another product to produce another similar app. This applies to the app as a whole and individual parts of the app and associated documentation.
  <br/>
  b) Copying and/or offering the app for illegal duplication on third-party systems.
  <br/>
  c) The aforementioned limitations shall not apply if the user’s rights resulting of Sec. 69d para. 2 and 3 and 69 e UrhG (German Copyright Act) are concerned. Notwithstanding the foregoing provision, the user’s one-off sale subject to the principle of exhaustion without retaining own rights of use is admissible.
  <br/>
  2.4 On conclusion of said agreement the provider makes the app available with the functions described in the product specification.
  <br/>
  2.5 The provider retains all the rights with respect to the app, which were not explicitly granted to the user, also regarding any of the following copies, disregarding the form or media.
  <br/>
  2.6 The provider retains the rights to the title, any protected rights to the app, including but not limited to any patents, business secrets, copyrights, brand names, trademarks and registered trademarks and registered trademarks.
  <br/>
  2.7 Despite the careful operation of our app, planned (maintenance) or unplanned interruptions may occur. There is no right to uninterrupted availability of our service.
</p>
<h4>3. CONCLUSION OF CONTRACT</h4>
<p>
  3.1 Provided that a contract is concluded via an app store (an app store where the app is downloaded) the app store’s terms of conditions shall additionally apply. In this respect, the following shall apply: The provider’s product catalogue illustrated in the store does not constitute a binding offer in the legal sense. By pressing the confirm button the user accepts the offer. The contract on the free use of the app is concluded when the user receives a confirmation email from the store or via delivery by download. The contract text is not stored by the provider.
  <br/>
  3.2 The agreement of use shall be valid until its termination on destruction/deletion of the app and all its copies.
  <br/>
  3.3 The right of use will be terminated immediately if the user does not fully comply with the terms and conditions of said agreement. In case of an infringement of these provisions the user must destroy the app and all the associated materials and copies.
</p>
<h4>4. WARRANTY AND LIABILITY</h4>
<p>
  4.1. The warranty is based on the statutory provisions.
  <br/>
  4.2 Due to technical reasons the use of the app may be temporarily limited or not possible. The user has to accept this to a reasonable extent. 4.3 and 4.4 shall remain unaffected.
  <br/>
  4.3 If the provider provides the app free of charge, the provider shall only be liable for damages caused by gross negligence or intent.
  <br/>
  4.4 If a defect has been fraudulently concealed by the provider, the provider shall be liable for any damages caused to the user.
  <br/>
  4.5 The store operator (app store operator where the software was downloaded) shall not be liable for warranty and liability claims with respect to the app or its use, in particular, no product liability claims or claims based on consumer protection. However, the user is free to directly contact the provider or inform the store operator in case of claims to defect and liability.
  <br/>
  4.6 Furthermore, the store operator is entitled to enforce the terms of contract in his own name vis- à-vis the end client.
</p>
<h4>5. RESPONSIBILITY FOR THE CONTENT</h4>
<p>
  5.1 The provider’s app offers calculation methods of high-pressure water tools. These serve as an initial orientation. However, they cannot replace an exact, safe calculation.
  <br/>
  5.2 The user is responsible that any content (figures etc.) provided is free of rights of third parties and, from a legal perspective, also suitable for this purpose and for making the content available. The user is responsible for the completeness and correctness of the content provided.
  <br/>
  5.3 The app also allows the user to contact a trading partner of the provider. Any contracts and obligations concluded beyond this agreement with trading partners are exclusively concluded between the user and the respective trading partner.
  <br/>
  5.4 The user must not use addresses, contact data and other personally identifiable data which he received by using the app from a trading partner for other purposes than the contractual and pre- contractual communication and for the respective request in the framework of business activities with the trading partner. In particular, it is forbidden to further sell or use the data for sending advertisement, unless the respective trading partner has given his consent in this respect.
</p>
<h4>6. MISCELLANEOUS</h4>
<p>
  6.1 In case individual provisions of the contract with the client, including these terms and conditions, shall be or become fully or partly ineffective the validity of the remaining provisions shall remain unaffected.
  <br/>
  6.2 The relationship between the contracting parties shall be governed by the applicable laws of the Federal Republic of Germany under exclusion of the United Nations Convention on Contracts for the International Sale of Goods, CISG. With respect to a consumer this legal option shall only apply if no compulsory statutory conditions of the state where he has his residence or common residence are restricted.
  <br/>
  6.3 If the user is a merchant, legal person of public law or of a special fund under public law the provider’s headquarters is the exclusive jurisdiction for any disputes arising from this contract. The same shall apply if the user has no general jurisdiction in Germany or if the residence or usual place of residence at the time the claim has been asserted is not known.
  <br/>
  6.4 The contract language is German. This agreement has initially been drawn up in German. Even if one or several translations were made available the German version shall prevail in case of conflicts.
</p>
`
  }
});

const appName = 'Water Jetting Calculator';

class Disclaimer extends Component {

  render() {

    const { formatMessage } = this.props.intl;

    return (
      <Layout caption={formatMessage(messages.sidemenu_disclaimer)}>
        <div className="disclaimer-container">
          <div className="disclaimer">
            <h2><FormattedMessage id="sidemenu_disclaimer" defaultMessage="Disclaimer"/></h2>
            <div className='disclaimer-content' dangerouslySetInnerHTML={{__html: formatMessage(messages.sidemenu_disclaimer_content)}} />
          </div>
        </div>
      </Layout>
    );
  }
}

export default injectIntl(Disclaimer)
