import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {getImageURI} from 'service/CordovaService';

export default class AsyncImage extends Component {

    static propTypes = {
        src : PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            uri :  null
        }
    }

    srcToPromise = (src) => {
        if(src)
            return getImageURI(src);
        else
            return null
    }

    componentDidMount() {
        let {src, ...otherProps} = this.props;
        src = this.srcToPromise(src);
        if(src && src instanceof Promise) {
            src.then((uri) => {
                setTimeout(() => {
                    this.setState({
                        uri : uri
                    })
                })
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.src != prevProps.src) {
            let {src, ...otherProps} = this.props;
            src = this.srcToPromise(src);

            src.then((uri) => {
                setTimeout(() => {
                    this.setState({
                        uri : uri
                    })
                })
            })
        }
    }

    render = () => {
        const {src, ...otherProps} = this.props;
        const { uri } = this.state;

        if(uri) {
            return <img src={uri} {...otherProps} />;
        } else {
            return null;
        }
    }

}