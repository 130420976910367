import React, { Component } from 'react';
import PropTypes from 'prop-types';

import colors from 'style'
import 'components/layout/productWizard/wizardItem.scss';
import {FormattedMessage} from "react-intl";
import {MdMoreVert as Ellipsis} from "react-icons/md";

export default class WizardItem extends Component {

  static propTypes = {
    image: PropTypes.any,
    label: PropTypes.any,
    onClick: PropTypes.func,
    border: PropTypes.bool,
    type: PropTypes.oneOf(['default', 'simple', 'hint']),
    style: PropTypes.object,
    isActive: PropTypes.bool,
    isDisabled: PropTypes.bool
  }

  static defaultProps = {
    type: "default",
    style: {},
    isActive: false,
    isDisabled: false,
  }

  constructor(props) {
    super(props);
  }

  onClick = () => {
    if(this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    return (
      <div className={`product-wizard-item product-wizard-item--${this.props.isActive?'active':''} product-wizard-item-type--${this.props.type} ${!this.props.isDisabled && this.props.onClick?'product-wizard-item-type--clickable':''} ${this.props.border?'product-wizard-item-type--border':''} product-wizard-item--${this.props.isDisabled?'disabled':''}`} style={this.props.style} onClick={()=>this.onClick()}>
        <If condition={this.props.image}>
          <div className={"product-wizard-item-image"}>
            {this.props.image}
          </div>
        </If>
        <If condition={this.props.label}>
          <div className={"product-wizard-item-label"}>
            {this.props.label}
          </div>
        </If>
        <If condition={this.props.onClick}>
          <Ellipsis className="product-wizard-masterjet-label-ellipsis" />
        </If>
      </div>
    )
  }
}

