import { createSelector } from 'reselect'
import _ from 'lodash';
import {getDistance} from 'service/GeoService';

import {getMyLocation, getMyCountry} from 'storeState/ui/selectors';
import {getMasterjetConfigurationParams} from "storeState/ui/selectors";

const getStateRemoteData = (state) => state.data.data; //?state.data.data:{};
const getStateUnits = (state) => state.data.units;
const getStateCurrentDataUnitSystem = (state) => state.data.currentDataUnitSystem;
export const getEnableGoogleAnalytics = (state) => state.data.enableGoogleAnalytics;

export const getUnits = createSelector(
    getStateUnits,
    (units) => {
        return _.sortBy(units, (x) => x.id);
    }
);

export const getFreeUnitId = createSelector(
    getUnits,
    (units) => {
        if (units.length > 0) {
            return _.max(_.map(units, (unit) => unit.id)) + 1
        } else {
            return 1;
        }
    }
)

export const getCurrentDataUnitSystem = createSelector(
    getStateCurrentDataUnitSystem,
    (currentDataUnitSystem) => {
        return currentDataUnitSystem;
    }
)

export const getDataunits = createSelector(
    getStateRemoteData,
    (remoteData) => {
        const {dataunits} = remoteData;
        if(!dataunits) return [];
        return _.keyBy(dataunits, 'id');
    }
)

export const getDataunitsBySymbol = createSelector(
    getStateRemoteData,
    (remoteData) => {
        const {dataunits} = remoteData;
        if(!dataunits) return [];
        return _.keyBy(dataunits, 'symbol');
    }
)

export const getEnhancedProducts = createSelector(
    getStateRemoteData, getDataunits, function(remoteData, dataunits) {
        const {products} = remoteData;

        if(!products) return [];

        return _.map(products, (product) => {
            let p = {
                ...product,
                dataunits : {}
            };
            _.each(p.data,(v, k) => {
                if(!dataunits[k]) {
                    console.log("remoteData", remoteData);
                    console.log("dataunits", dataunits);
                    console.log("p", p);
                    console.log("k", k);
                    console.log("v", v);
                } else {
                  p.dataunits[dataunits[k].symbol] = {
                      ...dataunits[k],
                      "value" : p.data[k],
                      "value_with_unit" : p.data[k] + " " + dataunits[k]["si_unit"]
                  }
                }
            })
            return p;
        })
    }
)

export const getProductsBySubCategoryStringID = createSelector(
    getStateRemoteData, getEnhancedProducts,
    (remoteData, products) => {
        const {subcategories} = remoteData;
        if(!subcategories) return [];
        let ret = {};
        //console.log("products",products);
        for(let s of subcategories) {
            ret[s.string_id] = _.filter(products, (p) => p.subcategory_id == s.id);
        }
        console.log("getProductsBySubCategoryStringID",ret)
        return ret;
    }
)

export const getNozzles = createSelector(
    getProductsBySubCategoryStringID,
    (data) => {
        return data.nozzles || [];
    }
)

export const getTCHNozzles = createSelector(
    getProductsBySubCategoryStringID,
    (data, nozzleHolder) => {
        console.log("Nozle-Holder:", nozzleHolder);
        console.log("TCH_nozzles:", data.tch_nozzles);
        return data.tch_nozzles || [];
    }
)

export const getMasterJetNozzles = createSelector(
  getProductsBySubCategoryStringID,
  (data) => {
    return data.mj_nozzles || [];
  }
)

export const getHoses = createSelector(
    getProductsBySubCategoryStringID,
    (data) => {
        return data.hoses || [];
    }
)

export const getNozzleHolders = createSelector(
    getProductsBySubCategoryStringID, getTCHNozzles,
    (data, nozzles) => {

        if(data && data.aquamats) {
            for(let nzh of data.nozzle_holders) {
                if(nzh.dataunits["nozzles"]) {
                    let nzhNozzles = [];
                    const separatedString = nzh.dataunits["nozzles"].value;
                    const ids = _.map(_.split(separatedString, ";"), (x) => _.trim(x));
                    for (let n of nozzles) {
                        if (ids.indexOf(n.id) > -1) {
                            nzhNozzles.push(n);
                        }
                    }
                    nzh.nozzles = nzhNozzles;
                } else {
                    nzh.nozzles = nozzles; //As fallback we use all nozzles and emit a warning
                    console.warn(`The TCH nozzle-holder ${nzh.name} has not specified its nozzles. Choosing all TCH Nozzles.`)
                }
            }
        }

        return _.sortBy(data.nozzle_holders, (nzh) => new Number(nzh.dataunits.NZHLa.value)) || [];
    }
)

export const getAquamats = createSelector(
    getProductsBySubCategoryStringID, getNozzleHolders,
    (data, nozzleHolders) => {
        if(data && data.aquamats) {
          for(let tch of data.aquamats) {
              let tchNzhs = [];
              const separatedString = tch.dataunits["NZHs"].value;
              const ids = _.map(_.split(separatedString,";"),(x) => _.trim(x));
              for(let nzh of nozzleHolders) {
                  if(ids.indexOf(nzh.id)>-1) {
                      tchNzhs.push(nzh);
                  }
              }
              tch.NZHs = tchNzhs;
          }
        }
        console.log("data.aquamats",data.aquamats)
        return data.aquamats || [];
    }
)

export const getFormulas = createSelector(
    getStateRemoteData,
    (remoteData) => {
        return remoteData?_.mapValues(_.keyBy(remoteData.formulas,"string_id"),"expression"):null;
    }
)

export const getContacts = createSelector(
    getStateRemoteData,
    (remoteData) => {
        const {contacts} = remoteData;
        if(!contacts) return [];
        return contacts;
    }
)

export const getContactSuggestions = createSelector(
    getMyLocation, getMyCountry, getContacts,
    (location, country, contacts) => {
        if(!location) {
            return [];
        } else {
            let suggestions = _.filter(contacts, (c) => {
                return c.country_code == country;
            })
            let maxSuggestions = 1000;
            if(suggestions.length==0) { //fallback if no suggestion found in same country
                maxSuggestions = 5;
                suggestions = contacts;
            }
            let suggestionsWithDistance = _.map(suggestions,(contact) => {
                return {
                    ...contact,
                    distance : getDistance(location.latitude, location.longitude, contact.latitude, contact.longitude)
                }
            });
            let sorted = _.sortBy(suggestionsWithDistance,"distance");
            return _.slice(sorted,0,Math.max(maxSuggestions,sorted.length));
        }
    }
)

export const getMasterjetProducts = createSelector(
  getProductsBySubCategoryStringID,
  getMasterJetNozzles,
    (data, nozzleTypes) => {
      if(data && data.masterjets) {
        for(let mj of data.masterjets) {
          let mjnz = [];
          const separatedString = mj.dataunits["nozzle_types"].value;
          const ids = _.map(_.split(separatedString,";"),(x) => _.trim(x));
          for(let nzh of nozzleTypes) {
            if(ids.indexOf(nzh.id)>-1) {
              mjnz.push(nzh);
            }
          }
          mj.mjnz = mjnz;
        }
      }
      return data.masterjets || [];
    }
)
