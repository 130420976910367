import { createSelector } from 'reselect'

export const getNews = (state) => {
  if(!state.news || !state.news.items){
    return [];
  }else{
    return state.news.items;
  }
}

