import React, { Component } from 'react';
import Layout from 'layout/Layout';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UIActions from 'storeState/ui/actions';
import { menuItems } from 'storeState/ui/constants';
import { getFormulas, getDataunitsBySymbol, getUnits} from 'storeState/data/selectors';
import { getNozzleCalculationParams } from 'storeState/ui/selectors';
import { TabNavigation, Tab } from 'layout/tabNavigation/TabNavigation';
import SectionHeader from 'layout/sectionHeader/SectionHeader';
import UnitSelector from 'layout/unitSelector/UnitSelector';
import CalculationResult from 'layout/calculationResult/CalculationResult';
import DataUnitTextField from 'layout/dataUnitTextField/DataUnitTextField';
import NozzleConfigurationTable from 'layout/configurationTable/NozzleConfigurationTable';
import {evaluateMathProgram, formatValueWithUnit, getRawValue} from 'service/MathService';
import {getRPMLable} from 'service/LableService';
import _ from 'lodash';
import './nozzlecalculation.scss';

const messages = defineMessages({
    nozzle_calculation_caption: {
        id: 'nozzle_calculation_caption',
        defaultMessage: 'Nozzle calculation'
    }
});

class NozzleCalculation extends Component {

  constructor(props) {
      super(props);
      this.lastParams = null;
      this.state = {
          result : {
              warnings : [],
              values : []
          }
      }
  }

  componentDidMount() {
      setTimeout(() => {
          this.props.uiActions.setActiveMenuItem({item: menuItems.nozzleCalculation})
      })
      this.doRecalc();
  }

  getUnitParams() {
      let unitParams={};
      if(this.props.calculationParams && this.props.calculationParams.unit) {
          unitParams = this.props.calculationParams.unit;
      } else {
          if(this.props.units.length>0) {
              unitParams = {
                  unit: this.props.units[0],
                  ...this.props.units[0]
              }
          } else {
              unitParams = {}
          }
      }
      return unitParams;
  }

  handleUnitSelect = (unit) => {
      this.props.uiActions.setCalculationParameters({
          key : "nozzleCalculation",
          params : {
              unit: {
                  ...this.getUnitParams(),
                  ...unit
              }
          }
      });
  }

  handleNozzleConfigurationChange = (items) => {
      this.props.uiActions.setCalculationParameters({
          key : "nozzleCalculation",
          params : {
              nozzles: items
          }
      });
  }

  componentDidUpdate(prevProps, prevState) {

    if(this.props.calculationParams.unit) {
      const propUnit = _.find(this.props.units, un => un.id === this.props.calculationParams.unit.id);
      if(propUnit) {
        const comparePropParams = {
          maxFlowrate: propUnit.maxFlowrate,
          maxPressure: propUnit.maxPressure,
          rpmMax: propUnit.rpmMax,
          rpmMin: propUnit.rpmMin,
        }
        const compareCalcParams = {
          maxFlowrate: this.props.calculationParams.unit.maxFlowrate,
          maxPressure: this.props.calculationParams.unit.maxPressure,
          rpmMax: this.props.calculationParams.unit.rpmMax,
          rpmMin: this.props.calculationParams.unit.rpmMin,
        }
        if(!_.isEqual(comparePropParams, compareCalcParams)) {
          this.handleUnitSelect(propUnit);
        }
      }
    }

    this.doRecalc();
  }

  doRecalc = () => {
    if(this.recalcTimer) {
      clearTimeout(this.recalcTimer);
    }
    this.recalcTimer = setTimeout(() => {
        this.recalculate()
    },300)
  }

  recalculate = () => {
      const f = this.props.formulas.nozzle_calculation;
      if(this.props.calculationParams && this.props.calculationParams.nozzles && this.props.calculationParams.nozzles.length>0) {

          let warnings = [];

          const unitParams = this.getUnitParams();

          if(!unitParams || !unitParams.rpmMin || !unitParams.rpmMax) {
              warnings.push({
                  type: "info",
                  label: <FormattedMessage id="nozzle_calculation_wshort_NOTSET_UHD" defaultMessage="R.P.M. not set"/>,
                  info: <FormattedMessage id="nozzle_calculation_wlong_NOTSET_UHD"
                                          defaultMessage="If you have an electrically driven or diesel driven plunger high pressure water unit, please create a present under “Properties” to be warned if your R.P.M. are possible, by setting the max. and min. limits of your engine."/>
              })
          }
          if(!unitParams || !unitParams.maxFlowrate) {
              warnings.push({
                  type: "info",
                  label: <FormattedMessage id="nozzle_calculation_wshort_NOTSET_QMAX"
                                           defaultMessage="No max. flow rate set"/>,
                  info: <FormattedMessage id="nozzle_calculation_wlong_NOTSET_QMAX"
                                          defaultMessage="If you have an electrically driven or diesel driven plunger high pressure water unit, please create a present under “Properties” to be warned if your flow rate is possible, by setting the max. limits of your unit."/>
              })
          }
          if(!unitParams || !unitParams.maxPressure) {
              warnings.push({
                  type: "info",
                  label: <FormattedMessage id="nozzle_calculation_wshort_NOTSET_PBMAX" defaultMessage="No max. op. pressure set" />,
                  info: <FormattedMessage id="nozzle_calculation_wlong_NOTSET_PBMAX" defaultMessage="If you have an electrically driven or diesel driven plunger high pressure water unit, please create a present under “Properties” to be warned if your operating pressure is possible, by setting the max. limits of your unit." />
              })
          }

          const params = {
              nozzles : _.map(this.props.calculationParams.nozzles, (nozzle) => { return {
                  'di': nozzle.diameter,
                  'n': nozzle.amount,
                  'μ': nozzle.efficiency,
                  'pNZmax': nozzle.maxPressure
              }}),
              pB: unitParams.pressure ? unitParams.pressure : "0 bar", //bar
              pBmax : unitParams.maxPressure ? unitParams.maxPressure : null, //bar
              Qmax : unitParams.maxFlowrate ? unitParams.maxFlowrate : null, //400 l/mins;
              uHDmax : unitParams.rpmMax ? unitParams.rpmMax : null, //300 mins ^ -1 in mins ^ -1;
              uHDmin : unitParams.rpmMin ? unitParams.rpmMin : null //300 mins ^ -1 in mins ^ -1;
          };
          if(_.isEqual(params, this.lastParams)) {
              return;
          }
          const res = evaluateMathProgram(f, params);
          this.lastParams = params;

          /*
            MAX_FR (in N)
            MAX2_FR (in N)
            WARN_UHD_LOW
            WARN_UHD_HIGH
            WARN_FR_HIGH
            WARN_FR_VERY_HIGH
            WARN_QTOT
            WARN_PB_HIGH
          */
          if(res["WARN_PNZMAX_EXCEED"]) {
            let wNozzle = _.minBy(this.props.calculationParams.nozzles, nozzle => {return getRawValue(nozzle.maxPressure)});
            warnings.push({
              type: "error",
              label: <FormattedMessage id="flowrate_wshort_PNZMAX" defaultMessage="Nozzle insert at max. op. pressure" />,
              info: <FormattedMessage id="flowrate_wlong_PNZMAX" defaultMessage="Please do not exceed the maximum pressure specifications of the nozzle insert, for {nozzle} nozzles it is {pressure}"
                values={{nozzle: wNozzle.type.name, pressure: formatValueWithUnit({ valueWithUnit: wNozzle.maxPressure, si_unit: "bar", us_unit: "psi", precision: 0 })}}
              />
            });
          }
          if(res["WARN_UHD_LOW"]) {
              warnings.push({
                  type: "error",
                  label: <FormattedMessage id="nozzle_calculation_wshort_UHD_LOW" defaultMessage="R.P.M. to low" />,
                  info: <FormattedMessage id="nozzle_calculation_wlong_UHD_LOW" defaultMessage="The min. r.p.m of the high pressure water unit has been exceeded for the desired flow rate." />
              })
          }
          if(res["WARN_UHD_HIGH"]) {
              warnings.push({
                  type: "error",
                  label: <FormattedMessage id="nozzle_calculation_wshort_UHD_HIGH" defaultMessage="R.P.M. to high" />,
                  info: <FormattedMessage id="nozzle_calculation_wlong_UHD_HIGH" defaultMessage="The max. r.p.m of the high pressure water unit has been exceeded for the desired flow rate." />
              })
          }
          if(res["WARN_FR_VERY_HIGH"]) {
              warnings.push({
                  type: "error",
                  label: <FormattedMessage id="nozzle_calculation_wshort_FR_VERY_HIGH" defaultMessage="Hand held reaction force" />,
                  info: <FormattedMessage id="nozzle_calculation_wlong_FR_VERY_HIGH" defaultMessage="The hand held use of high pressure water blasting guns is forbidden above 250 N reaction force. Please see local waterblasting safety regulations." />
              })
          } else if(res["WARN_FR_HIGH"]) {
              warnings.push({
                  type: "warning",
                  label: <FormattedMessage id="nozzle_calculation_wshort_FR_HIGH" defaultMessage="Hand held reaction force" />,
                  info: <FormattedMessage id="nozzle_calculation_wlong_FR_HIGH" defaultMessage="Please be warned that the use of hand held high pressure water blasting guns without a shoulder stock is not recommended above 150 N reaction force. Please see local waterblasting safety regulations." />
              })
          }
          if(res["WARN_QTOT"]) {
              warnings.push({
                  type: "error",
                  label: <FormattedMessage id="nozzle_calculation_wshort_QTOT" defaultMessage="Max. flow rate exceeded" />,
                  info: <FormattedMessage id="nozzle_calculation_wlong_QTOT" defaultMessage="The max. flow rate associated with your unit has been exceeded! Go to „Properties“ to change your max. flow rate or decrease your flow rate in your result by changing your input." />
              })
          }
          if(res["WARN_PB_HIGH"]) {
              warnings.push({
                  type: "error",
                  label: <FormattedMessage id="nozzle_calculation_wshort_PB_HIGH" defaultMessage="Caution! Over pressurizing!" />,
                  info: <FormattedMessage id="nozzle_calculation_wlong_PB_HIGH" defaultMessage="Over pressurizing of the pump – rupture disc burst or safety valve actuating possible!" />
              })
          }

          let values = [{
              type: (res["WARN_QTOT"]) ? "error" : "valid",
              label: <FormattedMessage id="flowrate_result_qtot" defaultMessage="Total flowrate"/>,
              value: (<DataUnitTextField decimalPlaces={1} align="right-m-left-d" value={res['Qtot']} editable={false} si="l/mins" siLabel="l/min" us="gal/mins" usLabel="gal/min" />)
          },{
              type: res["WARN_FR_VERY_HIGH"] ? "error" : res["WARN_FR_HIGH"] ? "warning" : "valid",
              label: <FormattedMessage id="flowrate_result_fr" defaultMessage="Reaction Force"/>,
              value: (<DataUnitTextField decimalPlaces={0} align="right-m-left-d" value={res['Fr']} editable={false} si="N" us="lbf" />)
          }]

          if(typeof res['uHD'] !== "undefined") {
              values.push({
                  type: (res["WARN_UHD_LOW"] || res["WARN_UHD_HIGH"]) ? "error" : "valid",
                  label: <FormattedMessage id="flowrate_result_rpm" defaultMessage="RPM"/>,
                  value: (<DataUnitTextField decimalPlaces={0} align="right-m-left-d" value={res['uHD']} editable={false} si="mins^-1" customLabel={getRPMLable()} />)
              })
          }

          this.setState({
              result : {
                  values : values,
                  warnings : warnings
              }
          })
      }
  }

  render() {

    const { formatMessage } = this.props.intl;
    const unitParams = this.getUnitParams()

    return (
      <Layout caption={formatMessage(messages.nozzle_calculation_caption)}>
        <div className='nozzle-tab-info-mobile'><FormattedMessage id='nozzle_tabs_info' defaultMessage='Please choose your results'/></div>
        <TabNavigation>
          <Tab to="/nozzlecalculation" active={true}><FormattedMessage id="nozzle_tabs_flowrate" defaultMessage="Flow rate" /></Tab>
          <Tab to="/nozzlediameter" active={false}><FormattedMessage id="nozzle_tabs_diameter" defaultMessage="Nozzle diameter" /></Tab>
          <div className='nozzle-tab-info'><FormattedMessage id='nozzle_tabs_info' defaultMessage='Please choose your results'/></div>
        </TabNavigation>

        <SectionHeader><FormattedMessage id="nozzle_calculation_header_unit" defaultMessage="UNIT"/></SectionHeader>
        <UnitSelector
            onUnitChange={this.handleUnitSelect}
            showPressure={true}
            units = {this.props.units}
            {...unitParams}
        />

        <SectionHeader><FormattedMessage id="nozzle_calculation_header_configuration" defaultMessage="NOZZLE CONFIGURATION"/></SectionHeader>
        <NozzleConfigurationTable
            editableDiameter={true}
            onConfigurationChange={this.handleNozzleConfigurationChange}
            enableDeletion={true}
            showDiameter={true}
            items={this.props.calculationParams && this.props.calculationParams.nozzles ? this.props.calculationParams.nozzles : null}
        />
        <CalculationResult
            values = {this.state.result.values}
            warnings = {this.state.result.warnings}
        />
      </Layout>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    //enabled: getEnabled(state),
      formulas: getFormulas(state),
      dataunits: getDataunitsBySymbol(state),
      calculationParams: getNozzleCalculationParams(state),
      units: getUnits(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(UIActions, dispatch),
  }
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(NozzleCalculation))
