import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {FormattedMessage, injectIntl} from 'react-intl';
import clone from 'lodash/clone';
import Modal from 'react-modal';
import CircularSlider from './CircularSlider';
import {getRawValue} from 'service/MathService';

import './angleSelectorModal.scss';

export default class AngleSelectorModal extends Component {

    static propTypes = {
        onChange : PropTypes.func,
        value : PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);
    }

    emitChange = ({ value }) => {
        if(this.props.onChange) {
            this.props.onChange({
                value : Math.round(value) + " deg"
            })
        }
    }

    render() {
        const {} = this.props;

        return (
          <div className='angle-selector-modal-container'
          onTouchMove={e=>{
            e.stopPropagation();
            e.preventDefault();}
          }>
            <CircularSlider
              onChange={ (value) => this.emitChange({ value }) }
              value={ Math.round(getRawValue(this.props.value)) }
              radius={ 45 }
              border={ 7 }
              min={ 0 }
              max={ 180 }
              angle={ 0 }
              origin={ 1.0 }
              start={ 0 }
            />
          </div>
        );
    }
}
