import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {FormattedMessage, injectIntl} from 'react-intl';
//import clone from 'lodash/clone';
//import Ellipsis from 'react-icons/lib/fa/ellipsis-v';
import Modal from 'react-modal';
import DataUnitTextField from 'layout/dataUnitTextField/DataUnitTextField';
import AngleSelectorModal from './AngleSelectorModal';
import {MdClose as IconClose} from "react-icons/md";
import NozzleImage from 'assets/images/nozzle_angle.png';
import Button from 'layout/button/Button';
import ButtonBar from 'layout/buttonBar/ButtonBar';

import './angleSelector.scss';

export default class AngleSelector extends Component {

    static propTypes = {
        onChange : PropTypes.func,
        value : PropTypes.string.isRequired,
        heading: PropTypes.object
    }

    constructor(props) {
        super(props);
        this.state = {
          selectionModalOpen: false,
          value: props.value
        }
        this.changeEmitted = false;
    }

    componentDidUpdate(){
      if(this.props.value != this.state.value && this.changeEmitted){
        this.changeEmitted = false;
        this.setState({
          value: this.props.value
        })
      }
    }

    handleEllipsisClick = () => {
        this.setState({
            selectionModalOpen : true
        })
    }

    handleSelectionModalCancel = () => {
        this.setState({
            selectionModalOpen : false
        })

        this.emitChange({value: this.state.value});
    }

    emitChange = ({value}) => {
      this.changeEmitted = true;
      if(this.props.onChange) {
          this.props.onChange({
              value
          })
      }
    }

    emitChangeLocal = ({value}) => {
        this.setState({
          value: value,
        });
    }

    render() {
        const {selectionModalOpen} = this.state;
        const {} = this.props;

        //<Ellipsis className="angle-selector-ellipsis" />
        return (
            <div className='config-modal'>
                <div className="angle-selector-row angle-selector-row-unit" onClick={this.handleEllipsisClick} >
                    <div className="angle-selector-row-value">
                        <DataUnitTextField value={this.props.value} si="deg" siLabel="°" editable={false} />
                    </div>
                </div>
                <If condition={selectionModalOpen}>
                    <Modal isOpen={true} className="ci-modal ci-modal-scroller-max ci-modal-nopadding angle-modal" overlayClassName="ci-modal-overlay" onRequestClose={this.handleSelectionModalCancel}>
                      <div className="angle-selector-close" onClick={this.handleSelectionModalCancel}>
                        <IconClose />
                      </div>
                      <If condition={this.props.heading}>
                          <div className="angle-selector-heading">
                            {this.props.heading}
                          </div>
                      </If>
                      <AngleSelectorModal value={this.state.value} onChange={(ev) => this.emitChangeLocal(ev)} />
                      <div className='angle-selector-nozzle-image-container'>
                        <img className="angle-selector-nozzle-image" src={NozzleImage} />
                      </div>
                      <div className='angle-selector-content'>
                          {this.props.children}
                      </div>
                      <div className="angle-selector-button-container" onClick={this.handleSelectionModalCancel}>
                        <ButtonBar>
                          <Button>OK</Button>
                        </ButtonBar>
                      </div>
                    </Modal>
                </If>
            </div>
        );
    }
}
