import {rootReducer, rootSaga, localPersistenceMiddleware} from "./storeState";
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga'

const sagaMiddleware = createSagaMiddleware();

let store;
if(process.env.NODE_ENV === "development") {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(sagaMiddleware, localPersistenceMiddleware)));
} else {
  store = createStore(rootReducer, {}, applyMiddleware(sagaMiddleware, localPersistenceMiddleware));
}

sagaMiddleware.run(rootSaga);

export default store;
