import React,  { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-modal';
import {isIosStatusBar} from 'service/LayoutService';
import ReactDOM from 'react-dom'

import './modalselector.scss';
const iosStatusBar = isIosStatusBar();

export default class ModalSelector extends Component {

    static propTypes = {
        items : PropTypes.array.isRequired,
        renderSelectedItem : PropTypes.func,
        renderModalItemRow : PropTypes.func,
        renderModalNoItemsRow : PropTypes.func,
        selectedItem : PropTypes.any,
        onChange : PropTypes.func,
        getItemKey : PropTypes.func
    }

    static defaultProps = {
        getItemKey : (item) => item.id
    }

    constructor(props) {
        super(props);

        this.state = {
            selectionModalOpen : false
        }
    }

    handleSelectionModalCancel = () => {
        this.setState({
            selectionModalOpen : false
        })
    }

    handleItemSelect = (item) => {
        this.setState({
            selectionModalOpen : false
        })
        this.emitChange(item);
    }

    handleElementClick = () => {
        this.setState({
            selectionModalOpen : true
        })
    }

    emitChange = (item) => {
        if(this.props.onChange) {
            this.props.onChange(item);
        }
    }

    render = () =>  {
        const { items, renderSelectedItem, renderModalItemRow, renderModalNoItemsRow, selectedItem, getItemKey } = this.props;

        return (
            <div className={`modal-selector-container`}>
                <div className="modal-selector-element" onClick={this.handleElementClick}>
                    {renderSelectedItem(selectedItem)}
                </div>
                <If condition={this.state.selectionModalOpen}>
                    <Modal isOpen={true} className={`ci-modal ci-modal-nopadding modal-selector-container modal-selector-scroller ${iosStatusBar?'modal-selector-ios-status-bar':''}`} overlayClassName="ci-modal-overlay" onRequestClose={this.handleSelectionModalCancel}>
                        <If condition={items.length>0}>
                          <div ref={ref=>{
                              if(ref){
                                let domNode = ReactDOM.findDOMNode(ref);
                                let scrollDOMContainer = domNode.parentNode;
                                let selectedIndex = _.findIndex(items, item => {
                                  return item == selectedItem;
                                });
                                if(selectedIndex > 0){
                                  let scrollHeight = 0;
                                  for(var i=0; i<(selectedIndex); i++){
                                    scrollHeight += domNode.childNodes[i].clientHeight + 2;
                                  }
                                  scrollDOMContainer.scrollTop = Math.max(0,(scrollHeight + (domNode.childNodes[selectedIndex].clientHeight+2)/2 - scrollDOMContainer.clientHeight/2))
                                }
                              }
                            }}>
                              <For each="item" of={items}>
                                <div key={getItemKey(item)} className={`modal-selector-modal-row ${item && item == selectedItem?'selected':''}`} onClick={() => this.handleItemSelect(item)}>
                                    {renderModalItemRow(item, item == selectedItem)}
                                </div>
                              </For>
                            </div>
                        <Else />
                            <div key="no-items" className="modal-selector-modal-row modal-selector-modal-row-no-items">
                                {renderModalNoItemsRow()}
                            </div>
                        </If>
                    </Modal>
                </If>
            </div>

        )
    }

}
