import * as types from './constants';

export function setInitialMenuOpened(initialMenuOpened) {
  return { type: types.SET_INITIAL_MENU_OPENED, payload: {initialMenuOpened} };
}

export function setActiveMenuItem(params) {
  return { type: types.SET_ACTIVE_MENU_ITEM, payload: { item: params.item } };
}

export function setMyLocation(coords) {
  return { type: types.SET_MY_LOCATION, payload: { coords: coords } };
}

export function setMapLocation(coords) {
  return { type: types.SET_MAP_LOCATION, payload: { coords: coords } };
}

export function setReverseGeocodeResult(rgeo) {
  return { type: types.SET_REVERSE_GEOCODE_RESULT, payload: { rgeo: rgeo } };
}

export function setLocale(locale, initial) {
  return { type: types.SET_LOCALE, payload: { locale: locale, initial: initial } };
}

export function setAgbAccepted(accepted) {
    return {type: types.SET_AGB_ACCEPTED, payload: {accepted: accepted}};
}

export function setInitialSettingsSet(set) {
    return {type: types.SET_INITIAL_SETTINGS_SET, payload: {set: set}};
}

export function setCalculationParameters({key, params}) {
    return { type: types.SET_CALCULATION_PARAMETERS, payload: { key, params } };
}

export function showInfoMessage({message}) {
  return { type: types.SHOW_INFO_MESSAGE, payload: { message } };
}
