import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'components/layout/productWizard/wizardProductItem.scss';
import AsyncImage from "components/layout/asyncImage/AsyncImage";
import CollapseButton from "../collapseButton/collapseButton";
import masterjet_image from "assets/images/masterjet.png";

export default class WizardProductItem extends Component {

  collapseButton = null;

  static propTypes = {
    image: PropTypes.any,
    label: PropTypes.any,
    attributes: PropTypes.array,
    collapseAttributes: PropTypes.array,
    onClick: PropTypes.func
  }

  static defaultProps = {
    attributes: []
  }

  constructor(props) {
    super(props);
    this.state = {
      attributesCollapsed: true
    }
  }

  onClick = () => {
    if(this.props.onClick) {
      this.props.onClick();
    }
  }

  onCollapse = (collapsed) => {
    this.setState({
      attributesCollapsed: collapsed
    });
  }

  onButtonClick = (e) => {
    e.stopPropagation();
    this.collapseButton?.handleCollapse();
  }

  render() {

    return (
      <div className={`product-wizard-product-container ${this.props.border?'border':''}`}>

        <div className={`product-wizard-product-item product-wizard-product-item-type--${this.props.type} ${this.props.onClick?'clickable':''}`}
             style={this.props.style}
             onClick={()=>this.onClick()}>

          <div className={'product-wizard-product-item-container'}>
            <div className={"product-wizard-product-item-image"}>
              <If condition={this.props.image}>
                <AsyncImage src={this.props.image} />
              <Else/>
                <img src={masterjet_image} />
              </If>
            </div>
            <div className={"product-wizard-product-item-content"}>
              <If condition={this.props.label}>
                <div className={"product-wizard-product-item-label"}>
                  {this.props.label}
                </div>
              </If>
              <If condition={this.props.attributes.length > 0}>
                <div className={"product-wizard-product-item-attributes"}>
                  <For each="attribute" of={this.props.attributes}>
                    <div className={"product-wizard-product-item-attribute"}>
                      {attribute}
                    </div>
                  </For>
                </div>
              </If>
          </div>

          </div>
          <If condition={this.props.attributes.length > 0}>
            <div className={"product-wizard-product-item-collapse-container"} onClick={this.onButtonClick}>
              <CollapseButton ref={btn => this.collapseButton = btn} onCollapse={this.onCollapse}></CollapseButton>
            </div>
          </If>
        </div>

        <If condition={!this.state.attributesCollapsed && this.props.attributes.length > 0}>
          <div className={"product-wizard-product-item-collapse-attributes-container"}>
            <For each="attr" of={this.props.collapseAttributes}>
            <div className={"product-wizard-product-item-collapse-attribute"}>
              <div className={"product-wizard-product-item-collapse-attribute-label"}>{attr.label}</div>
              <div className={"product-wizard-product-item-collapse-attribute-value"}>{attr.value}</div>
            </div>
            </For>
          </div>
        </If>

      </div>
    )
  }
}

