import React, {Component} from 'react';
import PropTypes from 'prop-types';

import DataUnitLabel from 'layout/dataUnitLabel/DataUnitLabel';
import DataUnitTextField from 'layout/dataUnitTextField/DataUnitTextField';
import { connect } from "react-redux";
import clone from 'lodash/clone';
import {MdMoreVert as Ellipsis} from "react-icons/md";
import Modal from 'react-modal';
import ReactDOM from 'react-dom';
import {injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import {getRPMLable} from 'service/LableService';

import UnitImage from 'assets/images/unit.png';

import './unitselector.scss';
import {Navigate} from "react-router";

Modal.setAppElement('#root');

const messages = defineMessages({
  unit_selector_max_op_pressure: {
    id: 'unit_selector_max_op_pressure',
    defaultMessage: 'Max Op. Pressure'
  },
  unit_selector_op_pressure: {
    id: 'unit_selector_op_pressure',
    defaultMessage: 'Op. Pressure'
  },
  unit_selector_max_flowrate: {
    id: 'unit_selector_max_flowrate',
    defaultMessage: 'Max Flowrate'
  },
  unit_selector_flowrate: {
    id: 'unit_selector_flowrate',
    defaultMessage: 'Flowrate'
  },
  unit_selector_rpm_min: {
    id: 'unit_selector_rpm_min',
    defaultMessage: 'R.P.M. min'
  },
  unit_selector_rpm_max: {
    id: 'unit_selector_rpm_max',
    defaultMessage: 'R.P.M. max'
  }
});

class UnitSelector extends Component {

    static propTypes = {
        onUnitChange : PropTypes.func,
        unit: PropTypes.object.isRequired,
        units: PropTypes.array.isRequired,
        showMaxPressure: PropTypes.bool,
        editMaxPressure: PropTypes.bool,
        showPressure: PropTypes.bool,
        showRpmMin: PropTypes.bool,
        showRpmMax: PropTypes.bool,
        showFlowrate: PropTypes.bool,
        showMaxFlowrate: PropTypes.bool,
    }

    constructor(props) {
        super(props);
        this.state = {
            selectionModalOpen: false,
            navigate: null,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.unit != this.props.unit) {
            let unit = this.props.unit;
            setTimeout(() => {
                this.handleUnitSelect(unit)
            })
        }
    }

    handleUnitSelect = (newUnit) => {
        this.setState({
            selectionModalOpen : false
        })
        this.props.onUnitChange({
            unit: newUnit,
            pressure: 0,
            ...(newUnit ? newUnit : {
                maxPressure : null,
                rpmMin : null,
                rpmMax : null,
                flowrate : null,
                maxFlowrate : null
            })
        })
    }

    handlePropertiesSelect = () => {
        this.setState({
            selectionModalOpen : false
        })

      this.setState({
        navigate: "/properties"
      })
    }

    handleValueChange = (field,value) => {
        let change = {};
        change[field] = value;
        this.props.onUnitChange(change);
    }

    handleMaxPressureChange = (value) => this.handleValueChange("maxPressure", value);
    handlePressureChange = (value) => this.handleValueChange("pressure", value);
    handleRpmMinChange = (value) => this.handleValueChange("rpmMin", value);
    handleRpmMaxChange = (value) => this.handleValueChange("rpmMax", value);
    handleFlowrateChange = (value) => this.handleValueChange("flowrate", value);
    handleMaxFlowrateChange = (value) => this.handleValueChange("maxFlowrate", value);

    handleEllipsisClick = () => {
        this.setState({
            selectionModalOpen : true
        })
    }

    handleSelectionModalCancel = () => {
        this.setState({
            selectionModalOpen : false
        })
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(!_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state)) {
            return true;
        }
        return false;
    }

    render() {

        if(this.state.navigate) return <Navigate to={this.state.navigate}/>

        const {pressure, maxPressure, rpmMin, rpmMax, maxFlowrate, flowrate, unit, units} = this.props;
        const { formatMessage } = this.props.intl;
        const ceRenderObj = new Object();

        return (
            <div className="unit-selector">
                <div className="unit-selector-inner">
                    <div className="unit-selector-image-container">
                      <img className="unit-selector-image" src={UnitImage} />
                    </div>
                    <div className="unit-selector-rows">
                        <div className="unit-selector-row unit-selector-row-unit" onClick={this.handleEllipsisClick}>
                            <div className="unit-selector-row-label"><FormattedMessage id="unit_selector_unit" defaultMessage="Unit" /></div>
                            <div className="unit-selector-row-value">
                                <If condition={unit}>
                                    <span>{unit.name}</span>
                                <Else />
                                    <FormattedMessage id="unit_selector_please_select" defaultMessage="Please select" />
                                </If>
                            </div>
                            <Ellipsis className="unit-selector-ellipsis" />
                        </div>
                        <If condition={this.props.showMaxPressure}>
                            <div className="unit-selector-row">
                                <div className="unit-selector-row-label">
                                    <DataUnitLabel si={formatMessage(messages.unit_selector_max_op_pressure)} />
                                </div>
                                <div className="unit-selector-row-value">
                                    <DataUnitTextField align="right" renderObj={ceRenderObj} value={maxPressure} si="bar" us="psi" precision={2} editable={this.props.editMaxPressure} onChange={this.handleMaxPressureChange} />
                                </div>
                            </div>
                        </If>
                        <If condition={this.props.showPressure}>
                            <div className="unit-selector-row">
                                <div className="unit-selector-row-label">
                                    <DataUnitLabel si={formatMessage(messages.unit_selector_op_pressure)} />
                                </div>
                                <div className="unit-selector-row-value">
                                    <DataUnitTextField align="right" renderObj={ceRenderObj} value={pressure} si="bar" us="psi" precision={2} editable={true} onChange={this.handlePressureChange} />
                                </div>
                            </div>
                        </If>
                        <If condition={this.props.showMaxFlowrate}>
                            <div className="unit-selector-row">
                                <div className="unit-selector-row-label">
                                    <DataUnitLabel si={formatMessage(messages.unit_selector_max_flowrate)} />
                                </div>
                                <div className="unit-selector-row-value">
                                    <DataUnitTextField align="right" renderObj={ceRenderObj} value={maxFlowrate} si="l/mins" us="gal/mins" siLabel="l/min" usLabel="gal/min" precision={2} editable={true} onChange={this.handleMaxFlowrateChange} />
                                </div>
                            </div>
                        </If>
                        <If condition={this.props.showFlowrate}>
                            <div className="unit-selector-row">
                                <div className="unit-selector-row-label">
                                    <DataUnitLabel si={formatMessage(messages.unit_selector_flowrate)} />
                                </div>
                                <div className="unit-selector-row-value">
                                    <DataUnitTextField align="right" renderObj={ceRenderObj} value={flowrate} si="l/mins" us="gal/mins" siLabel="l/min" usLabel="gal/min" precision={2} editable={true} onChange={this.handleFlowrateChange} />
                                </div>
                            </div>
                        </If>
                        <If condition={this.props.showRpmMin}>
                            <div className="unit-selector-row">
                                <div className="unit-selector-row-label">
                                    <DataUnitLabel si={formatMessage(messages.unit_selector_rpm_min)} />
                                </div>
                                <div className="unit-selector-row-value">
                                    <DataUnitTextField align="right" renderObj={ceRenderObj} value={rpmMin} customLabel={getRPMLable()} si="mins^-1" precision={2} editable={true} onChange={this.handleRpmMinChange} />
                                </div>
                            </div>
                        </If>
                        <If condition={this.props.showRpmMax}>
                            <div className="unit-selector-row">
                                <div className="unit-selector-row-label">
                                    <DataUnitLabel si={formatMessage(messages.unit_selector_rpm_max)} />
                                </div>
                                <div className="unit-selector-row-value">
                                    <DataUnitTextField align="right" renderObj={ceRenderObj} value={rpmMax} customLabel={getRPMLable()} si="mins^-1" precision={2} editable={true} onChange={this.handleRpmMaxChange} />
                                </div>
                            </div>
                        </If>
                        <If condition={this.state.selectionModalOpen}>
                            <Modal isOpen={true} className="ci-modal ci-modal-nopadding ci-modal-scroller" overlayClassName="ci-modal-overlay" onRequestClose={this.handleSelectionModalCancel}>
                                <div key="null-row" className={`unit-selector-modal-row ${!unit?'selected':''}`} onClick={() => this.handleUnitSelect(null)}><FormattedMessage id="unit_selector_modal_null_row" defaultMessage="- None -" /></div>
                                <div className='unit-selector-modal-row-spacer' />
                                <If condition={units.length>0}>
                                  <div ref={ref=>{
                                      if(ref){
                                        let domNode = ReactDOM.findDOMNode(ref);
                                        let scrollDOMContainer = domNode.parentNode;
                                        let selectedIndex = _.findIndex(units, item => {
                                          return unit && item.id == unit.id;
                                        });
                                        if(selectedIndex > 0){
                                          let scrollHeight = scrollDOMContainer.children[0].clientHeight + 2;
                                          for(var i=0; i<(selectedIndex); i++){
                                            scrollHeight += domNode.childNodes[i].clientHeight + 2;
                                          }
                                          scrollDOMContainer.scrollTop = Math.max(0,(scrollHeight + (domNode.childNodes[selectedIndex].clientHeight+2)/2 - scrollDOMContainer.clientHeight/2))
                                        }
                                      }
                                    }}>
                                      <For each="item" of={units} >
                                        <div key={item.id} >
                                          <div key={item.id + 0} className={`unit-selector-modal-row ${unit && item.id == unit.id?'selected':''}`} onClick={() => this.handleUnitSelect(item)}>
                                            {item.name} - <DataUnitTextField align="plain" value={item.maxPressure} si="bar" us="psi" precision={2} editable={false} /> - <DataUnitTextField align="plain" value={item.maxFlowrate} si="l/mins" us="gal/mins" siLabel="l/min" usLabel="gal/min" precision={2} editable={false} />
                                          </div>
                                          <div className='unit-selector-modal-row-spacer' />
                                        </div>
                                      </For>
                                    </div>
                                <Else />
                                  <div>
                                    <div key="no-items" className="unit-selector-modal-row unit-selector-modal-row-no-items"><FormattedMessage id="unit_selector_modal_no_units" defaultMessage="You have not set up any units yet." /></div>
                                    <div className='unit-selector-modal-row-spacer' />
                                  </div>
                                </If>
                                <div key="properties" className="unit-selector-modal-row" onClick={() => this.handlePropertiesSelect()}><FormattedMessage id="unit_selector_modal_properties" defaultMessage="Go to Properties" /></div>
                            </Modal>
                        </If>
                    </div>
                </div>
            </div>
        );
    }
}

export default injectIntl(UnitSelector);
