export const PREFIX = "UI_";

export const SET_ACTIVE_MENU_ITEM = PREFIX + "SET_ACTIVE_MENU_ITEM";

export const SET_MY_LOCATION = PREFIX + "SET_MY_LOCATION";
export const SET_MAP_LOCATION = PREFIX + "SET_MAP_LOCATION";
export const SET_REVERSE_GEOCODE_RESULT = PREFIX + "SET_REVERSE_GEOCODE_RESULT";
export const SET_INITIAL_MENU_OPENED = PREFIX + "SET_INITIAL_MENU_OPENED";
export const SET_CALCULATION_PARAMETERS = PREFIX + "SET_CALCULATION_PARAMETERS";

export const SET_LOCALE = PREFIX + "SET_LOCALE";
export const SET_AGB_ACCEPTED = PREFIX + "SET_AGB_ACCEPTED";
export const SET_INITIAL_SETTINGS_SET = PREFIX + "SET_INITIAL_SETTINGS_SET";
export const SHOW_INFO_MESSAGE = PREFIX + "SHOW_INFO_MESSAGE";

export const menuItems = {
    nozzleCalculation: "nozzleCalculation",
    conversion: "conversion",
    tankcleaningunit: "tankcleaningunit",
    masterjetconfiguration: "masterjetconfiguration",
    flowrate: "flowrate",
    properties: "properties",
    news: "news",
    contact: "contact",
    imprint: "imprint",
}
