import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import Layout from 'layout/Layout';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UIActions from 'storeState/ui/actions';
import { menuItems } from 'storeState/ui/constants';
import SectionHeader from 'layout/sectionHeader/SectionHeader';
import DataUnitTextField from 'layout/dataUnitTextField/DataUnitTextField';
import Dropdown from 'react-dropdown'
import _ from 'lodash';
import getConversions from './Conversions';
import { TabNavigation } from 'layout/tabNavigation/TabNavigation';
import Modal from 'react-modal';
import Button from 'layout/button/Button';
import ButtonBar from 'layout/buttonBar/ButtonBar';

import './conversion.scss';
import style from 'style';

const messages = defineMessages({
  conversion_caption: {
    id: 'conversion_caption',
    defaultMessage: 'Conversion'
  }
});

class Conversion extends Component {

  initConversions = () => {

    const { formatMessage } = this.props.intl;
    this.conversions = getConversions(formatMessage);
  }

  initlabels = () => {

    let labels = [];
    _.map(this.conversions, (group, key) => {

      let groupItems = [];
      _.map(group.units, (unit, key) => {

        groupItems.push({
          value: unit.unit,
          label: unit.label,
        });
      });

      //labels = labels.concat(groupItems);
      labels.push({
        type: 'group',
        name: group.label,
        items: groupItems,
      });
    })

    this.labels = _.sortBy(labels, label => label.name.toLowerCase());
    _.map(this.labels, label => {
      label.items = _.sortBy(label.items, item => item.label.toLowerCase());
    });

  }

  constructor(props) {
      super(props);

      this.initConversions();
      this.initlabels();

      this.state = {
        value: 0,
        unit: this.conversions[0].units[0],
        selectConversionActive: false,
      }
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.props.uiActions.setActiveMenuItem({ item: menuItems.conversion })
    })
  }

  setValue = (value) => {
    this.setState({value: value});
  }

  selectUnit = (elem) => {
    let group = _.find(this.conversions, (group) => _.find(group.units, (compareUnit) => compareUnit.unit == elem.value));
    let unit = _.find(group.units, (compareUnit) => compareUnit.unit == elem.value);
    this.setState({unit: unit});
  }

  handleDropDownRef = (el) => {
    this.setState({
        dropDownDom: ReactDOM.findDOMNode(el)
    });
  }

  render() {

    const {value, unit} = this.state;
    const { formatMessage } = this.props.intl;

    let group = _.find(this.conversions, (group) => _.find(group.units, (compareUnit) => compareUnit.unit == unit.unit));
    //let selectedElem = _.find(group.items, (label) => label.value==unit.unit);
    return (
      <Layout caption={formatMessage(messages.conversion_caption)}>
        {/*<TabNavigation />*/}
        <If condition={this.state.dropDownDom}>
          <div dangerouslySetInnerHTML={{__html:`
            <style>
              .Dropdown-menu {
                height: ${((window.innerHeight - this.state.dropDownDom.getBoundingClientRect().top)-80 + "px")};
                width: ${((window.innerWidth - this.state.dropDownDom.getBoundingClientRect().left)-80 + "px")};
                max-width: 400px;
              }
            </style>
          `}} />
        </If>
        <div className={`conversion-layout-container`}>
          <SectionHeader><FormattedMessage id="conversion_header" defaultMessage="Conversion"/></SectionHeader>
          <div className={`conversion-element-container`}>
            <div className={`conversion-data-unit-container`}>
              <DataUnitTextField
                value={value}
                editable={true}
                align={"center"}
                stretch={true}
                integerPlaces={9}
                decimalPlaces={6}
                onChange={(newVal) => this.setValue(newVal)}
                rerenderValue={this.state.unit.label}
                customLabel = {
                  <div className="conversion-dropdown-container" onClick={()=>{this.setState({selectConversionActive: true})}}>
                    <div className="Dropdown-root">
                      <div className="Dropdown-control">
                        {this.state.unit.label}
                        <span className="Dropdown-arrow"></span>
                      </div>
                    </div>
                    {/*
                    <div className="conversion-dropdown-container-noevents">
                      <Dropdown ref={this.handleDropDownRef} options={this.labels} onChange={(newUnit) => this.selectUnit(newUnit)} value={this.conversions[0].units[0]} />
                    </div>
                    */}
                  </div>
                }
              />

            </div>
          </div>
          <div className="conversion-result-triangle-container">
            <div className={`conversion-result-triangle`}></div>
          </div>
          <div className="conversion-result-header-container">
            <SectionHeader>
              <FormattedMessage id="conversion_results_header" defaultMessage="Results"/>
            </SectionHeader>
          </div>
          <div className="conversion-results-container">
            <If condition={group}>
              <For each="targetUnit" of={group.units}>
                <If condition={targetUnit.unit != unit.unit}>
                  <div className='conversion-element-result-container'>
                    <DataUnitTextField
                      value={`${this.state.value} ${unit.unit}`}
                      si={`${targetUnit.unit}`}
                      editable={false}
                      align={"center"}
                      stretch={true}
                      integerPlaces={9}
                      decimalPlaces={6}
                      customLabel = {
                        <div className="conversion-element-result-unit">{targetUnit.label}</div>
                      }
                    />
                  </div>
                </If>
              </For>
            </If>
          </div>
        </div>

        <Modal isOpen={this.state.selectConversionActive} className="ci-modal ci-modal-nopadding modal-selector-container modal-selector-scroller" overlayClassName="ci-modal-overlay" onRequestClose={()=>{this.setState({selectConversionActive: false})}}>
          <For each="group" of={this.labels}>
              <div key={`group_${group.name}`} className={`unit-selector-modal-group`}>
                {group.name}
              </div>
              <For each="item" of={group.items}>
                <div key={`item_${item.label}`} className={`unit-selector-modal-row ${item.value==this.state.unit.unit?'selected':''}`} onClick={()=>{this.setState({selectConversionActive: false}); this.selectUnit(item)}}>
                  {item.label}
                </div>
              </For>
              <div className='unit-selector-modal-row-spacer' />
          </For>
        </Modal>
      </Layout>
    );
  }
}

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(UIActions, dispatch)
  }
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(Conversion))
