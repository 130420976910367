import ApiService from 'service/ApiService';

class Api {

    constructor(getApiUrl) {
        this.getApiUrl = getApiUrl;
    }

    buildReadDataUrl = () => {
        return this.getApiUrl('data');
    }

}

export default new Api(ApiService.getApiUrl.bind(ApiService));
