import React, { Component } from 'react';
import Layout from 'layout/Layout';
import './impress.scss';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import ExternalLink from 'layout/externalLink/ExternalLink';

const messages = defineMessages({
  sidemenu_imprint: {
    id: 'sidemenu_imprint',
    defaultMessage: 'Imprint'
  }
});

class Impress extends Component {

  render() {

    const { formatMessage } = this.props.intl;

    return (
      <Layout caption={formatMessage(messages.sidemenu_imprint)}>
        <div className="impress">
          <h2><FormattedMessage id="sidemenu_imprint" defaultMessage="Imprint"/></h2>
          <div className='imprint-content'>
            <p>
              <b>Hammelmann GmbH</b><br/>
              Carl-Zeiss-Straße 6-8<br/>
              D-59302 Oelde<br/>
              <FormattedMessage id="impress_germany" defaultMessage="Deutschland"/>
            </p>
            <p>
              Tel.: <a href='tel:+492522760'>+49 (0) 2522 / 76-0</a><br/>
              E-Mail: <a href={"mailto:mail@hammelmann.de"}>mail@hammelmann.de</a>
            </p>
            <p>
              <FormattedMessage id="impress_companygroup" defaultMessage="Ein Unternehmen der"/> INTERPUMP GRUPPE
            </p>
            <p>
              <FormattedMessage id="impress_registrycourt" defaultMessage="Registergericht"/> Münster<br/>
              <FormattedMessage id="impress_registrynumber" defaultMessage="Register-Nr."/> HR B 10247<br/>
              <FormattedMessage id="impress_taxid" defaultMessage="UST.-ID-NUMMER"/> DE 246-951-655
            </p>
            <p>
              <b><FormattedMessage id="impress_ceo" defaultMessage="Geschäftsführer"/></b> Giari Fiorucci
            </p>
            <p style={{marginTop: "60px"}}>
              <FormattedMessage id="impress_realization" defaultMessage="Technische Umsetzung"/>:&nbsp;
              <ExternalLink href="https://www.actidoo.com/?utm_source=WaterJettingCalculator&utm_medium=link">ActiDoo GmbH</ExternalLink>
            </p>
          </div>
        </div>
      </Layout>
    );
  }
}

export default injectIntl(Impress)
