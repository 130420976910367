import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {Link} from 'react-router-dom';
import {injectIntl, FormattedMessage, defineMessages} from 'react-intl';
import DotDotDot from 'react-dotdotdot';

import './header.scss';
import logo from 'assets/images/logo.jpg';

import { menuItems } from "storeState/ui/constants";

import HeaderMenuItem from "./HeaderMenuItem";
//const RadiumLink = Radium(Link);

const messages = defineMessages({
  headermenu_masterjetconfiguration: {
    id: 'headermenu.masterjetconfiguration',
    defaultMessage: 'Masterjet konfigurieren'
  }
});

class Header extends Component {

  static propTypes = {
    caption: PropTypes.string,
    isMobile: PropTypes.bool,
    iosStatusbar: PropTypes.bool,
  };

  renderMenuItem = (to, id, itemId) => {

    const { formatMessage } = this.props.intl;

    return(
      <HeaderMenuItem to={to} menuItemId={itemId}>
        <If condition={id === "headermenu.masterjetconfiguration"}>
          {formatMessage(messages.headermenu_masterjetconfiguration)}
        <Else />
          <FormattedMessage id={id} />
        </If>
      </HeaderMenuItem>
    );
  }

  render() {

    const {caption, isMobile, iosStatusbar} = this.props;

    return (
      <div>
        <If condition={iosStatusbar}>
          <div className={'ios-statusbar'} />
        </If>
        <div className={`header ${isMobile?'mobile':''} ${iosStatusbar?'ios-statusbar':''}`}>
          <Link to={`/nozzlecalculation`} className='header-logo'>
            <img src={logo} className={`header-logo-img`}/>
          </Link>
          <div className={`header-menu`}>
            {this.renderMenuItem('/nozzlecalculation', 'headermenu.nozzlecalculation', menuItems.nozzleCalculation)}
            {this.renderMenuItem('/flowrate', 'headermenu.flowrate', menuItems.flowrate)}
            {this.renderMenuItem('/tankcleaningunit', 'headermenu.tankcleaningunit', menuItems.tankcleaningunit)}
            {this.renderMenuItem('/masterjetconfiguration', 'headermenu.masterjetconfiguration', menuItems.masterjetconfiguration)}
            {this.renderMenuItem('/conversion', 'headermenu.conversion', menuItems.conversion)}
            {this.renderMenuItem('/properties', 'headermenu.properties', menuItems.properties)}
          </div>
          <div className={`header-title`}>
            <div className="center-top">
              <DotDotDot clamp={1}>
                {caption}
              </DotDotDot>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Header)
