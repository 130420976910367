import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {FormattedMessage, injectIntl} from 'react-intl';
import { connect } from "react-redux";
import _ from 'lodash';

import 'components/layout/productWizard/productWizardMasterjet.scss';
import SectionHeader from "components/layout/sectionHeader/SectionHeader";
import WizardItem from "components/layout/productWizard/WizardItem";

import MODAL_TYPE from "components/layout/productWizard/masterJetModals/MasterjetModalTypes.constants";
import {MdClose as CloseIcon} from "react-icons/md";

import WizardNavigation from "components/layout/productWizard/WizardNavigation";
import {bindActionCreators} from "redux";
import {getMasterjetConfigurationParams} from "storeState/ui/selectors";
import * as UIActions from 'storeState/ui/actions';
import {MASTERJET_CONFIGURATION_PARAMETERS} from "components/views/masterjetConfiguration/MasterjetConfiguration";

import masterjet_pressure_3200 from 'assets/images/new/Edelstahl-mit-Rillen.png';
import masterjet_pressure_3200_kontur from 'assets/images/new/Edelstahl-mit-Rillen-KONTUR.png';
import masterjet_pressure_1800 from 'assets/images/new/Edelstahl-ohne-Rillen.png';
import masterjet_pressure_1800_kontur from 'assets/images/new/Edelstahl-ohne-Rillen-KONTUR.png';
import masterjet_pressure_1000 from 'assets/images/new/Bronze-ohne-Rillen.png';
import masterjet_pressure_1000_kontur from 'assets/images/new/Bronze-ohne-Rillen-KONTURR.png';
import masterjet_pressure_chart from 'assets/images/new/Druckstufe-Schaubild.png';
import masterjet_pressure_practice from 'assets/images/new/Druckstufe-Praxisbild.png';

class ModalPressure extends Component {

  static propTypes = {
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    filteredProducts: PropTypes.array
  }

  static defaultProps = {
    filteredProducts: []
  }

  constructor(props) {
    super(props);

    const initialThreads = _.uniq(_.map(this.props.filteredProducts, product => product.dataunits.threadType.value));
    const hasInitialThreadsAM = initialThreads.indexOf("A") !== -1 || initialThreads.indexOf("M") !== -1;
    const hasInitialThreadsG = initialThreads.indexOf("G") !== -1;
    const initialHighestMaxPressure = parseInt(_.max(_.map(this.props.filteredProducts, product => product.dataunits.p_max.value)))
    const initialLowestMaxPressure = parseInt(_.min(_.map(this.props.filteredProducts, product => product.dataunits.p_max.value)))

    this.state = {
      hasInitialThreadsAM: hasInitialThreadsAM,
      hasInitialThreadsG: hasInitialThreadsG,
      initialHighestMaxPressure: initialHighestMaxPressure,
      initialLowestMaxPressure: initialLowestMaxPressure
    };
  }

  setMaxPressure = (value) => {
    let newParams = {
      maxPressure: value
    };

    if(this.props.calculationParams.maxPressure !== value) {
      // reset
      newParams.productId = false;
      newParams.variableSpeed = false;
    }

    this.props.uiActions.setCalculationParameters({
      key : MASTERJET_CONFIGURATION_PARAMETERS,
      params : newParams
    });
  }

  setThreadType = (value) => {
    let newParams = {
      threadType: value
    };

    if(this.props.calculationParams.threadType !== value) {
      // reset
      newParams.productId = false;
      newParams.variableSpeed = false;
    }

    this.props.uiActions.setCalculationParameters({
      key : MASTERJET_CONFIGURATION_PARAMETERS,
      params : newParams
    });
  }

  choose3200 = () => {
    this.setMaxPressure(3200);
    const thread = this.props.calculationParams.threadType;
    if (thread !== "A") {
      this.setThreadType("M");
    }
  };
  choose1800 = () => {
    this.setMaxPressure(1800);
    const thread = this.props.calculationParams.threadType;
    if (thread !== "A") {
      this.setThreadType("M");
    }
  };
  choose1000 = () => {
    this.setMaxPressure(1000);
    this.setThreadType("G");
  };

  render() {
    return (
      <div className="product-wizard-masterjet-modal-item-container">

        <SectionHeader style={{padding: 0}}>
          <div className="product-wizard-masterjet-modal-item-section-row">
            <div className={"product-wizard-masterjet-modal-item-section-row-icon"} onClick={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_PRODUCT)}>
              <CloseIcon></CloseIcon>
            </div>
            <div className={"product-wizard-masterjet-modal-item-section-row-label"}>
              <FormattedMessage id="product_wizard_masterjet_pressure" defaultMessage="Druckstufe"/>
            </div>
          </div>
        </SectionHeader>

        <WizardNavigation
          onPrev={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_THREAD)}
          onNext={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_VARIABLE_SPEED)}
          onNextDisabled={!this.props.calculationParams.maxPressure}
        />

        <WizardItem type={"hint"} label={<FormattedMessage id="product_wizard_masterjet_pressure_hint" defaultMessage="Wie sieht der Sechskant am Anschlussgewinde aus?"/>}/>

        <div className={"product-wizard-item-collection"}>

          <If condition={this.state.hasInitialThreadsAM && this.state.initialHighestMaxPressure <= 3200}>
            <WizardItem
              type={"simple"}
              border={true}
              isActive={this.props.calculationParams.maxPressure === 3200}
              image={<img src={this.props.calculationParams.maxPressure === 3200 ? masterjet_pressure_3200 : masterjet_pressure_3200_kontur} />}
              label={<div className={"product-wizard-masterjet-table-modal-item-label"}><FormattedMessage id="product_wizard_masterjet_pressure_notches" defaultMessage="Edelstahl mit Rillen"/></div>}
              onClick={()=>{this.choose3200()}}
            />
          </If>

          <If condition={this.state.hasInitialThreadsAM && (this.state.initialLowestMaxPressure <= 1800 || this.state.initialHighestMaxPressure <= 1800)}>
            <WizardItem
              type={"simple"}
              border={true}
              isActive={this.props.calculationParams.maxPressure === 1800}
              image={<img src={this.props.calculationParams.maxPressure === 1800 ? masterjet_pressure_1800 : masterjet_pressure_1800_kontur} />}
              label={<div className={"product-wizard-masterjet-table-modal-item-label"}><FormattedMessage id="product_wizard_masterjet_pressure_no_notches" defaultMessage="Edelstahl ohne Rillen"/></div>}
              onClick={()=>{this.choose1800()}}
            />
          </If>

          <If condition={this.state.hasInitialThreadsG && this.state.initialLowestMaxPressure <= 1000}>
            <WizardItem
              type={"simple"}
              border={true}
              isActive={this.props.calculationParams.maxPressure === 1000}
              image={<img src={this.props.calculationParams.maxPressure === 1000 ? masterjet_pressure_1000 : masterjet_pressure_1000_kontur} />}
              label={<div className={"product-wizard-masterjet-table-modal-item-label"}><FormattedMessage id="product_wizard_masterjet_pressure_no_notches_bronze" defaultMessage="Bronze ohne Rillen"/></div>}
              onClick={()=>{this.choose1000()}}
            />
          </If>

        </div>

        <WizardItem type={"hint"} label={<FormattedMessage id="product_wizard_masterjet_pressure_info_title" defaultMessage="Warum ist das wichtig zu wissen?"/>}/>
        <WizardItem type={"default"} label={
          <>
            <div style={{fontWeight: 'bold', marginBottom: 8}}>
              <FormattedMessage id="product_wizard_masterjet_pressure_info_subtitle" defaultMessage="Es gibt mehrere Druckstufen & Anschlussgewinde"/>
            </div>
            <FormattedMessage id="product_wizard_masterjet_pressure_info_description"
                              defaultMessage="Die 2 Versionen der Masterjet (Compact) 1800 und 3200 leisten Wasserdrücke bis 1800 bar und 3200 bar. Beide Versionen haben Edelstahlgewinde. Das bronzene Anschlussgewinde hat nur die Masterjet 1000, die Wasser- drücke bis 1000 bar erlaubt."/>
          </>
        }/>
        <div className={"product-wizard-masterjet-modal-item-image product-wizard-masterjet-modal-item-image-fullwidth product-wizard-masterjet-modal-item-image--border-false"}>
          <img src={masterjet_pressure_chart} />
        </div>
        <div className={"product-wizard-masterjet-modal-item-image product-wizard-masterjet-modal-item-image-fullwidth product-wizard-masterjet-modal-item-image--border-false"}>
          <img src={masterjet_pressure_practice} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    calculationParams: getMasterjetConfigurationParams(state) || {}
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(UIActions, dispatch),
  }
}

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalPressure))
