import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {Link} from 'react-router-dom';
import './nozzleDiameterSlider.scss';
import {FormattedMessage} from "react-intl";
import SectionHeader from "components/layout/sectionHeader/SectionHeader";
import {FaMinus, FaPlus} from "react-icons/fa";
import Slider from 'rc-slider';
import colors from 'style'
import ReactDOM from "react-dom";

export default class NozzleDiameterSlider extends Component {

    static propTypes = {
      isEqual: PropTypes.bool,
      onChange: PropTypes.func,
      value: PropTypes.number,
      stepCount: PropTypes.number,
      label: PropTypes.func,
      nozzleName: PropTypes.string
    }

    static defaultProps = {
      isEqual: true,
      onChange: () => {},
      value: 0,
      stepCount: 10,
      label: v => v,
      nozzleName: 'P'
    }

    constructor(props) {
      super(props);
    }

    render() {
        return (
            <div className={`nozzle-diameter-slider`}>
                <div className="nozzle-diameter-slider__header">
                    <div className={`nozzle-diameter-slider__indicator nozzle-diameter-slider__indicator--${this.props.isEqual?'valid':'invalid'}`} />
                    <div className={`nozzle-diameter-slider__flex`}>
                        <FormattedMessage id="nozzle_diameter_slider_caption" defaultMessage="Düsendurchmesser anpassen:"/>
                    </div>
                    <div className={`nozzle-diameter-slider__value`}>
                      {this.props.label(this.props.value)}
                    </div>
                </div>
                <div className="nozzle-diameter-slider__content">
                    <div className={`nozzle-diameter-slider__button`} onClick={()=>{
                      this.props.onChange(Math.max(this.props.value-1, 0));
                    }}>
                        <FaMinus/>
                    </div>
                    <div ref = {ref => {
                      if(ref) {
                        const domNode = ReactDOM.findDOMNode(ref);
                        domNode.querySelector(".rc-slider-handle").setAttribute("data-name", this.props.nozzleName);
                      }
                    }} className={`nozzle-diameter-slider__flex nozzle-diameter-slider__slider nozzle-diameter-slider__slider--${this.props.isEqual?'valid':'invalid'}`}>
                        <Slider value={this.props.value} step={1} dots={true} min={0} max={this.props.stepCount-1}
                                onChange={(value)=>{
                                  this.setState({value: value}, () => {
                                    this.props.onChange(value);
                                  })
                                }}
                                trackStyle={{
                                    backgroundColor: colors.secondary1,
                                    height: 8,
                                    borderRadius: 0
                                }}
                                railStyle={{
                                    backgroundColor: colors.secondary1,
                                    height: 8,
                                    borderRadius: 0
                                }}
                                dotStyle={{
                                    backgroundColor: colors.secondary1,
                                    height: 20,
                                    borderRadius: 0,
                                    border: 'none',
                                    bottom: -10,
                                    width: 3
                                }}
                                activeDotStyle={{
                                    backgroundColor: colors.secondary1,
                                }}
                                handleStyle={{
                                    border: '2px solid black',
                                    width: 32,
                                    height: 32,
                                    marginTop: -12,
                                    opacity: 1,
                                    boxShadow: 'none',
                                    backgroundColor: this.props.isEqual ? colors.white : colors.black,
                                    borderColor: this.props.isEqual ? colors.black : colors.white,
                                }}
                        />
                    </div>
                    <div className={`nozzle-diameter-slider__button`} onClick={()=>{
                      this.props.onChange(Math.min(this.props.value+1, this.props.stepCount-1));
                    }}>
                        <FaPlus/>
                    </div>
                </div>
            </div>
        )
    }

}
