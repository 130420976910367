import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {FormattedMessage, injectIntl} from 'react-intl';
import { connect } from "react-redux";
import _ from 'lodash';
import NozzleImage from "components/layout/nozzleImage/NozzleImage";
import colors from 'style'

import 'components/layout/productWizard/productWizardMasterjet.scss';
import SectionHeader from "components/layout/sectionHeader/SectionHeader";
import WizardItem from "components/layout/productWizard/WizardItem";

import MODAL_TYPE from "components/layout/productWizard/masterJetModals/MasterjetModalTypes.constants";
import {MdClose as CloseIcon} from "react-icons/md";
import WizardNavigation from "components/layout/productWizard/WizardNavigation";
import {bindActionCreators} from "redux";
import {getMasterjetConfigurationParams} from "storeState/ui/selectors";
import * as UIActions from 'storeState/ui/actions';
import {MASTERJET_CONFIGURATION_PARAMETERS} from "components/views/masterjetConfiguration/MasterjetConfiguration";

import masterjet_nozzle_count_chart from "assets/images/new/Duesenanzahl-Schaubild.png";
import masterjet_nozzle_count_practice from "assets/images/new/Duesenanzahl-Praxisbild.png";

class ModalNozzleCount extends Component {

  static propTypes = {
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    filteredProducts: PropTypes.array
  }

  static defaultProps = {
    filteredProducts: []
  }

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  setValue = (value) => {

    let newParams = {
      maxNozzleCount: value
    };

    if(this.props.calculationParams.maxNozzleCount !== value) {
      // reset
      newParams.productId = false;
      newParams.nozzleCount = false;
      newParams.nozzleVariant = false;
      newParams.performanceClass = false;
      newParams.threadType = false;
      newParams.maxPressure = false;
    }

    this.props.uiActions.setCalculationParameters({
      key : MASTERJET_CONFIGURATION_PARAMETERS,
      params : newParams
    });

    // this.props.openModal(MODAL_TYPE.MODAL_TYPE_NOZZLE_AMOUNT);
  }

  getItems = () => {
    let numnoz_allowed = [];
    _.each(this.props.filteredProducts, product => {
      const maxNozzle = parseInt(_.max(_.keys(product.dataunits.numnoz_to_alpha_avg.value)));
      numnoz_allowed.push(maxNozzle)
    })
    numnoz_allowed = _.sortBy(_.uniq(numnoz_allowed), val=>val);

    let output = [];
    _.each(numnoz_allowed, numNoz => {

      const items = _.map(Array(numNoz), num => {return {type: 'placeholder_active'}});

      output.push(
        <WizardItem
          key={`ModalNozzleCount_num_noz_${numNoz}`}
          type={"simple"}
          border={true}
          image={<NozzleImage size={60} style={{border: `1px solid ${colors.black}`}} items={items} ></NozzleImage>}
          label={<div className={"product-wizard-masterjet-table-modal-item-label"}>{numNoz} <FormattedMessage id={"product_wizard_masterjet_product_nozzle_count_label"} defaultMessage={"Düsen"} /></div>}
          onClick={()=>this.setValue(numNoz)}
          isActive={this.props.calculationParams?.maxNozzleCount === numNoz}
        />
      )
    })

    return output;
  }

  render() {

    return (
      <div className="product-wizard-masterjet-modal-item-container">
        <SectionHeader style={{padding: 0}}>
          <div className="product-wizard-masterjet-modal-item-section-row">
            <div className={"product-wizard-masterjet-modal-item-section-row-icon"} onClick={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_PRODUCT)}>
              <CloseIcon></CloseIcon>
            </div>
            <div className={"product-wizard-masterjet-modal-item-section-row-label"}>
              <FormattedMessage id="product_wizard_masterjet_nozzle_count" defaultMessage="Düsenanzahl"/>
            </div>
          </div>
        </SectionHeader>
        <WizardNavigation
          onPrev={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_PRODUCT_APPLICATION)}
          onNext={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_NOZZLE_AMOUNT)}
          onNextDisabled={!this.props.calculationParams.maxNozzleCount}
        />
        <WizardItem type={"hint"} label={<FormattedMessage id="product_wizard_masterjet_nozzle_count_hint" defaultMessage="Wie viele Düsenöffnungen sehen Sie?"/>}/>
        <div className={"product-wizard-item-collection"}>
          {this.getItems()}
        </div>
        <WizardItem type={"hint"} label={<FormattedMessage id="product_wizard_masterjet_to_nozzle_count_info_title" defaultMessage="Warum ist das wichtig zu wissen?"/>}/>
        <WizardItem type={"default"} label={
          <>
            <div style={{fontWeight: 'bold', marginBottom: 8}}>
              <FormattedMessage id="product_wizard_masterjet_to_nozzle_count_info_subtitle" defaultMessage="Düsenauslegung = Abtrageergebnis"/>
            </div>
            <FormattedMessage id="product_wizard_masterjet_to_nozzle_count_info_description" defaultMessage="Die Anzahl, der Durchmesser und die Anordnung der Düsen hat einen großen Einfluss auf das Abtragsergebnis."/>
          </>
        }/>
        <div className={"product-wizard-masterjet-modal-item-image product-wizard-masterjet-modal-item-image-fullwidth product-wizard-masterjet-modal-item-image--border-false"}>
          <img src={masterjet_nozzle_count_chart} />
        </div>
        <div className={"product-wizard-masterjet-modal-item-image product-wizard-masterjet-modal-item-image-fullwidth product-wizard-masterjet-modal-item-image--border-false"}>
          <img src={masterjet_nozzle_count_practice} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    calculationParams: getMasterjetConfigurationParams(state) || {}
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(UIActions, dispatch),
  }
}

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalNozzleCount))
