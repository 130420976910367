
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './loadingindicator.scss';
import {Loader} from 'react-loaders';
import style from 'style';

class LoadingIndicator extends Component {

  static propTypes = {
    colorName: PropTypes.string,
    className: PropTypes.string
  };

  static defaultProps = {
    colorName: "primary1",
  }

  render() {
    return (
      <div className={`loading-indicator loading-indicator-${this.props.colorName} ${this.props.className ? this.props.className : null}`}>
        <Loader type="line-scale" active={true} />
      </div>
    );
  }
}

export default LoadingIndicator;
