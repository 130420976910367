import React, { Component } from 'react';
import Layout from 'layout/Layout';
import { injectIntl, FormattedMessage, FormattedDate, defineMessages } from 'react-intl';
import { bindActionCreators } from "redux";
import * as UIActions from 'storeState/ui/actions';
import { connect } from "react-redux";
import { menuItems } from 'storeState/ui/constants';
import { getNews } from 'storeState/news/selectors';

import './news.scss';

const messages = defineMessages({
  news_caption: {
    id: 'news_caption',
    defaultMessage: 'News'
  }
});

class News extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount() {
    setTimeout(() => {
      this.props.uiActions.setActiveMenuItem({ item: menuItems.news })
    })
  }

  render() {

    const { formatMessage } = this.props.intl;
    const { news } = this.props;

    return (
      <Layout caption={formatMessage(messages.news_caption)}>
        <div className="news">
          <If condition={news}>
            <For each="item" of={news}>
                <div className="news-item">
                  <div className="news-item-date">
                    <FormattedDate value={new Date(item.published)} />
                  </div>
                  <div className="news-item-title" dangerouslySetInnerHTML={{__html : item.title}} />
                  <div className="news-item-summary" dangerouslySetInnerHTML={{__html : item.summary}} />
                </div>
            </For>
          </If>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    news: getNews(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(UIActions, dispatch),
  }
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(News))
