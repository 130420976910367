import reducer from './reducer';
import saga from './saga';
import * as actions from './actions';
import * as constants from './constants';
import * as selectors from './selectors';
import localPersistence from './localPersistence';

export default {
  reducer: reducer,
  actions: actions,
  constants: constants,
  selectors: selectors,
  saga: saga,
  localPersistence: localPersistence
};
