import React, { Component } from 'react';
import Layout from 'layout/Layout';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UIActions from 'storeState/ui/actions';
import { menuItems } from 'storeState/ui/constants';
import { getFormulas, getDataunitsBySymbol, getAquamats } from 'storeState/data/selectors';
import { getTankCleaningUnitCalculationParams } from 'storeState/ui/selectors';
import SectionHeader from 'layout/sectionHeader/SectionHeader';
import AquamatSelector from 'layout/aquamatSelector/AquamatSelector';
import CalculationResult from 'layout/calculationResult/CalculationResult';
import DataUnitTextField from 'layout/dataUnitTextField/DataUnitTextField';
import NozzleConfigurationTable from 'layout/configurationTable/NozzleConfigurationTable';
import {evaluateMathProgram, formatValue, formatValueWithUnit, formatValueWithUnitAndLabel, convert, isASmallerOrEqualB, getRawValue } from 'service/MathService';
import { FaAngleLeft as IconLeft, FaAngleRight as IconRight } from 'react-icons/fa';
import _ from 'lodash';
import './tankcleaningunit.scss';
import ReactDOM from 'react-dom';
import {getRPMLable} from 'service/LableService';

import imgHase from 'assets/images/hase.png';
import imgSchildkroete from 'assets/images/schildkroete.png';

const messages = defineMessages({
  tankcleaningunit_caption: {
    id: 'tankcleaningunit_caption',
    defaultMessage: 'Set-up Tank cleaning unit'
  },
  tankcleaningunit_nozzle_type_changed: {
    id: 'tankcleaningunit_nozzle_type_changed',
    defaultMessage: 'Der Düsentyp wurde automatisch angepasst'
  }
});

function unitToParams(unit) {
    return {
        pTCHmax: unit ? unit.dataunits["pTCHmax"].value_with_unit : null,
        uRECmax: unit ? unit.dataunits["uRECmax"].value_with_unit : null,
        C1: unit ? unit.dataunits["C1"].value_with_unit : null,
        C2: unit ? unit.dataunits["C2"].value_with_unit : null,
        C3: unit ? unit.dataunits["C3"].value_with_unit : null,
        C4: unit ? unit.dataunits["C4"].value_with_unit : null,
        C5b: unit ? unit.dataunits["C5b"].value_with_unit : null,
        C6b: unit ? unit.dataunits["C6b"].value_with_unit : null,
        QUmax: unit ? unit.dataunits["QUmax"].value_with_unit : null,
        Kv: unit ? unit.dataunits["Kv"].value_with_unit : null,
        fdtrec: unit ? unit.dataunits["fdtrec"].value_with_unit : null,
        fdtmax: unit ? unit.dataunits["fdtmax"].value_with_unit : null,
        fpl: unit ? unit.dataunits["fpl"].value_with_unit : null,
        peff: unit ? unit.dataunits["peff"].value_with_unit : null,
        powerTCHmax: unit ? unit.dataunits["powerTCHmax"].value_with_unit : null,
        powerTCHrec: unit ? unit.dataunits["powerTCHrec"].value_with_unit : null,
    }
}

function nzhToParams(nzh) {
    return {
        pNZHMax: nzh.dataunits.pNZHMax.value_with_unit,
        NZHLa: nzh.dataunits.NZHLa.value_with_unit,
        numnoz: nzh.dataunits["numnoz"].value_with_unit,
    }
}

class TankCleaningUnit extends Component {

  constructor(props) {
    super(props);
      this.lastParams = null;
      this.state = {
      result : {
        warnings : [],
        values : [],
        rpmMin : null,
        rpmMax : null,
        rpmMaxType : "valid",
        rpmMinType : "valid",
        rpmOverallType : "valid"
      },
    }
  }

  handleAquamatSelectorChange = (change) => {

      const { formatMessage } = this.props.intl;

      let new_nozzle_holder_id = null;
      let old_nozzle_holder_id = null;
      if(change.nozzle_holder) {
          new_nozzle_holder_id = change.nozzle_holder.id;
      }

      if(this.props.calculationParams && this.props.calculationParams.aquamatSelector && this.props.calculationParams.aquamatSelector.nozzle_holder) {
          old_nozzle_holder_id = this.props.calculationParams.aquamatSelector.nozzle_holder.id;
      }

      console.log("new_nozzle_holder_id", new_nozzle_holder_id);
      console.log("change", change);
      console.log("old_nozzle_holder_id", old_nozzle_holder_id);

      let nozzle = this.props.calculationParams.nozzle;

      let nozzleHolder = this.props.calculationParams.nozzle_holder;

      if(change.nozzle_holder) {
        nozzleHolder = change.nozzle_holder;
        const nozzleValid = _.find(change.nozzle_holder.nozzles, noz => {return noz.id === nozzle.type.id})
        if(!nozzleValid) {
          this.props.uiActions.showInfoMessage({message: formatMessage(messages.tankcleaningunit_nozzle_type_changed)})
          nozzle = null
        }
      } else {
        let unit = change.unit ? change.unit : this.getAquamatSelectorParams().unit;

        if(unit.NZHs.length > 0) {
          const nozzleHolderValid = _.find(unit.NZHs, nozH => {return nozH.id === old_nozzle_holder_id})
          if(!nozzleHolderValid) {
            nozzleHolder = unit.NZHs[0]
            const nozzleValid = _.find(nozzleHolder.nozzles, noz => {return noz.id === nozzle.type.id})
            if(!nozzleValid) {
              this.props.uiActions.showInfoMessage({message: formatMessage(messages.tankcleaningunit_nozzle_type_changed)})
              nozzle = null
            }
          } else {
            nozzleHolder = nozzleHolderValid;
          }
        } else {
          nozzleHolder = "0 mm"
        }
      }

      this.props.uiActions.setCalculationParameters({
          key : "tankCleaningUnit",
          params : {
              aquamatSelector : {
                ...this.getAquamatSelectorParams(),
                ...change,
                ...(change.unit && !change.pressure ? { pressure: change.unit.dataunits["pTCHmax"].value_with_unit} : {}),
                nozzle_holder: nozzleHolder
              },
              nozzle: nozzle
          }
      });
  }

  handleNozzleConfigurationChange = (items) => {
      this.props.uiActions.setCalculationParameters({
          key : "tankCleaningUnit",
          params : {
              nozzle: items[0]
          }
      });
  }

  getAquamatSelectorParams() {
      let unitParams={};
      if(this.props.calculationParams && this.props.calculationParams.aquamatSelector) {
          unitParams = this.props.calculationParams.aquamatSelector;
      } else {
          if(this.props.units.length>0) {
              unitParams = {
                  unit: this.props.units[0],
                  nozzle_holder: ((this.props.units[0].NZHs.length>0) ? this.props.units[0].NZHs[0] : "0 mm"),
                  pressure: this.props.units[0].dataunits["pTCHmax"].value_with_unit
              }
          } else {
              unitParams = {}
          }
      }
      return unitParams;
  }

    componentDidMount() {
        setTimeout(() => {
            this.props.uiActions.setActiveMenuItem({ item: menuItems.tankcleaningunit })
        })
        this.doRecalc()
    }

    componentDidUpdate(prevProps, prevState) {
        this.doRecalc();
    }

    doRecalc = () => {
        if(this.recalcTimer) {
            clearTimeout(this.recalcTimer);
        }
        this.recalcTimer = setTimeout(() => {
            this.recalculate()
        },300)
    }

  verifyInput = () => {
    let aquamatSelectorParams = this.getAquamatSelectorParams();
    if(!aquamatSelectorParams
        || !aquamatSelectorParams
        || !aquamatSelectorParams.unit
        || !aquamatSelectorParams.nozzle_holder
        || !aquamatSelectorParams.pressure
        || !this.props.calculationParams.nozzle) {
      return false;
    }
    return true;
  }

  recalculate = () => {
    const f = this.props.formulas.tch_calculation_v5;
    if(this.verifyInput()) {
      try {
        let aquamatSelectorParams = this.getAquamatSelectorParams();
        const params = _.cloneDeep({
            'nozzle' : {
                'μ' : this.props.calculationParams.nozzle.efficiency,
                'di' : this.props.calculationParams.nozzle.diameter,
                'pNZmax': this.props.calculationParams.nozzle.maxPressure
            },
            //r : aquamatSelectorParams.nozzle_holder,
            pB: aquamatSelectorParams.pressure,
            ...unitToParams(aquamatSelectorParams.unit),
            ...nzhToParams(aquamatSelectorParams.nozzle_holder)
        });
        //console.log("params",params);
        //console.log("calcparams",this.props.calculationParams);
        if(_.isEqual(params,this.lastParams)) {
          return;
        }
        const res = evaluateMathProgram(f, params);
        this.lastParams = params;
        let warnings = [];

        let wUnit = aquamatSelectorParams.unit;
        let wUnitParams = unitToParams(wUnit);
        let wNozzle = this.props.calculationParams.nozzle;
        let wNZH = aquamatSelectorParams.nozzle_holder;
        let wNZHParams = nzhToParams(aquamatSelectorParams.nozzle_holder);

        if(res["WARN_PNZMAX_EXCEED"]) {
          warnings.push({
            type: "error",
            label: <FormattedMessage id="flowrate_wshort_PNZMAX" defaultMessage="Nozzle insert at max. op. pressure" />,
            info: <FormattedMessage id="flowrate_wlong_PNZMAX" defaultMessage="Please do not exceed the maximum pressure specifications of the nozzle insert, for {nozzle} nozzles it is {pressure}"
              values={{nozzle: wNozzle.type.name, pressure: formatValueWithUnit({ valueWithUnit: wNozzle.maxPressure, si_unit: "bar", us_unit: "psi", precision: 0 })}}
            />
          });
        }
        if(res["WARN_MA_VERY_LOW"]) {
          warnings.push({
            type: "error",
            label: <FormattedMessage id="tch_wshort_MA_VERY_LOW" defaultMessage="Tank Cleaner does not rotate" />,
            info: <FormattedMessage id="tch_wlong_MA_VERY_LOW" defaultMessage="The driving torque deceeded the lower limit. Select nozzle holder with larger lever arm or increase pressure and flow rate." />
          })
        }
        else if(res["WARN_MA_LOW"]) {
          warnings.push({
            type: "warning",
            label: <FormattedMessage id="tch_wshort_MA_LOW" defaultMessage="Tank Cleaner might not rotate" />,
            info: <FormattedMessage id="tch_wlong_MA_LOW" defaultMessage="The driving torque is close to the lower limit. The recommended brake adjustment is between 10% and 50%. Select nozzle holder with larger lever arm or increase pressure and flow rate." />
          })
        }
        if(res["WARN_MA_VERY_HIGH"]) {
          warnings.push({
            type: "error",
            label: <FormattedMessage id="tch_wshort_MA_VERY_HIGH" defaultMessage="Maximum allowed driving torque exceeded."/>,
            info: <FormattedMessage id="tch_wlong_MA_VERY_HIGH" defaultMessage="The max. driving torque for {unit} is {mamax}. Select nozzle holder with lower lever arm or decrease pressure and flow rate."
                                    values={{unit: wUnit.name, mamax: formatValueWithUnitAndLabel({ valueWithUnit: res.Mamax, si_unit: "N*m", precision: 0, si_label: "Nm" })}}/>
          })
        }

        if(res["WARN_PB_HIGH"]) {
          warnings.push({
            type: "error",
            label: <FormattedMessage id="tch_wshort_PB_HIGH" defaultMessage="Maximal operating pressure exceeded" />,
            info: <FormattedMessage id="tch_wlong_PB_HIGH" defaultMessage="The max. operating pressure of {unit} is {pTCHmax}. Please operate your tank cleaning head only in the pressure limits individually to every tank cleaning head, nozzle holder, etc."
                    values={{ unit: wUnit.name, pTCHmax: formatValueWithUnitAndLabel({ valueWithUnit: wUnitParams.pTCHmax, si_unit: "bar", us_unit: "psi", precision: 0 }) }}
            />,
          })
        }

        if(!res["WARN_MA_VERY_LOW"] && res["WARN_UTCHMAX_HIGH"]) {
            warnings.push({
                type: "warning",
                label: <FormattedMessage id="tch_wshort_UTCHMAX_HIGH" defaultMessage="High RPM at small brake adjustment."/>,
                info: <FormattedMessage id="tch_wlong_UTCHMAX_HIGH" defaultMessage="The max. recommended RPM for the {unit} so {rpmMax}. Please adjust the break between 50% and 100% to not exceed the recommended RPM. Verify before use. Overspeed may cause system damage and generally increases wear. Select nozzle holder with smaller lever arm or reduce pressure and flow rate."
                                        values={{unit: wUnit.name, rpmMax: formatValueWithUnitAndLabel({ valueWithUnit: wUnitParams.uRECmax, si_unit: "mins^-1", us_unit: "mins^-1", precision: 0, si_label: getRPMLable(), us_label: getRPMLable() })}}/>
            })
        }

        /*if(res["WARN_QTOT_HIGH"]) {
           warnings.push({
             type: "error",
             label: <FormattedMessage id="tch_wshort_QTOT_HIGH" defaultMessage="The maximum flowrate has been exceeded" />,
             info: <FormattedMessage id="tch_wlong_QTOT_HIGH" defaultMessage="The total flowrate is too high. Please reduce it or choose another unit. The maximum flowrate for {unit} is {flowrate}."
               values={{unit: wUnit.name, flowrate: formatValueWithUnitAndLabel({ valueWithUnit: wUnitParams.QUmax, si_unit: "l/mins", us_unit: "gal/mins", precision: 0 })}}/>
          })
        }*/

        if(res["WARN_DELTAP"]) { // Fall 3 & Fall 4
          warnings.push({
            type: "warning",
            label: <FormattedMessage id="tch_wshort_DELTAP_HIGH" defaultMessage="Reduced efficiency due to high pressure loss in tank cleaning head." />,
            info: <FormattedMessage id="tch_wlong_DELTAP_HIGH" defaultMessage="The pressure loss inside the tank cleaning head is {relpl}% of the operating pressure. Please check if a tank cleaning head of the next bigger type with a higher nominal diameter is suitable for your use case."
                                    values={{relpl: (res["relpl"]*100.0).toFixed(0)}}/>
          })
        }

        if(res["WARN_QTOT_HIGH"]) {
          // Fall 2 & Fall 3
          warnings.push({
            type: "warning",
            label: <FormattedMessage id="tch_wshort_QTOT_HIGH" defaultMessage="The maximum flowrate has been exceeded" />,
            info: <FormattedMessage id="tch_wlong_QTOT_HIGH" defaultMessage="Please check if a tank cleaning head of the next bigger type with a higher nominal diameter is suitable for your use case."
                                    values={{unit: wUnit.name, flowrate: formatValueWithUnitAndLabel({ valueWithUnit: wUnitParams.QUmax, si_unit: "l/mins", us_unit: "gal/mins", precision: 0, si_label: "l/min", us_label: "gal/min"  })}}/>
          })
        }

        if(res["WARN_PNZHMAX_EXCEED"]) {
          warnings.push({
              type: "error",
              label: <FormattedMessage id="tch_wshort_PNZHMAX_HIGH" defaultMessage="Maximum nozzle holder pressure exceeded" />,
              info: <FormattedMessage id="tch_wlong_PNZHMAX_HIGH" defaultMessage="The maximum operating pressure for {nzh} is {pressure}. Please operate your tank cleaning head only in the pressure limits individually to every tank cleaning head, nozzle holder, etc."
                                      values={{nzh: wNZH.dataunits.displayName.value, pressure: formatValueWithUnitAndLabel({ valueWithUnit: wNZHParams.pNZHMax, si_unit: "bar", us_unit: "psi", precision: 0 })}}/>
          })
        }

        if((!res["WARN_POWER_VERY_HIGH"]) && res["WARN_POWER_HIGH"]) {
          warnings.push({
              type: "warning",
              label: <FormattedMessage id="tch_wshort_POWER_HIGH" defaultMessage="Recommended power exceeded" />,
              info: <FormattedMessage id="tch_wlong_POWER_HIGH" defaultMessage="The maximum recommended power {powerTCHrec} for {unit} is exceeded. Choosing a lower pressure or selecting a smaller nozzle diameter to achieve a lower flowrate, will reduce the power."
                                      values={{unit: wUnit.name, powerTCHrec: formatValueWithUnitAndLabel({ valueWithUnit: wUnitParams.powerTCHrec, si_unit: "kW", us_unit: "kW", precision: 0 })}}/>
          })
        }

        if(res["WARN_POWER_VERY_HIGH"]) {
          warnings.push({
            type: "error",
            label: <FormattedMessage id="tch_wshort_POWER_VERY_HIGH" defaultMessage="Allowable power exceeded" />,
            info: <FormattedMessage id="tch_wlong_POWER_VERY_HIGH" defaultMessage="The selected operating parameters are above the limit. Excessive power leads to increased wear and reduced service life of the tank cleaning unit. Use a device of the next size."
                                  values={{}}/>
          })
        }

        // Bei Zahlen <=2 sollen die Nachkommastellen erhöht werden
        let res_MA_decimals=0;
        if(isASmallerOrEqualB(getRawValue(convert(res['Ma'], "N*m")),2)) {
            res_MA_decimals=2;
        }

        let values = [{
          type: (res["WARN_MA_VERY_LOW"] || res["WARN_MA_VERY_HIGH"]) ? "error" : ((res["WARN_POWER_HIGH"] || res["WARN_MA_LOW"]) ? "warning" : "valid"),
          label: (<FormattedMessage id="tankcleaningunit_result_ma" defaultMessage="Drive torque"/>),
          value: (<DataUnitTextField decimalPlaces={res_MA_decimals} align="right-m-left-d" value={res['Ma']} editable={false} si="N*m" siLabel="Nm" />)
        },{
          type: (res["WARN_POWER_VERY_HIGH"]) ? "error" :((res["WARN_POWER_HIGH"] || res["WARN_QTOT_HIGH"]) ? "warning" : "valid"),
          //type: "valid",
          label: (<FormattedMessage id="tankcleaningunit_result_qtot" defaultMessage="Total flow rate"/>),
          value: (<DataUnitTextField decimalPlaces={1} align="right-m-left-d" value={res['Qtot']} editable={false} si="l/mins" siLabel="l/min" us="gal/mins" usLabel="gal/min" />)
        }];

        if(res["WARN_DELTAP"]) {
          values.push({
            type: (res["WARN_DELTAP"]) ? "warning" : "valid",
            label: (<FormattedMessage id="tankcleaningunit_result_deltap" defaultMessage="Pressure loss Aquamat"/>),
            value: (<DataUnitTextField decimalPlaces={0} align="right-m-left-d" value={res['finalDelta']} editable={false} si="bar" siLabel="bar" us="psi" usLabel="psi" />)
          });
        }


        this.setState({
          result : {
            values : values,
            warnings : warnings,
            rpmMin : formatValue(res['UTCHMIN'],"mins^-1",0),
            rpmMax : formatValue(res['UTCHMAX'],"mins^-1",0),
            rpmOverallType : res['WARN_POWER_VERY_HIGH'] || res['WARN_MA_VERY_HIGH'] || res["WARN_MA_VERY_LOW"] || res['WARN_PB_HIGH'] || res['WARN_PNZHMAX_EXCEED'] || res['WARN_PNZMAX_EXCEED']  ? 'error' : 'valid',
            //rpmMaxType: res["WARN_PB_HIGH"] ? "error" : (res["WARN_UTCHMAX_HIGH"] ? "warning" : "valid"),
            rpmMaxType: res["WARN_UTCHMAX_HIGH"] ? "warning" : "valid",
            rpmMinType: (res["WARN_POWER_HIGH"] || res["WARN_MA_LOW"]) ? "warning" : "valid",
          }
        })
      } catch(ex) {
        console.error("Calculation eror occured");
        console.error(ex, ex.stack);
        this.resetResults();
      }
    } else {
      this.resetResults();
    }
  }

  resetResults = () => {
      let reset = {
          result: {
              values: [],
              warnings: [],
              rpmMin: null,
              rpmMax: null,
              rpmMaxType: "valid",
              rpmMinType: "valid",
              rpmOverallType: "valid"
          },
      };
      let tmp = _.merge(this.state, reset)
      if(!_.isEqual(tmp, this.state)) {
          this.setState(reset)
      }
  }

  render() {

    const { formatMessage } = this.props.intl;
    const aquamatSelectorProps = this.getAquamatSelectorParams();
    // debugger;
    // console.log("TEST")
    // console.log(aquamatSelectorProps.nozzle_holder.dataunits.numnoz.value)
    // console.log(typeof aquamatSelectorProps.nozzle_holder.dataunits.numnoz.value)


    return (
        <Layout caption={formatMessage(messages.tankcleaningunit_caption)}>

          <SectionHeader><FormattedMessage id="tankcleaningunit_header_unit" defaultMessage="YOUR UNIT"/></SectionHeader>
          <AquamatSelector
              onChange={this.handleAquamatSelectorChange}
              units = {this.props.units}
              {...aquamatSelectorProps}
          />

          <SectionHeader><FormattedMessage id="tankcleaningunit_header_nozzle" defaultMessage="NOZZLE CONFIGURATION"/></SectionHeader>
          <NozzleConfigurationTable
              onConfigurationChange={this.handleNozzleConfigurationChange}
              maxCount={1}
              fixAmount={parseFloat(aquamatSelectorProps.nozzle_holder.dataunits.numnoz.value)}
              nozzleTypes={aquamatSelectorProps.nozzle_holder.nozzles}
              enableDeletion={false}
              showDiameter={true}
              items={this.props.calculationParams && this.props.calculationParams.nozzle ? [this.props.calculationParams.nozzle] : []}
          />

          <div className="tankcleaningunit-result-rpm-header">
            <FormattedMessage id="tankcleaningunit_result_rpm" defaultMessage="APPROX. ADJUSTABLE RPM" />
          </div>
          <div className={`tankcleaningunit-result-rpm tankcleaningunit-result-rpm-${(this.state.result && this.state.result.rpmOverallType) ? this.state.result.rpmOverallType :  'valid'}`}
            ref={(ref)=>{
              if(ref){
                let elemNode = ReactDOM.findDOMNode(ref);
                elemNode.style.flexDirection = 'row';
                let elemDimension = elemNode.getBoundingClientRect();
                let fullWidth = 0;
                _.map(elemNode.childNodes, node=>{
                  fullWidth+=node.getBoundingClientRect().width;
                });

                let lableBig = ReactDOM.findDOMNode(this.refs.lableBig);
                let lableSmall = ReactDOM.findDOMNode(this.refs.lableSmall);
                if(fullWidth > (elemDimension.width+15)){
                  elemNode.style.flexDirection = 'column';
                  if(lableBig && lableSmall){
                    lableBig.style.display = 'block';
                    lableSmall.style.display = 'none';
                  }
                }else{
                  elemNode.style.flexDirection = 'row';
                  if(lableBig && lableSmall){
                    lableBig.style.display = 'none';
                    lableSmall.style.display = 'block';
                  }
                }
              }
            }}
          >
            <div className={`tankcleaningunit-result-rpm-min tankcleaningunit-result-rpm-min-${(this.state.result && this.state.result.rpmMinType) ? this.state.result.rpmMinType :  'valid'}`}>
              <If condition={this.state.result && this.state.result.rpmMin}>
                <div>{this.state.result.rpmMin > 0 ? this.state.result.rpmMin : 0}</div>
                <div className="tankcleaningunit-result-rpm-min-lable">{getRPMLable()}</div>
              </If>
            </div>
            <div className="tankcleaningunit-result-rpm-arrow">
              {/*
                <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 483.63 87.58">
                  <line stroke="#1d1d1b" strokeMiterlimit="10" strokeWidth="10.71px" class="cls-1" x1="11.61" y1="43.78" x2="472.02" y2="43.78"/>
                  <polygon class="cls-2" fill="#1d1d1b" points="47.09 87.58 54.4 79.74 15.72 43.8 54.4 7.84 47.09 0 0 43.8 47.09 87.58"/>
                  <polygon class="cls-2" fill="#1d1d1b" points="436.54 87.58 429.23 79.74 467.91 43.8 429.23 7.84 436.54 0 483.63 43.8 436.54 87.58"/>
                </svg>
              */}
              <div className={`tankcleaningunit-result-rpm-min-extended tankcleaningunit-result-rpm-min-${(this.state.result && this.state.result.rpmMinType) ? this.state.result.rpmMinType :  'valid'}`} />
              <div className={`tankcleaningunit-result-rpm-max-extended tankcleaningunit-result-rpm-max-${(this.state.result && this.state.result.rpmMaxType) ? this.state.result.rpmMaxType :  'valid'}`} />
              <IconLeft className="tankcleaningunit-result-rpm-arrow-icon left" />
              <div className="tankcleaningunit-result-rpm-arrow-line" />
              <IconRight className="tankcleaningunit-result-rpm-arrow-icon right" />
            </div>
            <div className={`tankcleaningunit-result-rpm-max tankcleaningunit-result-rpm-max-${(this.state.result && this.state.result.rpmMaxType) ? this.state.result.rpmMaxType :  'valid'}`}>
              <If condition={this.state.result && this.state.result.rpmMax}>
                <div>{this.state.result.rpmMax > 0 ? this.state.result.rpmMax : 0}</div>
                <div className="tankcleaningunit-result-rpm-max-lable">{getRPMLable()}</div>
              </If>
            </div>
          </div>
          {/*
            <div ref="lableSmall" className="tankcleaningunit-result-rpm-unit-lable">
              min <sup style={{top: -3}}>-1</sup>
            </div>
          */}
          <div className="tankcleaningunit-result-rpm-unit">
            <div className="tankcleaningunit-result-lable-container">
              <div className="tankcleaningunit-result-icon-container">
                <img className="tankcleaningunit-result-icon" src={imgSchildkroete} />
              </div>
            </div>
            <div className="tankcleaningunit-result-lable-container-flex" >
              <div className="tankcleaningunit-result-subtitle-container">
              </div>
            </div>
            <div className="tankcleaningunit-result-lable-container">
              <div className="tankcleaningunit-result-icon-container">
                <img className="tankcleaningunit-result-icon" src={imgHase} />
              </div>
            </div>
          </div>
          <div className="tankcleaningunit-result-rpm-unit tankcleaningunit-result-rpm-unit-dark">
            <div className="tankcleaningunit-result-lable-container">
              max.
            </div>
            <div className="tankcleaningunit-result-lable-container-flex" >
              <div className="tankcleaningunit-result-subtitle-container">
                <FormattedMessage id="tankcleaningunit_result_subtitle" defaultMessage="BRAKE ADJUSTMENT" />
              </div>
            </div>
            <div className="tankcleaningunit-result-lable-container">
              min.
            </div>
          </div>

          <CalculationResult
              values = {this.state.result.values}
              warnings = {this.state.result.warnings}
          />
        </Layout>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    //enabled: getEnabled(state),
    formulas: getFormulas(state),
    dataunits: getDataunitsBySymbol(state),
    calculationParams: getTankCleaningUnitCalculationParams(state),
    units: getAquamats(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(UIActions, dispatch),
  }
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(TankCleaningUnit))
