import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {Link} from 'react-router-dom';
import './optionSelect.scss';

export class OptionSelect extends Component {

    static propTypes = {
        children : PropTypes.array,
        background: PropTypes.oneOf(['light', 'dark', 'modal']),
        responsive: PropTypes.bool,
        border: PropTypes.bool,
    }

    static defaultProps = {
        background: 'light',
        responsive: true,
        border: true,
    }

    render() {
        return (
            <div className={`option-select option-select--${this.props.background} option-select option-select--border-${this.props.border} option-select option-select--responsive-${this.props.responsive} ${!this.props.children || this.props.children.length==0?'no-elems':''}`}>
                {this.props.children}
            </div>
        )
    }

}

export class OptionSelectItem extends Component {

    static propTypes = {
        children : PropTypes.any,
        active: PropTypes.bool,
        onClick: PropTypes.func,
        labelTop: PropTypes.any,
        labelBottom: PropTypes.any,
        disabled: PropTypes.bool,
    }

    static defaultProps = {
        onClick: () => {}
    }

    render() {
        return (
          <div className={`option-select-item-container`}>
              <If condition={this.props.labelTop}>
                 <div className={`option-select-item-label`} style={{marginBottom: 16}}>{this.props.labelTop}</div>
              </If>
              <div className={`option-select-item ${this.props.disabled?'option-select--disabled':''} ${this.props.active ? 'option-select-item-active' : ''}`} onClick={this.props.onClick}>{this.props.children}</div>
              <If condition={this.props.labelBottom}>
                  <div className={`option-select-item-label`} style={{marginTop: 16}}>{this.props.labelBottom}</div>
              </If>
          </div>
        );
    }
}
