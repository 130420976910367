import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getCurrentDataUnitSystem } from 'storeState/data/selectors';
import { dataUnitSystems } from 'storeState/data/constants';

class DataUnitLabel extends Component {

    static propTypes = {
        si: PropTypes.string.isRequired,
        us: PropTypes.string,
    };

    render() {
        let value = "";
        
        if(this.props.useUsSystem && this.props.us) {
            value = this.props.us;
        } else {
            value = this.props.si;
        }

        return <span>{value}</span>;
    }
}

function mapStateToProps(state, props) {
    return {
        useUsSystem: getCurrentDataUnitSystem(state)==dataUnitSystems.us,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DataUnitLabel);
