import React, { Component } from 'react';
import Layout from 'layout/Layout';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UIActions from 'storeState/ui/actions';
import { menuItems } from 'storeState/ui/constants';
import { getFormulas, getDataunitsBySymbol, getUnits } from 'storeState/data/selectors';
import { getFlowrateCalculationParams } from 'storeState/ui/selectors';
import { TabNavigation, Tab } from 'layout/tabNavigation/TabNavigation';
import SectionHeader from 'layout/sectionHeader/SectionHeader';
import UnitSelector from 'layout/unitSelector/UnitSelector';
import CalculationResult from 'layout/calculationResult/CalculationResult';
import DataUnitTextField from 'layout/dataUnitTextField/DataUnitTextField';
import NozzleConfigurationTable from 'layout/configurationTable/NozzleConfigurationTable';
import HoseConfigurationTable from 'layout/configurationTable/HoseConfigurationTable';
import {evaluateMathProgram, formatValueWithUnit} from 'service/MathService';
import {getRPMLable} from 'service/LableService';
import _ from 'lodash';
import './flowrate.scss';
import {getRawValue} from 'service/MathService';

const messages = defineMessages({
  flowrate_caption: {
    id: 'flowrate_caption',
    defaultMessage: 'Flow rate & pressure loss'
  }
});

class Flowrate extends Component {

  constructor(props) {
    super(props);
      this.lastParams = null;
      this.state = {
      result : {
        warnings : [],
        values : []
      },
    }
  }

  handleUnitSelect = (unit) => {
      this.props.uiActions.setCalculationParameters({
          key : "flowrate",
          params : {
              unit: {
                  ...this.getUnitParams(),
                  ...unit
              }
          }
      });
  }

  handleNozzleConfigurationChange = (items) => {
    this.props.uiActions.setCalculationParameters({
      key : "flowrate",
      params : {
          nozzles: items
      }
    });
  }

  handleHoseConfigurationChange = (items) => {
    this.props.uiActions.setCalculationParameters({
      key : "flowrate",
      params : {
          hoses: items
      }
    });
  }

  getUnitParams() {
      let unitParams={};
      if(this.props.calculationParams && this.props.calculationParams.unit) {
          unitParams = this.props.calculationParams.unit;
      } else {
          if(this.props.units.length>0) {
              unitParams = {
                  unit: this.props.units[0],
                  ...this.props.units[0]
              }
          } else {
              unitParams = {}
          }
      }
      return unitParams;
  }

  componentDidMount() {
      setTimeout(() => {
          this.props.uiActions.setActiveMenuItem({ item: menuItems.flowrate })
      })
      this.doRecalc()
  }

  componentDidUpdate(prevProps, prevState) {

    if(this.props.calculationParams.unit) {
      const propUnit = _.find(this.props.units, un => un.id === this.props.calculationParams.unit.id);
      if(propUnit) {
        const comparePropParams = {
          maxFlowrate: propUnit.maxFlowrate,
          maxPressure: propUnit.maxPressure,
          rpmMax: propUnit.rpmMax,
          rpmMin: propUnit.rpmMin,
        }
        const compareCalcParams = {
          maxFlowrate: this.props.calculationParams.unit.maxFlowrate,
          maxPressure: this.props.calculationParams.unit.maxPressure,
          rpmMax: this.props.calculationParams.unit.rpmMax,
          rpmMin: this.props.calculationParams.unit.rpmMin,
        }
        if(!_.isEqual(comparePropParams, compareCalcParams)) {
          this.handleUnitSelect(propUnit);
        }
      }
    }

    this.doRecalc();
  }

  verifyInput = () => {
    if(!this.props.calculationParams || !this.props.calculationParams.nozzles || !this.props.calculationParams.hoses || !this.getUnitParams().flowrate) {
      return false;
    }
    return true;
  }

  doRecalc = () => {

      if(this.recalcTimer) {
          clearTimeout(this.recalcTimer);
      }
      this.recalcTimer = setTimeout(() => {
          this.recalculate()
      },300)
  }

  recalculate = () => {
    const f = this.props.formulas.flowrate_pressure_loss;
    if(this.verifyInput()) {
      try {
        let warnings = [];

        if(!this.props.calculationParams.unit.rpmMin || !this.props.calculationParams.unit.rpmMax) {
          warnings.push({
            type: "info",
            label: <FormattedMessage id="nozzle_calculation_wshort_NOTSET_UHD" defaultMessage="R.P.M. not set"/>,
            info: <FormattedMessage id="nozzle_calculation_wlong_NOTSET_UHD"
                                    defaultMessage="If you have an electrically driven or diesel driven plunger high pressure water unit, please create a present under “Properties” to be warned if your R.P.M. are possible, by setting the max. and min. limits of your engine."/>
          })
        }
        if(!this.props.calculationParams.unit.maxFlowrate) {
          warnings.push({
            type: "info",
            label: <FormattedMessage id="nozzle_calculation_wshort_NOTSET_QMAX"
                                     defaultMessage="No max. flow rate set"/>,
            info: <FormattedMessage id="nozzle_calculation_wlong_NOTSET_QMAX"
                                    defaultMessage="If you have an electrically driven or diesel driven plunger high pressure water unit, please create a present under “Properties” to be warned if your flow rate is possible, by setting the max. limits of your unit."/>
          })
        }
        if(!this.props.calculationParams.unit.maxPressure) {
          warnings.push({
            type: "info",
            label: <FormattedMessage id="nozzle_calculation_wshort_NOTSET_PBMAX" defaultMessage="No max. op. pressure set" />,
            info: <FormattedMessage id="nozzle_calculation_wlong_NOTSET_PBMAX" defaultMessage="If you have an electrically driven or diesel driven plunger high pressure water unit, please create a present under “Properties” to be warned if your operating pressure is possible, by setting the max. limits of your unit." />
          })
        }

        const params = {
            'hoses' : _.map(this.props.calculationParams.hoses, (hose) => {
                return {
                    'di' : hose.diameter,
                    'Lh' : hose.length,
                    'n' : hose.amount,
                    'CL1' : hose.CL1,
                    'CL2' : hose.CL2,
                }
            }),
            'nozzles' : _.map(this.props.calculationParams.nozzles, (nozzle) => {
                return {
                    'μ' : nozzle.efficiency,
                    'di' : nozzle.diameter,
                    'alpha' : nozzle.angle,
                    'na' : nozzle.amount,
                    'pNZmax': nozzle.maxPressure
                }
            }),
            Qh : this.props.calculationParams.unit.flowrate,
            pB: this.props.calculationParams.unit.pressure, //bar
            pBmax : this.props.calculationParams.unit.maxPressure, //bar
            Qmax : this.props.calculationParams.unit.maxFlowrate, //400 l/mins;
            uHDmax : this.props.calculationParams.unit.rpmMax, //300 mins ^ -1 in mins ^ -1;
            uHDmin : this.props.calculationParams.unit.rpmMin//300 mins ^ -1 in mins ^ -1;
        };

        if(_.isEqual(params, this.lastParams)) {
          return;
        }

        const res = evaluateMathProgram(f, params);

        this.lastParams = params;

        /*WARN_W = max(map(hoses,fw)) > MAX_W;
        WARN_PDELTA = pdelta > (pB*MAX_PDELTA_FACTOR);
        WARN_FR_HIGH = Fr>=MAX_FR;
        WARN_FR_VERY_HIGH = Fr>=MAX2_FR;
        WARN_FR_POSITIVE = Fr>0N;
        WARN_FR_LOW = Fr<=MIN_FR;
        WARN_QTOT = Qtot>Qmax;
        WARN_PB_HIGH = pB>pBmax;
        WARN_ALPHA_HIGH = maxAlpha > MAX_ALPHA;
        WARN_UHD_LOW = uHD<=uHDmin;
        WARN_UHD_HIGH = uHD<=uHDmax;
        */

        if(res["WARN_PNZMAX_EXCEED"]) {
          let wNozzle = _.minBy(this.props.calculationParams.nozzles, nozzle => {return getRawValue(nozzle.maxPressure)});
          warnings.push({
            type: "error",
            label: <FormattedMessage id="flowrate_wshort_PNZMAX" defaultMessage="Nozzle insert at max. op. pressure" />,
            info: <FormattedMessage id="flowrate_wlong_PNZMAX" defaultMessage="Please do not exceed the maximum pressure specifications of the nozzle insert, for {nozzle} nozzles it is {pressure}"
              values={{nozzle: wNozzle.type.name, pressure: formatValueWithUnit({ valueWithUnit: wNozzle.maxPressure, si_unit: "bar", us_unit: "psi", precision: 0 })}}
            />
          });
        }

        if(res["WARN_PDELTA"]) {
          warnings.push({
            type: "error",
            label: <FormattedMessage id="flowrate_wshort_PDELTA" defaultMessage="Pressure loss above 10%" />,
            info: <FormattedMessage id="flowrate_wlong_PDELTA" defaultMessage="Pressure loss above 10% - Unefficient operation! Try to reduce pressure loss by using high pressure hoses with enlarged nominal diameter." />
          })
        }

        if(res["WARN_W"]) {
          warnings.push({
            type: "error",
            label: <FormattedMessage id="flowrate_wshort_W" defaultMessage="Increased hose wear" />,
            info: <FormattedMessage id="flowrate_wlong_W" defaultMessage="The maximal recommended water flow velocity inside the hose has been exceeded. If you operate your system with your current calculated parameters, hose wear will increase significantly." />
          })
        }
        if(res["WARN_UHD_LOW"]) {
          warnings.push({
            type: "error",
            label: <FormattedMessage id="flowrate_wshort_UHD_LOW" defaultMessage="R.P.M. to low" />,
            info: <FormattedMessage id="flowrate_wlong_UHD_LOW" defaultMessage="The min. r.p.m of the high pressure water unit has been exceeded for the desired flow rate." />
          })
        }
        if(res["WARN_UHD_HIGH"]) {
          warnings.push({
            type: "error",
            label: <FormattedMessage id="flowrate_wshort_UHD_HIGH" defaultMessage="R.P.M. to high" />,
            info: <FormattedMessage id="flowrate_wlong_UHD_HIGH" defaultMessage="The max. r.p.m of the high pressure water unit has been exceeded for the desired flow rate." />
          })
        }
        if(res["WARN_FR_VERY_HIGH"]) {
          warnings.push({
            type: "error",
            label: <FormattedMessage id="flowrate_wshort_FR_VERY_HIGH" defaultMessage="Hand held use dangerous" />,
            info: <FormattedMessage id="flowrate_wlong_FR_VERY_HIGH" defaultMessage="The hand held use of high pressure water blasting guns is forbidden above a 250 N reaction force." />
          })
        } else if(res["WARN_FR_HIGH"]) {
          warnings.push({
            type: "warning",
            label: <FormattedMessage id="flowrate_wshort_FR_HIGH" defaultMessage="Hand held use dangerous" />,
            info: <FormattedMessage id="flowrate_wlong_FR_HIGH" defaultMessage="Please be warned that the use of hand held high pressure water blasting guns without a shoulder stock is prohibited above 150 N reaction force." />
          })
        }
        if(res["WARN_FR_POSITIVE"]) {
          warnings.push({
            type: "warning",
            label: <FormattedMessage id="flowrate_wshort_FR_POSITIVE" defaultMessage="Only on rigid lances" />,
            info: <FormattedMessage id="flowrate_wlong_FR_POSITIVE" defaultMessage="Use only on rigid lances. Not permitted on high pressure hoses or flexible lances." />
          })
        }
        if(res["WARN_FR_LOW"]) {
          warnings.push({
            type: "error",
            label: <FormattedMessage id="flowrate_wshort_FR_LOW" defaultMessage="Hand held use dangerous" />,
            info: <FormattedMessage id="flowrate_wlong_FR_LOW" defaultMessage="Holding flexible lances / high pressure hoses by hands can be dangerous." />
          })
        }
        if(res["WARN_QTOT"]) {
          warnings.push({
            type: "error",
            label: <FormattedMessage id="flowrate_wshort_QTOT" defaultMessage="Max. flow rate exceeded" />,
            info: <FormattedMessage id="flowrate_wlong_QTOT" defaultMessage="The max. flow rate associated with your unit has been exceeded! Go to „Properties“ to change your max. flow rate or decrease your flow rate in your result by changing your input." />
          })
        }
        if(res["WARN_PB_HIGH"]) {
          warnings.push({
            type: "error",
            label: <FormattedMessage id="flowrate_wshort_PB_HIGH" defaultMessage="Caution! Over pressurizing!" />,
            info: <FormattedMessage id="flowrate_wlong_PB_HIGH" defaultMessage="Over pressurizing of the pump – rupture disc burst or safety valve actuating possible!" />
          })
        }

        if(res["WARN_ALPHA_HIGH"]) {
          warnings.push({
            type: "warning",
            label: <FormattedMessage id="flowrate_wshort_ALPHA_HIGH" defaultMessage="Inside pipe use only" />,
            info: <FormattedMessage id="flowrate_wlong_ALPHA_HIGH" defaultMessage="Nozzle orientation above 45° is not allowed on an hand held water blasting system, that is operated outside a pipe. The nozzle jet facing the operator may injure him." />
          })
        }

        let values = [{
          type: (res["WARN_PB_HIGH"]) ? "error" : "valid",
          label: (<FormattedMessage id="flowrate_result_pges" defaultMessage="Overall Pressure"/>),
          value: (<DataUnitTextField decimalPlaces={0} align="right-m-left-d" value={res['pges']} editable={false} si="bar" us="psi" />)
        },{
          type: (res["WARN_PDELTA"]) ? "error" : "valid",
          label: (<FormattedMessage id="flowrate_result_pdelta" defaultMessage="Pressure Loss"/>),
          value: (
            <DataUnitTextField
              decimalPlaces={0}
              align="right-m-left-d"
              value={res['pdelta']}
              editable={false}
              si="bar"
              us="psi"
              transformValue={value => {
                // round last digit to 5 or 0;
                let lastDigit = value % 10;
                let result = value;
                if(lastDigit!= 0 && lastDigit != 5){
                  if(lastDigit > 5){
                    result += (5-lastDigit);
                  }else{
                    result -= lastDigit;
                  }
                }
                return result;
              }}
            />
          )
        },{
          type: (res["WARN_FR_VERY_HIGH"] || res["WARN_FR_LOW"]) ? "error" : (res["WARN_FR_POSITIVE"] || res["WARN_FR_HIGH"]) ? "warning" : "valid",
          label: (<FormattedMessage id="flowrate_result_fr" defaultMessage="Reaction Force"/>),
          value: (<DataUnitTextField decimalPlaces={0} align="right-m-left-d" value={res['Fr']} editable={false} si="N" us="lbf" />)
        }]

        if(typeof res['uHD'] !== "undefined") {
          values.push({
            type: (res["WARN_UHD_LOW"] || res["WARN_UHD_HIGH"]) ? "error" : "valid",
            label: "RPM",
            value: (<DataUnitTextField decimalPlaces={0} align="right-m-left-d" value={res['uHD']} editable={false} si="mins^-1" customLabel={getRPMLable()} />)
          })
        }

        this.setState({
          result : {
            values : values,
            warnings : warnings,
          }
        })
      } catch(ex) {
        console.error("Calculation eror occured");
        console.error(ex, ex.stack);
        this.resetResults();
      }
    } else {
      this.resetResults();
    }
  }

  resetResults = () => {
      let reset = {
          result: {
              values: [],
              warnings: [],
          },
      };
      let tmp = _.merge(this.state, reset)
      if(!_.isEqual(tmp, this.state)) {
          this.setState(reset)
      }
  }


  render() {

    const { formatMessage } = this.props.intl;
    const unitParams = this.getUnitParams()

    return (
        <Layout caption={formatMessage(messages.flowrate_caption)}>

          <SectionHeader><FormattedMessage id="flowrate_header_unit" defaultMessage="UNIT"/></SectionHeader>
          <UnitSelector
              onUnitChange={this.handleUnitSelect}
              showFlowrate={true}
              showPressure={false}
              showMaxPressure={false}
              units = {this.props.units}
              {...unitParams}
          />

          <SectionHeader><FormattedMessage id="hose_calculation_header_configuration" defaultMessage="HOSE CONFIGURATION"/></SectionHeader>
          <HoseConfigurationTable
              onConfigurationChange={this.handleHoseConfigurationChange}
              enableDeletion={true}
              items={this.props.calculationParams && this.props.calculationParams.hoses ? this.props.calculationParams.hoses : null}
          />
          <SectionHeader><FormattedMessage id="nozzle_calculation_header_configuration" defaultMessage="NOZZLE CONFIGURATION"/></SectionHeader>
          <NozzleConfigurationTable
              onConfigurationChange={this.handleNozzleConfigurationChange}
              enableDeletion={true}
              showDiameter={true}
              showAngle={true}
              items={this.props.calculationParams && this.props.calculationParams.nozzles ? this.props.calculationParams.nozzles : null}
          />
          <CalculationResult
              values = {this.state.result.values}
              warnings = {this.state.result.warnings}
          />
        </Layout>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    //enabled: getEnabled(state),
    formulas: getFormulas(state),
    dataunits: getDataunitsBySymbol(state),
    calculationParams: getFlowrateCalculationParams(state),
    units: getUnits(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(UIActions, dispatch),
  }
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(Flowrate))
