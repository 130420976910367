import jsonp from 'jsonp';
import qs from 'query-string';
import {isIos} from 'service/LayoutService';
import FetchService from "./FetchService";

const prefix   = 'nominatim_callback_';
const BASE_URL = 'https://nominatim.openstreetmap.org/';

let cbCounter = 0;

function noop() {}

// export function geocode(options, callback, context) {
//     return new Promise((resolve,reject) => {
//         callback = callback || noop;
//         options = options || {q: ''};
//
//         options.format = 'json';
//         options.addressdetails = 1;
//
//         let url = BASE_URL + 'search?' + qs.stringify(options);
//         let name = options.callback || prefix + (cbCounter++);
//         jsonp(url, {prefix: prefix, param: 'json_callback', name: name}, function (err, result) {
//             resolve({err, result});
//             if (name in global) global[name] = undefined;
//         });
//     });
// }

export function reverse(options, callback, context) {
    return new Promise((resolve,reject) => {
        callback = callback || noop;
        options = options || {};
        options.format = 'json';
        options.addressdetails = 1;

        let url = BASE_URL + 'reverse?' + qs.stringify(options);
        let headers = FetchService.getJsonHeaders();

        headers.append("User-Agent", "wjc");

        FetchService.fetch({
            url: url,
            method: "GET",
            headers: headers,
        }).then((result)=>{
            resolve({err: null, result: result});
        }).catch((err)=>{
            resolve({err: err, result: null});
        });
    });
}

export function getMapsUrl(detail) {
    if(isIos()){
        return `http://maps.apple.com/?daddr=${detail.zip}+${detail.city}+${detail.street}+${detail.country}`;
    }else{
        return `http://maps.google.com/maps?daddr=${detail.zip}+${detail.city}+${detail.street}+${detail.country}`;
    }
}


if (typeof(Number.prototype.toRad) === "undefined") {
    Number.prototype.toRad = function() {
        return this * Math.PI / 180;
    }
}

export function getDistance(lat1,lon1,lat2,lon2) {
    var R = 6371; // km
    var dLat = (lat2-lat1).toRad();
    var dLon = (lon2-lon1).toRad();
    var lat1 = lat1.toRad();
    var lat2 = lat2.toRad();

    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    var d = R * c;
    return d;
}