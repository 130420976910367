import * as types from "./constants";

let initState = {
  enabled: true
};

export default function reducer(state = initState, action = "") {

  if(action.type.startsWith(types.PREFIX)){

    switch (action.type) {
      case types.ENABLE:
        return {
          ...state,
          enabled: true
        }
        break;
      case types.DISABLE:
        return {
          ...state,
          enabled: false
        }
        break;
      default:
        return state
        break;
    }
  }

  return state;
}
