import React, { Component } from 'react';
import PropTypes from 'prop-types';

import colors from 'style'
import 'components/layout/productWizard/wizardNavigation.scss';
import {FormattedMessage} from "react-intl";
import {MdArrowBack, MdArrowForward, MdMoreVert as Ellipsis} from "react-icons/md";
import WizardItem from "components/layout/productWizard/WizardItem";

export default class WizardNavigation extends Component {

  static propTypes = {
    onPrev: PropTypes.func,
    onPrevLabel: PropTypes.any,
    onNext: PropTypes.func,
    onNextLabel: PropTypes.any,
    onNextDisabled: PropTypes.bool,
    type: PropTypes.oneOf(['default', 'full'])
  }

  static defaultProps = {
    type: 'default',
    onNextDisabled: false
  }

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={`product-wizard-navigation product-wizard-navigation--type-${this.props.type}`}>
        <If condition={this.props.onPrev}>
          <div className={`product-wizard-navigation-item`}>
            <WizardItem
              type={"simple"}
              label={
                <div>
                  <MdArrowBack style={{marginRight: 8}} />
                  <If condition={this.props.onPrevLabel}>
                    {this.props.onPrevLabel}
                  <Else />
                    <FormattedMessage id="product_wizard_masterjet_navigation_prev" defaultMessage="Zurück"/>
                  </If>
                </div>
              }
              onClick={()=>this.props.onPrev()}
            />
          </div>
        </If>
        <If condition={this.props.onNext && this.props.onPrev}>
          <div style={{width: 16}}></div>
        </If>
        <If condition={this.props.onNext}>
          <div className={`product-wizard-navigation-item`}>
            <WizardItem
              type={"simple"}
              label={
                <div>
                  <If condition={this.props.type === "full"}>
                    <MdArrowForward style={{marginRight: 8}} />
                  </If>
                  <If condition={this.props.onNextLabel}>
                    {this.props.onNextLabel}
                  <Else />
                    <FormattedMessage id="product_wizard_masterjet_navigation_next" defaultMessage="Weiter"/>
                  </If>
                  <If condition={this.props.type !== "full"}>
                    <MdArrowForward style={{marginLeft: 8}} />
                  </If>
                </div>
              }
              isDisabled={this.props.onNextDisabled}
              onClick={()=>this.props.onNext()}
            />
          </div>
        </If>
      </div>
    )
  }
}

