import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {FormattedMessage, injectIntl} from 'react-intl';
import { connect } from "react-redux";
import _ from 'lodash';
import NozzleImage from "components/layout/nozzleImage/NozzleImage";
import colors from 'style'

import 'components/layout/productWizard/productWizardMasterjet.scss';
import SectionHeader from "components/layout/sectionHeader/SectionHeader";
import WizardItem from "components/layout/productWizard/WizardItem";

import MODAL_TYPE from "components/layout/productWizard/masterJetModals/MasterjetModalTypes.constants";
import {MdClose as CloseIcon} from "react-icons/md";
import WizardNavigation from "components/layout/productWizard/WizardNavigation";
import {bindActionCreators} from "redux";
import {getMasterjetConfigurationParams} from "storeState/ui/selectors";
import * as UIActions from 'storeState/ui/actions';
import {MASTERJET_CONFIGURATION_PARAMETERS} from "components/views/masterjetConfiguration/MasterjetConfiguration";
import {getNozzleArrangement} from "service/MasterJetService";

import masterjet_performance_class_chart from "assets/images/new/Performance-Class-Schaubild.png";
import masterjet_performance_class_practice from "assets/images/new/Performance-Class-Praxisbild.png";

class ModalPerformanceClass extends Component {

  static propTypes = {
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  static defaultProps = {

  }

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  setValue = (value) => {

    let newParams = {
      performanceClass: value
    };

    if(this.props.calculationParams.performanceClass !== value) {
      // reset
      newParams.productId = false;
      newParams.threadType = false;
      newParams.maxPressure = false;
    }

    this.props.uiActions.setCalculationParameters({
      key : MASTERJET_CONFIGURATION_PARAMETERS,
      params : newParams
    });
  }

  getPCTypes = () => {
    return _.uniq(_.map(this.props.filteredProducts, product => {
      let parsed = parseInt(product.dataunits.PC.value)
      if(isNaN(parsed)) parsed = 0;
      return parsed;
    }));
  }

  getItems = () => {

    const pCTypes = this.getPCTypes();

    let output = [];
    _.each(pCTypes.sort(), pCType => {

      const maxNozzle = this.props.calculationParams.maxNozzleCount;
      const maxCount = this.props.calculationParams.nozzleCount;
      const nozzleArrangement = getNozzleArrangement(maxCount, maxNozzle);
      let items = [];
      for(let i = 0; i < maxNozzle; i++) {
        if(nozzleArrangement.indexOf(i) >= 0) {
          items.push({type: 'placeholder_active'})
        } else {
          items.push({type: 'placeholder_inactive'})
        }
      }

      output.push(
        <WizardItem
          type={"simple"}
          border={true}
          isActive={this.props.calculationParams.performanceClass === pCType}
          image={
            <NozzleImage size={60} pc={pCType} style={{border: `1px solid ${colors.black}`}} items={items} ></NozzleImage>
          }
          label={<div className={"product-wizard-masterjet-table-modal-item-label"}><FormattedMessage id="product_wizard_masterjet_product_performance_class_pc" defaultMessage="PC"/> {pCType === 0 ? 'LOW FLOW' : pCType}</div>}
          onClick={()=>this.setValue(pCType)}
        />
      )
    })

    return output;
  }

  render() {

    return (
      <div className="product-wizard-masterjet-modal-item-container">

        <SectionHeader style={{padding: 0}}>
          <div className="product-wizard-masterjet-modal-item-section-row">
            <div className={"product-wizard-masterjet-modal-item-section-row-icon"} onClick={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_PRODUCT)}>
              <CloseIcon></CloseIcon>
            </div>
            <div className={"product-wizard-masterjet-modal-item-section-row-label"}>
              <FormattedMessage id="product_wizard_masterjet_performance_class" defaultMessage="Performance Class"/>
            </div>
          </div>
        </SectionHeader>

        <WizardNavigation
          onPrev={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_NOZZLE_DIAMETER)}
          onNext={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_THREAD)}
          onNextDisabled={this.props.calculationParams.performanceClass === null || this.props.calculationParams.performanceClass === false}
        />
        <WizardItem type={"hint"} label={<FormattedMessage id="product_wizard_masterjet_performance_class_hint" defaultMessage="Wie viele geschlossene Punkte sind übereinander auf dem Düsenhalter?"/>}/>
        <div className={"product-wizard-item-collection"}>
          {this.getItems()}
        </div>
        <WizardItem type={"hint"} label={<FormattedMessage id="product_wizard_masterjet_to_performance_class_info_title" defaultMessage="Warum ist das wichtig zu wissen?"/>}/>
        <WizardItem type={"default"} label={
          <>
            <div style={{fontWeight: 'bold', marginBottom: 8}}>
              <FormattedMessage id="product_wizard_masterjet_to_performance_class_info_subtitle" defaultMessage="Es gibt verschiedene Leistungsstufen"/>
            </div>
            <FormattedMessage id="product_wizard_masterjet_to_performance_class_info_description" defaultMessage="Die Masterjet hat durch die PC-Varianten andere Düsenwinkel in den sonst gleich aussehenden Düsenhaltern verbaut. Diese Winkel haben Einfluss auf die Tiefenwirkung der Düseneinsätze, und den Rückstoß des Systems. Die verfügbare PC Klasse richtet sich nach der Leistung des Hochdruckaggregats."/>
          </>
        }/>
        <div className={"product-wizard-masterjet-modal-item-image product-wizard-masterjet-modal-item-image-fullwidth product-wizard-masterjet-modal-item-image--border-false"}>
          <img src={masterjet_performance_class_chart} />
        </div>
        <div className={"product-wizard-masterjet-modal-item-image product-wizard-masterjet-modal-item-image-fullwidth product-wizard-masterjet-modal-item-image--border-false"}>
          <img src={masterjet_performance_class_practice} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    calculationParams: getMasterjetConfigurationParams(state) || {}
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(UIActions, dispatch),
  }
}

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalPerformanceClass))
