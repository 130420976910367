import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import _ from 'lodash'

import 'components/layout/productWizard/productWizardMasterjet.scss'
import SectionHeader from 'components/layout/sectionHeader/SectionHeader'
import WizardItem from 'components/layout/productWizard/WizardItem'

import MODAL_TYPE from 'components/layout/productWizard/masterJetModals/MasterjetModalTypes.constants'
import { MdClose as CloseIcon } from 'react-icons/md'
import { OptionSelect, OptionSelectItem } from 'components/layout/optionSelect/OptionSelect'

import WizardNavigation from 'components/layout/productWizard/WizardNavigation'
import { bindActionCreators } from 'redux'
import * as UIActions from 'storeState/ui/actions'
import { MASTERJET_CONFIGURATION_PARAMETERS } from 'components/views/masterjetConfiguration/MasterjetConfiguration'
import { getMasterjetConfigurationParams } from 'storeState/ui/selectors'

import masterjet_thread_clean from 'assets/images/new/Gewindtyp-M-Kontur.png'
import masterjet_thread_clean_selected from 'assets/images/new/Gewindtyp-M.png'
import masterjet_thread_point from 'assets/images/new/Gewindtyp-A-Kontur.png'
import masterjet_thread_point_selected from 'assets/images/new/Gewindtyp-A.png'

import masterjet_thread_chart from 'assets/images/new/Gewindetyp-Schaubild.png'
import masterjet_thread_practice from 'assets/images/new/Gewindetyp-Praxisbild.png'

class ModalThread extends Component {

  static propTypes = {
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  static defaultProps = {

  }

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  setValue = (value) => {

    let newParams = {
      threadType: value
    };

    if(this.props.calculationParams.threadType !== value) {
      // reset
      newParams.productId = false;
      newParams.maxPressure = false;
    }

    this.props.uiActions.setCalculationParameters({
      key : MASTERJET_CONFIGURATION_PARAMETERS,
      params : newParams
    });
  }

  getThreadTypes = () => {
    return _.uniq(_.map(this.props.filteredProducts, product => product.dataunits.threadType.value));
  }

  threadTypeHasPoint = () => {
    if (this.props.calculationParams.threadType !== false) {
      return this.props.calculationParams.threadType === "A";
    }
  }

  render() {

    return (
      <div className="product-wizard-masterjet-modal-item-container">
        <SectionHeader style={{padding: 0}}>
          <div className="product-wizard-masterjet-modal-item-section-row">
            <div className={"product-wizard-masterjet-modal-item-section-row-icon"} onClick={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_PRODUCT)}>
              <CloseIcon></CloseIcon>
            </div>
            <div className={"product-wizard-masterjet-modal-item-section-row-label"}>
              <FormattedMessage id="product_wizard_masterjet_thread" defaultMessage="Gewindetyp"/>
            </div>
          </div>
        </SectionHeader>
        <WizardNavigation
          onPrev={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_PERFORMANCE_CLASS)}
          onNext={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_PRESSURE)}
          onNextDisabled={!this.props.calculationParams.threadType}
        />
        <WizardItem type={"hint"} label={<FormattedMessage id="product_wizard_masterjet_thread_hint" defaultMessage="Hat eine Fläche des Sechskants am Anschlussgewinde einen Punkt?"/>}/>
        <OptionSelect border={false} background={"modal"} responsive={false}>
          <OptionSelectItem
            labelBottom={
               <div className={"product-wizard-modal-label"}>
                  <div className={"product-wizard-modal-label-pic-container"}>
                     <img src={this.threadTypeHasPoint() === true ? masterjet_thread_point_selected : masterjet_thread_point} />
                  </div>
                  <div className={"product-wizard-modal-label-text-container"}>
                     <FormattedMessage id="product_wizard_masterjet_thread_yes" defaultMessage="Ja"/>
                  </div>
               </div>
            }
            onClick={()=>{this.setValue("A")}}
            disabled={this.getThreadTypes().indexOf("A") === -1}
            active={this.threadTypeHasPoint() === true}
          >
             <FormattedMessage id="product_wizard_masterjet_thread_point" defaultMessage="Punkt"/>
          </OptionSelectItem>
          <OptionSelectItem
            labelBottom={
               <div className={"product-wizard-modal-label"}>
                  <div className={"product-wizard-modal-label-pic-container"}>
                     <img src={this.threadTypeHasPoint() === false ? masterjet_thread_clean_selected : masterjet_thread_clean} />
                  </div>
                  <div className={"product-wizard-modal-label-text-container"}>
                     <FormattedMessage id="product_wizard_masterjet_thread_no" defaultMessage="Nein"/>
                  </div>
               </div>
            }
            onClick={()=>{this.setValue("M_G")}}
            disabled={this.getThreadTypes().indexOf("M") === -1 && this.getThreadTypes().indexOf("G") === -1}
            active={this.threadTypeHasPoint() === false}
          >
             <FormattedMessage id="product_wizard_masterjet_thread_clean" defaultMessage="Glatt"/>
          </OptionSelectItem>
        </OptionSelect>
        <WizardItem type={"hint"} label={<FormattedMessage id="product_wizard_masterjet_thread_info_title" defaultMessage="Warum ist das wichtig zu wissen?"/>}/>
        <WizardItem type={"default"} label={
          <>
            <div style={{fontWeight: 'bold', marginBottom: 8}}>
              <FormattedMessage id="product_wizard_masterjet_thread_info_subtitle" defaultMessage="Es gibt verschiedene Gewindearten"/>
            </div>
            <FormattedMessage id="product_wizard_masterjet_thread_info_description"
                              defaultMessage="Die Masterjet (Compact) 1800 & 3200 gibt es in zwei Gewindetypen, metrisches Feingewinde und amerikanisches Zollgewinde mit den Endungen M (metrisch) und A (UNF).Die Masterjet 1000 trägt auch keinen Punkt und hat ein britisches Zollgewinde mit der Endung G (BSP)."/>
          </>
        }/>
        <div className={"product-wizard-masterjet-modal-item-image product-wizard-masterjet-modal-item-image-fullwidth product-wizard-masterjet-modal-item-image--border-false"}>
          <img src={masterjet_thread_chart} />
        </div>
        <div className={"product-wizard-masterjet-modal-item-image product-wizard-masterjet-modal-item-image-fullwidth product-wizard-masterjet-modal-item-image--border-false"}>
          <img src={masterjet_thread_practice} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    calculationParams: getMasterjetConfigurationParams(state) || {}
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(UIActions, dispatch),
  }
}

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalThread))
