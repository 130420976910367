import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {FormattedMessage, injectIntl} from 'react-intl';
import { connect } from "react-redux";
import { getNozzles, getDataunitsBySymbol, getTCHNozzles } from "storeState/data/selectors";
import ConfigurationTable from './ConfigurationTable';
import DataUnitTextField from 'layout/dataUnitTextField/DataUnitTextField';
import _ from 'lodash';
import Modal from 'react-modal';
import ButtonBar from 'layout/buttonBar/ButtonBar';
import Button from 'layout/button/Button';
import style from 'style';
import AngleSelector from 'layout/angleSelector/AngleSelector';
import {getUploadUrl} from 'service/ApiService';
import {isIosStatusBar} from 'service/LayoutService';
import AsyncImage from 'layout/asyncImage/AsyncImage';
import ReactDOM from 'react-dom'

import './nozzleconfigurationtable.scss';
const iosStatusBar = isIosStatusBar();

const MODAL_STEP_TYPE_SELECTION = 1;
const MODAL_STEP_CUSTOM_TYPE = 2;
const MODAL_STEP_DIAMETER = 3;
const MODAL_STEP_CUSTOM_DIAMETER = 4;

export function getNozzleDiametersByNozzleType(nozzleType) {
    if(nozzleType) {
        try {
            let separatedString = nozzleType.dataunits["nd"].value;
            let list = _.split(separatedString, ";");
            list = list.sort();
            const result = _.map(list, (x) => _.trim(x) + nozzleType.dataunits["nd"].si_unit);
            return result;
        } catch(e) {
            console.warn("Cannot load nozzle diameters for nozzleType:", nozzleType)
            return [];
        }
    } else {
        return [];
    }
}

class NozzleConfigurationTable extends Component {

    static propTypes = {
        onConfigurationChange : PropTypes.func,
        maxCount : PropTypes.number,
        showDiameter : PropTypes.bool,
        showAngle : PropTypes.bool,
        enableDeletion : PropTypes.bool,
        items : PropTypes.array,
        fixAmount : PropTypes.number,
        editableDiameter: PropTypes.bool,
        nozzleTypes: PropTypes.object // nozzleTypes can be passed directly (used for TCH nozzles)
    }

    static defaultProps = {
        editableDiameter : true
    }

    constructor(props) {
        super(props);
        this.state = {
            items : props.items ? props.items : [],
            nozzleTypeSelectionModal: null,
            nozzleTypeSelectionModalItem : null,
            customInputValue: 0.75, //value of input field for custom nozzle type
            renderObj: Math.random(),
        };
    }

    emitConfigurationChange = () => {
        if(this.props.onConfigurationChange) {
            let nozzles = [];
            _.each(this.state.items, (nozzle) => {
                nozzles.push({
                    "id" : nozzle.id,
                    "amount" : this.props.fixAmount ? this.props.fixAmount : nozzle.amount,
                    "diameter" : nozzle.diameter,
                    "efficiency" : nozzle.efficiency,
                    "type" : nozzle.type,
                    "maxPressure" : nozzle.maxPressure,
                    "angle" : nozzle.angle
                });
            })
            this.props.onConfigurationChange(nozzles);
        }
    }


    componentDidMount() {
        setTimeout(() => {
            this.selectDefaultValues(this.props);
        })
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        setTimeout(() => {
            this.selectDefaultValues(this.props)
        })

        if(!_.isEqual(this.props.items, prevProps.items)) {
            console.log("setting nozzle configuration state", this.props.items)
            this.setState({
                items: this.props.items,
                renderObj: Math.random()
            })
        }

        if(prevState!==this.state) {
            setTimeout(() => {
                this.emitConfigurationChange()
            })
        }
    }

    getDefaultParams(props) {
        return {
            "id": this.state.items.length == 0 ? 0 : _.max(_.map(this.state.items, 'id')) + 1,
            "type": props ? props.nozzleTypes[0] : this.props.nozzleTypes[0],
            "amount": props && typeof props.fixAmount != "undefined" ? props.fixAmount : 1,
            "diameter": "2.0mm",
            "efficiency": props ? props.nozzleTypes[0].dataunits["μ"].value : this.props.nozzleTypes[0].dataunits["μ"].value,
            "maxPressure": props ? props.nozzleTypes[0].dataunits["pNZmax"].value_with_unit : this.props.nozzleTypes[0].dataunits["pNZmax"].value_with_unit,
            "angle": "0 deg"
        }
    }

    selectDefaultValues = (props) => {
        if(this.state.items.length==0 && props.nozzleTypes && props.nozzleTypes.length > 0) {
            this.addItem(this.getDefaultParams(props))
        }
    }

    addItem = (params) => {
        const ids = _.map(this.state.items,(x) => x.id);;
        const id = (_.max(ids) || 0) + 1;
        const newItem = {
            id,
            ...params
        }
        this.setState({
            items : [
                ...this.state.items,
                newItem
            ]
        })
    }

    renderAmount = (item) => {
        return <DataUnitTextField align='center-auto' renderObj={this.state.renderObj} value={this.props.fixAmount ? this.props.fixAmount: item.amount} decimalPlaces={0} editable={this.props.fixAmount==null} onChange={(value) => this.handleAmountChange(item,value)} />
    }

    renderType = (item) => {
        let value = null;
        if(typeof item.type == 'object' && !_.isNumber(item.type)) {
            return <div className='config-modal' onClick={()=>this.handleNozzleTypeModalDialogRequest(item)}>{item.type.name}</div>
        } else {
            return <div className='config-modal' onClick={()=>this.handleNozzleTypeModalDialogRequest(item)}>{parseFloat(item.type)}</div>
        }
    }

    renderDiameter = (item) => {
        return <div onClick={()=>this.handleNozzleDiameterModalDialogRequest(item)}>
            <DataUnitTextField align='center-auto' renderObj={this.state.renderObj} value={item.diameter} si="mm" us="in" decimalPlaces={3} editable={false} />
        </div>;
    }

    renderAngle = (item) => {
        //return <DataUnitTextField renderObj={this.state.renderObj} value={item.angle} si="deg" siLabel="°" decimalPlaces={3} editable={false} onChange={(value) => this.handleDiameterChange(item,value)} />
        const heading = <FormattedMessage id="nozzle_configuration_table_angle_heading" defaultMessage="Nozzle direction" />;

        return (
            <AngleSelector heading={heading} onChange={({value}) => this.handleAngleChange(item,value)} value={item.angle}>
                <div className="nozzle-configuration-table-angle-title">
                    <FormattedMessage id="nozzle_configuration_table_angle_title" defaultMessage="Determine the nozzle angle" />
                </div>
                <div className="nozzle-configuration-table-angle-text">
                    <FormattedMessage id="nozzle_configuration_table_angle_text" defaultMessage="Warning: Use only rigid lances. Not permitted at high pressure hoses or flexible lances." />
                </div>
            </AngleSelector>
        );
    }

    getImageUrl = (item) => {
        return item.type && item.type.image ? item.type.image : null;
    }

    onImageClick = (item) => {
      this.handleNozzleTypeModalDialogRequest(item);
    }

    handleNozzleTypeModalDialogRequest = (item) => {
        this.setState({
            nozzleTypeSelectionModal : MODAL_STEP_TYPE_SELECTION,
            nozzleTypeSelectionModalItem : item
        })
    }

    handleSelectionModalCancel = () => {
        this.setState({
            nozzleTypeSelectionModal : null,
            nozzleTypeSelectionModalItem: null
        })
    }

    handleNozzleTypeSelection = (nozzleType) => {
        this.state.nozzleTypeSelectionModalItem.type = nozzleType;
        this.state.nozzleTypeSelectionModalItem.efficiency = new Number(nozzleType.dataunits["μ"]["value"])
        this.state.nozzleTypeSelectionModalItem.maxPressure = nozzleType.dataunits["pNZmax"]["value_with_unit"]
        this.setState({
            items: [
                ...this.state.items
            ],
            nozzleTypeSelectionModal: null,
            nozzleTypeSelectionModalItem: null
        })
    }

    handleNozzleTypeCustomSelection = () => {
        this.setState({
            nozzleTypeSelectionModal : MODAL_STEP_CUSTOM_TYPE,
            customInputValue : this.state.nozzleTypeSelectionModalItem.efficiency
        })
    }

    handleNozzleDiameterCustomSelection = () => {
        this.setState({
            nozzleTypeSelectionModal : MODAL_STEP_CUSTOM_DIAMETER,
            customInputValue : this.state.nozzleTypeSelectionModalItem.diameter
        })
    }

    handleNozzleTypeCustomSelectionOK = () => {
        this.state.nozzleTypeSelectionModalItem.type = new Number(this.state.customInputValue);
        this.state.nozzleTypeSelectionModalItem.efficiency = new Number(this.state.customInputValue);
        this.state.nozzleTypeSelectionModalItem.maxPressure = "1000000 bar";
        this.setState({
            items: [
                ...this.state.items
            ],
            nozzleTypeSelectionModal: null,
            nozzleTypeSelectionModalItem: null
        })
    }

    handleNozzleDiameterCustomSelectionOK = () => {
        this.state.nozzleTypeSelectionModalItem.diameter = this.state.customInputValue;

        this.setState({
            items: [
                ...this.state.items
            ],
            nozzleTypeSelectionModal: null,
            nozzleTypeSelectionModalItem: null
        })
    }


    handleCustomTypeInputChange = (v) => {
        this.setState({
            customInputValue : v
        });
    }

    handleNozzleDiameterModalDialogRequest = (item) => {

      let hasValues = false;
      if(item.type) {
          try {
              const separatedString = item.type.dataunits["nd"].value;
              hasValues = separatedString != null && separatedString != "";
          } catch(e) {

          }
      }

      if(hasValues){
        this.setState({
            nozzleTypeSelectionModal : MODAL_STEP_DIAMETER,
            nozzleTypeSelectionModalItem : item
        })
      }else{
        this.setState({
            nozzleTypeSelectionModal : MODAL_STEP_CUSTOM_DIAMETER,
            nozzleTypeSelectionModalItem : item,
            customInputValue : item.diameter
        })
      }
    }

    handleAddItemClick = () => {
        let params = this.getDefaultParams(this.props);
        if(this.state.items.length>0) {
            params = Object.assign(params, _.omit(_.clone(this.state.items[this.state.items.length-1]), "id"));
        }
        this.addItem(params)
    }

    handleDeleteItemClick = (item) => {
        this.setState({
            items: _.filter(this.state.items,(i)=>i.id!=item.id)
        })
    }

    handleDiameterChange = (item, value) => {
        item.diameter = value;
        this.setState({
            items: [
                ...this.state.items,
            ],
            nozzleTypeSelectionModal: null,
            nozzleTypeSelectionModalItem: null
        })
    }

    handleAmountChange = (item, value) => {
        item.amount = value;
        this.setState({
            items: [
                ...this.state.items
            ],
        })
    }

    handleAngleChange = (item, value) => {
        item.angle = value;
        this.setState({
            items: [
                ...this.state.items
            ],
        })
    }

    render() {
        const { items, nozzleTypeSelectionModal, customInputValue, nozzleTypeSelectionModalItem } = this.state;
        const { nozzleTypes } = this.props;
        const columns = [
            {
                "id" : "type",
                "heading" : <FormattedMessage id="nozzle_configuration_table_type" defaultMessage="Nozzle type" />,
                "renderField" : this.renderType
            },
            {
                "id" : "amount",
                "heading" : <FormattedMessage id="nozzle_configuration_table_amount" defaultMessage="Amount" />,
                "renderField" : this.renderAmount
            },
        ];
        if(this.props.showDiameter) {
            columns.push({
                "id": "diameter",
                "heading": <FormattedMessage id="nozzle_configuration_table_diameter" defaultMessage="Nozzle-Ø"/>,
                "renderField": this.renderDiameter
            },)
        }
        if(this.props.showAngle) {
            columns.push({
                "id": "angle",
                "heading": <FormattedMessage id="nozzle_configuration_table_angle" defaultMessage="Angle"/>,
                "renderField": this.renderAngle
            },)
        }
        //console.log("nozzleTypes",nozzleTypes)
        return (
            <div>
                <ConfigurationTable
                    columns = {columns}
                    items = {items}
                    allItems = {nozzleTypes}
                    addLabel = {<FormattedMessage id="nozzle_configuration_table_add" defaultMessage="Add another nozzle" />}
                    onAddItemClick = {this.handleAddItemClick}
                    onDeleteItemClick = {this.handleDeleteItemClick}
                    maxCount = {this.props.maxCount}
                    enableDeletion = {this.props.enableDeletion}
                    getImageUrl = {this.getImageUrl}
                    getEmptyImageValue = {this.renderType}
                    onImageClick = {this.onImageClick}
                />
                <Modal isOpen={ nozzleTypeSelectionModal!=null } className={`ci-modal ci-modal-nopadding nozzle-configuration-modal-scroller ${iosStatusBar?'nozzle-configuration-modal-ios-status-bar':''}`} overlayClassName="ci-modal-overlay" onRequestClose={this.handleSelectionModalCancel}>
                    <Choose>
                        <When condition={ nozzleTypeSelectionModal==MODAL_STEP_TYPE_SELECTION }>
                            <div className="nozzle-configuration-table-modal-item-container" ref={ref=>{
                                if(ref){
                                  let domNode = ReactDOM.findDOMNode(ref);
                                  let scrollDOMContainer = domNode.parentNode;
                                  let selectedIndex = _.findIndex(nozzleTypes, item => {
                                    return nozzleTypeSelectionModalItem.type && item.id==nozzleTypeSelectionModalItem.type.id;
                                  });
                                  if(selectedIndex > 0){
                                    let domNodeHeight = domNode.childNodes[0].clientHeight;
                                    scrollDOMContainer.scrollTop = Math.max(0,(selectedIndex * (domNodeHeight+2)) + (domNodeHeight+2)/2 - scrollDOMContainer.clientHeight/2)
                                  }
                                }
                              }}>
                                <For each="item" of={nozzleTypes} index="index">
                                    <div key="item{index}" className={`nozzle-configuration-table-modal-item ${nozzleTypeSelectionModalItem && nozzleTypeSelectionModalItem.type && item.id==nozzleTypeSelectionModalItem.type.id?'selected':''}`} onClick={() => this.handleNozzleTypeSelection(item)}>
                                        <span className="nozzle-configuration-table-modal-item-image">
                                            <AsyncImage src={item.image} />
                                        </span>
                                        <span className="nozzle-configuration-table-modal-item-name">
                                            {item.dataunits["jet_type"] && item.dataunits["jet_type"].value=='flat'?'▲':'●'}{'\t'}{item.name} ({item.dataunits["μ"].value}) Max: {Math.round(item.dataunits["pNZmax"].value)} bar
                                        </span>
                                    </div>
                                    <div className='nozzle-configuration-table-row-spacer' />
                                </For>
                                <div className="nozzle-configuration-table-modal-item-custom" onClick={() => this.handleNozzleTypeCustomSelection()}>
                                    <FormattedMessage id="nozzle_configuration_table_modal_custom" defaultMessage="Set-up your own nozzle factor" />
                                </div>
                            </div>
                        </When>
                        <When condition={ nozzleTypeSelectionModal==MODAL_STEP_CUSTOM_TYPE}>
                            <div className="nozzle-configuration-table-modal-custom-type-container">

                                <DataUnitTextField align="center-auto" value={customInputValue} onChange={this.handleCustomTypeInputChange} decimalPlaces={2} minValue={0} maxValue={1} editable={true} className="nozzle-configuration-table-modal-custom-type-input" />

                                <ButtonBar>
                                    <Button backgroundColor={style.secondary2} textColor={style.white} onClick={this.handleSelectionModalCancel}><FormattedMessage id="button_cancel" defaultMessage="Cancel" /></Button>
                                    <Button onClick={this.handleNozzleTypeCustomSelectionOK}><FormattedMessage id="button_ok" defaultMessage="OK" /></Button>
                                </ButtonBar>
                            </div>
                        </When>
                        <When condition={ nozzleTypeSelectionModal==MODAL_STEP_DIAMETER}>
                            <div className="nozzle-configuration-table-modal-diameter-container" ref={ref=>{
                                if(ref){
                                  let domNode = ReactDOM.findDOMNode(ref);
                                  let scrollDOMContainer = domNode.parentNode;
                                  let selectedIndex = _.findIndex(getNozzleDiametersByNozzleType(nozzleTypeSelectionModalItem.type), item => {
                                    return nozzleTypeSelectionModalItem.diameter && item==nozzleTypeSelectionModalItem.diameter;
                                  });
                                  if(selectedIndex > 0){
                                    let domNodeHeight = domNode.childNodes[0].clientHeight;
                                    scrollDOMContainer.scrollTop = Math.max(0,(selectedIndex * (domNodeHeight+2)) + (domNodeHeight+2)/2 - scrollDOMContainer.clientHeight/2)
                                  }
                                }
                              }}>
                                <For each="item" of={getNozzleDiametersByNozzleType(nozzleTypeSelectionModalItem.type)} index="index">
                                    <div key="item{index}" className={`nozzle-configuration-table-modal-item ${nozzleTypeSelectionModalItem && nozzleTypeSelectionModalItem.diameter && item==nozzleTypeSelectionModalItem.diameter?'selected':''}`} onClick={() => this.handleDiameterChange(nozzleTypeSelectionModalItem, item)}>
                                        <span className="nozzle-configuration-table-modal-item-name">
                                            <DataUnitTextField align="center-auto" value={item} si="mm" us="in" decimalPlaces={3} editable={false} className="nozzle-configuration-table-modal-diameter-value" />
                                        </span>
                                    </div>
                                    <div className='nozzle-configuration-table-row-spacer' />
                                </For>
                                <div className="nozzle-configuration-table-modal-item-custom" onClick={() => this.handleNozzleDiameterCustomSelection()}>
                                    <FormattedMessage id="nozzle_configuration_table_modal_custom_diameter" defaultMessage="Set-up your own nozzle diameter" />
                                </div>
                            </div>
                        </When>
                        <When condition={ nozzleTypeSelectionModal==MODAL_STEP_CUSTOM_DIAMETER}>
                            <div className="nozzle-configuration-table-modal-custom-diameter-container">

                                <DataUnitTextField align="center-auto" value={customInputValue} onChange={this.handleCustomTypeInputChange} si="mm" us="in" decimalPlaces={3} editable={true} className="nozzle-configuration-table-modal-custom-diameter-input" />

                                <ButtonBar>
                                    <Button backgroundColor={style.secondary2} textColor={style.white} onClick={this.handleSelectionModalCancel}><FormattedMessage id="button_cancel" defaultMessage="Cancel" /></Button>
                                    <Button onClick={this.handleNozzleDiameterCustomSelectionOK}><FormattedMessage id="button_ok" defaultMessage="OK" /></Button>
                                </ButtonBar>
                            </div>
                        </When>
                    </Choose>
                </Modal>
            </div>
        )
    }
}

// <input step="0.01" type="number" value={customInputValue} className="nozzle-configuration-table-modal-custom-type-input" onChange={this.handleCustomTypeInputChange} />

function mapStateToProps(state, props) {
    let nozzleTypes;
    if(props.nozzleTypes) {
        //nozzleTypes can be passed directly (used for TCH nozzles)
        nozzleTypes=props.nozzleTypes;
    } else {
        nozzleTypes=getNozzles(state)
    }

    return {
        nozzleTypes: nozzleTypes,
        dataunits: getDataunitsBySymbol(state)
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(NozzleConfigurationTable))
