import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import './footer.scss';
import {FormattedMessage, injectIntl} from 'react-intl';

class Footer extends Component {
  render() {

    return (
      <div className={`footer`}>
        {/*
        <div className="copyright">
          &copy; Hammelmann {new Date().getFullYear()}
        </div>
        */}
        <div className="footer-navigation">
          <Link to={`/news`}><FormattedMessage id="sidemenu.news" defaultMessage="News" /></Link>
        </div>
        {` | `}
        <div className="footer-navigation">
          <Link to={`/contact`}><FormattedMessage id="sidemenu.contact" defaultMessage="Contact" /></Link>
        </div>
        {` | `}
        <div className="footer-navigation">
          <Link to={`/disclaimer`}><FormattedMessage id="sidemenu.disclaimer" defaultMessage="Disclaimer" /></Link>
        </div>
        {` | `}
        <div className="footer-navigation">
          <Link to={`/impress`}><FormattedMessage id="sidemenu.imprint" defaultMessage="Imprint" /></Link>
        </div>
      </div>
    );
  }
}

export default Footer;
