import React, { Component, PropTypes } from 'react';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import * as UIActions from 'storeState/ui/actions';
import {getAgbAccepted} from 'storeState/ui/selectors';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {Link} from 'react-router-dom';

import './agbAccept.scss';

const messages = defineMessages({
  agb_popup_link: {
    id: 'agb_popup_link',
    defaultMessage: 'terms and conditions and our datapolicy'
  }
});

class AgbAccept extends Component {

    constructor(props){
        super(props);
    }

    setAgbAccepted = () => {
      this.props.uiActions.setAgbAccepted(true);
    }

    render() {

        const {agbAccepted} = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <If condition={!agbAccepted}>
              <div className={`agb-container`} onClick={this.setAgbAccepted}>
                <div className="agb-popup-container">
                  <div className="agb-popup">
                    <div className="agb-popup-title">
                      <FormattedMessage id="agb_popup_title" defaultMessage="Information"/>
                    </div>
                    <div className="agb-popup-text">
                      <FormattedMessage
                        id="agb_popup_text"
                        defaultMessage="By using this App you accept our {link}"
                        values={{
                          link: (<div className="agb-popup-link">
                                  <Link to={'/disclaimer'}>
                                    {formatMessage(messages['agb_popup_link'])}
                                  </Link>
                                </div>)
                        }}
                      />
                    </div>
                    <div className="agb-popup-button">
                      <FormattedMessage id="agb_popup_btn_accept" defaultMessage="OK"/>
                    </div>
                  </div>
                </div>
              </div>
            </If>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        agbAccepted: getAgbAccepted(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(UIActions, dispatch),
    }
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(AgbAccept))
