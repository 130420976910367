import React, { Component } from 'react';
import DataUnitTextField from 'layout/dataUnitTextField/DataUnitTextField';
import {MdClose as CloseIcon} from "react-icons/md";

export default (props) => {
    const {unit} = props;

    return (
        <div className="properties-unit-item-header" onClick={props.onClick}>
            <div className="properties-unit-item-header-name">{unit.name}</div>
            <div className="properties-unit-item-header-pressure">
                <DataUnitTextField value={unit.maxPressure} si="bar" siLabel="bar" us="psi" usLabel="psi" />
            </div>
            <div className="properties-unit-item-header-flowrate">
                <DataUnitTextField value={unit.maxFlowrate} si="l/mins" siLabel="l/min" us="gal/mins" usLabel="gal/min" />
            </div>
            <div className='properties-unit-item-header-delete-container'>
              <CloseIcon className="properties-unit-item-header-delete" onClick={(e) => { e.stopPropagation(); if(props.onDeleteClick) props.onDeleteClick(unit); }} />
            </div>
        </div>
    );
}
