import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {isIosStatusBar, isCordova, isMobile, isIos, isAndroid} from 'service/LayoutService';
import {injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import {getEnabled} from 'storeState/splash/selectors';
import Header from 'layout/header/Header';
import Footer from 'layout/footer/Footer';
import Sidemenu from 'layout/sidemenu/Sidemenu';
import Splash from 'layout/splash/Splash';
import AgbAccept from 'layout/popup/AgbAccept';
import InitialSettings from 'layout/popup/InitialSettings';
import { connect } from "react-redux";
import logo from 'assets/images/logo.jpg';
import style from 'style';
import './layout.scss';
import SmartBanner from 'react-smartbanner';
import '../../../node_modules/react-smartbanner/dist/main.css';

import AppStoreIcon from './appstore.png';
import {getInfoMessage} from "storeState/ui/selectors";
import {bindActionCreators} from "redux";
import * as UIActions from "storeState/ui/actions";
const cordova = isCordova();
const mobile = isMobile();
const iosDevice = isIos();
const androidDevice = isAndroid();
const iosStatusbar = isIosStatusBar();

const messages = defineMessages({
    splash_title : {
        id: 'splash_title',
        defaultMessage: 'Water Jetting Calculator'
    }
});

class Layout extends Component {

  static propTypes = {
      caption: PropTypes.string
  };

  constructor(props) {
    super(props);

    let smartBannerDisabled = false;
    let wjc_smart_app_banner = localStorage.getItem('wjc_smart_app_banner');
    if(wjc_smart_app_banner){
      let currentTimestamp = new Date();
      if(parseInt(wjc_smart_app_banner) < currentTimestamp - (24*60*60*60)){
        smartBannerDisabled = false;
      }else{
        smartBannerDisabled = true;
      }
    }

    this.state = {
      smartBannerDisabled: smartBannerDisabled,
    }
  }

  componentWillUnmount() {
    this.props.uiActions.showInfoMessage({message: false})
  }

  disableBanner(){
    let currentTimestamp = new Date();
    localStorage.setItem('wjc_smart_app_banner', `${currentTimestamp}`);
    this.setState({
      smartBannerDisabled: true,
    })
  }

  render() {

    const { caption } = this.props;
    const { smartBannerDisabled } = this.state;
    const { formatMessage } = this.props.intl;
    // console.log("this.props.infoMessage",this.props.infoMessage)
    return (
      <If condition={this.props.enabled}>
        <div className="splash-bg">
          <Splash logo={logo} hideSplashTimeout={10000} title={formatMessage(messages.splash_title)} backgroundColor={style.primary1} textColor={style.black} indicatorColor="black" />
        </div>
      <Else />
        <div className={`page-container ${cordova?'cordova':''} ${iosStatusbar?'ios-statusbar':''}`}>
          {/*<SmartBanner title={'Water Jetting Calculator'} />*/}
          <If condition={!smartBannerDisabled && !cordova && (iosDevice || androidDevice)}>
            <div className="smartbanner smartbanner-ios" style={{display: 'block'}}>
              <div className="smartbanner-container">
                <a className="smartbanner-close" onClick={()=>{this.disableBanner()}}>×</a>
                <span className="smartbanner-icon" style={{backgroundImage: `url(${AppStoreIcon})`}}></span>
                <div className="smartbanner-info">
                  <div className="smartbanner-title">Water Jetting Calculator</div>
                  <div className="smartbanner-author"></div>
                  <div className="smartbanner-description">Install the App</div>
                </div>
                <div className="smartbanner-wrapper">
                  <a href={iosDevice?'https://itunes.apple.com/de/app/water-jetting-calculator/id1181574352':'https://play.google.com/store/apps/details?id=de.hammelmann.waterjettingcalculator'} className="smartbanner-button">
                    <span className="smartbanner-button-text">App Store</span>
                  </a>
                </div>
              </div>
            </div>
          <Else />

          </If>
          <AgbAccept />
          <InitialSettings />
          <div className='sidemenu-container'>
            <Sidemenu
              iosStatusbar={iosStatusbar}
            />
          </div>
          <Header
            iosStatusbar={iosStatusbar}
            caption={caption}
          />
          <div className={`content`}>
            {this.props.children}
          </div>
          <div className='page-footer'>
            <Footer/>
          </div>
          <If condition={this.props.infoMessage && this.props.infoMessage.message}>
            <div key={`infomessage_${this.props.infoMessage.key}_${this.props.infoMessage.message}`} className={'layout-info-message'}>{this.props.infoMessage.message}</div>
          </If>
      </div>
    </If>
    );
  }
}

function mapStateToProps(state, props) {
    return {
      enabled: getEnabled(state),
      infoMessage: getInfoMessage(state)
    }
}

function mapDispatchToProps(dispatch) {
    return {
      uiActions: bindActionCreators(UIActions, dispatch),
    }
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(Layout))
