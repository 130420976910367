import React, {Component} from 'react';
import PropTypes from 'prop-types';

import ReactDOM from 'react-dom';
import { FormattedMessage } from 'react-intl';
import './calculationresult.scss';
import {PiWarningCircle as WarningIcon} from "react-icons/pi";
import SectionHeader from 'layout/sectionHeader/SectionHeader';

export default class CalculationResult extends Component {

    static propTypes = {
        values : PropTypes.array,
        warnings : PropTypes.array,
        customContentSlot1: PropTypes.any,
        customContentSlot2: PropTypes.any
    }

    static defaultProps = {
      values: [],
      warnings: [],
      customContentSlot1: null,
      customContentSlot2: null
    }

    constructor(props) {
        super(props);

        this.state = {
            activeInfoItem : null
        }
    }

    /*
        values : [{
            type: "warning", //valid, warning, error
            label: "Flow rate",
            value: 123123
        }],
        warnings:  [{
            type: "warning", //warning, error
            label: "Reaction force too high",
        }],
     */

    toggleInfoItem = (item) =>  {
        if(this.state.activeInfoItem == item) {
            this.setState({
                activeInfoItem : null
            })
        } else {
            this.renderScroll = true;
            this.setState({
                activeInfoItem : item
            })
        }
    }

    render() {
        if(this.props.values.length == 0 && this.props.warnings==0) return null;

        let triangle_type;

        if(this.props.values.length>0) {
            triangle_type = this.props.values[0]["type"];
        } else {
            triangle_type = this.props.warnings[0]["type"];
        }

        return (
            <div className="calculation-result">
                <div className="calculation-result-triangle-container">
                    <div className={`calculation-result-triangle calculation-result-triangle-${triangle_type}`}></div>
                </div>
                <div className="calculation-result-header-container">
                  <SectionHeader>
                    <FormattedMessage id="calculation_results_header" defaultMessage="Results"/>
                  </SectionHeader>
                </div>
                <div className="calculation-result-container">
                  {this.props.customContentSlot1}
                  <div className="calculation-result-values">
                      {this.props.values.map((item,index)=>{
                          return <div key={index} onClick={item.onClick} className={`calculation-result-values-item calculation-result-values-item-${item.type}`}>
                              <div className={`calculation-result-values-item-value`}>
                                  {item.value}
                              </div>
                              <div className={`calculation-result-values-item-label`}>
                                  {item.label}
                              </div>
                          </div>
                      })}
                  </div>
                  {this.props.customContentSlot2}
                  <If condition={this.props.warnings.length>0}>
                      <div className="calculation-result-warnings-count">
                          <WarningIcon className="calculation-result-warnings-count-icon" />
                          <FormattedMessage
                              id="calculation_result_warning_count"
                              defaultMessage={`{warningCount, number} {warningCount, plural,
                                  one {warning}
                                  other {warnings}
                              }`}
                              values={{warningCount: this.props.warnings.length}}
                          />
                      </div>
                      <div className="calculation-result-warnings">
                          {this.props.warnings.map((item,index)=>{
                             return <div key={index} className={`calculation-result-warnings-item calculation-result-warnings-item-${item.type}`} onClick={() => this.toggleInfoItem(item)}>
                                  <div className='calculation-result-warnings-item-title'>
                                    <div className='calculation-result-warnings-item-title-left'>
                                      <WarningIcon className="calculation-result-warnings-item-icon" />
                                      <div className="calculation-result-warnings-item-label">
                                          {item.label}
                                      </div>
                                    </div>
                                    <div className={`calculation-result-warnings-item-moreinfo ${this.state.activeInfoItem!=item && item.info?'active':''}`}>
                                        <If condition={this.state.activeInfoItem!=item}>
                                            <FormattedMessage
                                                id="calculation_result_warning_more_info"
                                                defaultMessage={`More Info`}
                                            />
                                        <Else />
                                            <FormattedMessage
                                                id="calculation_result_warning_less_info"
                                                defaultMessage={`Less Info`}
                                            />
                                        </If>
                                    </div>
                                  </div>
                                  <div className={`calculation-result-warnings-item-infotext ${this.state.activeInfoItem==item?'active':''}`}
                                    ref={ref=>{
                                      if(ref && this.state.activeInfoItem==item && this.renderScroll){
                                        this.renderScroll = false;
                                        let elemNode = ReactDOM.findDOMNode(ref);
                                        var elemBounds = elemNode.getBoundingClientRect();
                                        var screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
                                        if(elemBounds.top + elemBounds.height > screenHeight){
                                          let containerElem = document.getElementsByClassName('page-container')[0];
                                          containerElem.scrollTop = containerElem.scrollTop + (elemBounds.top + elemBounds.height - screenHeight);
                                        }
                                      }
                                    }}
                                  >
                                      {item.info}
                                  </div>
                              </div>
                          })}
                      </div>
                  </If>
                </div>
            </div>
        )
    }
}
