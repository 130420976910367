import React, { Component } from 'react';
import Layout from 'layout/Layout';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UIActions from 'storeState/ui/actions';
import { menuItems } from 'storeState/ui/constants';
import { getFormulas, getDataunitsBySymbol, getUnits} from 'storeState/data/selectors';
import { getNozzleCalculationParams } from 'storeState/ui/selectors';
import { TabNavigation, Tab } from 'layout/tabNavigation/TabNavigation';
import SectionHeader from 'layout/sectionHeader/SectionHeader';
import UnitSelector from 'layout/unitSelector/UnitSelector';
import CalculationResult from 'layout/calculationResult/CalculationResult';
import DataUnitTextField from 'layout/dataUnitTextField/DataUnitTextField';
import NozzleConfigurationTable from 'layout/configurationTable/NozzleConfigurationTable';
import {evaluateMathProgram, formatValueWithUnit, getRawValue} from 'service/MathService';
import {getRPMLable} from 'service/LableService';
import _ from 'lodash';
import './masterjetconfiguration.scss';
import {OptionSelect, OptionSelectItem} from "layout/optionSelect/OptionSelect";
import {getMasterjetConfigurationParams} from "storeState/ui/selectors";
import NozzleDiameterSlider from "components/layout/nozzleDiameterSlider/NozzleDiameterSlider";
import NozzleImage from "components/layout/nozzleImage/NozzleImage";
import {getMasterjetProducts} from "storeState/data/selectors";
import NozzleConfigurationTableMasterjet from 'components/layout/configurationTable/NozzleConfigurationTableMasterjet';
import CalculationResultCustom from "components/layout/calculationResultCustom/CalculationResultCustom";
import ProductWizardMasterjet from "components/layout/productWizard/ProductWizardMasterjet";
import {formatValueWithUnitAndLabel} from "service/MathService";
import {getNozzleArrangement} from "service/MasterJetService";
import {formatValueWithUnitAndLabelAsString} from "service/MathService";
import AsyncImage from "components/layout/asyncImage/AsyncImage";
import masterjet_image from "assets/images/masterjet.png";
import MediaQuery from 'react-responsive'

const messages = defineMessages({
    masterjet_configuration_caption: {
        id: 'masterjet_configuration_caption',
        defaultMessage: 'Masterjet Configuration'
    },
    masterjet_configuration_diameter_none: {
      id: 'masterjet_configuration_diameter_none',
      defaultMessage: '- keine -'
    }
});

export const MASTERJET_CONFIGURATION_PARAMETERS = "masterjetconfiguration";

class MasterjetConfiguration extends Component {

  constructor(props) {
      super(props);
      this.lastParams = null;
      this.state = {
          result : {
              warnings : [],
              values : []
          }
      }
  }

  componentDidMount() {
      setTimeout(() => {
        this.props.uiActions.setActiveMenuItem({item: menuItems.masterjetconfiguration})
      })
      this.doRecalc();
  }

  componentDidUpdate(prevProps, prevState) {

    if(this.props.calculationParams?.unit) {
      const propUnit = _.find(this.props.units, un => un.id === this.props.calculationParams.unit.id);
      if(propUnit) {
        const comparePropParams = {
          maxFlowrate: propUnit.maxFlowrate,
          maxPressure: propUnit.maxPressure,
          rpmMax: propUnit.rpmMax,
          rpmMin: propUnit.rpmMin,
        }
        const compareCalcParams = {
          maxFlowrate: this.props.calculationParams.unit.maxFlowrate,
          maxPressure: this.props.calculationParams.unit.maxPressure,
          rpmMax: this.props.calculationParams.unit.rpmMax,
          rpmMin: this.props.calculationParams.unit.rpmMin,
        }
        if(!_.isEqual(comparePropParams, compareCalcParams)) {
          this.handleUnitSelect(propUnit);
        }
      }
    }

    if(this.props.calculationParams.nozzleCount && !_.isEqual(this.props.calculationParams.nozzleCount,  prevProps.calculationParams.nozzleCount)) {
      if(this.props.calculationParams.nozzleVariant && (this.props.calculationParams.nozzleCount && this.props.calculationParams.nozzleCount % 2 != 0)) {
        this.props.uiActions.setCalculationParameters({
          key : MASTERJET_CONFIGURATION_PARAMETERS,
          params : {
            nozzleVariant: 'equal'
          }
        });
      }
    }

    if(this.props.activeNozzleType && !_.isEqual(this.props.activeNozzleType,  prevProps.activeNozzleType)) {

      const nd_μ_map = this.props.activeNozzleType.dataunits.nd_μ_map;
      const values = _.keys(nd_μ_map.value);
      let center = Math.floor(_.size(nd_μ_map.value)/2) - 1;
      let center2 = _.size(nd_μ_map.value) % 2 != 0 ? center : center + 1;

      this.props.uiActions.setCalculationParameters({
        key: MASTERJET_CONFIGURATION_PARAMETERS,
        params: {
          nozzleDiameter: {
            [0]: values[center],
            [1]: values[center2],
          },
          nozzleDiameterIndex: {
            [0]: center,
            [1]: center2
          }
        }
      });

    }

    this.doRecalc();
  }

  doRecalc = () => {
    if(this.recalcTimer) {
      clearTimeout(this.recalcTimer);
    }
    this.recalcTimer = setTimeout(() => {
        this.recalculate()
    },300)
  }

  getUnitParams() {
    let unitParams={};
    if(this.props.calculationParams && this.props.calculationParams.unit) {
      unitParams = this.props.calculationParams.unit;
    } else {
      if(this.props.units.length>0) {
        unitParams = {
          unit: this.props.units[0],
          ...this.props.units[0]
        }
      } else {
        unitParams = {}
      }
    }
    return unitParams;
  }

  recalculate = () => {

    const f = this.props.formulas.masterjet_v1;
    if(this.props.calculationParams && this.props.activeMasterJet && this.props.activeNozzleType) {

      let warnings = [];

      const unitParams = this.getUnitParams();

      if(!unitParams || !unitParams.rpmMin || !unitParams.rpmMax) {
        warnings.push({
          type: "info",
          label: <FormattedMessage id="nozzle_calculation_wshort_NOTSET_UHD" defaultMessage="R.P.M. not set"/>,
          info: <FormattedMessage id="nozzle_calculation_wlong_NOTSET_UHD"
                                  defaultMessage="If you have an electrically driven or diesel driven plunger high pressure water unit, please create a present under “Properties” to be warned if your R.P.M. are possible, by setting the max. and min. limits of your engine."/>
        })
      }
      if(!unitParams || !unitParams.maxFlowrate) {
        warnings.push({
          type: "info",
          label: <FormattedMessage id="nozzle_calculation_wshort_NOTSET_QMAX"
                                   defaultMessage="No max. flow rate set"/>,
          info: <FormattedMessage id="nozzle_calculation_wlong_NOTSET_QMAX"
                                  defaultMessage="If you have an electrically driven or diesel driven plunger high pressure water unit, please create a present under “Properties” to be warned if your flow rate is possible, by setting the max. limits of your unit."/>
        })
      }
      if(!unitParams || !unitParams.maxPressure) {
        warnings.push({
          type: "info",
          label: <FormattedMessage id="mnozzle_calculation_wshort_NOTSET_PBMAX" defaultMessage="No max. op. pressure set" />,
          info: <FormattedMessage id="nozzle_calculation_wlong_NOTSET_PBMAX" defaultMessage="If you have an electrically driven or diesel driven plunger high pressure water unit, please create a present under “Properties” to be warned if your operating pressure is possible, by setting the max. limits of your unit." />
        })
      }

      const masterJetDataunits = this.props.activeMasterJet.dataunits;
      const nozzleTypeDataunits = this.props.activeNozzleType.dataunits;
      const alpha_avg = masterJetDataunits.numnoz_to_alpha_avg.value[this.props.calculationParams.nozzleCount+'.0'] + " deg"

      let nozTypeACount = this.props.calculationParams.nozzleCount;
      let nozTypeBCount = 0;
      if(this.props.calculationParams.nozzleVariant === "unequal") {
        nozTypeACount = this.props.calculationParams.nozzleCount / 2;
        nozTypeBCount = nozTypeACount;
      }

      const getNozzles = (type, count) => {
        let nozzles = [];
        if(this.props.calculationParams.nozzleDiameter) {
          let nozDiam = this.props.calculationParams.nozzleDiameter[type];
          let nozF = this.props.activeNozzleType.dataunits.nd_μ_map.value[nozDiam]
          if(nozF === 0) {
            nozF = this.props.activeNozzleType.dataunits.μ.value;
          }
          for(let i=0; i<count; i++) {
            nozzles.push({
              d: nozDiam  + " " + this.props.activeNozzleType.dataunits.nd_μ_map.key_si_unit,
              f: nozF
            })
          }
        }
        return nozzles;
      }

      let nozzles = [...getNozzles(0, nozTypeACount), ...getNozzles(1, nozTypeBCount)];

      const params = {

        p_max: masterJetDataunits.p_max.value_with_unit, // 1800 bar masterjet,
        p_min: masterJetDataunits.p_min.value_with_unit, // 100 bar masterjet,
        v_max: masterJetDataunits.QUmax.value_with_unit, // 60 (l/mins) masterjet
        FR_min: masterJetDataunits.FR_min.value_with_unit, // 90 N masterjet
        FR_max: masterJetDataunits.FR_max.value_with_unit, // 130 N masterjet
        pNZmax: nozzleTypeDataunits.pNZmax.value_with_unit, // 3000 bar nozzle type
        manual_use_allowed: masterJetDataunits.manual_use_allowed.value, // masterjet
        alpha_avg: alpha_avg, // 27deg mapping nozzle count am masterjet
        nozzles: nozzles,
        pB: unitParams.pressure ? unitParams.pressure : "0 bar", //bar
        pBmax : unitParams.maxPressure ? unitParams.maxPressure : null, //bar
        Qmax : unitParams.maxFlowrate ? unitParams.maxFlowrate : null, //400 l/mins;
        uHDmax : unitParams.rpmMax ? unitParams.rpmMax : null, //300 mins ^ -1 in mins ^ -1;
        uHDmin : unitParams.rpmMin ? unitParams.rpmMin : null //300 mins ^ -1 in mins ^ -1;
      }

      if(_.isEqual(params, this.lastParams)) {
        return;
      }
      const res = evaluateMathProgram(f, params);
      this.lastParams = params;

      /*
        "Maximaler Düsendruck überschritten/ Betriebsdruck reduzieren.";
        WARN_PB_HIGH= pB > pBmax;

        "Maximaler Düsendruck überschritten/ Betriebsdruck reduzieren.";
        WARN_PB_HIGH_NZ_MAX = pB > pNZmax;

        "Handbetrieb! Rückstoßkräfte beachten!";
        WARN_FR_HIGH = FR > 150 N;

        "Handbetrieb! Rückstoßkräfte beachten!";
        WARN_FR_VERY_HIGH = FR > 250 N;

        "Antriebsdrehmoment zu gering";
        WARN_FR_MIN = FR < FR_min;

        "Antriebsdrehmoment zu hoch";
        WARN_FR_MAX = FR > FR_max;

        "MasterJet ist nicht für den Handbetrieb vorgesehen";
        WARN_MANUAL_USE_NOT_ALLOWED = (manual_use_allowed != "1.0");

        "Min Umdrehungszahl vom Aggregat unterschritten";
        WARN_UHD_LOW = uHD<uHDmin;

        "Max Umdrehungszahl vom Aggregat überschritten";
        WARN_UHD_HIGH = uHD>uHDmax;

        "Volumenstrom größer als Max Volumenstrom vom Aggregat";
        WARN_QTOT = v>Qmax;

        "Maximaler MasterJet Druck überschritten";
        WARN_PB_HIGH_MJ= pB > p_max;

        "Minimaler MasterJet Druck unterschritten";
        WARN_PB_LOW_MJ= pB < p_min;

        "Volumenstrom größer als Max Volumenstrom vom MasterJet";
        WARN_QTOT = v>v_max;
      */

      if(res["WARN_UHD_LOW"]) {
        warnings.push({
          type: "error",
          label: <FormattedMessage id="nozzle_calculation_wshort_UHD_LOW" defaultMessage="R.P.M. to low" />,
          info: <FormattedMessage id="nozzle_calculation_wlong_UHD_LOW" defaultMessage="The min. r.p.m of the high pressure water unit has been exceeded for the desired flow rate." />
        })
      }
      if(res["WARN_UHD_HIGH"]) {
        warnings.push({
          type: "error",
          label: <FormattedMessage id="nozzle_calculation_wshort_UHD_HIGH" defaultMessage="R.P.M. to high" />,
          info: <FormattedMessage id="nozzle_calculation_wlong_UHD_HIGH" defaultMessage="The max. r.p.m of the high pressure water unit has been exceeded for the desired flow rate." />
        })
      }
      if(res["WARN_FR_VERY_HIGH"]) {
        warnings.push({
          type: "error",
          label: <FormattedMessage id="nozzle_calculation_wshort_FR_VERY_HIGH" defaultMessage="Hand held reaction force" />,
          info: <FormattedMessage id="nozzle_calculation_wlong_FR_VERY_HIGH" defaultMessage="The hand held use of high pressure water blasting guns is forbidden above 250 N reaction force. Please see local waterblasting safety regulations." />
        })
      } else if(res["WARN_FR_HIGH"]) {
        warnings.push({
          type: "warning",
          label: <FormattedMessage id="nozzle_calculation_wshort_FR_HIGH" defaultMessage="Hand held reaction force" />,
          info: <FormattedMessage id="nozzle_calculation_wlong_FR_HIGH" defaultMessage="Please be warned that the use of hand held high pressure water blasting guns without a shoulder stock is not recommended above 150 N reaction force. Please see local waterblasting safety regulations." />
        })
      }
      if(res["WARN_QTOT"]) {
        warnings.push({
          type: "error",
          label: <FormattedMessage id="nozzle_calculation_wshort_QTOT" defaultMessage="Max. flow rate exceeded" />,
          info: <FormattedMessage id="nozzle_calculation_wlong_QTOT" defaultMessage="The max. flow rate associated with your unit has been exceeded! Go to „Properties“ to change your max. flow rate or decrease your flow rate in your result by changing your input." />
        })
      }
      if(res["WARN_PB_HIGH"]) {
        warnings.push({
          type: "error",
          label: <FormattedMessage id="nozzle_calculation_wshort_PB_HIGH" defaultMessage="Caution! Over pressurizing!" />,
          info: <FormattedMessage id="nozzle_calculation_wlong_PB_HIGH" defaultMessage="Over pressurizing of the pump – rupture disc burst or safety valve actuating possible!" />
        })
      }

      //----

      if(res["WARN_PB_HIGH_NZ_MAX"]) {
        warnings.push({
          type: "error",
          label: <FormattedMessage id="nozzle_calculation_wshort_PB_HIGH_NZ_MAX" defaultMessage="Maximaler Düsendruck überschritten" />,
          info: <FormattedMessage id="nozzle_calculation_wlong_PB_HIGH_NZ_MAX" defaultMessage="Maximaler Düsendruck überschritten / Betriebsdruck reduzieren." />
        })
      }
      if(res["WARN_FR_MIN"]) {
        warnings.push({
          type: "error",
          label: <FormattedMessage id="nozzle_calculation_wshort_FR_MIN" defaultMessage="Antriebsdrehmoment zu gering" />,
          info: <FormattedMessage id="nozzle_calculation_wlong_FR_MINX" defaultMessage="Antriebsdrehmoment zu gering" />
        })
      }
      if(res["WARN_FR_MAX"]) {
        warnings.push({
          type: "error",
          label: <FormattedMessage id="nozzle_calculation_wshort_FR_MAX" defaultMessage="Antriebsdrehmoment zu hoch" />,
          info: <FormattedMessage id="nozzle_calculation_wlong_FR_MAX" defaultMessage="Antriebsdrehmoment zu hoch" />
        })
      }
      if(res["WARN_MANUAL_USE_NOT_ALLOWED"]) {
        warnings.push({
          type: "warning",
          label: <FormattedMessage id="nozzle_calculation_wshort_MANUAL_USE_NOT_ALLOWED" defaultMessage="Masterjet ist nicht für den Handbetrieb vorgesehen" />,
          info: <FormattedMessage id="nozzle_calculation_wlong_MANUAL_USE_NOT_ALLOWED" defaultMessage="Masterjet ist nicht für den Handbetrieb vorgesehen" />
        })
      }
      if(res["WARN_PB_HIGH_MJ"]) {
        warnings.push({
          type: "error",
          label: <FormattedMessage id="nozzle_calculation_wshort_PB_HIGH_MJ" defaultMessage="Maximaler Masterjet Druck überschritten" />,
          info: <FormattedMessage id="nozzle_calculation_wlong_PB_HIGH_MJ" defaultMessage="Maximaler Masterjet Druck überschritten" />
        })
      }
      if(res["WARN_PB_LOW_MJ"]) {
        warnings.push({
          type: "error",
          label: <FormattedMessage id="nozzle_calculation_wshort_PB_LOW_MJ" defaultMessage="Minimaler Masterjet Druck unterschritten" />,
          info: <FormattedMessage id="nozzle_calculation_wlong_PB_LOW_MJ" defaultMessage="Minimaler Masterjet Druck unterschritten" />
        })
      }
      if(res["WARN_QTOT_MJ"]) {
        warnings.push({
          type: "error",
          label: <FormattedMessage id="nozzle_calculation_wshort_QTOT_MJ" defaultMessage="Volumenstrom größer als Max Volumenstrom vom Masterjet" />,
          info: <FormattedMessage id="nozzle_calculation_wlong_QTOT_MJ"
                                  defaultMessage="Die maximale Durchflussrate der gewählten Masterjet beträgt {maxFlowrate}. Bitte verringern Sie den Betriebsdruck oder den Düsendurchmesser.\n"
                                  values={{ maxFlowrate: formatValueWithUnitAndLabel({ valueWithUnit: unitParams.maxFlowrate, si_unit: "l/mins", us_unit: "gal/mins", precision: 0 }) }}/>
        })
      }

      const nd_μ_map = this.props.activeNozzleType.dataunits.nd_μ_map;
      let nozzleDiameter = null;
      if(this.props.calculationParams.nozzleDiameter){
        const valueA = this.props.calculationParams.nozzleDiameter[0];
        const formattedValueA = formatValueWithUnitAndLabelAsString({ valueWithUnit: `${valueA} ${nd_μ_map.key_si_unit}`, si_unit: "mm", us_unit: "in", precision: 2 })
        const nozzleCount = this.props.calculationParams?.nozzleVariant === 'equal' ? this.props.calculationParams?.nozzleCount : this.props.calculationParams?.nozzleCount / 2
        nozzleDiameter = <span>{`${nozzleCount} x ${formattedValueA}`}</span>;
        if(this.props.calculationParams?.nozzleVariant !== 'equal') {
          const valueB = this.props.calculationParams.nozzleDiameter[1];
          const formattedValueB = formatValueWithUnitAndLabelAsString({ valueWithUnit: `${valueB} ${nd_μ_map.key_si_unit}`, si_unit: "mm", us_unit: "in", precision: 2 })
          nozzleDiameter = (
            <span>
              <span className={"masterjet_label_desktop"} style={{whiteSpace: 'nowrap'}}>
                {`${nozzleCount} x ${formattedValueA}`}<br/>{`${nozzleCount} x ${formattedValueB}`}
              </span>
              <span className={"masterjet_label_mobile"} style={{whiteSpace: 'nowrap', paddingBottom: 8}}>
                {`${nozzleCount} x ${formattedValueA}`}<br/>{`${nozzleCount} x ${formattedValueB}`}
              </span>
            </span>
          )
        }
      }

      let values = [{
        type: "valid",
        label: <FormattedMessage id="masterjet_result_nozzle_diameter" defaultMessage="Gewählter Düsen-Ø"/>,
        value: (<DataUnitTextField align="right-m-left-d" customLabel={nozzleDiameter} editable={false} labelOnly={true} />)
      },{
        type: (res["WARN_PB_HIGH"] || res["WARN_PB_HIGH_NZ_MAX"] || res["WARN_PB_HIGH_MJ"] || res["WARN_PB_LOW_MJ"]) ? "error" : "valid",
        label: <FormattedMessage id="masterjet_result_PB" defaultMessage="Bertiebsdruck"/>,
        value: (<DataUnitTextField decimalPlaces={0} align="right-m-left-d" value={res['pB']} editable={false} si="bar" siLabel="bar" us="psi" usLabel="psi" />)
      },{
        type: (res["WARN_QTOT"] || res["WARN_QTOT_MJ"]) ? "error" : "valid",
        label: <FormattedMessage id="masterjet_result_v" defaultMessage="Volumenstrom"/>,
        value: (<DataUnitTextField decimalPlaces={1} align="right-m-left-d" value={res['v']} editable={false} si="l/mins" siLabel="l/min" us="gal/mins" usLabel="gal/min" />)
      },{
        type: res["WARN_FR_VERY_HIGH"] || res["WARN_FR_MIN"] || res["WARN_FR_MAX"] ? "error" : "valid",
        label: <FormattedMessage id="masterjet_result_fr" defaultMessage="Düsenrückstoß"/>,
        value: (<DataUnitTextField decimalPlaces={0} align="right-m-left-d" value={res['FR']} editable={false} si="N" us="lbf" />)
      }]

      this.setState({
        result : {
          values : values,
          warnings : warnings
        }
      })
    }
  }

  handleUnitSelect = (unit) => {
    this.props.uiActions.setCalculationParameters({
      key : MASTERJET_CONFIGURATION_PARAMETERS,
      params : {
        unit: {
          ...this.getUnitParams(),
          ...unit
        }
      }
    });
  }

  handleProductTypeSelect = (type) => {
    this.props.uiActions.setCalculationParameters({
      key : MASTERJET_CONFIGURATION_PARAMETERS,
      params : {
        productType: type
      }
    });
  }

  handleNozzleVariantSelect = (variant) => {
    this.props.uiActions.setCalculationParameters({
      key : MASTERJET_CONFIGURATION_PARAMETERS,
      params : {
        nozzleVariant: variant
      }
    });
  }

  renderNozzleSelection = (size, labelSize) => {

    if(!this.props.activeNozzleType) {
      return null;
    }

    const { formatMessage } = this.props.intl;

    const nozzleCount = this.props.calculationParams?.nozzleCount;
    const maxNozzle = parseInt(_.max(_.keys(this.props.activeMasterJet.dataunits.numnoz_to_alpha_avg.value)));
    const name = this.props.activeNozzleType.name;
    const nozzleArrangement = getNozzleArrangement(nozzleCount, maxNozzle);

    let nozzleItems = [];

    let diameterA = this.props.calculationParams.nozzleDiameter ? this.props.calculationParams.nozzleDiameter[0] : "-";
    let diameterB =  this.props.calculationParams.nozzleDiameter ? this.props.calculationParams.nozzleDiameter[1] : "-";
    let diameterUnit = this.props.activeNozzleType.dataunits.nd_μ_map.key_si_unit;
    const formattedValueA = formatValueWithUnitAndLabelAsString({ valueWithUnit: `${diameterA} ${diameterUnit}`, si_unit: "mm", us_unit: "in", precision: 2 })
    const formattedValueB = formatValueWithUnitAndLabelAsString({ valueWithUnit: `${diameterB} ${diameterUnit}`, si_unit: "mm", us_unit: "in", precision: 2 })
    let labelA = `${name} - ${formattedValueA}`;
    let labelB = `${name} - ${formattedValueB}`;

    let nozzleIndex = 0;
    for(let i = 0; i < maxNozzle; i++) {
      if(nozzleArrangement.indexOf(i) >= 0) {
        let type = 'green';
        let label = labelA;
        if(this.props.calculationParams?.nozzleVariant !== "equal") {
          type = nozzleIndex % 2 == 1 ? 'red' : 'green'
          label = nozzleIndex % 2 == 1 ? labelB : labelA
        }

        nozzleItems.push({type: type, label: name, outerLabel: label})
        nozzleIndex ++;
      } else {
        let outerLabel = formatMessage(messages.masterjet_configuration_diameter_none);
        nozzleItems.push({type: 'placeholder_inactive', label: '', outerLabel: outerLabel})
      }
    }

    const pc = this.props.activeMasterJet ? this.props.activeMasterJet.dataunits.PC.value : 0;

    return (
      <NozzleImage labelSize={labelSize} size={size} key={`${size}_${labelSize}_`+JSON.stringify(nozzleItems)} items={nozzleItems} pc={pc} showLabels={true} />
    )
  }

  renderNozzleVariant =() => {
    return (
      <div className='masterjet-nozzle-variant'>
        <div className='masterjet-nozzle-variant--header'><FormattedMessage id='masterjet_configuration_nozzle_variant_header' defaultMessage='Düsendurchmesservarianten:'/></div>
        <OptionSelect background={'light'}>
          <OptionSelectItem disabled={this.props.calculationParams?.nozzleCount%2 != 0 || this.props.calculationParams?.nozzleCount <= 2} onClick={()=>this.handleNozzleVariantSelect('unequal')} active={this.props.calculationParams?.nozzleVariant === 'unequal'}><FormattedMessage id="masterjet_configuration_nozzle_variant_unequal" defaultMessage="Unterschiedlich" /></OptionSelectItem>
          <OptionSelectItem onClick={()=>this.handleNozzleVariantSelect('equal')} active={this.props.calculationParams?.nozzleVariant === 'equal'}><FormattedMessage id="masterjet_configuration_nozzle_variant_equal" defaultMessage="Gleich" /></OptionSelectItem>
        </OptionSelect>
      </div>
    )
  }

  renderNozzleDiameterSlider = () => {

    if(!this.props.activeNozzleType) {
      return null;
    }

    const nd_μ_map = this.props.activeNozzleType.dataunits.nd_μ_map;

    const getLabel = (step) => {
      const value = _.keys(nd_μ_map.value)[step];
      const formattedValue = formatValueWithUnitAndLabel({ valueWithUnit: `${value} ${nd_μ_map.key_si_unit}`, si_unit: "mm", us_unit: "in", precision: 3 })
      const nozzleCount = this.props.calculationParams?.nozzleVariant === 'equal' ? this.props.calculationParams?.nozzleCount : this.props.calculationParams?.nozzleCount / 2
      return (
        <span>
          {nozzleCount} x {formattedValue}
        </span>
      );
    }

    const getIndexValue = (type = 0) => {
      return this.props.calculationParams.nozzleDiameterIndex ? this.props.calculationParams.nozzleDiameterIndex[type] : 0;
    }

    const onChange = (index, type = 0) => {
      const values = _.keys(nd_μ_map.value);
      // let otherType = type === 0 ? 1 : 0;
      // let otherIndex = values.length - 1 - index;

      this.props.uiActions.setCalculationParameters({
        key: MASTERJET_CONFIGURATION_PARAMETERS,
        params: {
          nozzleDiameter: {
            ...this.props.calculationParams.nozzleDiameter,
            [type]: values[index],
            // [otherType]: values[otherIndex]
          },
          nozzleDiameterIndex: {
            ...this.props.calculationParams.nozzleDiameterIndex,
            [type]: index,
            // [otherType]: otherIndex
          }
        }
      });
    }

    return (
      <div className={"masterjet_configuration_nozzle-slider-container"}>
        <NozzleDiameterSlider nozzleName={this.props.activeNozzleType.name} value={getIndexValue(0)} onChange={step => onChange(step, 0)} stepCount={_.size(nd_μ_map.value)} label={getLabel} isEqual={true}></NozzleDiameterSlider>
        <If condition={this.props.calculationParams?.nozzleVariant === 'unequal'}>
          <NozzleDiameterSlider nozzleName={this.props.activeNozzleType.name} value={getIndexValue(1)} onChange={step => onChange(step, 1)} stepCount={_.size(nd_μ_map.value)} label={getLabel} isEqual={false}></NozzleDiameterSlider>
        </If>
      </div>
    )
  }

  render() {

    const { formatMessage } = this.props.intl;

    const unitParams = this.getUnitParams()

    return (
      <Layout caption={formatMessage(messages.masterjet_configuration_caption)}>

        <SectionHeader><FormattedMessage id="masterjet_configuration_header_unit" defaultMessage="UNIT"/></SectionHeader>
        <UnitSelector
            onUnitChange={this.handleUnitSelect}
            showPressure={true}
            units = {this.props.units}
            {...unitParams}
        />

        <SectionHeader>
          <FormattedMessage id="masterjet_configuration_header_product" defaultMessage="Produkt auswählen"/>
        </SectionHeader>
        <div className={"masterjet_product_selection"}>
          <div className={"masterjet_product_selection-inner"}>
            <div className={"masterjet_product_selection_image-container"}>
              <If condition={this.props.activeMasterJet && this.props.activeMasterJet.image}>
                <AsyncImage src={this.props.activeMasterJet.image} />
              <Else />
                <img src={masterjet_image} />
              </If>
            </div>
            <div className={"masterjet_product_selection_content-container"}>
              {/*<OptionSelect background={'dark'}>*/}
              {/*  <OptionSelectItem onClick={()=>this.handleProductTypeSelect('manual')} active={!this.props.calculationParams?.productType || this.props.calculationParams?.productType === 'manual'}><FormattedMessage id="masterjet_configuration_product_type_manual" defaultMessage="Manuell" /></OptionSelectItem>*/}
              {/*  <OptionSelectItem onClick={()=>this.handleProductTypeSelect('application')} active={this.props.calculationParams?.productType === 'application'}><FormattedMessage id="masterjet_configuration_product_type_application" defaultMessage="Maschineneinsatz" /></OptionSelectItem>*/}
              {/*</OptionSelect>*/}
              <ProductWizardMasterjet />
            </div>
          </div>
        </div>

        <If condition={this.props.activeMasterJet}>
          <SectionHeader><FormattedMessage id="masterjet_configuration_header_nozzleconfiguration" defaultMessage="Düsenkonfiguration"/></SectionHeader>
          <div className={"masterjet_nozzle_configuration"}>
            <NozzleConfigurationTableMasterjet
              editableDiameter={true}
              onConfigurationChange={this.handleNozzleConfigurationChange}
              enableDeletion={true}
              showDiameter={true}
              items={this.props.calculationParams && this.props.calculationParams.nozzles ? this.props.calculationParams.nozzles : null}
            />
            {this.renderNozzleVariant()}
            {this.renderNozzleDiameterSlider()}
          </div>

          <div className={"masterjet_result"}>
            <MediaQuery minWidth={981}>
              <div className={"masterjet_nozzle_selection_desktop"}>
                <SectionHeader><FormattedMessage id="masterjet_configuration_nozzle_selection" defaultMessage="Ihre Düsenauswahl"/></SectionHeader>
                <div className={"masterjet_nozzle_selection_desktop_image"}>
                  {this.renderNozzleSelection(180, 'large')}
                </div>
              </div>
            </MediaQuery>
            <CalculationResult
              values = {this.state.result.values}
              warnings = {this.state.result.warnings}
              customContentSlot2 = {
                <MediaQuery maxWidth={980}>
                  <div className={"masterjet_nozzle_selection_mobile"}>
                    <CalculationResultCustom
                      title={<FormattedMessage id={`masterjet_configuration_nozzle_selection`} defaultMessage={'Ihre Düsenauswahl'}></FormattedMessage>}
                      toggle={true}
                      initialToggle={true}
                    >
                      {this.renderNozzleSelection(120, 'default')}
                    </CalculationResultCustom>
                  </div>
                </MediaQuery>
              }
            />
          </div>
        <Else />
          <div className={"masterjet-no-calculation"}></div>
        </If>
      </Layout>
    );
  }
}

function mapStateToProps(state, props) {

  const calculationParams = getMasterjetConfigurationParams(state);
  const masterjetProducts = getMasterjetProducts(state);

  let activeMasterJet = null;
  let activeNozzleType = null;
  if(calculationParams?.productId) {
    activeMasterJet = _.find(masterjetProducts, product => product.id === calculationParams.productId);
    if(calculationParams?.nozzleType) {
      activeNozzleType = _.find(activeMasterJet.mjnz, nozzle => nozzle.id === calculationParams.nozzleType);
    }
  }

  return {
    activeMasterJet: activeMasterJet,
    activeNozzleType: activeNozzleType,
    formulas: getFormulas(state),
    dataunits: getDataunitsBySymbol(state),
    calculationParams: calculationParams || {},
    masterjetProducts: masterjetProducts,
    units: getUnits(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(UIActions, dispatch),
  }
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(MasterjetConfiguration))
