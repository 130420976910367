import React, {Component} from 'react';
import PropTypes from 'prop-types';

import ReactDOM from 'react-dom';

import './circularSlider.scss';

export default class CircularSlider extends Component {

  static propTypes = {
    onChange: PropTypes.func,
    radius: PropTypes.number.isRequired,
    border: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    origin: function (props, name) {
      if (props[name] < 0 || props[name] > 1) {
        return new Error(`The ${ name } property must be between 0 and 1.`)
      }
    },
    start: function (props, name) {
      if (props[name] < props.min || props[name] > props.max) {
        return new Error(`The ${ name } property must be between min (${ props.min }) and max (${ props.max }).`)
      }
    },
    angle: function (props, name) {
      const halfPI = Math.PI / 2
      if (props[name] < -halfPI || props[name] > halfPI) {
        return new Error(`The ${ name } property must be between -π/2 and π/2.`)
      }
    },
    value: function (props, name) {
      if (props[name] < props.min || props[name] > props.max) {
        return new Error(`The ${ name } property must be between min (${ props.min }) and max (${ props.max }).`)
      }
    },
  };

  static defaultProps = {
    radius: 50,
    border: 50,
    origin: 1,
    start: 0,
    angle: Math.PI / 2,
    value: 0,
  };

  state = { isPinching: false };

  constructor(props) {
    super(props)
    this.x = 0
  }

  componentDidMount() {
    this.potar.addEventListener("mousemove", this.handleMouseMove)
    this.potar.addEventListener("mouseup", this.handleMouseUp)
    this.potar.addEventListener("touchmove", this.handleTouchMove)
    this.potar.addEventListener("touchend", this.handleTouchEnd)
  }

  componentWillUnmount() {
    this.potar.removeEventListener("mousemove", this.handleMouseMove)
    this.potar.removeEventListener("mouseup", this.handleMouseUp)
    this.potar.removeEventListener("touchmove", this.handleTouchMove)
    this.potar.removeEventListener("touchend", this.handleTouchEnd)
  }

  clamp = (min, max, value) => Math.max(min, Math.min(max, value))

  calcValue = (e) => {

    const {
      left,
      top,
      width,
      height,
    } = this.potar.getBoundingClientRect()

    const {onChange, min, max} = this.props;
    const circleWidth = width;
    const circleRadius = circleWidth/2;

    const p1 = {x: 0, y: 0};
    const p2 = {x:e.pageX-left-circleRadius, y:-1*(e.pageY-top-circleRadius)};

    var newAngle = Math.atan(p2.y/p2.x) * (180/Math.PI);
    if(p2.y < 0){
      if(p2.x <0){
        newAngle = min;
      }else{
        newAngle = max;
      }
    }else{
      if(newAngle <= 0){
        newAngle = Math.abs(newAngle);
      }else{
        newAngle = 180 - newAngle;
      }
    }

    newAngle = Math.round(newAngle);
    newAngle = Math.min(newAngle, 165);

    if (onChange) {
      onChange(this.clamp(min, max, newAngle));
    }
  }

  handleTouchEnd = (e) => {
    this.calcValue(e.touches[0] || e.changedTouches[0]);
  }
  handleMouseUp = (e) => {
    this.setState({selecting: false});
    this.calcValue(e);
  }
  handleTouchDown = (e) => {
    this.calcValue(e.touches[0] || e.changedTouches[0]);
  }
  handleMouseDown = (e) => {
    this.setState({selecting: true});
    this.calcValue(e);
  };
  handleTouchMove = (e) => {
    this.calcValue(e.touches[0] || e.changedTouches[0]);
    e.stopPropagation();
    e.preventDefault();
  }
  handleMouseMove = (e) => {
    if(this.state.selecting){
      this.calcValue(e);
    }
  };

  getPointOnCircle = (r, rOrig, cosine, sine, percent) => {

    let xC = r * (1 - cosine)
    let yC = r * (1 + sine)
    let x,y;
    if(r < rOrig){
      x = rOrig + xC + (r * -1 * Math.cos(Math.PI * percent));
      y = (rOrig - r) + yC + (r * -1 * Math.sin(Math.PI * percent))
    }else{
      x = rOrig - xC + (r * -1 * Math.cos(Math.PI * percent));
      y = (r+rOrig) - yC + (r * -1 * Math.sin(Math.PI * percent))
    }
    return {x,y};
  }

  render() {
    const {
      radius,
      border,
      min,
      max,
      origin,
      start,
      angle,
      value,
    } = this.props

    const diameter = 2 * radius
    const halfBorder = border / 2
    const length = (Math.PI + 2 * angle) * radius

    const percent = value < start ?
      Math.abs(value - start) / Math.abs(min - start) * (1 - origin) :
      Math.abs(value - start) / Math.abs(max - start) * origin

    const strokeDashoffset = value < start ?
      length * (percent + origin) :
      length * origin

    const strokeDasharray = `${ length * percent } ${ length * (1 - percent) }`
    const strokeWidth = border

    const cosine = Math.cos(angle)
    const sine = Math.sin(angle)
    const x = radius * (1 - cosine)
    const y = radius * (1 + sine)
    const shouldUse2Arcs = angle !== 0 && angle % (Math.PI / 2) === 0
    const d = `M ${ x } ${ y }
      ${ shouldUse2Arcs ? `A ${ radius } ${ radius } 0 1 1 ${ diameter - x } 0` : "" }
      A ${ radius } ${ radius } 0 ${ angle < 0 ? 0 : 1 } 1 ${ diameter - x } ${ y }`

    const radiusOuter = radius*1.1;
    const xOuter = radiusOuter * (1 - cosine)
    const yOuter = radiusOuter * (1 + sine)
    const dOuter = `M ${ xOuter } ${ yOuter }
      ${ shouldUse2Arcs ? `A ${ radiusOuter } ${ radiusOuter } 0 1 1 ${ diameter - xOuter } 0` : "" }
      A ${ radiusOuter } ${ radiusOuter } 0 ${ angle < 0 ? 0 : 1 } 1 ${ diameter - xOuter } ${ yOuter }`

    const pickerRadius = radius - border - (border/2);
    const pickerPoint = this.getPointOnCircle(pickerRadius, radius, cosine, sine, percent);

    const lineOuterRadius = radius + border + (border/2);
    const lineInnerRadius = radius - border - (border/2);
    const outerStrokeWidth = strokeWidth + (strokeWidth/2);

    let lables = [];
    let radiusText = radiusOuter + border + (border/2);
    const textStrokeWidth = outerStrokeWidth*2;
    for(var i=1; i<4; i++){
      let textPos = this.getPointOnCircle(radiusText, radius, cosine, sine, i/4);
      lables.push(
        <text
          className={`circular-slider-step-lable`}
          x={textPos.x-3}
          y={textPos.y+5}
        >
          {`${45*i}°`}
        </text>
      );
    }

    return (
      <div className="circular-slider-container">
        <svg
          ref={ (potar) => {
            if(potar){
              this.potar = potar

              //IE fix to set correct height
              let elemNode = ReactDOM.findDOMNode(potar);
              let elemBounds = elemNode.getBoundingClientRect();
              let elemHeigth = Math.round(elemBounds.width * 0.81);
              elemNode.style.height = `${elemHeigth}px`;
            }
          }}
          className={`circular-slider`}
          viewBox={ `${ -(textStrokeWidth) } ${ -(textStrokeWidth) } ${ diameter + textStrokeWidth*2 } ${ (diameter/2) + (textStrokeWidth*2) + 20 }` }
          onMouseDown={ this.handleMouseDown }
          onTouchStart={(e)=>this.handleTouchDown}
          >
          {/*<circle
            className={ `circular-slider-circle` }
            r={ radius - halfBorder }
            cx={ radius }
            cy={ radius } />*/}
          <path
            className={ `circular-slider-bar-steps` }
            style={{
              strokeWidth: outerStrokeWidth,
              strokeDashoffset: '0',
              strokeDasharray: '4, 7.5',
            }}
            d={ d } />
          <path
            className={ `circular-slider-progress` }
            style={{
              strokeWidth: outerStrokeWidth*0.97,
              strokeDashoffset,
              strokeDasharray: `${ length * 165/180 } ${ length * (1 - 165/180) }`,
            }}
            d={ d } />
          <path
            className={ `circular-slider-bar-steps-overlay` }
            style={{
              strokeWidth: outerStrokeWidth,
              strokeDashoffset: '7.5',
              strokeDasharray: '7.5, 4',
            }}
            d={ d } />
          <path
            className={ `circular-slider-bar` }
            style={{
              strokeWidth: strokeWidth,
            }}
            d={ d } />
          <path
            className={ `circular-slider-progress` }
            style={{
              strokeWidth: strokeWidth*1.03,
              strokeDashoffset,
              strokeDasharray: `${ length * 165/180 } ${ length * (1 - 165/180) }`,
            }}
            d={ d } />
          <circle
            className={ `circular-slider-picker` }
            r={ halfBorder }
            cx={ pickerPoint.x }
            cy={ pickerPoint.y } />
          {lables}
          <text
            className={`circular-slider-step-lable`}
            x={radius-3}
            y={y+25}
          >
            {`${value}°`}
          </text>
        </svg>
      </div>
    )
  }
}
