import * as types from "./constants";
import _ from 'lodash';
import packagedData from 'assets/data/data.json';
import {preloadImages} from 'service/CordovaService';

let initState = {
  data : packagedData, //server-data
  units : [],
  currentDataUnitSystem : types.dataUnitSystems.si,
  enableGoogleAnalytics: true,
};

export default function reducer(state = initState, action = "") {

  if(action.type.startsWith(types.PREFIX)){

    switch (action.type) {
      case types.READ_DATA_REQUEST:
        return {
          ...state,
          loading: {
            ...state.loading,
            data: true
          }
        }
        break;
      case types.READ_DATA_RESPONSE:
        if(!action.payload.error) {
          setTimeout(() => {
            preloadImages(action.payload.data)
          })
          return {
            ...state,
            data: action.payload.data,
            loading: {
              ...state.loading,
              data: false
            }
          }
        } else {
          return {
            ...state,
            loading: {
              ...state.loading,
              data: false
            }
          }
        }
        break;
      case types.SET_UNIT:
      {
        var unit = action.payload.unit;
        return {
          ...state,
          units: [
            ..._.filter(state.units, (x) => x && (x.id != unit.id)),
            {
                ...unit
            }
          ]
        }
        break;
      }
      case types.DELETE_UNIT:
      {
        var id = action.payload.id;
        return {
          ...state,
          units: [
            ..._.filter(state.units, (x) => x && (x.id != id)),
          ]
        }
        break;
      }
      case types.SET_DATA_UNIT_SYSTEM:
      {
        var dataUnitSystem = action.payload.dataUnitSystem;
        return {
          ...state,
          currentDataUnitSystem: dataUnitSystem
        }
        break;
      }
      case types.SET_ENABLE_GOOGLE_ANALYTICS:
      {
        var enabled = action.payload.enabled;
        return {
          ...state,
          enableGoogleAnalytics: enabled
        }
        break;
      }
      default:
        return state
        break;
    }
  }

  return state;
}
