import React, { Component } from 'react';
import './landing.scss';
import { connect } from "react-redux";
import Layout from 'layout/Layout';
import {bindActionCreators} from "redux";
import * as UIActions from 'storeState/ui/actions';
import {Navigate} from "react-router-dom";

class Landing extends Component {

  constructor(props) {
    super(props);

    this.state = {
      navigate: null
    }
  }

  componentDidMount(){
    setTimeout(()=>{
      this.setState({
        navigate: "/nozzlecalculation"
      })
    });
  }

  render() {

    if(this.state.navigate) return <Navigate to={this.state.navigate}/>

    return (
      <Layout caption="Landing Page!">

      </Layout>
    );
  }
}

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(UIActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Landing)
