import ApiService from 'service/ApiService';

class Api {

    constructor(getApiUrl) {
        this.getApiUrl = getApiUrl;
    }

    buildReadNewsUrl = ({language}) => {
      if(language!='de'){
        return this.getApiUrl('feed/en');
      }else{
        return this.getApiUrl('feed/de');
      }
    }

}

export default new Api(ApiService.getApiUrl.bind(ApiService));
