import { createSelector } from 'reselect'

export const getState = (state) => state.splash;

export const getEnabled = createSelector(
  getState,
  (splash) => {
    return splash.enabled;
  }
);
