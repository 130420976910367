import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {isMobile, isIos, isCordova} from 'service/LayoutService';
const cordova = isCordova();

//Default External Link used to display links in cordova inapp browser
class ExternalLink extends Component {

  static propTypes = {
    href: PropTypes.string,
  };

 openLinkInCordova = (e, url) => {
   //cordova.InAppBrowser.open(url, '_blank', 'location=yes');
   window.open(url, '_blank', 'location=yes')
   e.preventDefault();
 }

  render() {

    let {href, name} = this.props;

    if(!href.toLowerCase().startsWith('http')){
      href = 'http://'+href;
    }

    if(cordova){
      return (<a href="#" onClick={(e)=>this.openLinkInCordova(e, href)}>{this.props.children}</a>);
    }else{
      return (<a href={href} target='_blank'>{this.props.children}</a>);
    }
  }
}

export default ExternalLink;
