import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './contactbutton.scss';

export default class ContactMultiButton extends Component {

    static propTypes = {
        children : PropTypes.any.isRequired,
        className : PropTypes.string
    }

    render = () => {
        return (
            <div className={`contact-multi-button ${this.props.className ? this.props.className : ''}`} onClick={this.props.onClick}>
                {this.props.children}
            </div>
        );
    }

}