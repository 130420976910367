
export default function (formatMessage) {

  return [
    {
      label: formatMessage({id:'conversion_group_length', defaultMessage:'Length'}),
      units: [
        {
          unit: 'mm',
          label: 'mm',
        },
        {
          unit: 'inch',
          label: 'inch',
        },
        {
          unit: 'm',
          label: 'm',
        },
        {
          unit: 'ft',
          label: 'ft',
        },
      ],
    },
    {
      label: formatMessage({id:'conversion_group_area', defaultMessage:'Area'}),
      units: [
        {
          unit: 'm^2',
          label: 'm²',
        },
        {
          unit: 'ft^2',
          label: 'ft²',
        },
      ],
    },
    {
      label: formatMessage({id:'conversion_group_volume', defaultMessage:'Volume'}),
      units: [
        {
          unit: 'm^3',
          label: 'm³',
        },
        {
          unit: 'ft^3',
          label: 'ft³',
        },
        {
          unit: 'l',
          label: 'l',
        },
        {
          unit: 'gal',
          label: 'gal',
        },
      ],
    },
    {
      label: formatMessage({id:'conversion_group_power', defaultMessage:'Power'}),
      units: [
        {
          unit: 'kW',
          label: 'kW',
        },
        {
          unit: 'hp',
          label: 'hp',
        },
      ],
    },
    {
      label: formatMessage({id:'conversion_group_flowrate', defaultMessage:'Flow Rate'}),
      units: [
        {
          unit: 'l/mins',
          label: 'l/min',
        },
        {
          unit: 'gal/mins',
          label: 'gpm',
        },
        {
          unit: 'm^3/hour',
          label: 'm³/h',
        },
      ],
    },
    {
      label: formatMessage({id:'conversion_group_pressure', defaultMessage:'Pressure'}),
      units: [
        {
          unit: 'bar',
          label: 'bar',
        },
        {
          unit: 'psi',
          label: 'psi',
        },
        {
          unit: 'mbar',
          label: 'mbar',
        },
        {
          unit: 'MPa',
          label: 'MPa',
        },
        {
          unit: 'Pa',
          label: 'Pa',
        },
      ],
    },
    {
      label: formatMessage({id:'conversion_group_weight', defaultMessage:'Weight'}),
      units: [
        {
          unit: 'tonne',
          label: 't',
        },
        {
          unit: 'lbs',
          label: 'lbs',
        },
        {
          unit: 'kg',
          label: 'kg',
        },
        {
          unit: 'g',
          label: 'g',
        },
      ],
    },
    {
      label: formatMessage({id:'conversion_group_force', defaultMessage:'Force'}),
      units: [
        {
          unit: 'N',
          label: 'N',
        },
        {
          unit: 'lbf',
          label: 'lbf',
        },
      ],
    },
    {
      label: formatMessage({id:'conversion_group_torque', defaultMessage:'Torque'}),
      units: [
        {
          unit: 'Nm',
          label: 'Nm',
        },
        {
          unit: 'ftlb',
          label: 'ft lb',
        },
      ],
    },
    {
      label: formatMessage({id:'conversion_group_temperature', defaultMessage:'Temperature'}),
      units: [
        {
          unit: 'K',
          label: 'K',
        },
        {
          unit: 'degC',
          label: '°C',
        },
        {
          unit: 'degF',
          label: '°F',
        },
      ],
    },
  ];
}
