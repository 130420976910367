import * as types from "./constants";

let initState = {
  activeMenuItem: null,
  initialMenuOpened: false,
  locale: null,
  agbAccepted: false,
  initialSettingsSet: false,
  infoMessage: "",
  calculations : {
    flowrate: {},
    nozzleCalculation: {},
    nozzleDiameter:{},
    tankCleaningUnit:{}
  },
};

export default function reducer(state = initState, action = "") {

  if(action.type.startsWith(types.PREFIX)){

    switch (action.type) {
      case types.SET_INITIAL_MENU_OPENED:
        return {
          ...state,
          initialMenuOpened: action.payload.initialMenuOpened
        }
        break;
      case types.SHOW_INFO_MESSAGE:
        return {
          ...state,
          infoMessage: {
            message: action.payload.message,
            key: Math.floor(Math.random() * Date.now())
          }
        }
        break;
      case types.SET_ACTIVE_MENU_ITEM:
        return {
          ...state,
          activeMenuItem: action.payload.item
        }
        break;
      case types.SET_MY_LOCATION:
        return {
          ...state,
          myLocation : action.payload.coords
        }
        break;
      case types.SET_MAP_LOCATION:
        return {
          ...state,
          mapLocation : action.payload.coords
        }
        break;
      case types.SET_REVERSE_GEOCODE_RESULT:
        return {
          ...state,
          rgeo: action.payload.rgeo
        }
        break;
      case types.SET_LOCALE:
        return {
          ...state,
          locale: action.payload.locale,
          initialMenuOpened: action.payload.initial,
        }
        break;
      case types.SET_AGB_ACCEPTED:
        return {
            ...state,
            agbAccepted: action.payload.accepted,
        }
        break;
      case types.SET_INITIAL_SETTINGS_SET:
        return {
            ...state,
            initialSettingsSet: action.payload.set,
        }
        break;
      case types.SET_CALCULATION_PARAMETERS:
        let newCalculations = {
            ...state.calculations
        }
        newCalculations[action.payload.key] = {
            ...(newCalculations[action.payload.key] || {}),
            ...action.payload.params
        };
        return {
            ...state,
            calculations: newCalculations
        }
        break;
      default:
        return state
        break;
    }
  }

  return state;
}
