export const PREFIX = "DATA_";


export const READ_DATA_REQUEST = PREFIX + "READ_DATA_REQUEST";
export const READ_DATA_RESPONSE = PREFIX + "READ_DATA_RESPONSE";

export const SET_UNIT = PREFIX + "SET_UNIT";
export const DELETE_UNIT = PREFIX + "DELETE_UNIT";
export const SET_DATA_UNIT_SYSTEM = PREFIX+"SET_DATA_UNIT_SYSTEM";

export const SET_ENABLE_GOOGLE_ANALYTICS= PREFIX+"SET_ENABLE_GOOGLE_ANALYTICS";

export const dataUnitSystems = {
    us : "US",
    si : "SI"
};