import React, { Component, PropTypes } from 'react';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import * as UIActions from 'storeState/ui/actions';
import * as DataActions from 'storeState/data/actions';
import {getAgbAccepted, getInitialSettingsSet} from 'storeState/ui/selectors';
import { getCurrentDataUnitSystem } from 'storeState/data/selectors';
import { dataUnitSystems } from 'storeState/data/constants';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ToggleButton from 'layout/toggleButton/ToggleButton';

import style from 'style';
import './initialSettings.scss';

const messages = defineMessages({
  initial_settings_popup_toggle_lable_metric: {
    id: 'initial_settings_popup_toggle_lable_metric',
    defaultMessage: 'SI'
  },
  initial_settings_popup_toggle_lable_us: {
    id: 'initial_settings_popup_toggle_lable_us',
    defaultMessage: 'US'
  }
});

class InitialSettings extends Component {

    constructor(props){
        super(props);
    }

    handleUsUnitsToggle = (oldValue) => {
      this.props.dataActions.setDataUnitSystem({dataUnitSystem : oldValue ? dataUnitSystems.us : dataUnitSystems.si});
    }

    setInitialSettingsSet = () => {
      this.props.uiActions.setInitialSettingsSet(true);
    }

    render() {

        const {agbAccepted, initialSettingsSet} = this.props;

        return (
            <If condition={agbAccepted && !initialSettingsSet}>
              <div className={`initial-settings-container`}>
                <div className="initial-settings-popup-container">
                  <div className="initial-settings-popup">
                    <div className="initial-settings-popup-title">
                      <FormattedMessage id="initial_settings_popup_title" defaultMessage="Information"/>
                    </div>
                    <div className="initial-settings-popup-setting">
                      <div className="initial-settings-popup-setting-lables">
                        <div className="initial-settings-popup-setting-line1"><FormattedMessage id="initial_settings_popup_us_units_line1" defaultMessage="US Units?" /></div>
                        <div className="initial-settings-popup-setting-line2"><FormattedMessage id="initial_settings_popup_us_units_line2" defaultMessage="Inch, PSI, GPM?" /></div>
                      </div>
                      <div className="initial-settings-popup-setting-toggle">
                        <ToggleButton
                          inActiveLabel={this.props.intl.formatMessage(messages['initial_settings_popup_toggle_lable_us'])}
                          activeLabel={this.props.intl.formatMessage(messages['initial_settings_popup_toggle_lable_metric'])}
                          inActiveColor={style.secondary4}
                          thumpColor={style.white}
                          onToggle={this.handleUsUnitsToggle}
                          value={this.props.currentDataUnitSystem != dataUnitSystems.us}
                        />
                      </div>
                      <div className="initial-settings-popup-setting-lables">
                        <div className="initial-settings-popup-setting-line1"><FormattedMessage id="initial_settings_popup_metric_units_line1" defaultMessage="Metric?" /></div>
                        <div className="initial-settings-popup-setting-line2"><FormattedMessage id="initial_settings_popup_metric_units_line2" defaultMessage="mm, bar, litre?" /></div>
                      </div>
                    </div>
                    <div className="initial-settings-popup-button" onClick={this.setInitialSettingsSet}>
                      <FormattedMessage id="initial_settings_popup_btn_accept" defaultMessage="OK"/>
                    </div>
                  </div>
                </div>
              </div>
            </If>
        );
    }
}

function mapStateToProps(state, props) {
    return {
      agbAccepted: getAgbAccepted(state),
      initialSettingsSet: getInitialSettingsSet(state),
      currentDataUnitSystem: getCurrentDataUnitSystem(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(UIActions, dispatch),
        dataActions: bindActionCreators(DataActions, dispatch),
    }
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(InitialSettings))
