import ApiService from 'service/ApiService';
import { isCordova, isBlackBerry } from 'service/LayoutService';
import _ from 'lodash';
import packagedData from 'assets/data/data.json';

function checkIfFileExists(path){
    return new Promise(function(resolve,reject) {
        window.resolveLocalFileSystemURL(path, function() {
            console.log("file "+path+" exists")
            resolve();
        }, function() {
            console.log("file "+path+" does not exist")
            reject();
        });
    });
}

function findLocalImage(imgPath) {
    console.log("finding local image ", imgPath)
    return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-undef
        const fullPath = cordova.file.applicationDirectory+(isBlackBerry() ? '' : 'www/')+"assets/data/uploads/"+imgPath;
        console.log("finding local image (fullPath) ", fullPath)
        checkIfFileExists(fullPath).then(() => {
            console.log("found!")
            resolve("assets/data/uploads/"+imgPath)
        }).catch(() => {
            console.log("not found!")
            reject()
        })
    })
}

export function getImageURI(imgPath) {
    return new Promise((resolve, reject) => {
        if(!isCordova()) {
            resolve(ApiService.getUploadUrl(imgPath))
        } else {
            findLocalImage(imgPath).then((uri) => {
                resolve(uri)
            }).catch(() => {
                resolve(ApiService.getUploadUrl(imgPath));
            })
        }
    })
}

export function preloadImages(obj) {
    //console.log("Preloading images")
    _.each(obj,(v,i) => {
        if (v !== null && typeof v=="object") {
            preloadImages(v);
        } else if (v !== null && typeof v=="string") {
            if(v.toLowerCase().endsWith(".png")
                || v.toLowerCase().endsWith(".jpg")
                || v.toLowerCase().endsWith(".svg")) {
                getImageURI(v).then((uri) => {
                    //console.log("Preloading...",uri)
                    let image = new Image();
                    image.src = uri;
                })
            }
        }
    })
}
