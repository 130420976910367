import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './sectionheader.scss'

export default class SectionHeader extends Component {
    static propTypes = {
        children : PropTypes.any,
        style: PropTypes.object,
    };

    static defaultProps = {
        style: {}
    }

    render() {
        return (
            <div className="section-header" style={this.props.style}>{this.props.children}</div>
        );
    }
}
