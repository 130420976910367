import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './nozzleImage.scss';
import colors from 'style'
import _ from "lodash";
import {FormattedMessage} from "react-intl";

export default class NozzleImage extends Component {

    static propTypes = {
      items: PropTypes.array,
      size: PropTypes.number,
      pc: PropTypes.number,
      showLabels: PropTypes.bool,
      style: PropTypes.any,
      labelSize: PropTypes.oneOf(['default', 'large'])
    }

    static defaultProps = {
      labelSize: 'default',
      size: 80,
      pc: null,
      items: [],
      style: {},
      showLabels: false,
    }

    constructor(props) {
      super(props);

      this.state={
        labelLines: []
      }

      this.itemRefs = {};
      this.labelRefs = {};
      this.wrapperRefs = {};

      this.internalId = Math.floor(Math.random() * Date.now());
    }

  registerRef = (refType, index, ref) => {

      if(ref) {
        this[refType][index] = ref

        let itemLength = this.props.items.length;
        if(this.props.pc) {
          itemLength = itemLength + 1;
        }

        if(this.state.labelLines.length === 0 && _.size(this.wrapperRefs) > 0 && _.size(this.itemRefs) >= itemLength && _.size(this.labelRefs) >= itemLength) {

          const wrapperDom = ReactDOM.findDOMNode(this.wrapperRefs[0]);
          const wrapperBounds = wrapperDom.getBoundingClientRect();

          let lines = [];
          for(let i=0; i<_.size(this.labelRefs); i++) {
            const labelRef = this.labelRefs[i];
            const itemRef = this.itemRefs[i];
            const labelDom = ReactDOM.findDOMNode(labelRef);
            const itemDom = ReactDOM.findDOMNode(itemRef);
            const labelBounds = labelDom.getBoundingClientRect();
            const itemBounds = itemDom.getBoundingClientRect();

            const itemSize = this.props.size * 0.2;

            if(i === this.props.items.length) {
              // pc
              lines.push({
                from: {x: labelBounds.x - wrapperBounds.x , y: labelBounds.y - wrapperBounds.y + labelBounds.height / 2},
                to: {x: itemBounds.x - wrapperBounds.x, y: itemBounds.y - wrapperBounds.y}
              })
            } else {

              let labelOffset = {x: 0, y: 0}
              if(this.props.items.length === 5) {
                if (i === 0) {
                  labelOffset = {x: 0, y: this.props.size * -0.2}
                } else if (i === 1) {
                  labelOffset = {x: this.props.size * 0.15, y: this.props.size * -0.02}
                } else if (i === 2) {
                  labelOffset = {x: this.props.size * 0.05, y: this.props.size * 0.05}
                } else if (i === 3) {
                  labelOffset = {x: 0, y: 0}
                } else if (i === 4) {
                  labelOffset = {x: this.props.size * -0.25, y: this.props.size * -0.1}
                }
              }

              lines.push({
                from: {x: labelBounds.x - wrapperBounds.x, y: labelBounds.y - wrapperBounds.y},
                to: {x: itemBounds.x - wrapperBounds.x + labelOffset.x, y: itemBounds.y - wrapperBounds.y + labelOffset.y}
              })
            }

          }

          this.setState({
            labelLines: lines,
            viewBox: {
              width: wrapperBounds.width,
              height: wrapperBounds.height
            }
          })
        }
      }
    }

  renderItems = () => {
      let output = [];

      const itemSize = this.props.size * 0.2;

      let translate = this.props.size * 0.28
      let translateLabelMarker = this.props.size * 0.6

      let transformOffset = 210;

      if(this.props.items.length === 3) {
        transformOffset = 200
      } else if(this.props.items.length === 4) {
        transformOffset = 225
      } else if(this.props.items.length === 5) {
        transformOffset = 180
      } else if(this.props.items.length >= 6) {
        translate = this.props.size * 0.32
      }

      _.each(this.props.items, (item, index) => {

        let rotate = 360 / this.props.items.length * index + transformOffset;
        let style = {
          transform: `rotate(${rotate}deg) translate(${translate}px) rotate(-${rotate}deg)`,
          width: itemSize,
          height: itemSize,
          fontSize: itemSize * 0.7,
          margin: -1 * itemSize / 2,
          ...(item.style ? item.style : {})
        }

        output.push(
          <div className={`nozzle-image-item nozzle-image-item--type-${item.type}`} style={style}>{item.label}</div>
        )

        let styleLabelMarker = {
          transform: `rotate(${rotate}deg) translate(${translateLabelMarker}px) rotate(-${rotate}deg)`,
          width: 1,
          height: 1,
        }

        output.push(
          <div className={`nozzle-image-item nozzle-image-item--marker`} ref={ref => this.registerRef("itemRefs", index, ref)} style={styleLabelMarker}></div>
        )

      })

      if(this.props.pc) {
        let pcLabel = _.padStart('', this.props.pc, '·');
        output.push(
          <div className={`nozzle-image-item-pc`} style={{
            fontSize: itemSize * 1.4,
            lineHeight: `${itemSize * 1.4}px`,
            letterSpacing: `${itemSize * 0.02}px`,
            marginTop: 0,
          }}>{pcLabel}</div>
        )

        let styleLabelMarker = {
          transform: `rotate(${0}deg) translate(${translateLabelMarker}px) rotate(-${0}deg)`,
          width: 1,
          height: 1,
        }

        output.push(
          <div className={`nozzle-image-item`} ref={ref => this.registerRef("itemRefs", this.props.items.length, ref)} style={styleLabelMarker}></div>
        )
      }

      return output;
    }

    renderLabels = (position = 'top') => {

      let items = [];
      let indexOffset = 0;
      if(position === 'top') {
        indexOffset = 0
        items = this.props.items.slice(indexOffset, Math.ceil(this.props.items.length/2))
      } else if (position === 'bottom') {
        indexOffset = Math.ceil(this.props.items.length/2);
        items = this.props.items.slice(indexOffset, this.props.items.length)
      }

      let output = [];
      _.each(items, (item, index) => {
        const totalIndex = indexOffset + index
        output.push(
          <div className={`nozzle-image-item-label nozzle-image-item-label--index-${totalIndex} nozzle-image-item-label--${position}`}>
            <If condition={position === 'bottom'}>
              <div ref={ref => {this.registerRef("labelRefs", totalIndex, ref)}}></div>
            </If>
            <If condition={position === 'top'}>
              <div className={`nozzle-image-item-label-title`}>
                <FormattedMessage id={'nozzle_image_title'} defaultMessage={'Düse'}/> {totalIndex+1}
              </div>
            </If>
            <div className={`nozzle-image-item-label-info nozzle-image-item-label-info--type-${item.type}`}>
              {item.outerLabel}
            </div>
            <If condition={position === 'bottom'}>
              <div className={`nozzle-image-item-label-title`}>
                <FormattedMessage id={'nozzle_image_title'} defaultMessage={'Düse'}/> {totalIndex+1}
              </div>
            </If>
            <If condition={position === 'top'}>
              <div ref={ref => {this.registerRef("labelRefs", totalIndex, ref)}}></div>
            </If>
          </div>
        );
      })

      if(position === 'bottom') {
        output = _.reverse(output)
      }

      return (
        <div className={`nozzle-image-label-container`}>
          {output}
        </div>
      );
    }

    renderPcLabel = () => {
      const pc = parseInt(this.props.pc) || 0;
      return(
        <div className={`nozzle-image-item-label nozzle-image-item-label--pc`} ref={ref => {this.registerRef("labelRefs", this.props.items.length, ref)}}>
          <div ></div>
          <div className={`nozzle-image-item-label-info`}>
            PC {pc === 0 ? 'LF' : pc}
          </div>
        </div>
      )
  }

    renderLabelLines = () => {
      if(this.state.viewBox) {

        let lines = [];
        _.each(this.state.labelLines, (labelLine, index) => {

          let breakPointY = labelLine.to.y > labelLine.from.y ? labelLine.to.y - labelLine.to.y / 4 :  labelLine.to.y + labelLine.to.y / 3 / 4;

          if(index === this.props.items.length) {
            // pc line
            lines.push(
              <line key={`line_2_${index}_${JSON.stringify(labelLine)}`} x1={labelLine.from.x} y1={labelLine.from.y} x2={labelLine.to.x} y2={labelLine.to.y} style={{
                stroke: colors.secondary1,
                strokeWidth: 1
              }} markerEnd={`url(#arrowhead${this.internalId})`} />
            );
          } else {
            lines.push(
              <line key={`line_1_${index}_${JSON.stringify(labelLine)}`} x1={labelLine.from.x} y1={labelLine.from.y} x2={labelLine.from.x} y2={breakPointY} style={{
                stroke: colors.secondary1,
                strokeWidth: 1
              }} />
            )
            lines.push(
              <line key={`line_2_${index}_${JSON.stringify(labelLine)}`} x1={labelLine.from.x} y1={breakPointY} x2={labelLine.to.x} y2={labelLine.to.y} style={{
                stroke: colors.secondary1,
                strokeWidth: 1
              }} markerEnd={`url(#arrowhead${this.internalId})`} />
            )
          }
        });

        return(
          <svg className={`nozzle-image-lines`} viewBox={`0 0 ${this.state.viewBox.width} ${this.state.viewBox.height}`}>
            <defs>
              <marker id={`arrowhead${this.internalId}`} markerWidth="18" markerHeight="18" viewBox={`0 0 48 48`}
                      refX="23" refY="24" orient="auto">
                <path style={{fill: colors.secondary1}} d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z"/>
              </marker>
            </defs>
            <For each="labelLine" of={this.state.labelLines}>
              {lines}
            </For>
          </svg>
        )
      }
    }

    render() {

      if(this.props.items.length == 0) return null;

        return (
          <div className={`nozzle-image-container nozzle-image-label-size--${this.props.labelSize}`}>
            <div className={`nozzle-image-wrapper`} ref={ref => this.registerRef("wrapperRefs", 0, ref)}>
              <If condition={this.props.showLabels}>
                {this.renderLabels('top')}
              </If>
              <div className={`nozzle-image`} style={{
                ...this.props.style,
                width: this.props.size,
                height: this.props.size,
              }}>
                {this.renderItems()}
              </div>
              <If condition={this.props.showLabels}>
                {this.renderLabels('bottom')}
                {this.renderPcLabel()}
                {this.renderLabelLines()}
              </If>
            </div>
          </div>
        )
    }

}
