import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReactDOM from 'react-dom';
import { injectIntl, defineMessages } from 'react-intl';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { convert, formatValue, addUnit } from 'service/MathService';
import { getCurrentDataUnitSystem } from 'storeState/data/selectors';
import { dataUnitSystems } from 'storeState/data/constants';
import ContentEditable from 'react-contenteditable';
import he from 'he';
import DynamicNumber from 'layout/dynamicNumber';

import './dataunittextfield.scss';

const messages = defineMessages({
    decimal_separator: {
        id: 'decimal_separator',
        defaultMessage: '.'
    }
});

class DataUnitTextField extends Component {

    static propTypes = {
        className: PropTypes.string,
        align: PropTypes.string.isRequired, //left, center, right
        value: PropTypes.any, // this should include the unit ! (if the value has a unit)
        si: PropTypes.string,
        us: PropTypes.string,
        siLabel: PropTypes.string,
        usLabel: PropTypes.string,
        customLabel: PropTypes.any,
        decimalPlaces: PropTypes.number, // limit number of decimal places
        integerPlaces: PropTypes.number, // limit number of integer places
        allowPositive: PropTypes.bool,
        allowNegative: PropTypes.bool,
        transformValue: PropTypes.func, // custom transform Value
        //separatorSymbol: PropTypes.string, // must , or .
        enableThousandSeperator: PropTypes.bool,
        maxValue: PropTypes.number,
        minValue: PropTypes.number,
        editable: PropTypes.bool,
        onChange: PropTypes.func,
        rerenderValue: PropTypes.any,
        renderObj: PropTypes.any, //when this changes, we rerender!
        labelOnly: PropTypes.bool
        // this is required, because we do not want to rerender, when the value changes
        // because an input of 1 would result in 1.00 as prop
    };

    static defaultProps = {
        decimalPlaces: 2,
        integerPlaces: 9,
        editable: false,
        allowPositive: true,
        allowNegative: true,
        //separatorSymbol: ',',
        enableThousandSeperator: true,
        align: "center",
        labelOnly: false,
    }

    constructor(props) {
        super(props);
        this.oldVal = props.value;
        this.state = {
            disabled : false
        }
    }

    validateFloatString = (s) => {
        return !isNaN(s);
    }

    validateFloatRange = (s) => {
      const {maxValue, minValue} = this.props;
      //console.log("props", this.props);
      //console.log("validate float range",s);
      //console.log("validate float range maxValue",maxValue);
      let evaluation = true;
      if(maxValue){
        evaluation = evaluation && s <= maxValue;
      }
      if(minValue){
        evaluation = evaluation && s >= minValue;
      }
      return evaluation;
    }

    validateFloatPositiveNegative = (s) => {

      const {allowPositive, allowNegative} = this.props;

      let evaluation = true;
      if(!allowPositive){
        evaluation = evaluation && s <= 0;
      }
      if(!allowNegative){
        evaluation = evaluation && s >= 0;
      }
      return evaluation;
    }

    validateFloatLength = (s) => {

      const {decimalPlaces, integerPlaces} = this.props;

      let decimalLength = true;
      let integerLength = true;

      let parts = s.split('.');
      if(parts.length==2){
        if(parts[1] != null){
          decimalLength = ""+parts[1].length <= decimalPlaces
        }
        if(parts[0] != null){
          integerLength = ""+parts[0].length <= integerPlaces
        }
      }else{
        if(parts[0] != null){
          decimalLength = ""+parts[0].length <= integerPlaces
        }
      }
      return decimalLength && integerLength;
    }

    deconvert = (f) => {
        if(this.props.useUsSystem && this.props.us) {
            return convert(addUnit(f,this.props.us), this.props.si);
        } else if(this.props.si) {
            return convert(addUnit(f,this.props.si), this.props.si);
        }
        return f;
    }

    handleChange = (ev) => {
        this.calcInputWidth();
        if ( this.props.onChange ) {


            let isValid = true;
            let reRender = false;
            let v = he.decode(this.transformFromDynamicNumber(ev.target.value)).trim();

            //console.log("debug1", v);

            if(this.isEmpty(v)) {
                isValid = false;
            } else {
              try {
                if(this.validateFloatRange(v)){
                  if(this.validateFloatPositiveNegative(v)) {
                    if(this.validateFloatLength(v)) {
                      if (this.validateFloatString(v)) {
                        this.prevVal = this.transformToDynamicNumber(v);
                        v = this.deconvert(v)
                      } else {
                        console.log("This is not a float");
                        isValid = false;
                      }
                    } else {
                      console.log("Input is too long");
                      isValid = false;
                    }
                  } else {
                    console.log("Input is positive or negative not allowed");
                    isValid = false;
                  }
                } else {
                  console.log("Input is out of range");
                  isValid = false;
                  //force rerender to reset input val
                  reRender = true;
                }
              } catch(ex) {
                console.log("Exception ocured");
                console.error(ex);
                isValid = false;
              }
            }

            if(isValid){
              //console.log("send valid",v )
              //this.prevVal = v;
              this.props.onChange(v);
            }
            if(reRender){
              //console.log("rerender!")
              setTimeout(()=>{
                if(!this.prevVal){
                  this.prevVal = 0;
                }
                this.dynamicNumberDom.value = this.prevVal;
                this.calcInputWidth();
              },100)
            }
        }
    }

    conversion = (props) => {
        let value = props.value;
        let label="";
        if(props.useUsSystem && props.us) {
            value = value ? formatValue(convert(value, props.si), props.us, props.decimalPlaces) : null;
            label = (props.usLabel ? props.usLabel : props.us);
        } else {
            if(props.si) {
                value = value ? formatValue(convert(value, props.si), props.si, props.decimalPlaces) : null;
            } else {
                value = value ? new Number(value).toFixed(props.decimalPlaces) : null;
            }
            label = props.siLabel ? props.siLabel : props.si;
        }

        let transformedValue = parseFloat(new Number(value));
        if(this.props.transformValue){
          transformedValue = this.props.transformValue(transformedValue);
        }
        return {
            value: transformedValue,
            label: label
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(nextProps.renderObj!=this.props.renderObj || nextProps.useUsSystem != this.props.useUsSystem || nextProps.rerenderValue != this.props.rerenderValue) {
            this.rerender();
            return true;
        }
        if(!nextProps.editable) {
            return true;
        }
        /*let {value: oldValue, oldLabel} = this.conversion(this.props)
        let {value: newValue, newLabel} = this.conversion(nextProps)
        if (nextProps.editable && newValue!=oldValue) {
            return false; // if the value has changed (and hopefully nothing else), we do not want to update
        }*/
        return false;
    }

    isEmpty = (s) => {
        if(s==null)
            return true;
        if(s=="")
            return true;
        if(typeof s === 'string' && s.trim()=="")
            return true;

        return false;
    }

    rerender = () =>  {
        setTimeout(() => {
            this.setState({
                disabled : true
            })
            setTimeout(() => {
                this.setState({
                    disabled : false
                })
            })
        })
    }

    getSeparator() {
        const { formatMessage } = this.props.intl;
        return formatMessage(messages.decimal_separator);
    }

    getThousandSeparator() {
        //use U+2008 PUNCTUATION SPACE because normal space is used from module
        return " ";
    }

    transformToDynamicNumber = (value) => {
        if(value==null){
            return null;
        }

        var value = String(value);
        if(this.props.enableThousandSeperator) {
            value = value.split(".");
            value[0] = value[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.getThousandSeparator() );
            return value.join(this.getSeparator());
        } else {
            return value.replace(/\./g, this.getSeparator());
        }

        return value;
    }

    transformFromDynamicNumber = (value) => {
      if(value==null){
        return null;
      }
      const { enableThousandSeperator } = this.props;
      const separatorSymbol = this.getSeparator();
        if(enableThousandSeperator) {
          value = value.replace(this.getThousandSeparator(), '');
            // if(separatorSymbol == '.') {
            //     value = value.replace(',', '');
            // } else if(separatorSymbol == ',') {
            //     value = value.replace('.', '');
            // }
        }
      value = value.replace(separatorSymbol, '.');
      return value;
    }

    calcInputWidth = () => {
        if(this.dynamicNumberDom) {
            var tmp = document.createElement("span");
            tmp.className = "data-unit-textfield-tmp-input";
            tmp.innerHTML = this.dynamicNumberDom.value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');

            this.dynamicNumberDom.parentNode.appendChild(tmp);
            var theWidth = tmp.getBoundingClientRect().width;
            this.dynamicNumberDom.parentNode.removeChild(tmp);
            this.dynamicNumberDom.style.width = (theWidth+15) + "px";
        }
    }

    componentDidMount = () => {

    }

    handleClick = (ev) => {
        ev.preventDefault();
        if(this.dynamicNumberDom) {
            this.dynamicNumberDom.focus();
        }
    }

    render() {
        const {customLabel, integerPlaces, decimalPlaces, allowPositive, allowNegative, enableThousandSeperator} = this.props;
        let {value, label} = this.conversion(this.props);
        const { formatMessage } = this.props.intl;

        this.prevVal = null;

        if(this.props.align == 'plain'){
          return (
            <span>
              <If condition={!this.props.labelOnly}>
                {this.validateFloatString(value) ? this.transformToDynamicNumber(value) : 0}
              </If>
              {customLabel?(typeof customLabel === "function"?customLabel():customLabel):label}
            </span>
          );
        }

        return (
            <div onClick={this.handleClick} className={`data-unit-textfield data-unit-textfield-${this.props.align} ${this.props.editable ? 'data-unit-textfield-editable' : 'data-unit-textfield-not-editable'} ${this.props.className ? this.props.className : ''}`}>
                <If condition={!this.props.labelOnly}>
                  <If condition={this.props.editable}>
                    <div className={`dynamic-number-container`} >
                      <DynamicNumber
                        ref={ref=>{
                          if(ref){
                            this.dynamicNumber = ref;
                            this.dynamicNumberDom = ReactDOM.findDOMNode(ref);
                            this.dynamicNumberDom.autocomplete="false";
                            this.calcInputWidth();
                            // this.dynamicNumberDom.type = 'number';
                            // this.dynamicNumberDom.pattern = '[0-9,.]';
                          }
                        }}
                        // onFocus={()=>{this.dynamicNumberDom.type = 'text'}}
                        // onBlur={()=>{this.dynamicNumberDom.type = 'number'}}
                        // onKeyDown={()=>{this.dynamicNumberDom.type == 'number' ? this.dynamicNumberDom.type = 'text' : null}}
                        // onKeyUp={()=>{this.dynamicNumberDom.type == 'number' ? this.dynamicNumberDom.type = 'text' : null}}
                        value={value}
                        separator={this.getSeparator()}
                        integer={integerPlaces}
                        fraction={decimalPlaces}
                        positive={allowPositive}
                        negative={allowNegative}
                        thousand={enableThousandSeperator}
                        onChange={this.handleChange}
                        onFocus={()=>{
                          if(!this.prevVal) {
                            this.prevVal = this.dynamicNumberDom.value; //he.decode(this.transformFromDynamicNumber(this.dynamicNumberDom.value)).trim();
                          }
                          this.dynamicNumberDom.value='';
                        }}
                        onBlur={()=>{
                          if(this.dynamicNumberDom.value==''){
                            this.dynamicNumberDom.value=this.prevVal;
                          }
                        }}
                      />
                    </div>
                  <Else />
                      <div className={`data-unit-textfield-ce data-unit-textfield-ce-${this.props.align}`}>{this.validateFloatString(value) ? this.transformToDynamicNumber(value) : 0}</div>
                  </If>
                </If>
                <div className={`data-unit-textfield-label data-unit-textfield-label--${this.props.labelOnly?'labelOnly':''}`}> {customLabel?(typeof customLabel === "function"?customLabel():customLabel):label}</div>
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        useUsSystem: getCurrentDataUnitSystem(state)==dataUnitSystems.us,
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(DataUnitTextField));
