import { createSelector } from 'reselect'

export const getState = (state) => state.ui;

export const getActiveMenuItem = createSelector(
  getState,
  (ui) => {
    return ui.activeMenuItem;
  }
);

export const getMapLocation = createSelector(
    getState,
    (ui) => {
        return ui.mapLocation;
    }
);

export const getMyLocation = createSelector(
    getState,
    (ui) => {
        return ui.myLocation;
    }
);

export const getMyCountry = createSelector(
    getState,
    (ui) => {
        if(ui && ui.rgeo && ui.rgeo.address) {
            return ui.rgeo.address.country_code;
        } else {
            return null;
        }
    }
);

export const getLocale = createSelector(
    getState,
    (ui) => ui.locale
);

export const getAgbAccepted = createSelector(
    getState,
    (ui) => ui.agbAccepted
);

export const getInitialSettingsSet = createSelector(
    getState,
    (ui) => ui.initialSettingsSet
);

export const getFlowrateCalculationParams = createSelector(
    getState,
    (ui) => {
        return ui.calculations.flowrate
    }
)

export const getNozzleCalculationParams = createSelector(
    getState,
    (ui) => {
        return ui.calculations.nozzleCalculation
    }
)

export const getMasterjetConfigurationParams = createSelector(
  getState,
  (ui) => {
    return ui.calculations.masterjetconfiguration
  }
)

export const getInfoMessage = createSelector(
  getState,
  (ui) => {
    return ui.infoMessage
  }
)

export const getNozzleDiameterCalculationParams = createSelector(
    getState,
    (ui) => {
        return ui.calculations.nozzleDiameter
    }
)

export const getTankCleaningUnitCalculationParams = createSelector(
    getState,
    (ui) => {
        return ui.calculations.tankCleaningUnit
    }
)
