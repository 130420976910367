const MODAL_TYPE_PRODUCT = 1; //
const MODAL_TYPE_PRODUCT_CHOOSE = 2; // productId
const MODAL_TYPE_PRODUCT_APPLICATION = 3; // productType
const MODAL_TYPE_NOZZLE_COUNT = 4;  // maxNozzleCount
const MODAL_TYPE_NOZZLE_AMOUNT = 5;  // nozzleCount
const MODAL_TYPE_NOZZLE_DIAMETER = 6;  // nozzleVariant
const MODAL_TYPE_PERFORMANCE_CLASS = 7;  // performanceClass
const MODAL_TYPE_THREAD = 8;  // threadType
const MODAL_TYPE_PRESSURE = 9;  // maxPressure
const MODAL_TYPE_VARIABLE_SPEED = 10;  // variableSpeed?
const MODAL_TYPE_PRODUCT_CHOOSE_AFTER_WIZARD = 11;  // productId

export default {
  MODAL_TYPE_PRODUCT,
  MODAL_TYPE_PRODUCT_CHOOSE,
  MODAL_TYPE_PRODUCT_APPLICATION,
  MODAL_TYPE_NOZZLE_COUNT,
  MODAL_TYPE_NOZZLE_AMOUNT,
  MODAL_TYPE_NOZZLE_DIAMETER,
  MODAL_TYPE_PERFORMANCE_CLASS,
  MODAL_TYPE_THREAD,
  MODAL_TYPE_PRESSURE,
  MODAL_TYPE_VARIABLE_SPEED,
  MODAL_TYPE_PRODUCT_CHOOSE_AFTER_WIZARD
}
