import React, { Component, PropTypes } from 'react';
import './buttonBar.scss';

export default (props) => {
    const {className, children, ...otherProps} = props;

    return (
      <div className={`ci-buttonbar ${className}`} {...otherProps}>
          {children}
      </div>
    );
}
