import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from "react-redux";

import 'components/layout/productWizard/productWizardMasterjet.scss';
import SectionHeader from "components/layout/sectionHeader/SectionHeader";
import WizardItem from "components/layout/productWizard/WizardItem";

import MODAL_TYPE from "components/layout/productWizard/masterJetModals/MasterjetModalTypes.constants";
import {MdClose as CloseIcon} from "react-icons/md";
import {OptionSelect, OptionSelectItem} from "components/layout/optionSelect/OptionSelect";

import WizardNavigation from "components/layout/productWizard/WizardNavigation";
import {bindActionCreators} from "redux";
import * as UIActions from 'storeState/ui/actions';
import {MASTERJET_CONFIGURATION_PARAMETERS} from "components/views/masterjetConfiguration/MasterjetConfiguration";
import {getMasterjetConfigurationParams} from "storeState/ui/selectors";
import _ from "lodash";

import masterjet_variablespeed_variable from 'assets/images/new/Drehzahl-variabel-kontur.png';
import masterjet_variablespeed_variable_selected from 'assets/images/new/Drehzahl-variabel.png';
import masterjet_variablespeed_static from 'assets/images/new/Drehzahl-fest-Kontur.png';
import masterjet_variablespeed_static_selected from 'assets/images/new/Drehzahl-fest.png';
import masterjet_variablespeed_chart from 'assets/images/new/Variable-Drehzahl-Schaubild.png';
import masterjet_variablespeed_practice from 'assets/images/new/Variable-Drehzahl-Praxisbild.png';

class ModalVariableSpeed extends Component {

  static propTypes = {
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  static defaultProps = {

  }

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  setValue = (value) => {

    let newParams = {
      variableSpeed: value
    };

    if(this.props.calculationParams.variableSpeed !== value) {
      // reset
      newParams.productId = false;
    }

    this.props.uiActions.setCalculationParameters({
      key : MASTERJET_CONFIGURATION_PARAMETERS,
      params : newParams
    });
  }

  hasVariableSpeed = (value) => {
    return _.find(this.props.filteredProducts, product => product.dataunits.variableSpeed.value === value);
  }

  render() {
    const isVariable = this.props.calculationParams.variableSpeed != null && this.props.calculationParams.variableSpeed === "1.0";
    const isStatic = this.props.calculationParams.variableSpeed != null && (this.props.calculationParams.variableSpeed === "0.0" || this.props.calculationParams.variableSpeed === "None");

    return (
      <div className="product-wizard-masterjet-modal-item-container">
        <SectionHeader style={{padding: 0}}>
          <div className="product-wizard-masterjet-modal-item-section-row">
            <div className={"product-wizard-masterjet-modal-item-section-row-icon"} onClick={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_PRODUCT)}>
              <CloseIcon></CloseIcon>
            </div>
            <div className={"product-wizard-masterjet-modal-item-section-row-label"}>
              <FormattedMessage id="product_wizard_masterjet_variableSpeed" defaultMessage="Variable Drehzahl"/>
            </div>
          </div>
        </SectionHeader>
        <WizardNavigation
          onPrev={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_PRESSURE)}
          onNext={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_PRODUCT_CHOOSE_AFTER_WIZARD)}
          onNextDisabled={this.props.calculationParams.variableSpeed === null || this.props.calculationParams.variableSpeed === false}
        />
        <WizardItem type={"hint"} label={<FormattedMessage id="product_wizard_masterjet_variableSpeed_hint" defaultMessage="Können Sie die Drehzahl einstellen?"/>}/>
        <OptionSelect border={false} background={"modal"} responsive={false}>
          <OptionSelectItem
            labelBottom={
              <div className={"product-wizard-modal-label"}>
                <div className={"product-wizard-modal-label-pic-container"}>
                  <img src={isVariable ? masterjet_variablespeed_variable_selected : masterjet_variablespeed_variable} />
                </div>
                <div className={"product-wizard-modal-label-text-container"}>
                  <FormattedMessage id="product_wizard_masterjet_variableSpeed_yes" defaultMessage="Ja"/>
                </div>
              </div>
            }
            onClick={()=>{this.setValue("1.0")}}
            disabled={!this.hasVariableSpeed("1.0")}
            active={isVariable}
          >
            <FormattedMessage id="product_wizard_masterjet_variableSpeed_variable" defaultMessage="Einstellbar"/>
          </OptionSelectItem>
          <OptionSelectItem
            labelBottom={
              <div className={"product-wizard-modal-label"}>
                <div className={"product-wizard-modal-label-pic-container"}>
                  <img src={isStatic ? masterjet_variablespeed_static_selected : masterjet_variablespeed_static} />
                </div>
                <div className={"product-wizard-modal-label-text-container"}>
                  <FormattedMessage id="product_wizard_masterjet_variableSpeed_no" defaultMessage="Nein"/>
                </div>
              </div>
            }
            onClick={()=>{this.setValue("0.0")}}
            disabled={!this.hasVariableSpeed("0.0") && !this.hasVariableSpeed("None")}
            active={isStatic}
          >
            <FormattedMessage id="product_wizard_masterjet_variableSpeed_static" defaultMessage="Fest"/>
          </OptionSelectItem>
        </OptionSelect>
        <WizardItem type={"hint"} label={<FormattedMessage id="product_wizard_masterjet_variableSpeed_info_title" defaultMessage="Warum ist das wichtig zu wissen?"/>}/>
        <WizardItem type={"default"} label={
          <>
            <div style={{fontWeight: 'bold', marginBottom: 8}}>
              <FormattedMessage id="product_wizard_masterjet_variableSpeed_info_subtitle" defaultMessage="Wir haben zwei Grundversionen"/>
            </div>
            <FormattedMessage id="product_wizard_masterjet_variableSpeed_info_description"
                              defaultMessage="Die Masterjet erlaubt die Einstellung der Drehzahlleistung, die Masterjet Compact und 1000 ist für Größe und Gewicht optimiert und hat dadurch keine Drehzahleinstellung."/>
          </>
        }/>
        <div className={"product-wizard-masterjet-modal-item-image product-wizard-masterjet-modal-item-image-fullwidth product-wizard-masterjet-modal-item-image--border-false"}>
          <img src={masterjet_variablespeed_chart} />
        </div>
        <div className={"product-wizard-masterjet-modal-item-image product-wizard-masterjet-modal-item-image-fullwidth product-wizard-masterjet-modal-item-image--border-false"}>
          <img src={masterjet_variablespeed_practice} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    calculationParams: getMasterjetConfigurationParams(state) || {}
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(UIActions, dispatch),
  }
}

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalVariableSpeed))
