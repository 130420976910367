import React, {Component} from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import style from 'style';
import ToggleButton from 'react-toggle-button';


const messages = defineMessages({
    toggle_button_active: {
        id: 'toggle_button_active',
        defaultMessage: 'YES'
    },
    toggle_button_inactive: {
        id: 'toggle_button_inactive',
        defaultMessage: 'NO'
    }
});

export default injectIntl((props) => {
    let { thumpColor, className, activeColor, inActiveColor, activeLabel, inActiveLabel, intl, ...otherProps } = props;

    if(!activeLabel) {
        activeLabel = intl.formatMessage(messages.toggle_button_active)
    }
    if(!inActiveLabel) {
        inActiveLabel = intl.formatMessage(messages.toggle_button_inactive)
    }

    const trackStyle = { borderRadius: 0, height: '30px', borderWidth: '0px', width: '72px' }
    const thumbStyle = { borderRadius: 0, height: '30px', borderWidth: '0px', boxShadow: 'none' }
    const containerStyle = { width: '72px' }

    let active = {
        base : activeColor?activeColor:style.primary1,
        hover: activeColor?activeColor:style.primary1
    }

    let inactive = {
        base : inActiveColor?inActiveColor:style.secondary3,
        hover: inActiveColor?inActiveColor:style.secondary3
    }

    let tColor = style.secondary4;
    if(thumpColor){
      tColor = thumpColor;
    }

    let activeThumb = {
        base : tColor,
        hover: tColor
    }

    let inactiveThumb = {
        base : tColor,
        hover: tColor
    }

    return (
        <div className={ className }>
            <ToggleButton
                trackStyle = {trackStyle}
                thumbStyle = {thumbStyle}
                containerStyle = {containerStyle}
                thumbAnimateRange={[0, 54]}
                activeLabelStyle = {{ color: style.black, fontSize: style.fontsize1, fontFamily: 'Roboto, sans-serif' }}
                inactiveLabelStyle = {{ color: style.black, fontSize: style.fontsize1, fontFamily: 'Roboto, sans-serif' }}
                activeLabel={activeLabel}
                inactiveLabel={inActiveLabel}
                colors={{ active, inactive, activeThumb, inactiveThumb }}
                {...otherProps}
            />
        </div>
    );
})
