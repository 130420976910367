import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './button.scss';

import style from 'style';

export default class Button extends Component {

    static propTypes = {
        backgroundColor: PropTypes.string,
        textColor: PropTypes.string,
        onClick: PropTypes.func,
        style: PropTypes.object
    };

    static defaultProps = {
        backgroundColor: style.primary1,
        textColor: style.black,
        onClick: null,
        style: {}
    }

    handleClick = () => {
        if(this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {
        const { caption } = this.props;

        return (
          <div className={`ci-button ${this.props.className}`} style={{ backgroundColor: this.props.backgroundColor, color: this.props.textColor, display: 'inline-block', ...this.props.style }} onClick={this.handleClick}>
              {this.props.children}
          </div>
        );
    }

};
