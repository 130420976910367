import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {GoPlus as PlusIcon} from "react-icons/go";
import {MdClose as CloseIcon} from "react-icons/md";
import AsyncImage from 'layout/asyncImage/AsyncImage';
import './configurationtable.scss';

export default class ConfigurationTable extends Component {

    /*

    columns : [{
        "id" : "pressure", //for internal use
        "heading" : "Max Bar",
        "renderField" : function(item) {
            // render of row in table
        }
    }],
    items : [...],
    allItems : [...],
    renderSelectionItem : function(item) {
        // render of row in selection popup
    },
    addLabel : "Add another hose",
    onAddItemClick : function(),
    onItemDeleteClick : function(),
    enableDeletion : bool
    */

    renderImage(item) {
        if(this.props.getImageUrl) {
            let image = this.props.getImageUrl(item);
            if(image) {
                return <AsyncImage src={image} />
            }else if (this.props.getEmptyImageValue){
              let value = this.props.getEmptyImageValue(item);
              return <div className='empty-image-value'>{value}</div>
            }
        }
        return null;
    }

    render() {
        const {columns, items, addLabel} = this.props;
        const colWidth = `${(1/columns.length).toFixed(2)}%`;

        return (
            <table className="configuration-table">
                <thead>
                    <tr className="configuration-table-heading-row">
                        <For each="col" index="i" of={columns}>
                            <th key={col.id}>{col.heading}</th>
                        </For>
                        <If condition={this.props.enableDeletion}>
                            <th className="configuration-table-heading-delete" key="delete"></th>
                        </If>
                    </tr>
                </thead>
                <tbody>
                    <For each="item" of={items}>
                        <tr key={item.id} className="configuration-table-item-row">
                            <td className={`configuration-table-item-row-image ${this.props.onImageClick?'clickable':''}`} key={`${item.id}_image`} onClick={()=>{
                              if(this.props.onImageClick){
                                this.props.onImageClick(item);
                              }
                            }}>
                              {this.renderImage(item)}
                            </td>

                            <For each="col" index="i" of={columns}>
                                <td key={`${item.id}_${col.id}`}>
                                    <div className="configuration-table-item-row-col-heading">{col.heading}</div>
                                    <div className="configuration-table-item-row-col-content">{col.renderField(item)}</div>
                                </td>
                            </For>
                            <If condition={this.props.enableDeletion}>
                                <td key={`${item.id}_delete`} className={`configuration-table-item-row-delete ${items.length>1?'active':''}`}>
                                    <If condition={items.length>1}>
                                        <CloseIcon className="configuration-table-item-row-delete-icon" onClick={(e) => { e.stopPropagation(); if(this.props.onDeleteItemClick) this.props.onDeleteItemClick(item); }} />
                                    </If>
                                </td>
                            </If>
                        </tr>
                    </For>

                    <If condition={this.props.maxCount==null || this.props.items.length<this.props.maxCount}>
                        <tr key="add" className="configuration-table-add-row" onClick={this.props.onAddItemClick}>
                            <td colSpan={columns.length+(this.props.enableDeletion ? 1 : 0)}>
                                <span className="configuration-table-plus-icon"><PlusIcon /></span>
                                <span className="configuration-table-add-row-label">{addLabel}</span>
                            </td>
                        </tr>
                    </If>
                </tbody>
            </table>
        )
    }
}
