import * as types from './constants';

export function enableSplash(params) {
  return { type: types.ENABLE, payload: {} };
}

export function disableSplash(params) {
  return { type: types.DISABLE, payload: {} };
}

export function loadAndNavigate(language) {
    return { type: types.LOAD_AND_NAVIGATE, payload: {language} };
}
