import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import { connect } from "react-redux";
import _ from 'lodash';

import 'components/layout/productWizard/productWizardMasterjet.scss';
import SectionHeader from "components/layout/sectionHeader/SectionHeader";

import MODAL_TYPE from "components/layout/productWizard/masterJetModals/MasterjetModalTypes.constants";
import {MdClose as CloseIcon} from "react-icons/md";
import WizardProductItem from "components/layout/productWizard/WizardProductItem";
import {formatValueWithUnitAndLabel} from "service/MathService";
import {getMasterJetNozzles} from "storeState/data/selectors";
import {bindActionCreators} from "redux";
import * as UIActions from 'storeState/ui/actions';
import {getMasterjetConfigurationParams} from "storeState/ui/selectors";
import {MASTERJET_CONFIGURATION_PARAMETERS} from "components/views/masterjetConfiguration/MasterjetConfiguration";

const messages = defineMessages({
  product_wizard_masterjet_nozzles: {
    id: 'product_wizard_masterjet_nozzles',
    defaultMessage: 'Düsen'
  },
  product_wizard_masterjet_nozzle_type: {
    id: 'product_wizard_masterjet_nozzle_type',
    defaultMessage: 'Typ'
  },
  product_wizard_masterjet_nozzle_type_changed: {
    id: 'product_wizard_masterjet_nozzle_type_changed',
    defaultMessage: 'Der Düsentyp wurde automatisch angepasst'
  },
  product_wizard_masterjet_attributes_pressure_max: {
    id: 'product_wizard_masterjet_attributes_pressure_max',
    defaultMessage: 'Max. Betriebsdruck'
  },
  product_wizard_masterjet_attributes_flow_max: {
    id: 'product_wizard_masterjet_attributes_flow_max',
    defaultMessage: 'Max. Durchflussmenge'
  },
  product_wizard_masterjet_attributes_hps: {
    id: 'product_wizard_masterjet_attributes_hps',
    defaultMessage: 'HPS-Dichtsytem'
  },
  product_wizard_masterjet_attributes_pc: {
    id: 'product_wizard_masterjet_attributes_pc',
    defaultMessage: 'Leistungsklasse'
  },
  product_wizard_masterjet_attributes_pc_force: {
    id: 'product_wizard_masterjet_attributes_pc_force',
    defaultMessage: 'für Rückstoßkraft'
  },
  product_wizard_masterjet_attributes_nozzle_num: {
    id: 'product_wizard_masterjet_attributes_nozzle_num',
    defaultMessage: 'Anzahl der Düsen'
  },
  product_wizard_masterjet_attributes_nozzle_type: {
    id: 'product_wizard_masterjet_attributes_nozzle_type',
    defaultMessage: 'Düsentypen'
  },
  product_wizard_masterjet_attributes_thread: {
    id: 'product_wizard_masterjet_attributes_thread',
    defaultMessage: 'Anschlussgewinde'
  },
  product_wizard_masterjet_attributes_main_function: {
    id: 'product_wizard_masterjet_attributes_main_function',
    defaultMessage: 'Hauptfunktion'
  },
  product_wizard_masterjet_attributes_yes: {
    id: 'product_wizard_masterjet_attributes_yes',
    defaultMessage: 'Ja'
  },
  product_wizard_masterjet_attributes_no: {
    id: 'product_wizard_masterjet_attributes_no',
    defaultMessage: 'Nein'
  },
  product_wizard_masterjet_function_surface_cleaning_nozzle: {
    id: 'product_wizard_masterjet_function_surface_cleaning_nozzle',
    defaultMessage: 'Flächenreinigungsdüse'
  },
  product_wizard_masterjet_function_hedge: {
    id: 'product_wizard_masterjet_function_hedge',
    defaultMessage: 'Flächenreinigung für komplexe Geometrien'
  },
  product_wizard_masterjet_function_low_flow: {
    id: 'product_wizard_masterjet_function_low_flow',
    defaultMessage: 'Flächenreinigung für Hochdruckaggregate mit geringer Fördermenge'
  }
});

class ModalProductChoose extends Component {

  static propTypes = {
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    filteredProducts: PropTypes.array,
  }

  static defaultProps = {
    filteredProducts: []
  }

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  setValue = (product) => {

    const { formatMessage } = this.props.intl;
    const calculationParams = this.props.calculationParams;

    let newParams = {
      productId: product.id,
      nozzleType: calculationParams.nozzleType,
      nozzleCount: calculationParams.nozzleCount,
      maxNozzleCount: calculationParams.maxNozzleCount,
      nozzleVariant: calculationParams.nozzleVariant,
      performanceClass: calculationParams.performanceClass,
      maxPressure: calculationParams.maxPressure,
      threadType: calculationParams.threadType,
      nozzleDiameter: calculationParams.nozzleDiameter,
      nozzleDiameterIndex: calculationParams.nozzleDiameterIndex,
      variableSpeed: calculationParams.variableSpeed
    };

    if(!newParams.nozzleType || !_.find(product.mjnz, mjnz => mjnz.id === newParams.nozzleType)) {
      newParams.nozzleType = product.mjnz[0].id;

      this.props.uiActions.showInfoMessage({message: formatMessage(messages.product_wizard_masterjet_nozzle_type_changed)})
    }

    const nozAllowed = _.keys(product.dataunits.numnoz_to_alpha_avg.value);
    const maxNozzleCount = parseInt(nozAllowed[nozAllowed.length-1]);
    if(!newParams.nozzleCount) {
      newParams.nozzleCount = maxNozzleCount;
    } else {
      const numNozExists = _.find(nozAllowed, numnoz => parseInt(numnoz) === newParams.nozzleCount);
      if(!numNozExists) {
        newParams.nozzleCount = maxNozzleCount;
      }
    }
    newParams.maxNozzleCount = maxNozzleCount;

    if(!newParams.nozzleVariant || (newParams.nozzleCount && newParams.nozzleCount <= 2) || (newParams.nozzleCount && newParams.nozzleCount % 2 != 0)) {
      newParams.nozzleVariant = 'equal'

      if(newParams.nozzleDiameter) {
        let type0 = parseFloat(newParams.nozzleDiameter[0] ? newParams.nozzleDiameter[0] : "0")
        let type1 = parseFloat(newParams.nozzleDiameter[1] ? newParams.nozzleDiameter[1] : "0")
        if(type0 > type1) {
          newParams.nozzleDiameter = {0: `${type0}`}
          newParams.nozzleDiameterIndex = {0: this.props.calculationParams?.nozzleDiameterIndex[0]}
        } else {
          newParams.nozzleDiameter = {0: `${type1}`}
          newParams.nozzleDiameterIndex = {0: this.props.calculationParams?.nozzleDiameterIndex[1]}
        }
      }
    }

    let pc = parseInt(product.dataunits.PC.value)
    if(isNaN(pc)) pc = 0;
    newParams.performanceClass = pc;
    newParams.maxPressure = parseInt(product.dataunits.p_max.value);
    newParams.threadType = product.dataunits.threadType.value;

    newParams.productType = parseInt(product.dataunits.FR_max.value) <=250 ? 'manual' : 'application'

    newParams.variableSpeed = product.dataunits.variableSpeed.value;

    this.props.uiActions.setCalculationParameters({
      key : MASTERJET_CONFIGURATION_PARAMETERS,
      params : newParams
    });
    this.props.closeModal();
  }

  render() {

    const { formatMessage } = this.props.intl;

    let products = [];
    _.each(this.props.filteredProducts, product => {

      let attributes = [];
      attributes.push(formatValueWithUnitAndLabel({ valueWithUnit: product.dataunits.p_max.value_with_unit, si_unit: "bar", us_unit: "psi", precision: 0 }))
      attributes.push(formatValueWithUnitAndLabel({ valueWithUnit: product.dataunits.QUmax.value_with_unit, si_unit: "l/mins", us_unit: "gal/mins", precision: 0, si_label: "l/min", us_label: "gal/min"  }))
      attributes.push(product.dataunits.PC.symbol + (product.dataunits.PC.value !== 'None' ? parseInt(product.dataunits.PC.value) : ''))
      let nozAllowed = _.map(_.keys(product.dataunits.numnoz_to_alpha_avg.value), val => parseInt(val));
      attributes.push(nozAllowed.join(" / ") + " " + formatMessage(messages.product_wizard_masterjet_nozzles))
      attributes.push(_.map(product.mjnz, mjnz => formatMessage(messages.product_wizard_masterjet_nozzle_type) + ' ' + mjnz.name).join(", "))

      let collapseAttributes = [];
      collapseAttributes.push({
        label: `${formatMessage(messages.product_wizard_masterjet_attributes_pressure_max)}:`,
        value: product.dataunits.p_max?.value_with_unit ? formatValueWithUnitAndLabel({ valueWithUnit: product.dataunits.p_max.value_with_unit, si_unit: "bar", us_unit: "psi", precision: 0 }) : "-"
      });
      collapseAttributes.push({
        label: `${formatMessage(messages.product_wizard_masterjet_attributes_flow_max)}:`,
        value: product.dataunits.QUmax?.value_with_unit ? formatValueWithUnitAndLabel({ valueWithUnit: product.dataunits.QUmax.value_with_unit, si_unit: "l/mins", us_unit: "gal/mins", precision: 0, si_label: "l/min", us_label: "gal/min"  }) : "-"
      });
      // MISSING
      collapseAttributes.push({
        label: `${formatMessage(messages.product_wizard_masterjet_attributes_hps)}:`,
        value: (product.dataunits.hps?.value && product.dataunits.hps?.value !== false) ? (parseInt(product.dataunits.hps.value) === 1 ? formatMessage(messages.product_wizard_masterjet_attributes_yes) : formatMessage(messages.product_wizard_masterjet_attributes_no)) : '-'
      });
      collapseAttributes.push({
        label: `${formatMessage(messages.product_wizard_masterjet_attributes_pc)}:`,
        value: `${product.dataunits.PC.symbol}${product.dataunits.PC.value !== 'None' ? parseInt(product.dataunits.PC.value) : ''} ${formatMessage(messages.product_wizard_masterjet_attributes_pc_force)} ${parseInt(product.dataunits.FR_min.value)}-${parseInt(product.dataunits.FR_max.value)} ${product.dataunits.FR_max.si_unit}`
      });
      collapseAttributes.push({
        label: `${formatMessage(messages.product_wizard_masterjet_attributes_nozzle_num)}:`,
        value: nozAllowed ? nozAllowed.join(", ") : "-"
      });
      collapseAttributes.push({
        label: `${formatMessage(messages.product_wizard_masterjet_attributes_nozzle_type)}:`,
        value: product.mjnz ? _.map(product.mjnz, mjnz => mjnz.name).join(", ") : "-"
      });
      // incomplete? (ex. A für 9/16 UNF LH
      collapseAttributes.push({
        label: `${formatMessage(messages.product_wizard_masterjet_attributes_thread)}:`,
        value: product.dataunits.threadType?.value_with_unit ? product.dataunits.threadType.value_with_unit : '-'
      });
      // MISSING
      collapseAttributes.push({
        label: `${formatMessage(messages.product_wizard_masterjet_attributes_main_function)}:`,
        value: (product.dataunits.main_function?.value && product.dataunits.main_function?.value !== "") ? formatMessage(messages['product_wizard_masterjet_function_' + product.dataunits.main_function.value ]) : '-'
      });

      products.push(
        <WizardProductItem
          key={`product_masterjet_${product.id}`}
          border={true}
          label={product.name}
          image={product.image}
          attributes={attributes}
          collapseAttributes={collapseAttributes}
          onClick={()=>{this.setValue(product)}}
        />
      )
    })

    return (
      <div className="product-wizard-masterjet-modal-item-container">
        <SectionHeader style={{padding: 0}}>
          <div className="product-wizard-masterjet-modal-item-section-row">
            <div className={"product-wizard-masterjet-modal-item-section-row-icon"} onClick={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_PRODUCT)}>
              <CloseIcon></CloseIcon>
            </div>
            <div className={"product-wizard-masterjet-modal-item-section-row-label"}>
              <FormattedMessage id="product_wizard_masterjet_choose" defaultMessage="Wählen Sie direkt Ihren Masterjet-Typ"/>
            </div>
          </div>
        </SectionHeader>
        <If condition={products?.length > 0}>
          {products}
          <Else/>
          <div className={"product-wizard-masterjet-modal-item-section-empty"}>
            <FormattedMessage id="product_wizard_masterjet_not_found" defaultMessage="Keine Produkte für diese Auswahl gefunden"/>
          </div>
        </If>
      </div>
    );
  }
}

function mapStateToProps(state, props) {

  const calculationParams = getMasterjetConfigurationParams(state);

  return {
    calculationParams: calculationParams || {},
    masterJetNozzles: getMasterJetNozzles(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(UIActions, dispatch),
  }
}

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalProductChoose))
