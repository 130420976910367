import * as types from './constants';

export function readDataRequest(params) {
  return { type: types.READ_DATA_REQUEST, payload: {params: params} };
}
export function readDataResponse({ payload, error }) {
  return { type: types.READ_DATA_RESPONSE, payload: payload, error: error };
}

export function setUnit(params) {
  return { type: types.SET_UNIT, payload: { unit: params.unit } };
}

export function deleteUnit(params) {
  return { type: types.DELETE_UNIT, payload: { id: params.id } };
}

export function setDataUnitSystem(params) {
  return { type: types.SET_DATA_UNIT_SYSTEM, payload: { dataUnitSystem: params.dataUnitSystem } };
}

export function setEnableGoogleAnalytics(params) {
  return { type: types.SET_ENABLE_GOOGLE_ANALYTICS, payload: { ...params } };
}