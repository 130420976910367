import APIUrls from 'config';

export default {

  getApiUrl: function (url) {
    return `${APIUrls.apiUrl}/${url ? url : ''}`;
  },

  getUploadUrl: function (url) {
    return `${APIUrls.uploadUrl}/${url ? url : ''}`;
  },
}
