import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {FormattedMessage, injectIntl} from 'react-intl';
import { connect } from "react-redux";

import 'components/layout/productWizard/productWizardMasterjet.scss';
import SectionHeader from "components/layout/sectionHeader/SectionHeader";
import WizardItem from "components/layout/productWizard/WizardItem";

import MODAL_TYPE from "components/layout/productWizard/masterJetModals/MasterjetModalTypes.constants";
import {MdClose as CloseIcon} from "react-icons/md";
import {OptionSelect, OptionSelectItem} from "components/layout/optionSelect/OptionSelect";
import WizardNavigation from "components/layout/productWizard/WizardNavigation";
import * as UIActions from 'storeState/ui/actions';

import {bindActionCreators} from "redux";
import {getMasterjetConfigurationParams} from "storeState/ui/selectors";
import {MASTERJET_CONFIGURATION_PARAMETERS} from "components/views/masterjetConfiguration/MasterjetConfiguration";

import masterjet_spray_gun_250_1 from 'assets/images/masterjet_spray_gun_250_1.png';
import masterjet_spray_gun_250_2 from 'assets/images/masterjet_spray_gun_250_2.png';
import masterjet_product_application_chart from 'assets/images/new/Produkteinsatz-Schaubild.png';
import masterjet_product_application_practice from 'assets/images/new/Produkteinsatz-Praxisbild.png';

class ModalProductApplication extends Component {

  static propTypes = {
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  }

  static defaultProps = {

  }

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  setValue = (value) => {

    this.props.uiActions.setCalculationParameters({
      key : MASTERJET_CONFIGURATION_PARAMETERS,
      params : {
        productType: value
      }
    });
  }

  render() {
    return (
      <div className="product-wizard-masterjet-modal-item-container">
        <SectionHeader style={{padding: 0}}>
          <div className="product-wizard-masterjet-modal-item-section-row">
            <div className={"product-wizard-masterjet-modal-item-section-row-icon"} onClick={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_PRODUCT)}>
              <CloseIcon></CloseIcon>
            </div>
            <div className={"product-wizard-masterjet-modal-item-section-row-label"}>
              <FormattedMessage id="product_wizard_masterjet_product_application" defaultMessage="Produkteinsatz"/>
            </div>
          </div>
        </SectionHeader>

        <WizardNavigation
          onPrev={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_PRODUCT)}
          onNext={()=>this.props.openModal(MODAL_TYPE.MODAL_TYPE_NOZZLE_COUNT)}
          onNextDisabled={!this.props.calculationParams.productType}
        />
        <WizardItem type={"hint"} label={<FormattedMessage id="product_wizard_masterjet_product_application_spray_gun" defaultMessage="Bertieb an einer Hochdruckspritzpistole?"/>}/>
        <OptionSelect border={false} background={"modal"} responsive={false}>
          <OptionSelectItem
             labelBottom={
                <div className={"product-wizard-modal-label"}>
                   <div className={"product-wizard-modal-label-pic-container"}>
                      <img src={masterjet_spray_gun_250_2}/>
                   </div>
                   <div className={"product-wizard-modal-label-text-container"}>
                      <FormattedMessage id="product_wizard_masterjet_product_application_spray_gun_yes"
                                        defaultMessage="Ja"/>
                   </div>
                </div>
            }
            onClick={()=>{this.setValue('manual')}}
            active={this.props.calculationParams?.productType === "manual"}
          >
            {"< 250 N"}
          </OptionSelectItem>
          <OptionSelectItem
             labelBottom={
                <div className={"product-wizard-modal-label"}>
                   <div className={"product-wizard-modal-label-pic-container"}>
                      <img src={masterjet_spray_gun_250_1}/>
                   </div>
                   <div className={"product-wizard-modal-label-text-container"}>
                      <FormattedMessage id="product_wizard_masterjet_product_application_spray_gun_no"
                                        defaultMessage="Nein"/>
                   </div>
                </div>
            }
            onClick={()=>{this.setValue('application')}}
            active={this.props.calculationParams?.productType === "application"}
          >
            {"> 250 N"}
          </OptionSelectItem>
        </OptionSelect>
        <WizardItem type={"hint"} label={<FormattedMessage id="product_wizard_masterjet_product_application_info_title" defaultMessage="Warum ist das wichtig zu wissen?"/>}/>
        <WizardItem type={"default"} label={
          <>
            <div style={{fontWeight: 'bold', marginBottom: 8}}>
              <FormattedMessage id="product_wizard_masterjet_product_application_info_subtitle" defaultMessage="Die Performance Class ist entscheidend"/>
            </div>
            <FormattedMessage id="product_wizard_masterjet_product_application_info_description" defaultMessage="Ab einer gewissen Rückstoßkraft ist es nicht mehr erlaubt Düsen im Handbetrieb zu verwenden, der Maschineneinsatz ist dann Voraussetzungen."/>
          </>
        }/>
        <div className={"product-wizard-masterjet-modal-item-image product-wizard-masterjet-modal-item-image-fullwidth product-wizard-masterjet-modal-item-image--border-false"}>
          <img src={masterjet_product_application_chart} />
        </div>
        <div className={"product-wizard-masterjet-modal-item-image product-wizard-masterjet-modal-item-image-fullwidth product-wizard-masterjet-modal-item-image--border-false"}>
          <img src={masterjet_product_application_practice} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    calculationParams: getMasterjetConfigurationParams(state) || {}
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(UIActions, dispatch),
  }
}

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalProductApplication))
