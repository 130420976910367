import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import { connect } from "react-redux";
import { getNozzles, getDataunitsBySymbol, getTCHNozzles } from "storeState/data/selectors";
import ConfigurationTable from './ConfigurationTable';
import DataUnitTextField from 'layout/dataUnitTextField/DataUnitTextField';
import _ from 'lodash';
import Modal from 'react-modal';
import ButtonBar from 'layout/buttonBar/ButtonBar';
import Button from 'layout/button/Button';
import style from 'style';
import {getUploadUrl} from 'service/ApiService';
import {isIosStatusBar} from 'service/LayoutService';
import AsyncImage from 'layout/asyncImage/AsyncImage';
import ReactDOM from 'react-dom'
import NozzleImage from "components/layout/nozzleImage/NozzleImage";
import colors from 'style'
import './nozzleconfigurationtableMasterjet.scss';
import {MdClose as CloseIcon} from "react-icons/md";
import {GoPlus as PlusIcon} from "react-icons/go";
import {getMasterjetProducts} from "storeState/data/selectors";
import {getMasterjetConfigurationParams} from "storeState/ui/selectors";
import {bindActionCreators} from "redux";
import * as UIActions from 'storeState/ui/actions';
import {MASTERJET_CONFIGURATION_PARAMETERS} from "components/views/masterjetConfiguration/MasterjetConfiguration";
import {getNozzleArrangement} from "service/MasterJetService";

const iosStatusBar = isIosStatusBar();

const MODAL_TYPE_NOZZLE_COUNT = 1;
const MODAL_STEP_TYPE_SELECTION = 2;

class NozzleConfigurationTableMasterjet extends Component {

    static propTypes = {

    }

    static defaultProps = {

    }

    constructor(props) {
        super(props);

        this.state = {
            activeModal: null,
        };
    }

    componentDidMount() {

    }

    handleNozzleTypeModalDialogRequest = () => {
        this.setState({
            activeModal : MODAL_STEP_TYPE_SELECTION,
        })
    }

    handleNozzleCountModalDialogRequest = () => {
        this.setState({
            activeModal : MODAL_TYPE_NOZZLE_COUNT,
        })
    }

    handleModalCancel = () => {
        this.setState({
            activeModal : null,
        })
    }

    handleNozzleCountSelection = (nozzleCount) => {

        let newParams = {
            nozzleVariant: this.props.calculationParams?.nozzleVariant,
            nozzleDiameter: this.props.calculationParams?.nozzleDiameter,
            nozzleDiameterIndex: this.props.calculationParams?.nozzleDiameterIndex,
            nozzleCount: parseInt(nozzleCount)
        };
        if(!newParams.nozzleVariant || (newParams.nozzleCount && newParams.nozzleCount <= 2) || (newParams.nozzleCount && newParams.nozzleCount % 2 != 0)) {
            newParams.nozzleVariant = 'equal'

            if(newParams.nozzleDiameter) {
                let type0 = parseFloat(newParams.nozzleDiameter[0] ? newParams.nozzleDiameter[0] : "0")
                let type1 = parseFloat(newParams.nozzleDiameter[1] ? newParams.nozzleDiameter[1] : "0")
                if(type0 > type1) {
                    newParams.nozzleDiameter = {0: `${type0}`}
                    newParams.nozzleDiameterIndex = {0: this.props.calculationParams?.nozzleDiameterIndex[0]}
                } else {
                    newParams.nozzleDiameter = {0: `${type1}`}
                    newParams.nozzleDiameterIndex = {0: this.props.calculationParams?.nozzleDiameterIndex[1]}
                }
            }
        }

        this.props.uiActions.setCalculationParameters({
            key : MASTERJET_CONFIGURATION_PARAMETERS,
            params : newParams
        });
        this.handleModalCancel();
    }

    handleNozzleTypeSelection = (item) => {
        this.props.uiActions.setCalculationParameters({
            key : MASTERJET_CONFIGURATION_PARAMETERS,
            params : {
                nozzleType: item.id
            }
        });
        this.handleModalCancel();
    }

    getCurrentNozzleCount = () => {
        if(this.props.calculationParams?.nozzleCount) {
            return parseInt(this.props.calculationParams.nozzleCount);
        }
        return 0;
    }

    getCurrentNozzlePlugCount = () => {
        if(this.props.calculationParams?.nozzleCount && this.props.activeMasterJet) {
            const maxNozzle = parseInt(_.max(_.keys(this.props.activeMasterJet.dataunits.numnoz_to_alpha_avg.value)));
            return maxNozzle - parseInt(this.props.calculationParams.nozzleCount);
        }
        return 0;
    }

    getCurrentNozzelTypeName = () => {
        if(this.props.calculationParams?.nozzleType) {
            const activeNozzle = _.find(this.props.nozzleTypes, nozzleType => nozzleType.id === this.props.calculationParams?.nozzleType)
            if(activeNozzle) {
                return activeNozzle.name;
            }
        }
        return "-";
    }

    getNozzleImage = () => {
        const nozzleCount = this.getCurrentNozzleCount()
        const maxNozzle = this.getMaxNozzleCount()
        const label = this.getCurrentNozzelTypeName();
        const nozzleArrangement = getNozzleArrangement(nozzleCount, maxNozzle);

        let nozzleItems = [];

        let nozIndex = 0;
        for(let i = 0; i < maxNozzle; i++) {
            if(nozzleArrangement.indexOf(i) >= 0) {
                let type = 'green';
                if(this.props.calculationParams?.nozzleVariant !== "equal") {
                    type = nozIndex % 2 == 1 ? 'red' : 'green'
                }
                nozzleItems.push({type: type, label: label})
                nozIndex++;
            } else {
                nozzleItems.push({type: 'placeholder_inactive', label: label})
            }
        }

        const pc = this.props.activeMasterJet ? this.props.activeMasterJet.dataunits.PC.value : 0;

        return (
          <>
              <div className={"configuration-table-masterjet-mobile"}>
                <NozzleImage items={nozzleItems} pc={pc} />
              </div>
              <div className={"configuration-table-masterjet-desktop"}>
                <NozzleImage size={100} items={nozzleItems} pc={pc} />
              </div>
          </>
        )
    }

    getNozzleCountImage = (nozzleCount) => {
        const maxNozzle = this.getMaxNozzleCount();
        const nozzleArrangement = getNozzleArrangement(nozzleCount, maxNozzle);
        let nozzleItems = [];
        for(let i = 0; i < maxNozzle; i++) {
            if(nozzleArrangement.indexOf(i) >= 0) {
                nozzleItems.push({type: 'placeholder_active'})
            } else {
                nozzleItems.push({type: 'placeholder_inactive'})
            }
        }

        return (
          <NozzleImage size={40} style={{border: `1px solid ${colors.black}`}} items={nozzleItems} />
        )
    }

    getMaxNozzleCount = () => {
        if(this.props.activeMasterJet) {
            return _.max(_.keys(this.props.activeMasterJet.dataunits.numnoz_to_alpha_avg.value));
        }
        return 0;
    }

    render() {
        return (
            <div>

                <table className="configuration-table-masterjet">
                    <thead>
                    <tr className="configuration-table-masterjet-heading-row">
                        <th><FormattedMessage id="nozzle_configuration_masterjet_table_image" defaultMessage="Nozzle Image" /></th>
                        <th><FormattedMessage id="nozzle_configuration_masterjet_table_type" defaultMessage="Nozzle type" /></th>
                        <th><FormattedMessage id="nozzle_configuration_masterjet_table_amount" defaultMessage="Amount" /></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className="configuration-table-masterjet-item-row">
                        <td rowSpan={2} className="configuration-table-masterjet-item-row-image">
                            {this.getNozzleImage()}
                        </td>
                        <th className={"configuration-table-masterjet-desktop configuration-table-masterjet-label"}><FormattedMessage id="nozzle_configuration_masterjet_table_type" defaultMessage="Nozzle type" /></th>
                        <td className={`configuration-table-masterjet-item-row--clickable configuration-table-masterjet-noz-type`} onClick={()=>this.handleNozzleTypeModalDialogRequest()}>{this.getCurrentNozzelTypeName()}</td>
                        <td className={"configuration-table-masterjet-item-row--clickable configuration-table-masterjet-plug desktop"} onClick={()=>this.handleNozzleTypeModalDialogRequest()}><FormattedMessage id="nozzle_configuration_masterjet_table_plug" defaultMessage="Plug" /></td>
                        <td className={`configuration-table-masterjet-item-row--clickable configuration-table-masterjet-noz-count mobile`} onClick={()=>this.handleNozzleCountModalDialogRequest()}>{this.getCurrentNozzleCount()}</td>
                    </tr>
                    <tr className="configuration-table-masterjet-item-row">
                        <th className={"configuration-table-masterjet-item-row--clickable configuration-table-masterjet-desktop configuration-table-masterjet-label"} onClick={()=>this.handleNozzleTypeModalDialogRequest()}><FormattedMessage id="nozzle_configuration_masterjet_table_amount" defaultMessage="Amount" /></th>
                        <td className={"configuration-table-masterjet-item-row--clickable configuration-table-masterjet-plug mobile"} onClick={()=>this.handleNozzleTypeModalDialogRequest()}><FormattedMessage id="nozzle_configuration_masterjet_table_plug" defaultMessage="Plug" /></td>
                        <td className={`configuration-table-masterjet-item-row--clickable configuration-table-masterjet-noz-count desktop`} onClick={()=>this.handleNozzleCountModalDialogRequest()}>{this.getCurrentNozzleCount()}</td>
                        <td className={"configuration-table-masterjet-item-row--clickable configuration-table-masterjet-plug-count"} onClick={()=>this.handleNozzleCountModalDialogRequest()}>{this.getCurrentNozzlePlugCount()}</td>
                    </tr>
                    </tbody>
                </table>

                <Modal isOpen={ this.state.activeModal != null } className={`ci-modal ci-modal-nopadding nozzle-configuration-masterjet-modal-scroller ${iosStatusBar?'nozzle-configuration-masterjet-modal-ios-status-bar':''}`} overlayClassName="ci-modal-overlay" onRequestClose={this.handleModalCancel}>
                    <Choose>
                        <When condition={ this.state.activeModal == MODAL_STEP_TYPE_SELECTION }>
                            <div className="nozzle-configuration-table-modal-item-container" ref={ref=>{
                                if(ref){
                                    let domNode = ReactDOM.findDOMNode(ref);
                                    let scrollDOMContainer = domNode.parentNode;
                                    let selectedIndex = _.findIndex(this.props.nozzleTypes, item => {
                                        return this.props.calculationParams?.nozzleType && item.id == this.props.calculationParams?.nozzleType;
                                    });
                                    if(selectedIndex > 0){
                                        let domNodeHeight = domNode.childNodes[0].clientHeight;
                                        scrollDOMContainer.scrollTop = Math.max(0,(selectedIndex * (domNodeHeight+2)) + (domNodeHeight+2)/2 - scrollDOMContainer.clientHeight/2)
                                    }
                                }
                            }}>
                                <For each="item" of={this.props.nozzleTypes} index="index">
                                    <div key="item{index}" className={`nozzle-configuration-table-modal-item ${this.props.calculationParams?.nozzleType?.id == item.id?'selected':''}`} onClick={() => this.handleNozzleTypeSelection(item)}>
                                        <span className="nozzle-configuration-table-modal-item-image">
                                            <AsyncImage src={item.image} />
                                        </span>
                                        <span className="nozzle-configuration-table-modal-item-name">
                                            {item.dataunits["jet_type"] && item.dataunits["jet_type"].value=='flat'?'▲':'●'}{'\t'}{item.name} Max: {Math.round(item.dataunits["pNZmax"].value)} bar
                                        </span>
                                    </div>
                                    <div className='nozzle-configuration-table-row-spacer' />
                                </For>
                            </div>
                        </When>
                        <When condition={ this.state.activeModal == MODAL_TYPE_NOZZLE_COUNT }>
                            <div className="nozzle-configuration-masterjet-table-modal-item-container">
                                <If condition={this.props.activeMasterJet}>
                                    <For each="value" of={_.keys(this.props.activeMasterJet.dataunits.numnoz_to_alpha_avg.value)} index="index">
                                        <div className={`nozzle-configuration-masterjet-table-modal-item ${this.props.calculationParams?.nozzleCount == value?'selected':''}`} onClick={() => this.handleNozzleCountSelection(value)}>
                                            <span className="nozzle-configuration-masterjet-table-modal-item-image">
                                                {this.getNozzleCountImage(value)}
                                            </span>
                                                    <span className="nozzle-configuration-masterjet-table-modal-item-name">
                                                {parseInt(value)} <FormattedMessage id="nozzle_configuration_masterjet_nozzles" defaultMessage="Nozzles" /> + {_.max(_.keys(this.props.activeMasterJet.dataunits.numnoz_to_alpha_avg.value)) - parseInt(value)} <FormattedMessage id="nozzle_configuration_masterjet_plugs" defaultMessage="Plugs" />
                                            </span>
                                        </div>
                                    </For>
                                </If>
                                <div className='nozzle-configuration-masterjet-table-row-spacer' />
                            </div>
                        </When>
                    </Choose>
                </Modal>
            </div>
        )
    }
}

// <input step="0.01" type="number" value={customInputValue} className="nozzle-configuration-masterjet-table-modal-custom-type-input" onChange={this.handleCustomTypeInputChange} />

function mapStateToProps(state, props) {

    const calculationParams = getMasterjetConfigurationParams(state);
    const masterjetProducts = getMasterjetProducts(state);

    let nozzleTypes = [];
    let activeMasterJet = null;
    if(calculationParams?.productId) {
        activeMasterJet = _.find(masterjetProducts, product => product.id === calculationParams.productId);
        if(activeMasterJet) {
            nozzleTypes = activeMasterJet.mjnz;
        }
    }

    return {
        activeMasterJet: activeMasterJet,
        masterjetProducts: masterjetProducts,
        calculationParams: calculationParams,
        nozzleTypes: nozzleTypes,
        dataunits: getDataunitsBySymbol(state)
    }
}

function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(UIActions, dispatch),
    }
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(NozzleConfigurationTableMasterjet))
