import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {Link} from 'react-router-dom';
import './tabnavigation.scss';

export class TabNavigation extends Component {

    static propTypes = {
        children : PropTypes.array
    }

    render() {
        return (
            <div className={`tab-navigation ${!this.props.children || this.props.children.length==0?'no-elems':''}`}>
                {this.props.children}
            </div>
        )
    }

}

export class Tab extends Component {

    static propTypes = {
        children : PropTypes.any,
        active: PropTypes.bool,
        to: PropTypes.string
    }

    render() {
        return (
            <Link className={`tab-navigation-item ${this.props.active ? 'tab-navigation-item-active' : ''}`} to={this.props.to}>{this.props.children}</Link>
        );
    }
}
