import React, { Component, PropTypes } from 'react';
import {Link} from 'react-router-dom';
//import Radium from 'radium';
import ReactDOM from 'react-dom';

import { injectIntl } from 'react-intl';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getActiveMenuItem } from "storeState/ui/selectors";
//const RadiumLink = Radium(Link);

class HeaderMenuItem extends Component {

  render(){
    let { to, menuItemId, children, activeMenuItem } = this.props;
    return (
        <Link
          className={`header-menu-item ${menuItemId == activeMenuItem ? 'header-menu-item-active' : ''}`}
          to={to}
        >
          {children}
        </Link>
    );
  }
}

function mapStateToProps(state, props) {
    return {
        activeMenuItem: getActiveMenuItem(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //uiActions: bindActionCreators(UIActions, dispatch),
    }
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderMenuItem))
