import { combineReducers } from "redux";
import { all } from 'redux-saga/effects';

import splash from "./splash";
import ui from "./ui";
import data from "./data";
import news from "./news";

import {getLocalPersistenceMiddleware, localPersistenceReducer, LOAD_REDUX_STORE_FROM_LOCAL_STORAGE} from './persistence';

export const persistenceKeys = [
    ...data.localPersistence.map(x => ["data",...x]),
    ...news.localPersistence.map(x => ["news",...x]),
    ...ui.localPersistence.map(x => ["ui",...x]),
];

export const localPersistenceMiddleware = getLocalPersistenceMiddleware(persistenceKeys);


/***
 * Combination of all reducers
 */

const combinedReducer = combineReducers({
  splash: splash.reducer,
  ui: ui.reducer,
  data: data.reducer,
  news: news.reducer,
  localPersistence : localPersistenceReducer
});

export const rootReducer = (state,action) => {
    if(action.type == LOAD_REDUX_STORE_FROM_LOCAL_STORAGE) {
        return localPersistenceReducer(state, action);
    } else {
        return combinedReducer(state, action);
    }
}

export function* rootSaga() {
  yield all([
    data.saga(),
    news.saga(),
    ui.saga(),
    splash.saga()
  ]);
}
