import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import '@fontsource/roboto'
import './index.scss';
import 'bootstrap/scss/bootstrap.scss'
import { enableLocalStoragePersistenceMiddleware, loadReduxStoreFromLocalStorage } from 'storeState/persistence';
import { persistenceKeys } from 'storeState';
import { isCordova, isAndroid, isIos } from 'service/LayoutService';
import style from 'style';
import store from 'initStore';
import('intl')
    .then(() => import('intl/locale-data/jsonp/en'))
    .then(() => import('intl/locale-data/jsonp/de'))
    .then(() => import('intl/locale-data/jsonp/bg'))
    .then(() => import('intl/locale-data/jsonp/da'))
    .then(() => import('intl/locale-data/jsonp/el'))
    .then(() => import('intl/locale-data/jsonp/es'))
    .then(() => import('intl/locale-data/jsonp/fa'))
    .then(() => import('intl/locale-data/jsonp/fi'))
    .then(() => import('intl/locale-data/jsonp/fr'))
    .then(() => import('intl/locale-data/jsonp/he'))
    .then(() => import('intl/locale-data/jsonp/hi'))
    .then(() => import('intl/locale-data/jsonp/hu'))
    .then(() => import('intl/locale-data/jsonp/it'))
    .then(() => import('intl/locale-data/jsonp/ko'))
    .then(() => import('intl/locale-data/jsonp/nl'))
    .then(() => import('intl/locale-data/jsonp/nn'))
    .then(() => import('intl/locale-data/jsonp/pl'))
    .then(() => import('intl/locale-data/jsonp/pt'))
    .then(() => import('intl/locale-data/jsonp/rm'))
    .then(() => import('intl/locale-data/jsonp/ru'))
    .then(() => import('intl/locale-data/jsonp/sv'))
    .then(() => import('intl/locale-data/jsonp/th'))
    .then(() => import('intl/locale-data/jsonp/tr'))
    .then(() => import('intl/locale-data/jsonp/uk'))
    .then(() => import('intl/locale-data/jsonp/vi'))
    .then(() => import('intl/locale-data/jsonp/zh'))
    .then(() => runAppAfterIntl());

const cordova = isCordova();

const disableZoomForCordova = () => {
    if(cordova) {
        var viewports = document.head.querySelectorAll('meta[name=viewport]'),
            i;
        for (i = 0; i < viewports.length; i++) {
            try {
                document.head.removeChild(viewports[i]);
            } catch (ex) {
            }
        }

        var meta = document.createElement('meta');
        meta.setAttribute('name', 'viewport');
        meta.setAttribute('content', 'initial-scale=1.0,width=device-width,user-scalable=no,viewport-fit=cover');
        document.head.appendChild(meta);
    }
}

const closeKeyboard = () => {
    let activeElement = document.activeElement.tagName
    if(activeElement == "TEXTAREA" || activeElement == "INPUT") {
        document.activeElement.blur()
    } else {
        document.body.focus();
    }

    if(isIos()) {
        window.scrollTo(0, 0)
    }
    document.body.style.width = window.innerWidth;
    document.body.style.height = window.innerHeight;
}

const renderApp = () => {
  document.onkeyup=function(e) {
    if(e.which == 13){
      closeKeyboard();
      return false;
    } else {
        //alert(e.which);
    }
  }

  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(<App />);

  store.dispatch(enableLocalStoragePersistenceMiddleware());
  let loadAction = loadReduxStoreFromLocalStorage({persistenceKeys});
  if(loadAction) {
    store.dispatch(loadAction);
  }
}

function runMyApp() {
  disableZoomForCordova();
  if(cordova){
    document.addEventListener('deviceready', function() {
      if(isAndroid()) {
        // eslint-disable-next-line no-undef
        StatusBar.backgroundColorByHexString(style.secondary2);
      }
      renderApp();
    }, false);
  }else{
    renderApp();
  }
}


function runAppAfterIntl() {


    if (!global.Intl) {
        /* eslint-disable no-restricted-properties */
        require.ensure([
            'intl',
            'intl/locale-data/jsonp/en.js',
            'intl/locale-data/jsonp/de.js'
        ], function (require) {
            require('intl');
            require('intl/locale-data/jsonp/en.js');
            require('intl/locale-data/jsonp/de.js');
            runMyApp()
        });
    } else {
        runMyApp()
    }
}
runAppAfterIntl();
