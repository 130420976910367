import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import DataUnitLabel from 'layout/dataUnitLabel/DataUnitLabel';
import DataUnitTextField from 'layout/dataUnitTextField/DataUnitTextField';
import {getRPMLable} from 'service/LableService';

const messages = defineMessages({
  unit_property_model: {
  	id: 'unit_property_model',
  	defaultMessage: 'Model'
  },
  unit_property_max_op_pressure: {
  	id: 'unit_property_max_op_pressure',
  	defaultMessage: 'Max Op. Pressure'
  },
  unit_property_rpm_min: {
  	id: 'unit_property_rpm_min',
  	defaultMessage: 'R.P.M. min'
  },
  unit_property_rpm_max: {
  	id: 'unit_property_rpm_max',
  	defaultMessage: 'R.P.M. max'
  },
  unit_property_flow_rate_at_max_rpm: {
  	id: 'unit_property_flow_rate_at_max_rpm',
  	defaultMessage: 'Flow rate at max R.P.M.'
  }
});

class UnitItemProperties extends Component {

    static propTypes = {
        onUnitChange: PropTypes.func
    };

    handlePropertyChange = (field,value) => {
        if(this.props.unit[field] != value) {
            this.props.unit[field] = value;

            if (this.props.onUnitChange) {
                this.props.onUnitChange(this.props.unit);
            }
        }
    }

    handleNameChange = (ev) => this.handlePropertyChange("name", ev.target.value);
    handleMaxPressureChange = (value) => this.handlePropertyChange("maxPressure", value);
    handleRpmMinChange = (value) => this.handlePropertyChange("rpmMin", value);
    handleRpmMaxChange = (value) => this.handlePropertyChange("rpmMax", value);
    handleMaxFlowrateChange = (value) => this.handlePropertyChange("maxFlowrate", value);

    render() {
        const { unit } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div className="properties-unit-item-properties">
                <div className="properties-unit-item-properties-item">
                    <div className="properties-unit-item-properties-label">
                        <DataUnitLabel si={formatMessage(messages.unit_property_model)} />
                    </div>
                    <div className="properties-unit-item-properties-input properties-unit-item-properties-input-name">
                        <input value={unit.name} onChange={this.handleNameChange} />
                    </div>
                </div>
                <div className="properties-unit-item-properties-item">
                    <div className="properties-unit-item-properties-label">
                        <DataUnitLabel si={formatMessage(messages.unit_property_max_op_pressure)} />
                    </div>
                    <div className="properties-unit-item-properties-input">
                        <DataUnitTextField align="left" value={unit.maxPressure} si="bar" us="psi" precision={2} editable={true} onChange={this.handleMaxPressureChange} />
                    </div>
                </div>
                <div className="properties-unit-item-properties-item">
                    <div className="properties-unit-item-properties-label">
                        <DataUnitLabel si={formatMessage(messages.unit_property_rpm_min)} />
                    </div>
                    <div className="properties-unit-item-properties-input">
                        <DataUnitTextField align="left" value={unit.rpmMin} si="mins^-1" customLabel={getRPMLable()} precision={2} editable={true} onChange={this.handleRpmMinChange} />
                    </div>
                </div>
                <div className="properties-unit-item-properties-item">
                    <div className="properties-unit-item-properties-label">
                        <DataUnitLabel si={formatMessage(messages.unit_property_rpm_max)} />
                    </div>
                    <div className="properties-unit-item-properties-input">
                        <DataUnitTextField align="left" value={unit.rpmMax} si="mins^-1" customLabel={getRPMLable()} precision={2} editable={true} onChange={this.handleRpmMaxChange} />
                    </div>
                </div>
                <div className="properties-unit-item-properties-item">
                    <div className="properties-unit-item-properties-label">
                        <DataUnitLabel si={formatMessage(messages.unit_property_flow_rate_at_max_rpm)} />
                    </div>
                    <div className="properties-unit-item-properties-input">
                        <DataUnitTextField align="left" value={unit.maxFlowrate} si="l/mins" siLabel="l/min" us="gal/mins" usLabel="gal/min" precision={2} editable={true} onChange={this.handleMaxFlowrateChange} />
                    </div>
                </div>
            </div>
        )
    }

}

export default injectIntl(UnitItemProperties)
