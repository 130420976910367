import React, { Component } from 'react';
import {BrowserRouter, HashRouter, useLocation} from 'react-router-dom';
import {Router, Route, Routes, Navigate} from "react-router";
import {IntlProvider} from 'react-intl';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UIActions from 'storeState/ui/actions';
import {getLocale} from 'storeState/ui/selectors';
import { isCordova, isAndroid, isIos } from 'service/LayoutService';

import Landing from 'views/landing/Landing';
import Impress from 'views/impress/Impress';
import NozzleCalculation from 'views/nozzleCalculation/NozzleCalculation';
import MasterjetConfiguration from 'views/masterjetConfiguration/MasterjetConfiguration';
import Conversion from 'views/conversion/Conversion';
import NozzleDiameter from 'views/nozzleDiameter/NozzleDiameter';
import Flowrate from 'views/flowrate/Flowrate';
import TankCleaningUnit from 'views/tankCleaningUnit/TankCleaningUnit';
import News from 'views/news/News';
import Contact from 'views/contact/Contact';
import Disclaimer from 'views/disclaimer/Disclaimer';

import Properties from 'views/properties/Properties';

import messages from './locales/index';
import RouteContainer from "./RouteContainer";
const cordova = isCordova();

let currentLocale = 'en';

var language = navigator.languages && navigator.languages[0] ||
    navigator.language ||
    navigator.userLanguage;

if(messages[language] != null){
  currentLocale = language;
}else if(messages[language.split('-')[0]] != null){
  currentLocale = language.split('-')[0];
}

class AppContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  onEnter = (location) => {

  }

  onLeave = () => {
    this.props.uiActions.setInitialMenuOpened(false);
  }

  getIntlProviderData = () => {

    const {locale} = this.props;
    let useLocale = locale;
    if(!useLocale){
      useLocale = currentLocale;
      this.props.uiActions.setLocale(useLocale, true);
    }

    return {
      locale: useLocale,
      messages: messages[useLocale],
    };
  }

  componentDidMount() {
  }

  onUpdate = (prevLocation, location) => {

    if(prevLocation.pathname && prevLocation.pathname !== "/") {
      this.onLeave(prevLocation);
    }
    if(location.pathname) {
      this.onEnter(location);
    }

    window.scrollTo(0, 0);
  }

  renderRoute = (path, Component) => {

    const RouteElem = () => {
      const location = useLocation();
      return (
        <RouteContainer location={location} onUpdate={this.onUpdate}><Component /></RouteContainer>
      )
    }

    return (
      <Route exact path={path} element={<RouteElem />} />
    )
  }

  render() {

    const intlData = this.getIntlProviderData();
    const RouterComponent = cordova ? HashRouter : BrowserRouter;

    return (
        <IntlProvider {...intlData} key={`lang_${intlData.locale}`} onError={onIntlError}>
          <RouterComponent>
            <Routes>
              {this.renderRoute("/", Landing)}
              {this.renderRoute("/nozzlecalculation", NozzleCalculation)}
              {this.renderRoute("/masterjetconfiguration", MasterjetConfiguration)}
              {this.renderRoute("/conversion", Conversion)}
              {this.renderRoute("/nozzlediameter", NozzleDiameter)}
              {this.renderRoute("/flowrate", Flowrate)}
              {this.renderRoute("/tankcleaningunit", TankCleaningUnit)}
              {this.renderRoute("/properties", Properties)}
              {this.renderRoute("/landing", Landing)}
              {this.renderRoute("/impress", Impress)}
              {this.renderRoute("/news", News)}
              {this.renderRoute("/contact", Contact)}
              {this.renderRoute("/disclaimer", Disclaimer)}
              <Route path={"*"} element={<Navigate to='/' replace />} />
            </Routes>
          </RouterComponent>
        </IntlProvider>
    );
    //
  }
}

function onIntlError(e) {
  // console.log("INTL ERROR", e.message);
}

function mapStateToProps(state, props) {
  return {
    locale: getLocale(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(UIActions, dispatch),
  }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppContainer)
