import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {FormattedMessage, injectIntl} from 'react-intl';
import { connect } from "react-redux";
import { getHoses, getDataunitsBySymbol } from "storeState/data/selectors";
import ConfigurationTable from './ConfigurationTable';
import DataUnitTextField from 'layout/dataUnitTextField/DataUnitTextField';
import _ from 'lodash';
import Modal from 'react-modal';
import ButtonBar from 'layout/buttonBar/ButtonBar';
import Button from 'layout/button/Button';
import style from 'style';
import {getUploadUrl} from 'service/ApiService';
import ReactDOM from 'react-dom'

import './hoseconfigurationtable.scss';

const MODAL_STEP_TYPE_SELECTION = 1;
const MODAL_STEP_CUSTOM_TYPE = 2;

class HoseConfigurationTable extends Component {

    static propTypes = {
        onConfigurationChange : PropTypes.func,
        maxCount : PropTypes.number,
        enableDeletion : PropTypes.bool,
        items : PropTypes.array
    }

    constructor(props) {
        super(props);
        this.state = {
            items : props.items ? props.items : [],
            hoseTypeSelectionModal: null,
            hoseTypeSelectionModalItem : null,
            customInputValue: 0.75, //value of input field for custom hose type
            renderObj: Math.random(),
        };
    }

    emitConfigurationChange = () => {
        if(this.props.onConfigurationChange) {
            let hoses = [];
            _.each(this.state.items, (hose) => {
                hoses.push({
                    "id" : hose.id,
                    "amount" : hose.amount,
                    "length" : hose.length,
                    "diameter" : hose.diameter,
                    "type" : hose.type,
                    "CL1" : hose.CL1,
                    "CL2" : hose.CL2
                });
            })
            this.props.onConfigurationChange(hoses);
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.selectDefaultValues(this.props);
        })
    }

    componentDidUpdate(prevProps, prevState) {

        setTimeout(() => {
            this.selectDefaultValues(this.props)
        })

        if(!_.isEqual(this.props.items, prevProps.items)) {
            console.log("setting nozzle configuration state", this.props.items)
            this.setState({
                items: this.props.items,
                renderObj: Math.random()
            })
        }

        if(prevState!==this.state) {
            this.emitConfigurationChange()
        }
    }

    getDefaultParams(props) {
        return {
            "id" : this.state.items.length==0 ? 0 : _.max(_.map(this.state.items,'id'))+1,
            "length": "20m",
            "amount": "1",
            "type": props ? props.hoseTypes[0] : this.props.hoseTypes[0],
            "diameter": props ? props.hoseTypes[0].dataunits["di"].value_with_unit : this.props.hoseTypes[0].dataunits["di"].value_with_unit,
            "CL1": props ? props.hoseTypes[0].dataunits["CL1"].value_with_unit : this.props.hoseTypes[0].dataunits["CL1"].value_with_unit,
            "CL2": props ? props.hoseTypes[0].dataunits["CL2"].value_with_unit : this.props.hoseTypes[0].dataunits["CL2"].value_with_unit,
        }
    }

    selectDefaultValues = (props) => {
        if(this.state.items.length==0 && props.hoseTypes && props.hoseTypes.length > 0) {
            this.addItem(this.getDefaultParams(props))
        }
    }

    addItem = (params) => {
        console.log("adding hose", params)
        const ids = _.map(this.state.items,(x) => x.id);;
        const id = (_.max(ids) || 0) + 1;
        const newItem = {
            id,
            ...params
        }
        this.setState({
            items : [
                ...this.state.items,
                newItem
            ]
        })
    }

    renderAmount = (item) => {
        return <DataUnitTextField align='center-auto' renderObj={this.state.renderObj} value={item.amount} decimalPlaces={0} editable={true} onChange={(value) => this.handleAmountChange(item,value)} />
    }

    renderDiameter = (item) => {
        return (
            <div className='config-modal' onClick={()=>this.handleHoseTypeModalDialogRequest(item)}>
                {item.type.name}
            </div>
        )
    }

    renderLength = (item) => {
        return <DataUnitTextField align='center-auto' renderObj={this.state.renderObj} value={item.length} si="m" us="ft" decimalPlaces={3} editable={true} onChange={(value) => this.handleLengthChange(item,value)} />
    }

    handleHoseTypeModalDialogRequest = (item) => {
        this.setState({
            hoseTypeSelectionModal : MODAL_STEP_TYPE_SELECTION,
            hoseTypeSelectionModalItem : item
        })
    }

    handleSelectionModalCancel = () => {
        this.setState({
            hoseTypeSelectionModal : null,
            hoseTypeSelectionModalItem: null
        })
    }

    handleHoseTypeSelection = (hoseType) => {
        this.state.hoseTypeSelectionModalItem.type = hoseType;
        this.state.hoseTypeSelectionModalItem.diameter = hoseType.dataunits["di"]["value_with_unit"];
        this.state.hoseTypeSelectionModalItem.CL1 = hoseType.dataunits["CL1"]["value_with_unit"];
        this.state.hoseTypeSelectionModalItem.CL2 = hoseType.dataunits["CL2"]["value_with_unit"];
        this.setState({
            items: [
                ...this.state.items
            ],
            hoseTypeSelectionModal: null,
            hoseTypeSelectionModalItem: null
        })
    }

    handleHoseTypeCustomSelection = () => {
        this.setState({
            hoseTypeSelectionModal : MODAL_STEP_CUSTOM_TYPE
        })
    }

    handleHoseTypeCustomSelectionOK = () => {
        this.state.hoseTypeSelectionModalItem.type = new Number(this.state.customInputValue);
        this.state.hoseTypeSelectionModalItem.diameter = new Number(this.state.customInputValue);
        this.setState({
            items: [
                ...this.state.items
            ],
            hoseTypeSelectionModal: null,
            hoseTypeSelectionModalItem: null
        })
    }

    handleCustomTypeInputChange = (v) => {
        this.setState({
            customInputValue : v
        });
    }

    handleAddItemClick = () => {
        let params = this.getDefaultParams(this.props);
        if(this.state.items.length>0) {
            params = Object.assign(params, _.omit(_.clone(this.state.items[this.state.items.length-1]), "id"));
        }
        this.addItem(params)
    }

    handleDeleteItemClick = (item) => {
        this.setState({
            items: _.filter(this.state.items,(i)=>i.id!=item.id)
        })
    }

    handleLengthChange = (item, value) => {
        item.length = value;
        this.setState({
            items: [
                ...this.state.items
            ],
        })
    }

    handleAmountChange = (item, value) => {
        item.amount = value;
        this.setState({
            items: [
                ...this.state.items
            ],
        })
    }

    getImageUrl = (item) => {
        return item.type && item.type.image ? item.type.image : null;
    }

    render() {
        const { items, hoseTypeSelectionModal, customInputValue, hoseTypeSelectionModalItem } = this.state;
        const { hoseTypes } = this.props;
        const columns = [
            {
                "id" : "amount",
                "heading" : <FormattedMessage id="hose_configuration_table_amount" defaultMessage="AMOUNT" />,
                "renderField" : this.renderAmount
            },
            {
                "id" : "length",
                "heading" : <FormattedMessage id="hose_configuration_table_length" defaultMessage="LENGTH" />,
                "renderField" : this.renderLength
            },
            {
                "id": "diameter",
                "heading": <FormattedMessage id="hose_configuration_table_type" defaultMessage="TYPE"/>,
                "renderField": this.renderDiameter
            }
        ];

        let sortedHoseTypes = _.sortBy(hoseTypes, (type)=>{
          let vals = _.split(type.name, ' ');
          return parseInt(vals[vals.length-1]);
        });
console.log("hoseTypeSelectionModalItem",hoseTypeSelectionModalItem, sortedHoseTypes)
        return (
            <div>
                <ConfigurationTable
                    columns = {columns}
                    items = {items}
                    allItems = {hoseTypes}
                    addLabel = {<FormattedMessage id="hose_configuration_table_add" defaultMessage="Add another hose" />}
                    onAddItemClick = {this.handleAddItemClick}
                    onDeleteItemClick = {this.handleDeleteItemClick}
                    maxCount = {this.props.maxCount}
                    enableDeletion = {this.props.enableDeletion}
                    getImageUrl = {this.getImageUrl}
                />
                <Modal isOpen={ hoseTypeSelectionModal!=null } className="ci-modal ci-modal-nopadding ci-modal-scroller" overlayClassName="ci-modal-overlay" onRequestClose={this.handleSelectionModalCancel}>
                    <Choose>
                        <When condition={ hoseTypeSelectionModal==MODAL_STEP_TYPE_SELECTION }>
                            <div className="hose-configuration-table-modal-item-container" ref={ref=>{
                                if(ref){
                                  let domNode = ReactDOM.findDOMNode(ref);
                                  let scrollDOMContainer = domNode.parentNode;
                                  let selectedIndex = _.findIndex(sortedHoseTypes, item => {
                                    return hoseTypeSelectionModalItem.type && hoseTypeSelectionModalItem.type.id==item.id;
                                  });
                                  if(selectedIndex > 0){
                                    let domNodeHeight = domNode.childNodes[0].clientHeight;
                                    scrollDOMContainer.scrollTop = Math.max(0,(selectedIndex * (domNodeHeight+2)) + (domNodeHeight+2)/2 - scrollDOMContainer.clientHeight/2)
                                  }
                                }
                              }}>
                                <For each="item" of={sortedHoseTypes}>
                                    <div key={item.id} className={`hose-configuration-table-modal-item ${hoseTypeSelectionModalItem && hoseTypeSelectionModalItem.type && hoseTypeSelectionModalItem.type.id == item.id?'selected':''}`} onClick={() => this.handleHoseTypeSelection(item)}>
                                      {item.name}<span dangerouslySetInnerHTML={{__html:'&nbsp;'}} />(<DataUnitTextField align="nostyle" value={item.dataunits.di.value_with_unit} si="mm" siLabel="mm" us="inch" usLabel="Inch" />)
                                    </div>
                                    <div className='hose-configuration-table-row-spacer' />
                                </For>
                                {/*<div className="hose-configuration-table-modal-item-custom" onClick={() => this.handleHoseTypeCustomSelection()}>
                                    <FormattedMessage id="hose_configuration_table_modal_custom" defaultMessage="Set-up your own hose diameter" />
                                </div>*/}
                            </div>
                        </When>
                        <When condition={ hoseTypeSelectionModal==MODAL_STEP_CUSTOM_TYPE}>
                            <div className="hose-configuration-table-modal-custom-type-container">

                                <DataUnitTextField value={customInputValue} onChange={this.handleCustomTypeInputChange} decimalPlaces={2} editable={true} className="hose-configuration-table-modal-custom-type-input" />

                                <ButtonBar>
                                    <Button backgroundColor={style.secondary2} textColor={style.white} onClick={this.handleSelectionModalCancel}><FormattedMessage id="button_cancel" defaultMessage="Cancel" /></Button>
                                    <Button onClick={this.handleHoseTypeCustomSelectionOK}><FormattedMessage id="button_ok" defaultMessage="OK" /></Button>
                                </ButtonBar>
                            </div>
                        </When>
                    </Choose>
                </Modal>
            </div>
        )
    }
}

// <input step="0.01" type="number" value={customInputValue} className="hose-configuration-table-modal-custom-type-input" onChange={this.handleCustomTypeInputChange} />

function mapStateToProps(state, props) {
    return {
        hoseTypes: getHoses(state),
        dataunits: getDataunitsBySymbol(state)
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(HoseConfigurationTable))
